import { useMemo, useState } from 'react';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import get from 'lodash/get';
import { Address2Configuration } from 'fieldFactory/input/components/Address2/types';
import { ControlledJSONSchemaFormInner } from 'react-hook-form-utils/ControlledJsonSchemaForm';

const addressVerification = require('../../../layout-editor/add-field/util/configuration_schemas/edit/addressVerification.json');

const useAddressConfigFields = ({ initialData }: { initialData: Partial<FormFieldUnion> }) => {
    const initialFieldConfig = get(initialData, 'params.configs.fieldConfig');
    const initialBpmFormConfig: Address2Configuration = useMemo(() => {
        try {
            return initialFieldConfig && JSON.parse(initialFieldConfig);
        } catch (e) {
            console.error(e);
            return {};
        }
    }, [initialFieldConfig]);

    const [addressFieldConfig, setAddressFieldConfig] = useState<Address2Configuration>(initialBpmFormConfig);

    const ConfigElement = (
        <ControlledJSONSchemaFormInner
            value={addressFieldConfig}
            asString={false}
            onChange={(value) => setAddressFieldConfig(value)}
            schema={addressVerification.schema}
            uiSchema={addressVerification.uiSchema}
        />
    );
    const addressFieldConfigStr = useMemo(() => JSON.stringify(addressFieldConfig), [addressFieldConfig]);
    return {
        addressFieldConfig: addressFieldConfigStr,
        ConfigElement,
    };
};

export default useAddressConfigFields;
