import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';
import isEmbeddedViewContext from 'fieldFactory/display/components/MultiCard/embeddedViewContext';
import { editViewInPlaceContext } from 'layout-editor/editViewInPlaceContext/editViewInPlaceContext';
import { useContext, useMemo } from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import { createSelector } from 'reselect';
import getViewConf from 'util/getViewConf';
import useViewConfig from 'util/hooks/useViewConfig';
import isOffline from 'util/isOffline';

export const getHideBackTo = (viewConfig: ViewConfig, viewName?: string): string | boolean => {
    return getViewConf(viewConfig, viewName)
        .mapNullable((pc: EntityViewConfig) => pc.hideBackTo)
        .getOrElse(false);
};

const getHideBackToSelector = <T extends { viewName: string; viewConfig?: ViewConfig }>() => {
    const hideBackToSelector = createSelector(
        (state: RootState, props: T) => props.viewConfig || state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        getHideBackTo,
    );
    return hideBackToSelector;
};
export const useHideBackTo = (viewName: string) => {
    const viewConfig = useViewConfig();
    const hideBackToSelector = useMemo(getHideBackToSelector, []);
    const hideBackTo = useAppSelector((state: RootState) => hideBackToSelector(state, { viewName, viewConfig }));
    const res = useSingleKeyCachingExpression(hideBackTo, undefined, false);
    return res;
};

export const HideBackToController: React.FC<{ children: JSX.Element; viewName: string }> = ({ children, viewName }) => {
    const hideBackTo = useHideBackTo(viewName);
    const isEmbeddedView = useContext(isEmbeddedViewContext);
    const isEditingViewInPlace = useContext(editViewInPlaceContext);
    if (hideBackTo || isOffline() || isEmbeddedView || isEditingViewInPlace?.editing) {
        return null;
    }
    return children;
};
