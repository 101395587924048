import React, { useRef, useState, useMemo, useEffect, useContext } from 'react';
import { EventOrValueHandler, WrappedFieldMetaProps } from 'redux-form';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { useDispatch } from 'react-redux';
import Downshift from 'downshift';
import { FieldTitle } from '../../aor/FieldTitle';
import {
    Paper,
    MenuItem,
    CircularProgress,
    makeStyles,
    FormControl,
    InputLabel,
    Chip,
    Input as StandardInput,
    FormHelperText,
    FilledInput,
    OutlinedInput,
    InputBaseProps,
    Tooltip,
} from '@material-ui/core';
import { fromNullable, fromPredicate, option } from 'fp-ts/lib/Option';
import { traverse } from 'fp-ts/lib/Array';
import uniq from 'lodash/uniq';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import { getRestUrl, getPluralName, getRelatedField } from 'components/generics/utils/viewConfigUtils';
import classnames from 'classnames';
import { createStyles, Theme } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { grey } from '@material-ui/core/colors';
import EntityAutocompleteDropdown from '../AutocompleteDropdown';
import { createSelector } from 'reselect';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { refreshContext } from 'components/generics/form/refreshContext';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import useTextFieldUtils from 'fieldFactory/input/hooks/useTextFieldUtils';
import isOffline from 'util/isOffline';
import MET2 from './Multiple';
import { getUseOldManyWidgetImplSelector } from 'util/applicationConfig';
import preprocessFilter from 'clients/utils/preprocessFilter';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
        },
        chipArea: {
            marginTop: 16,
            backgroundColor: grey[100],
        },
        root: {
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        inputUnderline: {
            // '&::after': {
            //     borderBottom: 0,
            // },
        },
        paper: {
            position: 'absolute',
            zIndex: 5000,
            marginTop: theme.spacing(-1),
            overflowY: 'scroll',
            maxHeight: 300,
            left: 0,
            minWidth: '100%',
        },
        popoverPaper: {
            maxHeight: 150,
        },
        paperTop: {
            position: 'absolute',
            zIndex: 5000,
            overflowY: 'scroll',
            maxHeight: 150,
            left: 0,
            minWidth: '100%',
            bottom: `calc(100% - ${theme.spacing(4)}px)`, // fixed disance from top
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        iconButton: {
            position: 'absolute',
            right: 0,
            top: theme.spacing(2),
            height: 30,
            width: 30,
            padding: 0,
        },
        loadingSpinner: {
            position: 'absolute',
            right: 2,
            bottom: 4,
            padding: 0,
        },
    }),
);
export interface MultipleEntityTypeaheadCommonProps {
    randomizeNameAsBrowserAutocompleteHack?: boolean;
    expansions?: string[];
    filterString?: string;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    isPopover?: boolean;
    label?: string;
    isRequired?: boolean;
    renderLabel?: boolean;
    emptyText?: string;
    disabled?: boolean;
    allowEmptyQuery: boolean;
    input: {
        onBlur: EventOrValueHandler<string[] | null>;
        value?: string[] | null;
    };
    source: string;
    reference: string;
    meta: WrappedFieldMetaProps;
    record?: {};
    search?: string | boolean;
    create?: string | boolean;
    resultSize?: number;
}
export type MultipleEntityTypeaheadProps = MultipleEntityTypeaheadCommonProps &
    (
        | {
              mode: 'SetRelationshipOnAdd';
              resource: string;
              record: { id: string };
          }
        | { mode: 'IdsList' }
    );

export const createCurrentlySelectedEntitiesSelector = () => {
    return createSelector(
        (state: RootState, args: { reference: string; value: string[] }) => args.value,
        (state: RootState, args: { reference: string; value: string[] }) => state.admin.entities[args.reference],
        (value, refEntities) => {
            return fromPredicate<string[]>(Boolean)(value)
                .chain((ids) =>
                    fromNullable(refEntities).chain<EntityRecord[]>((e) => {
                        const r = traverse(option)<string, EntityRecord>(ids, (id) => fromNullable(e[id]));
                        return r;
                    }),
                )
                .getOrElse([]);
        },
    );
};

const addEntityToStore = (record: { id: string; entityType: string }) => ({
    type: 'ADD_ENTITY_TO_STORE',
    payload: {
        data: {
            entities: {
                [record.entityType]: {
                    [record.id]: record,
                },
            },
        },
    },
});
type EntityRecord = RootState['admin']['entities'][0][0];
const MultipleEntityTypeaheadComponent: React.FC<MultipleEntityTypeaheadProps> = (props) => {
    const {
        label,
        randomizeNameAsBrowserAutocompleteHack = true,
        input: { value = [], onBlur },
        meta,
        expansions,
        disabled,
        renderLabel = true,
        emptyText = 'None Selected',
        isPopover,
        reference,
        dropdownPosition = 'below',
        source,
        allowEmptyQuery,
        filterString,
        tooltipText,
        resultSize,
    } = props;
    const { touched, error } = meta;
    const uniqueNameToThrowOffChromeAutoFill = useRef(new Date().toISOString());
    const refresh = useContext(refreshContext);
    const dispatch = useDispatch();
    const viewConfig = useAppSelector((state: RootState) => state.viewConfig);
    const classes = useStyles(props);
    const restUrl = useAppSelector(getRestUrl(props.reference));
    const refEntityDisplayNamePlural = useAppSelector((state: RootState) => getPluralName(state.viewConfig, reference));
    const getcurrentlySelectedEntities = useMemo(createCurrentlySelectedEntitiesSelector, []);
    const currentlySelectedEntities = useAppSelector((state: RootState) =>
        getcurrentlySelectedEntities(state, { reference, value }),
    );
    const errorMessageId = useRef(uniqueId('entity-typeahead'));
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const paperClass = dropdownPosition === 'below' ? classes.paper : classes.paperTop;
    const filterObject = getFilterFromFilterString(filterString);
    const newFilter = useMemo(() => preprocessFilter(filterObject, viewConfig), [filterObject, viewConfig]);
    useEffect(() => {
        if (Array.isArray(value)) {
            const notFoundIds =
                expansions && expansions.length > 0
                    ? value
                    : value.filter((id) => !currentlySelectedEntities.find((e) => e.id === id));
            if (notFoundIds.length === 0) {
                return;
            }
            setLoading(true);
            const $completionStream = Observable.create((observer) => {
                notFoundIds.forEach((id) => {
                    dispatch(
                        crudGetOneAction({
                            id,
                            resource: reference,
                            appendExpansions: expansions,
                            view: -1,
                            cb: () => {
                                observer.next();
                            },
                            errorsCbs: {
                                '*': () => {
                                    observer.next();
                                },
                            },
                        }),
                    );
                });
            }).pipe(take(notFoundIds.length));
            const subscription = $completionStream.subscribe(
                () => {},
                () => {
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                },
            );
            return () => {
                subscription.unsubscribe();
            };
        }
    }, []); // eslint-disable-line
    const { forceLabelShrink, fieldVariant } = useContext(themeOverrideContext);
    const Input = fieldVariant === 'filled' ? FilledInput : fieldVariant === 'outlined' ? OutlinedInput : StandardInput;
    const { evaluateFormattedMessage, translate } = useEvaluateFormattedMessage();
    const { InputPropsClasses, createInputLabelProps, createFormHelperTextProps, muiErrorProp, helperText } =
        useTextFieldUtils(meta);
    const El = (
        <EntityInspect
            reference={reference}
            formId={`frommultipleentitytypeahead ${source}`}
            renderComponent={(args) => (
                <div className={classes.root}>
                    <Downshift
                        inputValue={inputValue}
                        stateReducer={(state, changes) => {
                            if (changes.type === '__autocomplete_click_item__') {
                                return {
                                    ...changes,
                                    isOpen: true,
                                };
                            }
                            return changes;
                        }}
                        selectedItem={currentlySelectedEntities || []}
                        onSelect={(record, ds) => {
                            if (record) {
                                // make record immediately available
                                dispatch(fetchStart());
                                dispatch(addEntityToStore(record));
                                dispatch(fetchEnd());

                                setInputValue('');
                                const addValue = () => {
                                    const newValue = uniq([...value, record.id]);
                                    // IF we have expansions, we will have to fetch...
                                    if (expansions && expansions.length > 0) {
                                        setLoading(true);
                                        setImmediate(() => {
                                            dispatch(
                                                crudGetOneAction({
                                                    id: record.id,
                                                    resource: reference,
                                                    view: -1,
                                                    appendExpansions: expansions,
                                                    cb: () => {
                                                        setImmediate(() => {
                                                            setLoading(false);
                                                            onBlur(newValue);
                                                        });
                                                    },
                                                    errorsCbs: {
                                                        '*': () => {
                                                            setLoading(false);
                                                            onBlur(newValue);
                                                        },
                                                    },
                                                }),
                                            );
                                        });
                                    } else {
                                        onBlur(newValue);
                                    }
                                };
                                if (props.mode === 'SetRelationshipOnAdd') {
                                    const fieldOpposite = `${getRelatedField(
                                        viewConfig,
                                        props.resource,
                                        props.source.slice(0, -3),
                                    )}Ids`;

                                    setLoading(true);
                                    dispatch(
                                        crudGetOneAction({
                                            resource: reference,
                                            id: record.id,
                                            view: -1,
                                            cb: (id, newData) => {
                                                // new we use the data with the expansion to edit
                                                dispatch(
                                                    crudUpdateAction({
                                                        resource: reference,
                                                        data: {
                                                            id,
                                                            partialUpdate: true,
                                                            [fieldOpposite]: uniq([
                                                                ...(newData[fieldOpposite] || []),
                                                                props.record.id,
                                                            ]),
                                                        },
                                                        previousData: newData,
                                                        cb: () => {
                                                            setLoading(false);
                                                            refresh();
                                                        },
                                                        errorsCbs: {
                                                            '*': () => {
                                                                setLoading(false);
                                                            },
                                                        },
                                                    }),
                                                );
                                            },
                                            appendExpansions: [fieldOpposite],
                                            errorsCbs: {
                                                '*': () => {
                                                    setLoading(false);
                                                },
                                            },
                                        }),
                                    );
                                    // set relationship on opposite side, and refetch base?
                                    // duplicate manymany behavior.
                                } else {
                                    addValue();
                                }
                            } else {
                                // onBlur(null);
                            }
                        }}
                        itemToString={(record) => record.title}
                    >
                        {({
                            inputValue,
                            getInputProps,
                            getLabelProps,
                            getMenuProps,
                            getItemProps,
                            setItemCount,
                            clearItems,
                            selectedItem,
                            highlightedIndex,
                            isOpen,
                            getRootProps,
                            openMenu,
                            clearSelection,
                        }) => {
                            const InputProps = getInputProps(
                                (() => {
                                    const _inputProps = {
                                        'aria-errormessage': touched && error ? errorMessageId.current : undefined,
                                        placeholder: selectedItem ? selectedItem.title : emptyText,
                                        disabled,
                                        style: {
                                            textOverflow: 'ellipsis',
                                            marginRight: '60px',
                                        },
                                        onChange: (e) => {
                                            setInputValue(e.target.value);
                                        },
                                        onFocus: () => !disabled && openMenu(),
                                        onClick: () => !disabled && openMenu(),
                                        autoComplete: 'never',
                                    };
                                    if (randomizeNameAsBrowserAutocompleteHack) {
                                        _inputProps['name'] = uniqueNameToThrowOffChromeAutoFill.current;
                                    }
                                    return _inputProps;
                                })(),
                            );
                            return (
                                <FormControl
                                    variant={fieldVariant}
                                    key={fieldVariant}
                                    fullWidth={true}
                                    margin="none"
                                    error={muiErrorProp}
                                    disabled={disabled}
                                >
                                    {renderLabel && (
                                        <InputLabel
                                            {...createInputLabelProps()}
                                            focused={false}
                                            shrink={forceLabelShrink}
                                            {...getLabelProps()}
                                        >
                                            <FieldTitle label={label} isRequired={false} />
                                        </InputLabel>
                                    )}
                                    <div className={classes.chipArea}>
                                        {currentlySelectedEntities.map(({ title, id }) => (
                                            <Chip
                                                key={id}
                                                tabIndex={0}
                                                aria-roledescription="Button Press Delete key to delete"
                                                label={title || id}
                                                className={classes.chip}
                                                onClick={() => args.selectId(id)}
                                                onDelete={() => {
                                                    if (!disabled) {
                                                        // ajax delete if on entity
                                                        if (props.mode === 'SetRelationshipOnAdd') {
                                                            setLoading(true);
                                                            dispatch(
                                                                crudUpdateAction({
                                                                    resource: props.resource,
                                                                    data: {
                                                                        id: props.record.id,
                                                                        partialUpdate: true,
                                                                        [source]: (value || []).filter(
                                                                            (_id) => _id !== id,
                                                                        ),
                                                                    },
                                                                    previousData: props.record,
                                                                    cb: () => {
                                                                        setLoading(false);
                                                                        setImmediate(() => {
                                                                            refresh();
                                                                        });
                                                                    },
                                                                    errorsCbs: {
                                                                        '*': () => {
                                                                            setLoading(false);
                                                                        },
                                                                    },
                                                                }),
                                                            );
                                                        } else {
                                                            onBlur(value.filter((lid) => lid !== id));
                                                        }
                                                    }
                                                }}
                                                deleteIcon={disabled ? <span style={{ width: '.5em' }} /> : undefined}
                                            />
                                        ))}
                                    </div>
                                    <div {...getRootProps()} className={classes.container}>
                                        <Input
                                            fullWidth={true}
                                            classes={{
                                                ...InputPropsClasses,
                                                root: classnames(InputPropsClasses.root, classes.inputRoot),
                                                underline: classnames(
                                                    InputPropsClasses.underline,
                                                    classes.inputUnderline,
                                                ),
                                            }}
                                            inputProps={{
                                                ...InputProps,
                                                'aria-label': label,
                                            }}
                                            {...(InputProps as any)}
                                        />
                                        {muiErrorProp && (
                                            <FormHelperText
                                                error={muiErrorProp}
                                                {...createFormHelperTextProps(InputProps as InputBaseProps)}
                                            >
                                                {helperText}
                                            </FormHelperText>
                                        )}
                                        {isOffline()
                                            ? null
                                            : loading && (
                                                  <CircularProgress
                                                      style={{ height: 24, width: 24 }}
                                                      className={classes.loadingSpinner}
                                                  />
                                              )}
                                        <div {...getMenuProps()}>
                                            {isOpen && (
                                                <Paper
                                                    className={
                                                        isPopover
                                                            ? classnames(paperClass, classes.popoverPaper)
                                                            : paperClass
                                                    }
                                                    square={true}
                                                >
                                                    {(() => {
                                                        if (!inputValue && !allowEmptyQuery) {
                                                            return (
                                                                <MenuItem component="div" aria-live="polite" disabled>
                                                                    You have to enter a search query
                                                                </MenuItem>
                                                            );
                                                        }

                                                        return (
                                                            <EntityAutocompleteDropdown
                                                                selectedItem={selectedItem}
                                                                highlightedIndex={highlightedIndex}
                                                                restUrl={restUrl}
                                                                refEntityDisplayNamePlural={refEntityDisplayNamePlural}
                                                                getItemProps={getItemProps}
                                                                inputValue={inputValue}
                                                                filter={newFilter}
                                                                setItemCount={setItemCount}
                                                                resultSize={resultSize}
                                                            />
                                                        );
                                                    })()}
                                                </Paper>
                                            )}
                                        </div>
                                    </div>
                                </FormControl>
                            );
                        }}
                    </Downshift>
                </div>
            )}
        />
    );
    if (tooltipText) {
        return (
            <Tooltip title={tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                <div>{El}</div>
            </Tooltip>
        );
    }
    return El;
};

const Combined = (props) => {
    const useOld = useAppSelector(getUseOldManyWidgetImplSelector);
    if (useOld) {
        return <MultipleEntityTypeaheadComponent {...props} />;
    }
    return <MET2 {...props} />;
};
export default Combined;
