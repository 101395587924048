import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import RuntimeValidatedGroupedBarChart, { GroupedBarData } from 'dashboard2/widgets/report2/GroupedBar/GroupedBarChart';
import { useMemo } from 'react';

const TemplatableGroupedBarChart = (props: { title: string; jsonData: string }) => {
    const parsedJSONData = useMemo(() => {
        try {
            return {
                type: 'success' as const,
                data: JSON.parse(props.jsonData) as GroupedBarData,
            };
        } catch (e) {
            return {
                type: 'failure' as const,
                message: e.message,
            };
        }
    }, [props.jsonData]);
    if (parsedJSONData.type === 'failure') {
        return (
            <Alert severity="error">
                <AlertTitle>Configuration Error</AlertTitle>
                <p>Expected data-json to be a JSON string. Error:</p>
                <pre>{parsedJSONData.message}</pre>
                <p>Received:</p>
                <pre>{props.jsonData}</pre>
            </Alert>
        );
    }
    return <RuntimeValidatedGroupedBarChart titleText={props.title} groupedBarData={parsedJSONData.data} />;
};

export default TemplatableGroupedBarChart;
