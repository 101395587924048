import React, { useState } from 'react';
import { FormControlLabel, Switch, TextareaAutosize } from '@material-ui/core';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';

const ModedHtmlEditor: React.FC<{
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement> | string) => void;
    onBlur: React.FocusEventHandler<HTMLTextAreaElement>;
}> = (props) => {
    const [wysiwygMode, setWysiwygMode] = useState(true);
    return (
        <div>
            <FormControlLabel
                control={
                    <Switch
                        color="primary"
                        checked={wysiwygMode}
                        onChange={(e) => setWysiwygMode(e.target.checked)}
                        defaultChecked
                    />
                }
                label="Wysiwyg"
            />
            {wysiwygMode ? (
                <TinyMCEEditor
                    init={{
                        forced_root_block: false as any,
                        branding: false,
                        plugins: 'autoresize',
                    }}
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    value={props.value}
                    onEditorChange={(e) => props.onChange(e)}
                />
            ) : (
                <div>
                    <TextareaAutosize
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        style={{ width: '100%' }}
                    />
                </div>
            )}
        </div>
    );
};
export default ModedHtmlEditor;
