import { getLocation } from 'connected-react-router';
import React from 'react';
import { useAppSelector } from 'reducers/rootReducer';

const HideOnRoute: React.FC<{
    routeRegex: string;
}> = (props) => {
    const location = useAppSelector(getLocation);
    const rx = new RegExp(props.routeRegex);
    if (rx.test(location.pathname)) {
        return null;
    }
    return <>{props.children}</>;
};

export default HideOnRoute;
