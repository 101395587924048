import React, { FunctionComponent } from 'react';
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles, Theme, createStyles, Button } from '@material-ui/core';
import useViewConfig from 'util/hooks/useViewConfig';
import AutocompleteSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';
import FieldPath from 'layout-editor/add-field/components/FieldPath';
import { useValidationResolver } from '../ViewDefinitionConfig/EditExpression';
import { UpdateMeta } from 'expression-tester/entity-form/ViewDefinitionConfig/EditExpression';
import { useFormFieldWarning } from './useFormFieldWarning';
import getTypeAtCursor from 'ace-editor/util/getTypeAtCursor';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
        warning: {
            color: theme.palette.warning.dark,
        },
    }),
);

export interface ExpressionData {
    expression: string;
    compileSuccess: boolean;
    methodsAndFunctions: string[];
    fieldPath: string;
}

interface EditExpressionProps {
    viewName?: string;
    initialValues?: Pick<ExpressionData, 'expression' | 'fieldPath'> &
        Partial<Pick<ExpressionData, 'compileSuccess' | 'methodsAndFunctions'>>;
    onSubmit: (data: ExpressionData) => void;
    widgetMapsToSourceInsteadOfId?: boolean;
}

// for Entity forms only
const DefaultValueExpressionForm: FunctionComponent<EditExpressionProps> = (props) => {
    const classes = useStyles(props);
    const { viewName, onSubmit } = props;
    const viewConfig = useViewConfig();
    const rootEntity = viewConfig.views[viewName].entity;
    const methods = useFormContext<ExpressionData>();
    const { errors } = methods;
    const { warning, triggerWarning } = useFormFieldWarning<FormData, string, string | undefined>({
        name: 'expression',
        validate(value) {
            if (errors && Object.keys(errors).length > 0) {
                return;
            }

            const resolvedType = getTypeAtCursor(viewConfig, rootEntity)(value);
            if (resolvedType._type === 'map' && '__className' in resolvedType) {
                return `This expression does not evaluate to a primitive value.`;
            }
        },
    });
    return (
        <>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <label>
                    <b>Expression *</b>
                    <Controller
                        rules={{ required: 'Provide an expression' }}
                        as={AutocompleteSpelEditor as any}
                        defaultValue={props.initialValues?.['expression']}
                        name="expression"
                        control={methods.control as any}
                        hideDocs
                        onChange={triggerWarning}
                    />
                </label>
                <ErrorMessage errors={errors} name="expression" />
                <pre className={classes.error}>{errors['methodsAndFunctions']}</pre>
                <pre className={classes.error}>{errors['fieldsRequired']}</pre>
                {warning && <pre className={classes.warning}>{warning}</pre>}
                <FieldPath
                    required
                    allowPropertiesOnManys={false}
                    defaultValue={props.initialValues?.fieldPath}
                    resource={rootEntity}
                    depth={3}
                    appendId
                />
                <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                    Save
                </Button>
            </form>
            <UpdateMeta />
        </>
    );
};

const WrappedDefaultValueForm: FunctionComponent<{
    viewName: string;
    initialValues?: Pick<ExpressionData, 'expression' | 'fieldPath'> &
        Partial<Pick<ExpressionData, 'compileSuccess' | 'methodsAndFunctions'>>;
    onSubmit: (data: ExpressionData) => void;
}> = ({ viewName, initialValues, onSubmit }) => {
    const viewConfig = useViewConfig();
    const resolver = useValidationResolver(viewName, viewConfig);
    const methods = useForm<ExpressionData>({
        resolver,
        defaultValues: initialValues,
        mode: 'onChange',
    });

    return (
        <FormProvider {...methods}>
            <DefaultValueExpressionForm viewName={viewName} initialValues={initialValues} onSubmit={onSubmit} />
        </FormProvider>
    );
};
export default WrappedDefaultValueForm;
