import React, { useContext, useMemo } from 'react';
import NumberField from './components/aor/NumberField';
import EmailField from './components/aor/EmailField';
import BooleanField from './components/aor/BooleanField';
import TextField from './components/aor/TextField';
import get from 'lodash/get';
import grey from '@material-ui/core/colors/grey';
import ReactOverflowTooltip from 'react-overflow-tooltip';
import ReferenceField from './components/aor/ReferenceField';
import ListSelect from '../input/components/ListSelect';
import {
    getValueSetForFieldExpr,
    getRefEntityName,
    applyNoWrap,
    fieldIsSortable,
    getCustomViewName,
    someEntityInPathIsDynamic,
} from '../../components/generics/utils/viewConfigUtils/index';
import ValueSetField from './components/ValueSetField';
import ValueSetListField from './components/ValueSetListField';
import * as fieldTypes from '../fieldTypes';
import DateField from './components/DateField';
import { TextField as MITF } from '@material-ui/core';
import DateTimeField from './components/DateTimeField';
import RefManyMultiSelectIdsList from '../input/components/RefmanyMultiselectIdsList';
import expressionElement from './expressionElement';
import {
    isDisabledEntityField,
    /* getHtmlId, */ getLabelledBy,
    getConfigProperty,
    isFieldFromFlowable,
    getImageWidth,
    simpleGetConfProperty,
} from '../input/fieldUtils';
import FieldSubscriber from './experimental/FieldSubscriber';
import MultiCheckbox from '../input/components/MultiCheckbox';
import ValuesetOneCheckbox from '../input/components/ValuesetOneCheckbox';
import getDisplayAddress from '../input/components/Address/getDisplayAddress';
import FormHelperTextNoErr from '../input/components/TextField/FormHelperTextNoErr';
import { fromNullable, tryCatch } from 'fp-ts/lib/Either';
import Checkbox from '../display/components/DisplayCheckbox';
import { rowIdContext } from '../../components/generics/genericList/renderList';
import Currency from './components/CurrencyDisplay';
import Percentage from './components/PercentDisplay';
import { getAriaSupportProperties } from '../ariaSupport';
import {
    getShowViewName,
    getEditViewName,
    getViewName,
    getOverrideNavButtonHtml,
} from 'fieldFactory/input/fieldUtils/reference';
import DmsDoc, { DmsImageDoc } from 'fieldFactory/input/components/DmsDoc';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import withTooltip from 'fieldFactory/util/withTooltip';
import EvaluateInEntityContext from 'expressions/components/EvaluateExpressionInEntityContext';
import formTypeContext from 'components/generics/form/formTypeContext';
import { withEvaluatedFilter } from 'fieldFactory/input/inputHigherOrderComponents/withEvaluatedFilter';
import isOffline from 'util/isOffline';
import getRenderMultiCardList from './components/MultiCard/renderMultiCardList';
import DisplayJsonSchemaForm from 'fieldFactory/input/components/JsonSchemaForm/DisplayJsonSchemaForm';
import XManyComponent from './components/RefmanyMultiselect/XManyComponent';
import NullableBooleanField from './components/NullableBooleanField';
import { getValuesetGroup } from 'fieldFactory/input/fieldUtils/valueset';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { css } from 'emotion';
import { formContext } from 'components/generics/form/EntityFormContext/Show';
import isPlainObject from 'lodash/isPlainObject';
import ComponentPlaceholder from './components/ComponentPlaceholder';
import BpmFormField from 'fieldFactory/input/components/bpmForm/BpmFormField';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import { useAppSelector } from 'reducers/rootReducer';

const useAvailableConceptsForShowField = (currentValue, plainSource /** plainSource is sans 'Id' or 'Ids' */) => {
    const sfc = useContext(formContext);
    const conceptsEntry = sfc.valuesetFieldAvailableConceptIds?.[plainSource];
    const conceptIds = useMemo(() => {
        const conceptIds = isPlainObject(conceptsEntry) ? Object.keys(conceptsEntry) : undefined;
        if (conceptIds && currentValue && !conceptsEntry[currentValue]) {
            return [...conceptIds, currentValue];
        }
        return conceptIds;
    }, [conceptsEntry, currentValue]);
    return conceptIds;
};

const Present =
    (fieldDefinition) =>
    ({ addUnderlineAndMinHeight, hoverableEllipses } = { addUnderlineAndMinHeight: true, hoverableEllipses: true }) =>
    (BaseComponent) =>
    (props) => {
        const u = undefined;
        const toReturn = (
            <div>
                <rowIdContext.Consumer>
                    {(id) => (
                        <div
                            /*
                        REMOVED_TEST_ID
                        id={
                        id ? `${getHtmlId(fieldDefinition)}:${id}` : getHtmlId(fieldDefinition) */
                            {...props.ariaInputProps}
                            style={{
                                overflow: hoverableEllipses ? 'hidden' : u,
                                textOverflow: hoverableEllipses ? 'ellipsis' : u,
                                borderBottom: addUnderlineAndMinHeight
                                    ? `1px ${props.disabled ? 'dotted' : 'solid'} ${grey[400]}`
                                    : u,
                                minHeight: addUnderlineAndMinHeight ? '1.3em' : u,
                            }}
                        >
                            <BaseComponent {...props} />
                        </div>
                    )}
                </rowIdContext.Consumer>
                {fieldDefinition.description && (
                    <FormHelperTextNoErr>{fieldDefinition.description}</FormHelperTextNoErr>
                )}
            </div>
        );
        if (hoverableEllipses) {
            return <ReactOverflowTooltip title={get(props.record, props.source)}>{toReturn}</ReactOverflowTooltip>;
        }
        return toReturn;
    };

const generateField = (fieldDefinition, viewConfig, c, liveProps) => {
    const t = fieldTypes;
    const ariaSupportProps = getAriaSupportProperties(fieldDefinition.row, fieldDefinition.column, fieldDefinition);
    const ariaInputProps = {};
    let renderLabel = true;
    if (ariaSupportProps && ariaSupportProps.labelledBy) {
        ariaInputProps['aria-labelledby'] = ariaSupportProps.labelledBy;
        renderLabel = false;
    }
    let RComponent;

    const parsedConfig = (() => {
        const { config } = fieldDefinition;
        try {
            return config ? JSON.parse(config) : null;
        } catch (e) {
            console.log('bad config on ' + fieldDefinition.name + ' field: ' + JSON.stringify(config));
            console.error(e);
            return null;
        }
    })();
    let propConfiguration = {
        'data-originaldefinition': fieldDefinition['data-originaldefinition'],
        addLabel: renderLabel,
        row: fieldDefinition.row,
        column: fieldDefinition.column,
        span: fieldDefinition.span,
        source: fieldDefinition.name,
        label: fieldDefinition.label,
        ariaInputProps,
        sortable:
            parsedConfig?.sortable ??
            (fieldDefinition.configuredEntity &&
                fieldIsSortable(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name, 'POP_LAST') &&
                !someEntityInPathIsDynamic(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name)),
        cellAlign: parsedConfig?.cellAlign,
    };
    propConfiguration.inlineEditable = getConfigProperty('inlineEditable')(fieldDefinition).getOrElse(false);

    if (fieldDefinition.htmlConfig) {
        propConfiguration = {
            ...propConfiguration,
            alignSelf: true,
            addLabel: false,
        };
        return expressionElement(fieldDefinition, { ...propConfiguration, liveProps });
    }
    if (fieldDefinition.type === fieldTypes.ADDRESS_VERIFICATION) {
        return getDisplayAddress(propConfiguration, fieldDefinition, liveProps);
    }

    const wrap = Present(fieldDefinition)(c);
    switch (fieldDefinition.type) {
        case t.HTML_EXPRESSION:
            /*
            console.error('HTML expression field without an htmlConfig given: ', fieldDefinition);
            return <br />;
            */
            propConfiguration = {
                ...propConfiguration,
                alignSelf: true,
                addLabel: false,
            };
            RComponent = (props) =>
                expressionElement(fieldDefinition, {
                    ...propConfiguration,
                    ...props,
                    ...liveProps,
                    id: `${fieldDefinition._dataSource === DataSource.FLOWABLE ? 'flowable' : ''}expression:r${
                        fieldDefinition.row
                    }c${fieldDefinition.column}`,
                });
            break;
        case t.COMPONENT: {
            RComponent = ComponentPlaceholder;
            break;
        }
        case t.VALUESET_SUGGEST:
        case t.TEXT:
        case t.MULTILINE_TEXT: {
            RComponent = wrap(TextField);
            if (fieldDefinition.type === t.TEXT || fieldDefinition.type === t.VALUESET_SUGGEST) {
                const translate = getConfigProperty('translate')(fieldDefinition).getOrElse(false);
                propConfiguration.translate = translate;
            }
            const noWrap = applyNoWrap(fieldDefinition.config);
            const lineWrap = noWrap ? 'pre' : 'pre-wrap';
            const overflowOption = noWrap ? 'auto' : 'visible';
            const display = noWrap ? 'block' : 'unset';
            const overflowWrap = noWrap ? 'normal' : liveProps.isForSearch ? 'anywhere' : 'break-word';
            propConfiguration = {
                ...propConfiguration,
                elStyle: { display, overflow: overflowOption, whiteSpace: lineWrap, overflowWrap },
            };
            break;
        }
        case t.BPM_FORM_BUILDER: {
            // Doesn't really make sense on a Show view.
            RComponent = () => null;
            break;
        }
        case t.BPM_FORM: {
            RComponent = BpmFormField;
            const readSchemasFromField = simpleGetConfProperty('readSchemasFromField', '')(fieldDefinition);

            const bpmFormDefinition = simpleGetConfProperty('bpmFormDefinition', '')(fieldDefinition);

            propConfiguration = {
                ...propConfiguration,
                disabled: true,
                from: isFieldFromFlowable(fieldDefinition) ? 'Flowable' : 'Entity',
                readSchemasFromField,
                bpmFormDefinition:
                    bpmFormDefinition &&
                    (() => {
                        try {
                            return JSON.parse(bpmFormDefinition)?.definition;
                        } catch (e) {
                            console.error(e);
                            return '';
                        }
                    })(),
            };
            break;
        }
        case t.PERCENT: {
            RComponent = wrap(Percentage);
            break;
        }
        case t.CURRENCY:
            propConfiguration = {
                ...propConfiguration,
                disabled: true,
            };
            if (propConfiguration.addLabel) {
                propConfiguration.ariaInputProps['aria-label'] = propConfiguration.label;
            }
            RComponent = Currency;
            break;
        case t.NULLABLE_BOOLEAN:
            RComponent = NullableBooleanField;
            break;
        case t.INTEGER:
        case t.FLOAT:
            RComponent = wrap(NumberField);
            break;
        case t.TOGGLE:
            RComponent = wrap(BooleanField);
            propConfiguration = {
                ...propConfiguration,
                elStyle: { margin: 'unset' },
            };
            break;
        case t.CHECKBOX:
            propConfiguration = {
                ...propConfiguration,
                elStyle: { margin: 'unset' },
                addLabel: false,
                addUnderlineAndMinHeight: false,
                hideLabel: c.hideCheckboxLabel,
            };
            RComponent = Present(fieldDefinition)({ addUnderlineAndMinHeight: false, getOwnData: true })(Checkbox);
            break;
        case t.UST_DAY:
        case t.DATE:
            RComponent = wrap(DateField);
            break;
        case t.DATETIME:
            RComponent = wrap(DateTimeField);
            break;
        case t.ZONE_DATE:
            RComponent = (props) => <div>ZONE_DATE is unsupported.</div>;
            break;
        case t.IMAGE:
        case t.FILE_UPLOAD:
            RComponent = fieldDefinition.type === t.IMAGE ? DmsImageDoc : DmsDoc;
            propConfiguration.imageWidth = getImageWidth(fieldDefinition);
            propConfiguration.type = 'Display(Entity)';
            break;
        case t.EMAIL:
            RComponent = wrap(EmailField);
            break;
        case t.VALUESET_SELECT:
            RComponent = wrap(ValueSetField);
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                valueSetCode: getValueSetForFieldExpr(
                    viewConfig,
                    fieldDefinition.configuredEntity,
                    fieldDefinition.name,
                ),
                source: `${fieldDefinition.name}Id`,
            };
            break;
        case t.VALUESET_CHECKBOX: {
            RComponent = (props) => {
                const currentValue = get(props.record, props.source);
                const plainSource = props.source?.endsWith('Id') ? props.source.slice(0, -2) : props.source;
                const conceptIds = useAvailableConceptsForShowField(currentValue, plainSource);
                return <ValuesetOneCheckbox {...props} conceptIds={conceptIds} />;
            };
            const confEither = fromNullable({})(fieldDefinition.config).chain((conf) =>
                tryCatch(() => JSON.parse(conf)),
            );
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                labelledBy: getLabelledBy(fieldDefinition),
                addLabel: false, // label is part of the display here.,
                renderLabel: liveProps.isForShow ? renderLabel : false,
                sortable: false,
                valueSetCode: getValueSetForFieldExpr(
                    viewConfig,
                    fieldDefinition.configuredEntity,
                    fieldDefinition.name,
                ),
                group: getValuesetGroup(fieldDefinition),
                source: `${fieldDefinition.name}Id`,
                disabled: true,
                direction: confEither.map((conf) => conf.direction).getOrElse(undefined),
            };
            break;
        }
        case t.VALUESET_MULTISELECT:
            RComponent = wrap(ValueSetListField);
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                sortable: false,
                valueSetCode: getValueSetForFieldExpr(
                    viewConfig,
                    fieldDefinition.configuredEntity,
                    fieldDefinition.name,
                ),
                source: `${fieldDefinition.name}Ids`,
            };
            break;
        case t.REFONE_JOIN_SELECT:
        case t.ENTITY_TYPEAHEAD:
        case t.REFONE_SELECT: {
            const refEntityName = getRefEntityName(
                viewConfig,
                fieldDefinition.configuredEntity,
                fieldDefinition.name,
                'POP_LAST',
            );

            // const refEntityLabel = getRefEntityLabel(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name);
            propConfiguration = {
                fetchOwnData: false,
                ...propConfiguration,
                reference: refEntityName,
                source: `${fieldDefinition.name}Id`,
                allowEmpty: true,
            };
            const openTo = getConfigProperty('openTo')(fieldDefinition).toUndefined();

            const disabledDisplayHtml = getConfigProperty('disabledDisplayHtml')(fieldDefinition).toUndefined();

            propConfiguration.showViewName = getShowViewName(fieldDefinition);
            if (isOffline()) {
                propConfiguration.editViewName = -1;
            } else {
                propConfiguration.editViewName = getEditViewName(fieldDefinition);
            }

            if (disabledDisplayHtml) {
                propConfiguration.addLabel = false;
                RComponent = (props) => {
                    return (
                        <ReferenceField
                            {...props}
                            openTo={openTo}
                            linkType="popover"
                            renderButton={({ onClick }) => (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onClick();
                                    }}
                                    className={css`
                                        background-color: Transparent;
                                        background-repeat: no-repeat;
                                        border: none;
                                        cursor: pointer;
                                        overflow: hidden;
                                        outline: none;
                                    `}
                                >
                                    <SafeHtmlAsReact html={disabledDisplayHtml} />
                                </button>
                            )}
                        />
                    );
                };
            } else if (c.disabledInputField) {
                // Since it's disabled (meaning, on an edit/create page),
                // we need to provide our own 'underline' to the component
                // (on show views underlines are added to all inputs by default.)
                RComponent = wrap((props) => (
                    <ReferenceField
                        {...props}
                        openTo={openTo}
                        linkType="popover"
                        renderField={({ record, source }) => (
                            <MITF
                                margin="none"
                                InputLabelProps={{ disabled: true }}
                                value={record && record.title}
                                disabled
                                fullWidth
                            />
                        )}
                    />
                ));
            } else {
                // don't provide underline- use Aor TextField for now.
                RComponent = wrap((props) => (
                    <ReferenceField
                        {...props}
                        openTo={openTo}
                        linkType={
                            !liveProps.isForSearch
                                ? 'popover' // <- in all views expect list view table cells
                                : openTo === 'show' // respect if show is specifically designated
                                ? 'show'
                                : 'editIfPermittedOtherwiseShow' //otherwise link to the edit view if we have permission
                        }
                        renderField={({ record, source }) => (
                            <React.Fragment>
                                <TextField record={record} source="title" />
                                <span className="casetivity-off-screen">Opens a modal dialog</span>
                            </React.Fragment>
                        )}
                    />
                ));
            }

            break;
        }
        case t.DMS_DOCUMENT: {
            RComponent = DmsDoc;
            propConfiguration.type = 'Display(Entity)';
            break;
        }
        case t.LIST: {
            RComponent = ListSelect;
            propConfiguration = {
                ...propConfiguration,
                ...fieldDefinition.params,
                disabled: true,
            };
            break;
        }
        case t.MULTI_CARD: {
            const config = {
                viewName: getViewName(fieldDefinition),
                showViewName: getShowViewName(fieldDefinition),
                editViewName: getEditViewName(fieldDefinition),
                hasCreate: getConfigProperty('hasCreate')(fieldDefinition).getOrElse(false),
                hasEdit: getConfigProperty('hasEdit')(fieldDefinition).getOrElse(false),
                label: propConfiguration.label,
            };
            propConfiguration.noEmptyTable = true;
            propConfiguration.overrideRenderList = getRenderMultiCardList(config);
            propConfiguration.titleElement = <h2>{propConfiguration.label}</h2>;
        }
        // eslint-disable-next-line no-fallthrough
        case t.INLINE_MANY:
        case t.REFMANY_JOIN_MULTISELECT:
        case t.REFMANY_MULTISELECT: {
            const filter = getConfigProperty('filter')(fieldDefinition).toUndefined();
            const hasCreate = getConfigProperty('hasCreate')(fieldDefinition).getOrElse(false);
            const openTo = getConfigProperty('openTo')(fieldDefinition).getOrElse('show');
            const noClick = getConfigProperty('noClick')(fieldDefinition).getOrElse(false);
            const hasEdit = getConfigProperty('hasEdit')(fieldDefinition).toUndefined();
            const disallowClickAwayNavigation =
                getConfigProperty('disallowClickAwayNavigation')(fieldDefinition).toUndefined();
            const overrideNavButtonHtml = getOverrideNavButtonHtml(fieldDefinition).toUndefined();
            const hidePaginationIfNotNeeded = simpleGetConfProperty(
                'hidePaginationIfNotNeeded',
                undefined,
            )(fieldDefinition);
            const createableIntermediateLinkIfMissing = simpleGetConfProperty(
                'createableIntermediateLinkIfMissing',
                false,
            )(fieldDefinition);

            const defineSPELVariables = getConfigProperty('defineSPELVariables')(fieldDefinition).getOrElse(null);

            propConfiguration = {
                ...propConfiguration,
                hasEdit,
                noClick,
                hidePaginationIfNotNeeded,
                overrideNavButtonHtml,
                hasCreate,
                openTo,
                config: fieldDefinition.config,
                addLabel: false,
                hasTable: true,
                renderLabel,
                label: renderLabel ? fieldDefinition.label : undefined,
                createableIntermediateLinkIfMissing,
                disallowClickAwayNavigation,
            };

            let InnerComponent = filter
                ? withEvaluatedFilter(
                      ({ filterString, ...props }) => (
                          <XManyComponent
                              {...props}
                              filter={filterString}
                              isDynamicallyTemplatedFilter={filter?.includes('$[')}
                          />
                      ),
                      filter,
                      'AdHocEntity',
                      fieldDefinition['data-originaldefinition'],
                  )
                : XManyComponent;
            RComponent = (props) => {
                const isPrintMode = useAppSelector((state) => state.printMode);
                const ftctxt = useContext(formTypeContext);
                if (ftctxt === 'NONE') {
                    return <XManyComponent {...props} />;
                }
                return (
                    <EvaluateInEntityContext expression={defineSPELVariables || null} defaultOnException={false}>
                        {({ expressionResult: evaluatedAdhocSPELVariables }) => (
                            <EvaluateInEntityContext expression={hasCreate} defaultOnException={true}>
                                {({ expressionResult: hasCreateResult }) => {
                                    return (
                                        <InnerComponent
                                            {...props}
                                            noEmptyTable={isPrintMode ? true : props.noEmptyTable}
                                            hasCreate={hasCreateResult}
                                            evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                        />
                                    );
                                }}
                            </EvaluateInEntityContext>
                        )}
                    </EvaluateInEntityContext>
                );
            };
            break;
        }
        case t.JSONSCHEMA_FORM:
            RComponent = DisplayJsonSchemaForm;
            try {
                const config = JSON.parse(fieldDefinition.config);
                if (config) {
                    propConfiguration.readSchemasFromField = config.readSchemasFromField;
                    propConfiguration.schema = config.schema ? JSON.parse(config.schema) : '';
                    propConfiguration.uiSchema = config.uiSchema ? JSON.parse(config.uiSchema) : '';
                }
            } catch (e) {
                console.error('could not parse the below config for field ' + fieldDefinition.name);
                console.log(fieldDefinition);
            }
            break;
        case t.JSONSCHEMA_FORM_BUILDER:
            RComponent = () => (
                <div style={{ textAlign: 'center' }}>
                    <p>Form Builder (not implemented in show views)</p>
                </div>
            );
            break;
        case t.MANYMANY_MULTI_CARD:
        case t.REFMANY_MULTISELECT_IDSLIST:
        case t.MULTIPLE_ENTITY_TYPEAHEAD:
        case t.REFMANYMANY_CHIP: {
            if (isOffline()) {
                propConfiguration.disabled = true;
                propConfiguration.noClick = true;
            }
            RComponent = RefManyMultiSelectIdsList;
            propConfiguration = {
                ...propConfiguration,
                hidePaginationIfNotNeeded: simpleGetConfProperty(
                    'hidePaginationIfNotNeeded',
                    undefined,
                )(fieldDefinition),
                openTo: getConfigProperty('openTo')(fieldDefinition).toUndefined(),
                type: 'Display(FromEntity)',
                renderer:
                    fieldDefinition.type === t.MANYMANY_MULTI_CARD
                        ? 'CARD_DISPLAY'
                        : fieldDefinition.type === t.REFMANYMANY_CHIP || fieldDefinition === t.MULTIPLE_ENTITY_TYPEAHEAD
                        ? 'CHIP'
                        : 'LIST',
                source: `${propConfiguration.source}Ids`,
                reference:
                    fieldDefinition.configuredEntity &&
                    getRefEntityName(viewConfig, fieldDefinition.configuredEntity, fieldDefinition.name, 'POP_LAST'),
                sortable: false,
                allowEmpty: true,
                addLabel: false,
            };
            let listFilter = getConfigProperty('listFilter')(fieldDefinition).toNullable();

            if (listFilter) {
                // code block duplicated from generateMemberField
                const InnerRComponent = RComponent;
                RComponent = withEvaluatedFilter(
                    ({ filterString, ...props }) => {
                        const filterObj = useMemo(() => {
                            return getFilterFromFilterString(filterString);
                        }, [filterString]);
                        return <InnerRComponent {...props} listFilter={filterObj} />;
                    },
                    listFilter,
                    'Entity',
                    fieldDefinition['data-originaldefinition'],
                );
            }

            const getViewNameOf = (type) =>
                getCustomViewName(type, false)(
                    isFieldFromFlowable(fieldDefinition)
                        ? propConfiguration.reference
                        : getRefEntityName(
                              viewConfig,
                              fieldDefinition.configuredEntity,
                              fieldDefinition.name,
                              'POP_LAST',
                          ),
                    viewConfig,
                    fieldDefinition.config,
                );
            propConfiguration.viewName = getViewName(fieldDefinition) ?? getViewNameOf('LIST');
            propConfiguration.editViewName = getEditViewName(fieldDefinition) ?? getViewNameOf('EDIT');
            propConfiguration.showViewName = getShowViewName(fieldDefinition) ?? getViewNameOf('SHOW');
            const defineSPELVariables = simpleGetConfProperty('defineSPELVariables', null)(fieldDefinition);

            if (defineSPELVariables) {
                const InnerRComponent = RComponent;
                RComponent = (props) => {
                    return (
                        <EvaluateInEntityContext
                            expression={defineSPELVariables || null}
                            defaultOnException={false}
                            useLiveValues
                        >
                            {({ expressionResult: evaluatedAdhocSPELVariables }) => {
                                return (
                                    <InnerRComponent
                                        {...props}
                                        evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                    />
                                );
                            }}
                        </EvaluateInEntityContext>
                    );
                };
            }
            break;
        }
        case t.VALUESET_MULTICHECKBOX: {
            RComponent = (props) => {
                const currentValue = get(props.record, props.source);
                const plainSource = props.source?.endsWith('Ids') ? props.source.slice(0, -3) : props.source;
                const conceptIds = useAvailableConceptsForShowField(currentValue, plainSource);
                return <MultiCheckbox {...props} conceptIds={conceptIds} />;
            };

            propConfiguration = {
                ...propConfiguration,
                source: `${propConfiguration.source}Ids`,
                addLabel: false,
                disabled: true,
                group: getValuesetGroup(fieldDefinition),
                label: renderLabel ? fieldDefinition.label : undefined,
            };
            break;
        }
        case t.ADDRESS_VERIFICATION_2: {
            RComponent = () => null;
            break;
        }
        default:
            console.log(`uncaught field: ${JSON.stringify(fieldDefinition)}`);
            return null;
    }
    if (
        c.getOwnData &&
        fieldDefinition.type !== t.MULTI_CARD &&
        fieldDefinition.type !== t.REFMANY_JOIN_MULTISELECT &&
        fieldDefinition.type !== t.REFMANY_MULTISELECT &&
        fieldDefinition.type !== t.EXPRESSION
    ) {
        const InnerComponent = RComponent;
        RComponent = class WrappedInSubscription extends React.Component {
            shouldComponentUpdate(nextProps) {
                if (this.props.record !== nextProps.record || this.props.source !== nextProps.source) {
                    return true;
                }
                return false;
            }
            render() {
                const props = this.props;
                if (props.record?.['_create'] || props.record?.['_needsSubmit']) {
                    // pending offline create
                    return <InnerComponent {...props} />;
                }
                return (
                    <FieldSubscriber
                        subscribeToEntireRecord={
                            fieldDefinition.type === t.IMAGE ||
                            fieldDefinition.type === t.FILE_UPLOAD ||
                            fieldDefinition.type === t.ADDRESS_VERIFICATION
                        }
                        defaultValue={c.defaultValue}
                        {...props}
                        id={(props.record || {}).id}
                        renderDisplayField={({ record }) => <InnerComponent {...props} record={record} />}
                    />
                );
            }
        };
    }
    if (!propConfiguration.ariaInputProps['aria-labelledby'] && typeof propConfiguration.label === 'string') {
        propConfiguration.ariaInputProps['aria-label'] = propConfiguration.label;
    }

    const tooltipText = simpleGetConfProperty('tooltipText', '')(fieldDefinition);
    if (tooltipText) {
        RComponent = withTooltip(tooltipText)(RComponent);
    }

    return (
        <RComponent
            disabled={isDisabledEntityField(fieldDefinition, liveProps, viewConfig) || undefined}
            {...propConfiguration}
            fieldInstanceIdentifier={fieldDefinition.fieldInstanceIdentifier || fieldDefinition.source}
            key={`${propConfiguration.source}-${propConfiguration.label}`}
            {...liveProps}
        />
    );
};

export default generateField;
