import * as FieldDataType from 'components/generics/utils/fieldDataTypes';
import ViewConfig from 'reducers/ViewConfigType';
import { getFields, getAttrOfTraversedFieldExprIncludingLinkedX } from 'components/generics/utils/viewConfigUtils';

const isObject = (value) => !!value && value.constructor === Object;

export const adjustSortFieldName = (field: string, viewConfig: ViewConfig, resource: string) => {
    try {
        // e.g. linkedEntity.title fails in the dataType lookup.
        const dataTypeOfField = field.endsWith('.title')
            ? 'STRING'
            : getAttrOfTraversedFieldExprIncludingLinkedX<'dataType'>(
                  viewConfig,
                  resource,
                  field.endsWith('Id') ? field.slice(0, -2) : field,
                  'dataType',
              );
        // valueSetFields and RefOnes end with Id, but must be searched from their original name
        if (field.endsWith('Id')) {
            if (dataTypeOfField === 'VALUESET') {
                return `${field.slice(0, -2)}.display`;
            }

            if (dataTypeOfField === 'REFONE') {
                // sort entities by title
                return `${field.slice(0, -2)}.title`;
            }
        }
        if (dataTypeOfField === 'ANYBLOB') {
            return `${field}FileName`;
        }
        return field;
    } catch (e) {
        return field;
    }
};

export const queryParametersForSearch = (data: {}, trailingAmp: boolean = false, modifier: string = '.equals') =>
    Object.keys(data)
        .filter((key) => typeof data[key] !== 'undefined' && data[key] !== '')
        .map((key) => {
            if (isObject(data[key])) {
                return Object.keys(data[key])
                    .filter((subkey) => data[key][subkey] && data[key][subkey].trim().length > 0)
                    .map((subkey) => `${key}.${subkey}${modifier}=${data[key][subkey]}`);
            }
            return [key, data[key]]
                .map(encodeURIComponent)
                .join('=')
                .split('_~_')
                .join('.')
                .split(encodeURIComponent('%')) // also support % as a wildcard search
                .join('*');
        })
        .join('&') + (trailingAmp ? '&' : '');

export const buildPaginationQueryString = (pagination: {
    sortField: string;
    sortDir: 'ASC' | 'DESC';
    page: number;
    perPage: number;
}) => {
    const { sortField, sortDir, page, perPage } = pagination;
    return Object.entries({
        sort: sortField.concat(',').concat(sortDir),
        page: page - 1,
        size: perPage,
    })
        .filter((t) => t[1])
        .map((t) => t.map(encodeURIComponent).join('='))
        .join('&');
};

const getSpecialCaseExpansionsForResource = (resource: string) => {
    const specialCases = [];
    if (resource.endsWith('Mrg')) {
        specialCases.push(`${`${resource.slice(0, 1).toLowerCase()}${resource.slice(1)}`.slice(0, -3)}.all`); // e.g. person.all
    }
    if (resource === 'AppCase') {
        specialCases.push('linkedEntity');
    }
    return specialCases;
};
export const specialCasesOnlyExpansionQuery = (
    leadChar: '&' | ',' | '',
    resource: string,
    appendExpansions: string[] = [],
) => {
    const specialCases = getSpecialCaseExpansionsForResource(resource);
    const expansions = [...specialCases, ...appendExpansions];
    if (expansions.length > 0) {
        const commaSeparated = Array.prototype.join.call(expansions, ',');
        if (leadChar === '') {
            return commaSeparated;
        }
        if (leadChar === ',') {
            return commaSeparated ? ',' + commaSeparated : '';
        }
        return `${leadChar}expand=${commaSeparated}`;
    }
    return '';
};

export const getExpansionReferences = (
    resource: string,
    viewConfig: ViewConfig,
    append: string[] = [],
    includeAllEntityReferences = true,
) => {
    const specialCases = getSpecialCaseExpansionsForResource(resource);
    const fields = getFields(viewConfig, resource);
    if (fields) {
        const references = !includeAllEntityReferences
            ? []
            : fields
                  .filter(
                      (f) =>
                          f.dataType === FieldDataType.REFONE ||
                          f.dataType === FieldDataType.REFMANYMANY ||
                          (f.dataType === FieldDataType.VALUESET && f.relatedEntity === 'Concept') ||
                          (f.dataType === FieldDataType.VALUESET_MANY && f.relatedEntity === 'Concept'),
                  )
                  .map((f) => f.name);
        return [...references, ...specialCases, ...append];
    }
    return [];
};

export const expansionQuery = (
    leadChar: '&' | '?' | ',' | '',
    resource: string,
    viewConfig: ViewConfig,
    append: string[] = [],
    includeAllEntityReferences = true,
) => {
    const references = getExpansionReferences(resource, viewConfig, append, includeAllEntityReferences);
    if (references.length > 0) {
        const commaSeparated = Array.prototype.join.call(references, ',');
        if (leadChar === '') {
            return commaSeparated;
        }
        if (leadChar === ',') {
            return commaSeparated ? ',' + commaSeparated : '';
        }
        return `${leadChar}expand=${commaSeparated}`;
    }
    return '';
};
