import { Button, Card, DialogActions, DialogContent } from '@material-ui/core';
import EditableTaskFormLayout from 'layout-editor/components/EditableTaskFormLayout';
import React, { useMemo, useReducer } from 'react';
import { TaskForm } from '../../reducers/taskFormType';
import { reduxForm } from 'redux-form';
import FormBuilderTabs from './components/Tabs';
import { CasetivityViewContextProvider } from 'util/casetivityViewContext';
import ServerTemplatedBracketsController from './components/ServerTemplatedBracketsController/ServerTemplatedBracketsController';
import { FormContextProvider } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import uniqueId from 'lodash/uniqueId';
import {
    useDuplicateLastRow,
    useFetchFormConfigValuesets,
    useFormInitialValues,
    useFormWithoutExpressions,
    useRenderFormPreview,
} from './TaskFormBuilder';
import Popup from 'components/Popup';
import LazyCodemirrorJSONEditor from 'components/CodemirrorJSONEditor/LazyEditor';
import WithTextState from 'auth/components/WithTextState';
import Alert from '@material-ui/lab/Alert';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';

interface AdhocBpmFormBuilderProps {
    currentForm: Partial<TaskForm>;
    setCurrentForm: (form: Partial<TaskForm>) => void;
    noOutcomes?: boolean;
}
export const defaultInitialTaskForm: Partial<TaskForm> = {
    fields: [],
    id: '',
    key: '',
    name: '',
    outcomes: [],
};

const Form = reduxForm({})((props) => (
    <form
        onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }}
    >
        {props.children}
    </form>
));

export const AdhocBpmFormBuilder: React.FC<AdhocBpmFormBuilderProps> = ({
    currentForm = defaultInitialTaskForm,
    setCurrentForm,
    noOutcomes,
}) => {
    useFetchFormConfigValuesets(currentForm);
    const [refreshKey, refresh] = useReducer((state) => state + 1, 1);

    const duplicateLastRow = useDuplicateLastRow({
        currentForm,
        setCurrentForm: (form) => {
            setCurrentForm(form);
            refresh();
        },
    });

    const initialValues = useFormInitialValues(currentForm);

    const formWithoutExpressions = useFormWithoutExpressions(currentForm);

    const renderFormPreview = useRenderFormPreview({ noOutcomes });

    const form = useMemo(() => uniqueId('bpm-form-builder-'), []);

    return (
        <div>
            <FormBuilderTabs
                keepTabsMounted
                layoutEditorElement={
                    <Card style={{ margin: '1em', padding: '1em' }}>
                        <CasetivityViewContextProvider rootViewContext="START_FORM" currentViewContext="bpm">
                            <FormContextProvider
                                formId={form}
                                contextType="start-form"
                                formDefinition={formWithoutExpressions}
                            >
                                <Form form={form} initialValues={initialValues}>
                                    <stagedFormDefinitionContext.Provider
                                        value={{ formDefinition: currentForm as TaskForm, setFormDefinition: () => {} }}
                                    >
                                        <EditableTaskFormLayout
                                            key={refreshKey}
                                            formDefinition={currentForm as TaskForm}
                                            onFormDefinitionChange={({ formDefinition }) =>
                                                setCurrentForm(formDefinition)
                                            }
                                        />
                                    </stagedFormDefinitionContext.Provider>
                                    <Button onClick={duplicateLastRow} variant="contained" color="primary">
                                        Duplicate bottom row
                                    </Button>
                                </Form>
                            </FormContextProvider>
                        </CasetivityViewContextProvider>
                    </Card>
                }
                previewElement={
                    <div>
                        <ServerTemplatedBracketsController
                            taskForm={currentForm as TaskForm}
                            renderWhenReady={renderFormPreview}
                        />
                    </div>
                }
            />
            <Popup
                ComponentProps={{ fullWidth: true }}
                renderDialogContent={({ closeDialog }) => (
                    <WithTextState initial={JSON.stringify(currentForm, null, 2)}>
                        {({ text, setText }) => {
                            const isValidJSON = (() => {
                                try {
                                    JSON.parse(text);
                                    return true;
                                } catch {
                                    return false;
                                }
                            })();
                            return (
                                <>
                                    <DialogContent>
                                        <LazyCodemirrorJSONEditor
                                            startingHeight={600}
                                            initialValue={text}
                                            onChange={setText}
                                        ></LazyCodemirrorJSONEditor>
                                    </DialogContent>
                                    <DialogContent>
                                        {!isValidJSON && <Alert severity="error">Invalid JSON</Alert>}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant="contained" onClick={closeDialog}>
                                            Close
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={!isValidJSON}
                                            onClick={() => {
                                                setCurrentForm(JSON.parse(text));
                                                closeDialog();
                                                refresh();
                                            }}
                                        >
                                            Apply
                                        </Button>
                                    </DialogActions>
                                </>
                            );
                        }}
                    </WithTextState>
                )}
                renderToggler={({ openDialog }) => (
                    <div style={{ marginLeft: '1em' }}>
                        <Button color="primary" size="small" variant="contained" onClick={openDialog()}>
                            Edit JSON
                        </Button>
                    </div>
                )}
            />
        </div>
    );
};
