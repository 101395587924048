import React from 'react';
import HtmlToReact from 'html-to-react';
import Clear from '@material-ui/icons/Clear';
import ListView from 'templatePage/templatableComponents/ListView';
import Accordian from 'templatePage/templatableComponents/Accordian';
import LinkButton from 'components/links/LinkButton';
import Popup from 'components/Popup';
import { IconButton, Button } from '@material-ui/core';
import ServerTemplatedPrintTemplateField from 'printTemplate/serverTemplating/component/ServerTemplatedPrintTemplateField';
import PrintTemplateNameLookupField from 'printTemplate/component/PrintTemplateField';
import SelectLocale from 'i18n/components/SelectLocale';
import { FormattedMessage } from 'react-intl';
import NavMenu from './templatableComponents/NavMenu';
import * as URI from 'uri-js';
import IframeResizer from 'iframe-resizer-react';
import ClassToggleButton from './templatableComponents/ClassToggleButton';
import HideOnRoute from 'components/HideOnRoute';
import B64ImagePopup from './templatableComponents/B64ImagePopup';
import GoToFieldLink from './templatableComponents/GoToFieldLink';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { ShowView } from 'components/generics/genericShow/index2';
import WithRandomFormId from './components/WithRandomFormId';
import TemplatableGroupedBarChart from './templatableComponents/GroupedBarChart';
import { useAppSelector } from 'reducers/rootReducer';

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

export const registerRoutesElement: {
    element?: React.FC<{}>;
} = {};

const Autofocus = (props) => {
    const elementRef = React.useRef(null);
    React.useEffect(() => {
        elementRef.current?.focus();
    }, []);
    return props.children({ elementRef });
};

const CasetivityMuiButton: React.FC<{}> = ({ children }) => {
    const printMode = useAppSelector((state) => state.printMode);
    if (printMode) {
        return null;
    }
    return (
        <Button variant="contained" color="primary">
            {children}
        </Button>
    );
};

const TAGS = {
    LANGUAGE_SELECT: 'data-languageselect',
    MESSAGE: 'data-message',
    PRINTTEMPLATE_DOCX: 'data-printtemplate',
    PRINTTEMPLATE_PDF: 'data-printtemplate-pdf',
    PRINTTEMPLATE_REPORT: 'data-printtemplate-report',
    LISTVIEW: 'data-listview',
    SHOWVIEW: 'data-showview',
    PUSHLINK: 'data-pushlink',
    DIALOGBUTTONTEXT: 'data-dialog-buttontext',
    ACCORDIANHEADER: 'data-accordianheader',
    NAVMENU: 'data-navmenu',
    FORMSTACK_IFRAME_URL: 'data-formstack-iframe',
    TOGGLECLASS: 'data-toggleclass',
    PRINTBUTTON: 'data-printbutton',
    B64_IMAGE_POPUP: 'data-b64-image-popup',
    GOTO_FIELD: 'data-goto-field',
    ENTITY_POPUP: 'data-popup-entitytype',
    REPORT_GROUPEDBAR: 'data-report-grouped-bar',
    MUI_BUTTON: 'data-casetivity-button',
};
// Order matters. Instructions are processed in
// the order they're defined
const createProcessingInstructions = (
    injectedMappings: {
        tag: string;
        Element: JSX.Element;
    }[] = [],
) => {
    const processingInstructions = [
        {
            // This is REQUIRED, it tells the parser
            // that we want to insert our React
            // component as a child
            replaceChildren: true,
            shouldProcessNode: function (node) {
                return (
                    node.attribs &&
                    (Object.values(TAGS).some((tag) => node.attribs[tag]) ||
                        injectedMappings.some(({ tag }) => node.attribs[tag]))
                );
            },
            processNode: function (node, children, index) {
                const listViewName = node.attribs?.[TAGS.LISTVIEW];
                if (listViewName) {
                    const noClick = (() => {
                        const noClickAttribute = node.attribs?.['data-listview-noclick'];
                        return noClickAttribute === 'true'
                            ? true
                            : noClickAttribute === 'false'
                            ? false
                            : typeof noClickAttribute === 'boolean'
                            ? noClickAttribute
                            : undefined;
                    })();
                    return <ListView viewName={listViewName} noClick={noClick} />;
                }
                const showViewName = node.attribs?.[TAGS.SHOWVIEW];
                if (showViewName) {
                    const id = node.attribs?.['data-id'];
                    return (
                        id && (
                            <WithRandomFormId key={id}>
                                {({ id: formId }) => (
                                    <section>
                                        <ShowView
                                            renderTitleElement={({ titleElem }) => <h2>{titleElem}</h2>}
                                            actions={<div />}
                                            id={id}
                                            viewName={showViewName}
                                            formId={formId}
                                        />
                                    </section>
                                )}
                            </WithRandomFormId>
                        )
                    );
                }

                const pushTo = node.attribs?.[TAGS.PUSHLINK];
                if (pushTo) {
                    const className = node.attribs?.['data-classname'];
                    return (
                        <LinkButton to={pushTo} className={className}>
                            {children}
                        </LinkButton>
                    );
                }
                const buttonClass = node.attribs?.['data-dialog-buttonclass'];
                const autofocus = node.attribs?.['data-dialog-autofocus'];
                const dialogText = node.attribs?.['data-dialog-buttontext'];
                if (dialogText) {
                    return (
                        <Popup
                            renderDialogContent={({ closeDialog }) => {
                                const CloseButtonElem = (
                                    <IconButton
                                        size="small"
                                        style={{ position: 'absolute', top: 0, right: 0 }}
                                        aria-label="Close dialog"
                                        onClick={closeDialog}
                                    >
                                        <Clear />
                                    </IconButton>
                                );
                                if (!autofocus) {
                                    return (
                                        <div style={{ position: 'relative', padding: '1em' }}>
                                            {CloseButtonElem}
                                            {children}
                                        </div>
                                    );
                                }
                                return (
                                    <Autofocus>
                                        {({ elementRef }) => (
                                            <div
                                                tabIndex={0}
                                                ref={elementRef}
                                                style={{ position: 'relative', padding: '1em' }}
                                            >
                                                {CloseButtonElem}
                                                {children}
                                            </div>
                                        )}
                                    </Autofocus>
                                );
                            }}
                            renderToggler={({ openDialog }) =>
                                buttonClass === ':mui-small-outlined' ? (
                                    <Button size="small" variant="outlined" onClick={openDialog()}>
                                        {dialogText}
                                    </Button>
                                ) : (
                                    <button type="button" className={buttonClass} onClick={openDialog()}>
                                        {dialogText}
                                    </button>
                                )
                            }
                        />
                    );
                }
                const accordianHeaderText = node.attribs?.[TAGS.ACCORDIANHEADER];
                if (accordianHeaderText) {
                    return <Accordian header={accordianHeaderText}>{children}</Accordian>;
                }
                const foundCustomElementToReplace = injectedMappings.find(({ tag, Element }) => node.attribs?.[tag]);
                if (foundCustomElementToReplace) {
                    return foundCustomElementToReplace.Element;
                }
                const printTemplateName =
                    node.attribs?.[TAGS.PRINTTEMPLATE_PDF] ||
                    node.attribs?.[TAGS.PRINTTEMPLATE_DOCX] ||
                    node.attribs?.[TAGS.PRINTTEMPLATE_REPORT];
                if (printTemplateName) {
                    const resource = node.attribs?.['data-resource'];
                    const id = node.attribs?.['data-id'];
                    const isPdf = Boolean(node.attribs?.[TAGS.PRINTTEMPLATE_PDF]);
                    const isReport = Boolean(node.attribs?.[TAGS.PRINTTEMPLATE_REPORT]);
                    const isButton = node.attribs?.['data-is-button'];
                    const isMuiButton = node.attribs?.['data-is-casetivity-button'];
                    const buttonClass = node.attribs?.['data-buttonclass'];
                    const anchorClass = node.attribs?.['data-anchorclass'];
                    const displayText = node.attribs?.['data-displaytext'];
                    if (isPdf || isReport) {
                        const type = isPdf ? 'pdf' : 'report';
                        if (isButton || isMuiButton) {
                            return (
                                <ServerTemplatedPrintTemplateField
                                    component={isButton ? 'button' : 'mui-button'}
                                    buttonClass={buttonClass}
                                    resource={resource}
                                    record={{
                                        id,
                                        entityType: resource,
                                    }}
                                    type={type}
                                    printTemplateName={printTemplateName}
                                    displayText={displayText}
                                />
                            );
                        }
                        return (
                            <ServerTemplatedPrintTemplateField
                                displayText={displayText}
                                padding="none"
                                resource={resource}
                                anchorClass={anchorClass}
                                type={type}
                                record={{
                                    id,
                                    entityType: resource,
                                }}
                                printTemplateName={printTemplateName}
                            />
                        );
                    }
                    return (
                        <PrintTemplateNameLookupField
                            displayText={displayText}
                            padding="none"
                            component={isButton ? 'button' : isMuiButton ? 'mui-button' : 'a'}
                            buttonClass={buttonClass}
                            anchorClass={anchorClass}
                            resource={resource}
                            record={{
                                id,
                                entityType: resource,
                            }}
                            printTemplateName={printTemplateName}
                        />
                    );
                }
                if (node.attribs?.[TAGS.LANGUAGE_SELECT]) {
                    return <SelectLocale />;
                }
                const message = node.attribs?.[TAGS.MESSAGE];
                if (message) {
                    const VALUE_PRE = 'data-values-';
                    const values = Object.fromEntries(
                        Object.entries(node.attribs ?? {})
                            .filter(([k]) => k.startsWith(VALUE_PRE))
                            .map(([k, v]) => [k.slice(VALUE_PRE.length), v]),
                    );
                    return <FormattedMessage id={message} values={values} />;
                }
                const navMenu = node.attribs?.[TAGS.NAVMENU];

                const iframeUrl = node.attribs?.[TAGS.FORMSTACK_IFRAME_URL];
                if (iframeUrl) {
                    if (!URI.parse(iframeUrl)?.host?.endsWith('formstack.com')) {
                        // don't allow iframe urls to point to anything other than formstack.
                        return <div />;
                    }
                    const iframeTitle = node.attribs?.['data-iframe-title'];
                    const iframe = (() => {
                        const iframeHeight = node.attribs?.['data-iframe-height'];
                        if (iframeHeight === 'auto') {
                            // required iframe-resize javascript inside the iframe content
                            // see https://github.com/davidjbradshaw/iframe-resizer-react
                            return (
                                <IframeResizer
                                    src={iframeUrl}
                                    title={iframeTitle}
                                    className="casetivity-formstack-iframe"
                                    style={{ width: '1px', minWidth: '100%' }}
                                />
                            );
                        }
                        const iframeWidth = node.attribs?.['data-iframe-width'];
                        return (
                            <iframe
                                className="casetivity-formstack-iframe"
                                src={iframeUrl}
                                title={iframeTitle}
                                height={iframeHeight}
                                width={iframeWidth}
                            />
                        );
                    })();
                    const hideOnRoute = node.attribs?.['data-hideonroute'];
                    if (hideOnRoute) {
                        return <HideOnRoute routeRegex={hideOnRoute}>{iframe}</HideOnRoute>;
                    }
                    return iframe;
                }
                const toggleClass = node.attribs?.[TAGS.TOGGLECLASS];
                if (toggleClass) {
                    const thisClass = node.attribs?.['data-thisclass'];
                    const toggleThisClass = node.attribs?.['data-toggle-thisclass'];
                    return (
                        <ClassToggleButton
                            toggleThisClass={toggleThisClass}
                            className={thisClass}
                            toggleClass={toggleClass}
                        >
                            {children}
                        </ClassToggleButton>
                    );
                }
                if (navMenu) {
                    return <NavMenu />;
                }
                const print = node.attribs?.[TAGS.PRINTBUTTON];

                if (print) {
                    const displayText = node.attribs?.['data-displaytext'];
                    const isButton = node.attribs?.['data-is-button'];
                    const buttonClass = node.attribs?.['data-buttonclass'];
                    const anchorClass = node.attribs?.['data-anchorclass'];
                    if (isButton) {
                        return (
                            <button
                                className={buttonClass}
                                onClick={() => {
                                    window.print();
                                }}
                            >
                                {displayText}
                            </button>
                        );
                    }
                    return (
                        // eslint-disable-next-line
                        <a
                            className={anchorClass}
                            onClick={() => {
                                window.print();
                            }}
                            // eslint-disable-next-line
                            href="javascript:;"
                        >
                            {displayText}
                        </a>
                    );
                }

                const groupedBarJson: string = node.attribs?.[TAGS.REPORT_GROUPEDBAR];

                if (groupedBarJson) {
                    const title = node.attribs['data-report-grouped-bar-title'];
                    return <TemplatableGroupedBarChart title={title} jsonData={groupedBarJson} />;
                }

                const B64IMAGE = node.attribs?.[TAGS.B64_IMAGE_POPUP]; // b64 data
                if (B64IMAGE) {
                    const filename = node.attribs?.['data-filename'];
                    const contentType = node.attribs?.['data-contenttype'];
                    const anchorClass = node.attribs?.['data-anchorclass'];
                    return (
                        <B64ImagePopup
                            AnchorProps={{
                                className: anchorClass,
                            }}
                            b64={B64IMAGE}
                            filename={filename}
                            contentType={contentType}
                        >
                            {children}
                        </B64ImagePopup>
                    );
                }

                const CasetivityButton = node.attribs?.[TAGS.MUI_BUTTON];
                if (CasetivityButton) {
                    return <CasetivityMuiButton>{children}</CasetivityMuiButton>;
                }

                const GoToSource = node.attribs?.[TAGS.GOTO_FIELD];
                if (GoToSource) {
                    /**
                     * Error: <div data-goto-field="firstName">Fix the first name</div>
                     */
                    return <GoToFieldLink field={GoToSource}>{children}</GoToFieldLink>;
                }
                const EntityPopup = node.attribs?.[TAGS.ENTITY_POPUP];
                const entityId = node.attribs?.['data-popup-id'];
                if (EntityPopup && entityId) {
                    /**
                     * Example:
                     * 
                     *  <div data-popup-entitytype="Person" data-popup-id="$[entityId]">
                            <!-- The piece marked 'data-above' will render above the edit form, with the links all wired up -->
                            <div data-above="y">
                                Error: <div data-goto-field="firstName">Go to firstName field</div>
                                Error: <div data-goto-field="orders">Go to claims field</div>
                            </div>
                            <!-- Button text -->
                            entity has an error
                        </div>
                     */
                    const arrChildren = React.Children.toArray(children);
                    const displayAbove = arrChildren.find((c) => c?.['props']?.['data-above']);
                    const renderchildren = arrChildren.filter((c) => c !== displayAbove);

                    return (
                        <EntityInspect
                            renderAboveEditForm={() => {
                                return <div style={{ padding: '1em' }}>{displayAbove}</div>;
                            }}
                            openTo="edit"
                            reference={EntityPopup}
                            formId={`popupto ${entityId} ${EntityPopup}`}
                            renderComponent={(args) => (
                                <button onClick={() => args.selectId(entityId)} className="casetivity-linkbutton">
                                    {renderchildren}
                                </button>
                            )}
                        />
                    );
                }
                return <div />;
            },
        },
        {
            // Anything else
            shouldProcessNode: function (node) {
                return true;
            },
            processNode: processNodeDefinitions.processDefaultNode,
        },
    ];
    return processingInstructions;
};

export default createProcessingInstructions;
