import React, { useState, useEffect, useMemo } from 'react';
import { RootState } from 'reducers/rootReducer';
import { RecursivePartial } from 'util/typeUtils';
import {
    Chip,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import { getDencryptTaskDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeTaskData';
import { push } from 'connected-react-router';
import { offlineTaskExpirationDatesKeyVal } from 'IndexedDB/offlineTaskExpirationDates';
import {
    useStyles,
    NoOfflineWork,
    useDeletePrompt,
    getCaseTitleFromEntities,
    ExpirationSecondaryText,
} from 'offline_app/presentation/ListOfOfflineTasks';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { getExpiredOfflineDataSubscribedComponentsRegistry } from '../ExpiredOfflineDataSubscribedComponentsRegistry';
import { isOfflineFromUrl } from 'util/isOffline';

type Data = {
    [taskKey: string]: {
        taskId: string;
        savedState: RecursivePartial<RootState>;
        expirationDate: Date;
    };
};

const SavedStatesList: React.FC<{}> = () => {
    const classes = useStyles();
    const [state, setState] = useState<Data>({});
    let isMounted = React.useRef(true);
    const [isPending, setIsPending] = useState<'initial' | 'starting_decrypt' | 'done'>('initial');
    useEffect(() => {
        const setData = async () => {
            setIsPending('initial');
            let data: Data = {};
            const allKeys = await idbKeyval.keys();
            for (let i = 0; i < allKeys.length; i++) {
                if (isMounted.current) {
                    const key = allKeys[i];
                    // this is a loop just waiting to get the PIN back...
                    const value = await getDencryptTaskDataPromptController().promptDecodeTaskData(key, false);
                    if (i === 0) {
                        // we have the pin, now our loop continues
                        setIsPending('starting_decrypt');
                    }
                    const expirationDate = (await offlineTaskExpirationDatesKeyVal.get(key))?.expires;
                    data[key.toString()] = {
                        taskId: key,
                        savedState: value as RecursivePartial<RootState>,
                        expirationDate,
                    };
                }
            }
            if (isMounted.current) {
                setState(data);
                setIsPending('done');
            }
        };
        setData();
        getExpiredOfflineDataSubscribedComponentsRegistry().registerCallback(setData);
        return () => {
            isMounted.current = false;
            getExpiredOfflineDataSubscribedComponentsRegistry().unregisterCallback(setData);
        };
    }, []);
    const dispatch = useDispatch();
    const { PromptElem, setDeletePrompt } = useDeletePrompt();
    const stateGroupedByProcess = useMemo(() => {
        return Object.values(state).reduce(
            (prev, curr) => {
                const processId = curr.savedState.bpm.tasks.byId[curr.taskId]?.processInstanceId;
                if (!prev[processId]) {
                    prev[processId] = [];
                }
                prev[processId].push(curr);
                return prev;
            },
            {} as {
                [processId: string]: Data[string][];
            },
        );
    }, [state]);
    if (isPending === 'initial') {
        return null;
    }
    if (isPending === 'starting_decrypt') {
        return (
            <div style={{ display: 'grid', placeItems: 'center' }}>
                <div>
                    <CircularProgress />
                </div>
            </div>
        );
    }
    if (Object.keys(state).length === 0) {
        return <NoOfflineWork />;
    }
    return (
        <div>
            {PromptElem}
            <p>
                The following tasks have been saved for offline editing. After coming back online, you will be able to
                review your changes and submit your work.
            </p>
            <List className={classes.root} subheader={<li />}>
                {Object.entries(stateGroupedByProcess)?.map(([processInstanceId, children]) => {
                    const caseTitle = (() => {
                        const [
                            {
                                savedState: {
                                    admin: { entities },
                                },
                            },
                        ] = children;
                        return getCaseTitleFromEntities(entities, processInstanceId);
                    })();

                    return (
                        <li key={'pid: ' + processInstanceId} className={classes.listSection}>
                            <ul className={classes.ul}>
                                <ListSubheader>{caseTitle}</ListSubheader>
                                {children.map(({ taskId, savedState, expirationDate }) => {
                                    const taskInstance = savedState.admin.entities['TaskInstance']?.[taskId];
                                    const profileName =
                                        savedState.profiles?.state === 'no_profiles'
                                            ? null
                                            : savedState.viewConfig?.user?.title;
                                    const taskName = (taskInstance as any)?.name;
                                    return (
                                        <ListItem
                                            onClick={() =>
                                                dispatch(
                                                    push(
                                                        `/processes/${processInstanceId}/tasks/${taskId}/start${
                                                            isOfflineFromUrl() ? window.location.search : ''
                                                        }`,
                                                    ),
                                                )
                                            }
                                            button
                                            key={'item: ' + processInstanceId + ': ' + taskId}
                                        >
                                            <ListItemText
                                                inset
                                                primary={taskName ?? 'Unknown Task'}
                                                secondary={<ExpirationSecondaryText expirationDate={expirationDate} />}
                                            />
                                            <ListItemSecondaryAction>
                                                {profileName && (
                                                    <Chip avatar={<AccountCircle />} size="small" label={profileName} />
                                                )}
                                                <IconButton
                                                    onClick={() =>
                                                        setDeletePrompt({
                                                            taskName,
                                                            appCaseName: caseTitle,
                                                            taskId,
                                                        })
                                                    }
                                                    edge="end"
                                                    aria-label="delete"
                                                    size="small"
                                                >
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </List>
        </div>
    );
};

export default SavedStatesList;
