import get from 'lodash/get';
import set from 'lodash/set';

interface Tree {
    [field: string]: Tree;
}

const convertExpansionsToTree =
    (options: { openChildren: string; closeChildren: string; peerNodeSeparator: string }) => (expansions: string[]) => {
        const { openChildren, closeChildren, peerNodeSeparator } = options;

        // build minimal tree
        const dict: Tree = {};
        expansions.forEach((exp) => {
            if (!get(dict, exp)) {
                set(dict, exp, {});
            }
        });

        const stringifyTree = (node: Tree): string => {
            const entries = Object.entries(node);
            if (entries.length === 0) {
                return '';
            }

            const children = entries.reduce((prev, [k, childTree], i, arr) => {
                const isLast = i === arr.length - 1;
                const childStr = stringifyTree(childTree);
                if (!childStr && !isLast) {
                    return `${prev}${k}${peerNodeSeparator}`;
                }
                return `${prev}${k}${childStr}`;
            }, '');
            return `${openChildren}${children}${closeChildren}`;
        };
        return stringifyTree(dict);
    };

export const parseTreeToExpansions =
    (options: { openChildren: string; closeChildren: string; peerNodeSeparator: string }) => (treeRep: string) => {
        const { openChildren, closeChildren, peerNodeSeparator } = options;

        const expansions: string[] = [];
        let contextStack: string[] = []; // stack;

        const currWordRef: {
            current: string[];
        } = {
            current: [],
        };

        const closeWordAndAdd = () => {
            expansions.push([...contextStack, currWordRef.current.join('')].join('.'));
            currWordRef.current = [];
        };

        for (let i = 0; i < treeRep.length; i++) {
            const ch = treeRep[i];
            if (ch === openChildren) {
                if (currWordRef.current.length > 0) {
                    const parentWord = currWordRef.current.join('');
                    closeWordAndAdd();
                    contextStack.push(parentWord);
                }
            } else if (ch === closeChildren) {
                if (currWordRef.current.length > 0) {
                    closeWordAndAdd();
                }
                contextStack.pop();
            } else if (ch === peerNodeSeparator) {
                closeWordAndAdd();
            } else {
                currWordRef.current.push(ch);
            }
        }
        return expansions;
    };

export default convertExpansionsToTree;
