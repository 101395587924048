if (!window.alert) {
    window.alert = (...args) => console.log(...args);
}
export const alertOnce = (expression: string) => {
    const key = `__alerted_casetivity_${expression}`;
    if (!window[key]) {
        window.alert(
            `Expression does not resolve to a primitive value: "${expression}". 
    This expression result has been left out of the default values.
            `,
        );
        window[key] = true;
    }
};
