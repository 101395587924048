import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import PopoverCreateButton from '../../../popovers/PopoverCreateButton';
import ListSelect, { ListSelectProps } from './Components/ContainedList';
import { getAccessLevelForEntity, allowsCreate } from 'components/generics/utils/viewConfigUtils';
import getFilterFromFilterString from './getFilterFromFilterString';
import useViewConfig from 'util/hooks/useViewConfig';
import { TypographyProps } from '@material-ui/core/Typography';

type ListSelectControllerProps = Pick<
    ListSelectProps,
    | 'expansions'
    | 'source'
    | 'viewName'
    | 'singleSelect'
    | 'input'
    | 'label'
    | 'useCheckboxes'
    | 'disabled'
    | 'meta'
    | 'openTo'
    | 'hyperlinks'
    | 'showListWhenDisabled'
    | 'noResultsHtml'
    | 'disableCellClick'
    | 'embeddedInFormId'
    | 'noNavButton'
> & {
    labelVariant?: TypographyProps['variant'];
    options?: { disabled?: boolean };
    hasCreate?: boolean;
    filter: string;
};

const ListSelectController: React.FC<ListSelectControllerProps> = ({
    source,
    viewName,
    filter: filterString,
    input,
    label,
    options,
    useCheckboxes,
    disabled,
    singleSelect,
    expansions = [],
    hasCreate = true,
    meta,
    openTo,
    labelVariant,
    hyperlinks,
    showListWhenDisabled,
    noResultsHtml,
    disableCellClick,
    embeddedInFormId,
    noNavButton,
}) => {
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName]?.entity;
    const [refreshKey, updateRefreshKey] = useState(0);
    const dispatch = useDispatch();
    const filter = useMemo(() => getFilterFromFilterString(filterString), [filterString]);
    const hasCreatePermission = allowsCreate(getAccessLevelForEntity(viewConfig, resource));
    const parentEntityName = (() => {
        const referenceField = Object.keys(filter).find((k) => k.endsWith('Id'));
        if (referenceField) {
            const f = viewConfig.entities[resource].fields[referenceField.slice(0, 'Id'.length * -1)];
            return f && f.relatedEntity;
        }
        return undefined;
    })();
    const parentFieldInChild = Object.keys(filter).find((k) => k.endsWith('Id'));
    const parentId =
        Object.keys(filter).find((k) => k.endsWith('Id')) &&
        filter[Object.keys(filter).find((k) => k.endsWith('Id')) as string];
    // in the context of a task-form, we should ensure this entity is there
    useEffect(() => {
        // if it's CasetivityEntity we won't be creating or anything so we can ignore.
        if (parentId && parentEntityName && parentEntityName !== 'CasetivityEntity') {
            dispatch(
                crudGetOneAction({
                    id: parentId,
                    resource: parentEntityName,
                    view: -1,
                }),
            );
        }
    }, []); // eslint-disable-line
    const actions = useMemo(() => {
        return hasCreate && !disabled && hasCreatePermission ? (
            <PopoverCreateButton
                resource={resource}
                label={'Create'}
                parentEntityName={parentEntityName}
                parentFieldInChild={parentFieldInChild}
                parentId={parentId}
                onCreateCb={() => updateRefreshKey((refreshKey) => refreshKey + 1)}
            />
        ) : null;
    }, [
        hasCreate,
        disabled,
        hasCreatePermission,
        parentEntityName,
        parentFieldInChild,
        parentId,
        resource,
        updateRefreshKey,
    ]);
    return (
        <ListSelect
            noNavButton={noNavButton}
            embeddedInFormId={embeddedInFormId}
            disableCellClick={disableCellClick}
            noResultsHtml={noResultsHtml}
            showListWhenDisabled={showListWhenDisabled}
            hyperlinks={hyperlinks}
            openTo={openTo}
            meta={meta}
            expansions={expansions}
            noRecentlyVisited={true}
            key={refreshKey}
            label={label}
            useCheckboxes={useCheckboxes}
            actions={actions}
            hasCreate={false}
            multiSelectable={true}
            singleSelect={singleSelect}
            updateUrlFromFilter={false}
            viewName={viewName}
            source={source}
            name={resource}
            filter={filter}
            input={input}
            labelVariant={labelVariant}
            disabled={disabled || options?.disabled}
        />
    );
};
export default ListSelectController;
