import React, { useEffect, useCallback } from 'react';
import { RootState, useAppSelector, useAppStore } from 'reducers/rootReducer';
import { changeOnline } from 'connectivity/actions';
import Banner from './Banner';
import { checkLastRequestTime } from 'reauth-interval-and-retries/check-last-request/actions';
import useIsAnonymous from 'util/hooks/useIsAnonymous';
import isOffline from 'util/isOffline';

interface DetectConnectivityProps {}
const DetectConnectivity: React.FunctionComponent<DetectConnectivityProps> = (props) => {
    const isOnline = useAppSelector((state: RootState) => state.connectivityStatus);
    const isAnon = useIsAnonymous();
    const store = useAppStore();
    const connectivityHandler = useCallback(() => {
        store.dispatch(changeOnline(navigator.onLine));
        if (navigator.onLine && !isAnon && !isOffline()) {
            const alreadyInFailureRecoveryLoop = (store.getState() as RootState).reauth.successiveFailureCount > 0;
            // try to salvage session immediately to beat other 401s if our token expired.
            store.dispatch(checkLastRequestTime(!alreadyInFailureRecoveryLoop));
        }
    }, [store, isAnon]);
    const onVisibilityChange = useCallback(() => {
        if (document.visibilityState === 'visible') {
            connectivityHandler();
        }
    }, [connectivityHandler]);
    useEffect(() => {
        window.addEventListener('online', connectivityHandler);
        window.addEventListener('offline', connectivityHandler);
        return () => {
            window.removeEventListener('online', connectivityHandler);
            window.removeEventListener('offline', connectivityHandler);
        };
    }, [connectivityHandler]);
    useEffect(() => {
        // check online status after refocusing window - may have lost onLine status while tab is idle.
        // https://strategicsolutionsgroup.atlassian.net/browse/FISH-3368
        document.addEventListener('visibilitychange', onVisibilityChange, false);
        return () => {
            window.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, [onVisibilityChange]);

    return (
        <div>
            <Banner isOnline={isOnline} />
        </div>
    );
};

export const ConnectivityBannerSpacer = () => {
    const isOnline = useAppSelector((state: RootState) => state.connectivityStatus);

    return <Banner isOnline={isOnline} />;
};

export default DetectConnectivity;
