import { filterFind } from 'ace-editor/util/typedocToStaticType/utils';
import { StaticType, Types } from '../../evalStatically/evalStatically';
import { typedocToStaticType } from 'ace-editor/util/typedocToStaticType/typedocToStaticType';
import { DeclarationReflection } from 'typedoc/dist/lib/serialization/schema';
const ijson = require('f/docs/i.json');

const startNode = filterFind(ijson, (node) => node.kindString === 'Function' && node.name === 'f');
if (!startNode) {
    throw new Error('Start node not found');
}
const signatureType = startNode.signatures?.[0]?.type?.['declaration'];
if (!signatureType) {
    throw new Error('Signature type or its declaration not found');
}
const fType = typedocToStaticType(ijson, signatureType as DeclarationReflection);

export const filledFType = (() => {
    // store references to prevent traversing cycles.
    const hitObjs: StaticType[] = [];
    const mappedObjs: StaticType[] = [];
    const traverse = (value: StaticType): StaticType & { __original?: StaticType } => {
        if (value._type !== 'map') {
            return value;
        }
        if (hitObjs.includes(value)) {
            return mappedObjs[hitObjs.indexOf(value)];
        }
        hitObjs.push(value);
        const newObj = {
            __original: value,
            staticValue: {},
            ...Types.map(false, {}),
        };
        const originalClassName = value.__className;
        if (originalClassName) {
            newObj['__className'] = originalClassName;
        }
        mappedObjs.push(newObj);

        Object.entries(value.staticValue ?? {}).forEach(([k, v]) => {
            const newKey = k.endsWith('(') ? k + ')' : k;
            const newValue = traverse(v);
            newObj.staticValue[newKey] = newValue;
        });
        return newObj;
    };
    return traverse(fType);
})();
