import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Dialog, Card, CardHeader, Button } from '@material-ui/core';
import { withStyles, createStyles, WithStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { RootState } from 'reducers/rootReducer';
import compose from 'recompose/compose';
import isOffline from 'util/isOffline';
import useRootTheme from 'components/layouts/hooks/useRootTheme';
import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { Theme as V4Theme } from '@material-ui/core/styles';

const ProvideTheme = (props) => {
    const theme = useRootTheme(createThemeV4);
    return <MuiThemeProvider theme={theme as V4Theme}>{props.children}</MuiThemeProvider>;
};

interface FormSaveNotifierProps {
    when: boolean;
}
const styles = createStyles({
    dialogContainer: {
        zIndex: 5000,
    },
});
const mapStateToProps = (state: RootState, props: FormSaveNotifierProps) => {
    return {
        enabled: state.disableFormSaveNotifier === 0,
    };
};
interface FormSaveNotifierComponentProps
    extends FormSaveNotifierProps,
        ReturnType<typeof mapStateToProps>,
        WithStyles<typeof styles> {}

const FormSaveNotifierComponent = (props: FormSaveNotifierComponentProps) => {
    const { when, classes, enabled } = props;
    // don't notify if offline
    const prompt = Boolean(enabled && when && !isOffline());

    useEffect(() => {
        if (prompt) {
            window.onbeforeunload = () => 'You have unsaved data. Are you sure you would like to continue?';
        }
        return () => {
            if (prompt) {
                window.onbeforeunload = null;
            }
        };
    }, [prompt]);

    return (
        <NavigationPrompt
            when={(crntLocation, nextLocation) => {
                return prompt;
            }}
        >
            {({ isActive, onCancel, onConfirm }) => (
                <ProvideTheme>
                    <Dialog
                        TransitionProps={
                            {
                                // https://github.com/dequelabs/axe-core/issues/146
                                role: 'presentation',
                            } as any
                        }
                        className={classes.dialogContainer}
                        open={isActive}
                    >
                        <Card>
                            <CardHeader title={'You have unsaved changes on this page.'} />
                            <div style={{ margin: '0 auto', width: '50%' }}>
                                <span>Are you sure you want to leave?</span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    padding: '20px',
                                }}
                            >
                                <Button color="primary" variant="contained" onClick={onCancel}>
                                    {' '}
                                    Cancel{' '}
                                </Button>
                                <Button color="primary" variant="contained" onClick={onConfirm}>
                                    {' '}
                                    Continue{' '}
                                </Button>
                            </div>
                        </Card>
                    </Dialog>
                </ProvideTheme>
            )}
        </NavigationPrompt>
    );
};

const FormSaveNotifier: React.SFC<FormSaveNotifierProps> = compose(
    withStyles(styles),
    connect(mapStateToProps),
)(FormSaveNotifierComponent);
export default FormSaveNotifier;
