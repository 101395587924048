import React, { Context, FunctionComponent, useMemo } from 'react';
import { ReactElement } from 'react';
import RGridInner from '../fields/display/RGrid';
import Hidden from './HiddenField';
import { formContext } from './EntityFormContext';
import { formContext as showFormContext, ShowFormContext } from './EntityFormContext/Show';
import { Subtract } from 'utility-types';
import stableStringify from 'fast-json-stable-stringify';
import { createGetEntities } from './EntityFormContext/util/getEntities';
import { createSelector } from 'reselect';
import { useAppSelector } from 'reducers/rootReducer';

export const getIsRemovedByCasetivityRemovedFieldsDataProperty =
    (casetivityRemovedFields: string[]) => (source: string) =>
        source &&
        casetivityRemovedFields?.some(
            (casetivityRemovedField) =>
                source === casetivityRemovedField || source.startsWith(casetivityRemovedField + '.'),
        );

interface RGridWithVisProps {
    fields?: ReactElement<{
        source?: string;
        fieldInstanceIdentifier?: string;
    }>[];
    fieldsToHide: {
        [f: string]: boolean;
    };
    lastRowDropdownsFlipUp?: boolean;
    hideMe?: true;
    casetivityRemovedFields?: string[];
}

const RGridWithVis: FunctionComponent<RGridWithVisProps> = React.memo((props) => {
    const { fields, fieldsToHide, hideMe, casetivityRemovedFields, ...rest } = props;
    // we use this to test if a fieldInstanceIdentifier (which might start with any number of underscores)
    // is marked hidden in 'fieldsToHide'
    const fieldElems = useMemo(() => {
        return (fields || []).map((f) => {
            const removedByCasetivityRemovedFieldsDataProperty = getIsRemovedByCasetivityRemovedFieldsDataProperty(
                casetivityRemovedFields,
            )(f.props.source);

            let shouldHide = f.props.fieldInstanceIdentifier
                ? fieldsToHide[f.props.fieldInstanceIdentifier]
                : f.props.source
                ? fieldsToHide[f.props.source]
                : false;

            if (
                !shouldHide &&
                f.props.fieldInstanceIdentifier &&
                f.props.source?.endsWith('Id') &&
                fieldsToHide[f.props.fieldInstanceIdentifier + 'Id']
            ) {
                /**
                 * This check is for old configurations where 'Id' is appended to the custom Id in our visibility expression entries
                 * 
                 * That was broken by https://src.casetivity.com/ssg/casetivity-front-end/-/commit/5e1de4ee9134cd1a1d7d3b4e1fd6c6aadb7cc596
                 * and this code block makes those work again.
                 * 
                 * You can check for stuff like this by storing visibility/edit expression in the console as a global variable and using
                 * (e.g. as 'temp1')
                 * and pasting in the console
                 * 
                 * 
                 * console.log(JSON.stringify(Object.entries(temp1).filter(t => Object.keys(t[1]).length).map(t => [t[0], Object.keys(Object.fromEntries(Object.entries(t[1]).filter(tt => {
                    if (tt[0].endsWith('Id')) {
                    const allFields = { ...window.vc.views[t[0]].fields, ...Object.assign({}, ...Object.values(window.vc.views[t[0]].tabs || {}).flatMap(t => t.fields)) }
                    const entry =  allFields[tt[0].slice(0, -2)];
                    return entry?.overrideId
                    }
                    return false
                    })))]).filter(t => Object.values(t[1]).length), null, 1))
                 */
                shouldHide = true;
            }
            if (
                !shouldHide &&
                f.props.fieldInstanceIdentifier?.endsWith('Id') &&
                fieldsToHide[f.props.fieldInstanceIdentifier.slice(0, 'Id'.length * -1)]
            ) {
                /**
                 * This can happen when using component views:
                 * We have no expression on a x-1 field 'foo' in the component.
                 * We use the component, and have a visibility expression on the component, so we generate a visibility expression using the component,
                 * which does <componentName> + <key>
                 * where key is either an id or the field name
                 * (So $componentName.foo) <- visibility expression
                 *
                 * But the component's fieldIdentifier when we generate the expanded fields is <componentName> + <source>
                 *
                 * So we have that being '$componentName.fooId' <- field props
                 *
                 * One possible alternate workaround to the above is to append 'Id' at the end when generating visibility expressions by checking if the field is a ref-1.
                 */
                shouldHide = true;
            }

            return shouldHide || hideMe || removedByCasetivityRemovedFieldsDataProperty ? (
                <Hidden dontShowCol={true} {...f.props}>
                    {f}
                </Hidden>
            ) : (
                f
            );
        });
    }, [fields, fieldsToHide, hideMe, casetivityRemovedFields]);
    return <RGridInner {...rest} fields={fieldElems} />;
});

type RGridWithVisibilityProps = Subtract<RGridWithVisProps, Pick<RGridWithVisProps, 'fieldsToHide'>> & {
    isShow?: boolean;
};
const RGridWithVisibilityInner: FunctionComponent<
    RGridWithVisibilityProps & {
        hiddenFields: {
            [field: string]: boolean;
        };
    }
> = React.memo(({ hiddenFields, ...props }) => {
    /*
        Below: only update if hiddenFields is structurally different
        (the reference changes frequently, e.g. on a form onChange).
    */
    const fieldsToHideStr = useMemo(() => {
        return stableStringify(hiddenFields);
    }, [hiddenFields]);
    const fieldsToHide = useMemo(() => {
        return hiddenFields;
    }, [fieldsToHideStr]); // eslint-disable-line

    return <RGridWithVis fieldsToHide={fieldsToHide} {...props} />;
});

export const useGetCasetivityRemovedFields = (fc: { fieldValues: { entityType?: string; id?: string } }) => {
    const { id, entityType } = fc.fieldValues;
    const getEntities = useMemo(createGetEntities, []);
    const getCasetivityRemovedFieldsSelector = useMemo(
        () =>
            createSelector(
                getEntities,
                (entities: {}) => entities[entityType]?.[id]?.casetivityRemovedFields as string[],
            ),
        [getEntities, entityType, id],
    );
    const _casetivityRemovedFields = useAppSelector(getCasetivityRemovedFieldsSelector);

    // don't change reference
    const casetivityRemovedFields = useMemo(() => {
        return _casetivityRemovedFields;
    }, [...(_casetivityRemovedFields || [])]); // eslint-disable-line
    return casetivityRemovedFields;
};

export const useCasetivityRemovedFields = (viewType: 'show' | 'edit') => {
    const fc = React.useContext(
        viewType === 'show' ? showFormContext : (formContext as unknown as Context<ShowFormContext>),
    );
    const casetivityRemovedFields = useGetCasetivityRemovedFields(fc);
    return [casetivityRemovedFields, fc] as [undefined | string[], ShowFormContext];
};

const RGridWithVisibility: FunctionComponent<RGridWithVisibilityProps> = React.memo(
    (props: RGridWithVisibilityProps) => {
        const [casetivityRemovedFields, fc] = useCasetivityRemovedFields(props.isShow ? 'show' : 'edit');

        return (
            <RGridWithVisibilityInner
                hiddenFields={fc.hiddenFields}
                casetivityRemovedFields={casetivityRemovedFields}
                {...props}
            />
        );
    },
);

export default RGridWithVisibility;
