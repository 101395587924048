import fclone from 'fclone';
import get from 'lodash/get';
import set from 'lodash/set';
import { isPlainObject } from 'lodash';

const arraysEqlAsIds = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sorted1 = arr1.map((e) => `${e}`).sort();
    const sorted2 = arr2.map((e) => `${e}`).sort();
    return !sorted1.find((e, i) => sorted2[i] !== e);
};

const equal = (_left, _right) => {
    let left = _left ?? null;
    let right = _right ?? null;
    if (left === '') {
        left = null;
    }
    if (right === '') {
        right = null;
    }
    if (Array.isArray(left) && Array.isArray(right)) {
        return arraysEqlAsIds(left, right);
    }
    if (Array.isArray(left) && left.length === 0 && !right) {
        return true;
    }
    if (Array.isArray(right) && right.length === 0 && !left) {
        return true;
    }
    if ((right === false && left === null) || (left === false && right === null)) {
        // treat null and false the same - 'null' boolean fields have their values treated as false.
        // ***We can do this because we have no 'null-boolean' field types on the entity-side.***
        // (If we receive 'boolean' as a value, we KNOW null will be treated as false.)

        // The alternative solution is to coerce initialValues such that boolean fields with null are replaced with a value of false.
        return true;
    }

    return right === left;
};

const getConflictingEdits = (
    previousInitialValues: {},
    newInitialValues: {},
    currentFormData: {},
): null | {
    formValues: {};
    newInitialValuesConflictedWith: {};
} => {
    if (previousInitialValues === newInitialValues) {
        return null;
    }
    const traverseCurrentFormData = (currentFormData: unknown) => {
        const acc: string[] = [];
        const innerTraverseCurrent = (currData: unknown, currPathToData: string = '') => {
            if (isPlainObject(currData)) {
                Object.entries(currData).forEach(([k, v]) => {
                    const nextPath = currPathToData ? currPathToData + '.' + k : k;
                    innerTraverseCurrent(v, nextPath);
                });
            } else {
                const prevValue = get(previousInitialValues, currPathToData, undefined);
                const incomingValue = get(newInitialValues, currPathToData, undefined);
                /**
                 * Based on what's in getConflictingEdits.spec.ts,
                 * we mark it as a conflict only if the key is present in both the previous and next initial values.
                 * So lets add that in as a condition.
                 */
                if (typeof prevValue === 'undefined' || typeof incomingValue === 'undefined') {
                    // require key to be present in both new and previous initial values to be marked as conflict.
                    return;
                }
                if (
                    !equal(currData, prevValue) &&
                    !equal(currData, incomingValue) &&
                    !equal(prevValue, incomingValue)
                ) {
                    acc.push(currPathToData);
                }
            }
        };
        // just adding fclone here to prevent recursing on cyclic references
        innerTraverseCurrent(fclone(currentFormData));
        return acc;
    };
    const diffPaths = traverseCurrentFormData(currentFormData);
    if (diffPaths.length === 0) {
        return null;
    }
    const formValues = diffPaths.reduce((prev, currPath) => {
        set(prev, currPath, get(currentFormData, currPath));
        return prev;
    }, {});

    const newInitialValuesConflictedWith = diffPaths.reduce((prev, currPath) => {
        set(prev, currPath, get(newInitialValues, currPath));
        return prev;
    }, {});

    return {
        formValues,
        newInitialValuesConflictedWith,
    };
};

export default getConflictingEdits;
