import React from 'react';
import { CardContent, Typography, Card, createStyles, Theme, makeStyles } from '@material-ui/core';
import { RootState, useAppSelector } from 'reducers/rootReducer';

export interface FormData {
    password1: string;
    password2: string;
}

export interface PasswordResetElements {
    PasswordElement: JSX.Element;
    PasswordConfirmElement: JSX.Element;
    PasswordResetStatusElement: JSX.Element;
    PageTextElement: JSX.Element;
    ActionsElement: JSX.Element;
}
export interface SelfResetProps {
    location: Location;
    renderForm?: (props: PasswordResetElements) => JSX.Element;
}
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            height: '100%',
            backgroundColor: theme.palette.primary.main,
        },
        applicationHeader: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 100px)',
            alignItems: 'center',
            justifyContent: 'center',
        },
        card: {
            minWidth: 300,
        },
    }),
);
const DefaultRendererComponent = ({
    PasswordElement,
    PasswordConfirmElement,
    PasswordResetStatusElement,
    PageTextElement,
    ActionsElement,
    showTitle,
}: PasswordResetElements & { showTitle: boolean }) => {
    const classes = useStyles();
    const displayTitle = useAppSelector((state: RootState) => state.basicInfo?.title ?? 'Casetivity');
    return (
        <div className={classes.background}>
            {showTitle ? (
                <div style={{ padding: '1em' }}>
                    <Typography className={classes.applicationHeader} variant="h4" component="h1">
                        {displayTitle}
                    </Typography>
                </div>
            ) : null}
            <div className={classes.main}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Reset Password
                        </Typography>
                        <br />
                        {PasswordElement}
                        {PasswordConfirmElement}
                        {PasswordResetStatusElement}
                        {PageTextElement}
                    </CardContent>
                    {ActionsElement}
                </Card>
            </div>
        </div>
    );
};
type GetRendererOptions = {
    showTitle: boolean;
};
export const getDefaultRenderer: (options: GetRendererOptions) => SelfResetProps['renderForm'] =
    (options) => (props) => {
        return <DefaultRendererComponent showTitle={options.showTitle} {...props} />;
    };
