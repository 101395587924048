import {
    useExpandedRecord,
    getRenderActionButton,
} from 'components/generics/viewActions/ActionsWithOverrides/Component';
import { EntityActions } from 'components/generics/viewActions/ActionsWithOverrides/EntityActions';
import React from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import { ButtonProps } from '@material-ui/core';
import { useAppSelector } from 'reducers/rootReducer';

interface ActionButtonProps {
    definition: EntityActions[0];
    ButtonProps?: ButtonProps;
}
const ActionButton: React.FC<ActionButtonProps> = ({ definition, ButtonProps }) => {
    const fc = useCurrentFormContext();
    const viewConfig = useViewConfig();

    const resource = viewConfig.views[fc.viewName]?.entity;

    const printMode = useAppSelector((state) => state.printMode);
    const expandedRecord = useExpandedRecord({
        id: (fc.fieldValues as { id?: string }).id,
        viewName: fc.viewName,
        // overrideActionButtonExp,
        overrideViewConfig: viewConfig,
        entityViewConfig: {
            entityActions: [definition],
        },
    });
    if (printMode) {
        return null;
    }
    return <div>{getRenderActionButton(expandedRecord, resource)(definition, ButtonProps)}</div>;
};
export default ActionButton;
