import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import { CompileExpression } from 'expressions/Provider/implementations/CompileExpression';
import React, { useState } from 'react';
import { Store } from 'redux';
import { accumulateExpressions } from 'expressions/Provider/hooks/accumulateExpressions';
import { RootState, useAppStore } from 'reducers/rootReducer';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import filterEntityByQueryRepresentation from 'isomorphic-query-filters/filterEntityByQueryRepresentation';
import useEvalContext from 'expressions/Provider/hooks/useEvalContext';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { unflatten } from 'flat';
import ViewConfig from 'reducers/ViewConfigType';
import getFormId from './filter/getFormId';
import { isPromiseResolved } from 'promise-status-async';
import getImpl from 'expressions/Provider/implementations/getImpl';
import Message from 'i18n/components/Message';

export const getMapSearchToCreateExpression = (viewConfig: ViewConfig, listViewName: string) => {
    const config = viewConfig.views[listViewName]?.config;
    if (!config) {
        return null;
    }
    try {
        const parsed = JSON.parse(config) as { mapSearchToCreateExpression?: string };
        return parsed?.mapSearchToCreateExpression ?? null;
    } catch (e) {
        console.error(e);
        return null;
    }
};

interface MappingCreateButtonProps {
    searchExpression: string;
    formId: string;
    entityType: string;
    onResult: (value: string) => void;
    children: (props: { onClick: () => void }) => void;
}
const compileExpression: CompileExpression = getImpl().compileExpression;
const parse = getNextBracketedExpression({
    bracketClosesOn: ']',
    bracketNaivelyOpensOn: '[',
    bracketOpensOn: '$[',
});
/**
 *
 * TODO:
 * Make this a while loop that continues until all searchEntityDatas are called on the path needed.
 * While "newSearchEntityDataCalls"
 *   track 'filledSearchEntityDataCalls'
 *
 */

// returns a promise giving the built string.
const evaluateExpressions = (expressions: string[], context: {}) => {
    const results = [];
    expressions.forEach((exp) => {
        const res = compileExpression(exp);
        if (res.type === 'parse_failure') {
            throw new Error('failed'); // TODO - fine for now
        }

        const result = res.evaluate(context, context);
        if (result.type === 'evaluation_failure') {
            throw new Error('failed'); // TODO - fine for now
        }
        results.push(result.result);
    });
    return results;
};
const onGetMappedCreate2 = async (store: Store, context: {}, template: string): Promise<string> => {
    const expressions = accumulateExpressions(template);
    let remoteSearches: { entityType: string; filterExpression: string; completed?: boolean }[] = [];
    const searchEntityData = (entityType: string, filterExpression: string) => {
        if (
            !remoteSearches.some(
                ({ entityType: et, filterExpression: fe }) => et === entityType && fe === filterExpression,
            )
        ) {
            remoteSearches.push({ entityType, filterExpression });
            return [];
        }
        // the remote search has been performed on this pass -
        // look up the data.
        const filterObj = getFilterFromFilterString(filterExpression);
        const entities = (store.getState() as RootState).admin.entities;
        const toSearch = Object.values(entities[entityType] ?? {});
        return toSearch.filter((record) => {
            return filterEntityByQueryRepresentation(store.getState().viewConfig)(filterObj)(record, entities);
        });
    };
    const newContext = { ...context, searchEntityData };
    let evaluatedResults: string[] = evaluateExpressions(expressions, newContext);
    const getPendingRemoteSearches = () => remoteSearches.filter(({ completed }) => !completed);
    while (getPendingRemoteSearches().length > 0) {
        const allPromises = getPendingRemoteSearches().map(
            ({ entityType, filterExpression }) =>
                new Promise<{ entityType: string; filterExpression: string }>((res, rej) =>
                    store.dispatch(
                        crudGetList({
                            resource: entityType,
                            filter: getFilterFromFilterString(filterExpression),
                            view: null,
                            pagination: {
                                page: 1,
                                perPage: 100, // TODO parameterize?
                            },
                            sort: {
                                field: 'id',
                                order: 'ASC',
                            },
                            cb: () => res({ entityType, filterExpression }),
                            errorsCbs: {
                                '*': rej,
                            },
                        }),
                    ),
                ),
        );
        const results = await Promise.all(allPromises);
        results.forEach(({ entityType, filterExpression }) => {
            remoteSearches
                .filter((entry) => entry.entityType === entityType && entry.filterExpression === filterExpression)
                .forEach((entry) => {
                    entry.completed = true;
                });
        });
        // wait for synchronous redux stuff to finish
        await new Promise((res) => setTimeout(res, 100));
        evaluatedResults = evaluateExpressions(expressions, newContext);
    }
    // now just template the values in.
    let i = 0;
    const evalString = (str: string) =>
        parse(str).fold(str, ({ before, inner, after }) => {
            const result = evaluatedResults[i++];
            return `${before}${result ?? ''}${evalString(after)}`;
        });
    return evalString(template);
};

const convertSearchValuesToValues = (obj): Record<string, any> => {
    return unflatten(
        Object.fromEntries(
            Object.entries(obj).flatMap(([k, v]) => {
                const [key, searchType] = k.split('_~_').join('.').split('__');
                if (!searchType || searchType === 'EXACT') {
                    return [[key, v]];
                }
                return [];
            }),
        ),
    );
};

const MappingCreateButton: React.FC<MappingCreateButtonProps> = ({
    formId,
    entityType,
    searchExpression,
    onResult,
    children,
}) => {
    const store = useAppStore();
    const context = useEvalContext();
    const [pending, setPending] = useState(false);
    const onClick = () => {
        const searchValues = (() => {
            const _form = store.getState().form[getFormId(entityType, formId)];
            const registeredValues = Object.fromEntries(
                Object.entries(_form.registeredFields ?? {}).map(([k, v]) => [k, _form.values[k] ?? null]),
            );
            return registeredValues;
        })();
        const values = convertSearchValuesToValues(searchValues);

        (async () => {
            const promise = onGetMappedCreate2(store, { ...context, ...values }, searchExpression);
            promise.then((res) => {
                setPending(false);
                onResult(res);
            });
            const isResolved = await isPromiseResolved(promise);
            if (!isResolved) {
                setPending(true);
            }
        })();
    };
    return (
        <>
            {children({ onClick })}
            <Dialog open={pending}>
                <DialogTitle>Loading</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'grid', placeItems: 'center', padding: '1em' }}>
                        <CircularProgress />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Typography>
                            <Message id="loading.pleaseWait" dm="Please wait..." />
                        </Typography>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default MappingCreateButton;
