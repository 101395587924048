import React from 'react';
import { getProcessDefinitions as getProcessDefinitionsAction } from 'bpm/processDefinitions/actions';
import { load as loadViewConfigAction } from 'viewConfig/actions';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { storageController } from 'storage';
import { useHistory } from 'react-router-dom';
import useViewConfig from 'util/hooks/useViewConfig';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/rootReducer';

const useSetupOnMount = () => {
    const viewConfig = useViewConfig();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const organizationId = React.useMemo(() => {
        return viewConfig && viewConfig.user && viewConfig.user.properties && viewConfig.user.properties.organizationId;
    }, [viewConfig]);
    const viewConfigIsLoading = useAppSelector((state: RootState) => state.viewConfigIsLoading);
    const fetchOrganizationIfUserHasOne = React.useCallback(
        (_organizationId: string) => {
            if (_organizationId && viewConfig.entities && viewConfig.entities.Organization) {
                dispatch(
                    crudGetOneAction(
                        {
                            resource: 'Organization',
                            id: _organizationId,
                            view: -1,
                            appendExpansions: ['all'],
                        },
                        false,
                    ),
                );
            }
        },
        [viewConfig, dispatch],
    );

    React.useEffect(() => {
        if (
            process.env.NODE_ENV !== 'test' &&
            !(window as any).CASETIVITY_ANON_VIEW_CONFIG &&
            !storageController.getToken()
        ) {
            const path = `/logout-redirect?redirectTo=${encodeURIComponent(
                window.location.pathname + window.location.search,
            )}`;
            setTimeout(() => push(path), 10);
        } else {
            if ((global as any).REFERRED_AUTH) {
                // auth token is present from auth referral, but viewConfig is not. Must load viewConfig
                dispatch(loadViewConfigAction());
            }
            fetchOrganizationIfUserHasOne(organizationId);
            if (!viewConfigIsLoading) {
                // lets let the viewConfig come back with our roles first
                // since the processDefinitions query depends on them.
                dispatch(getProcessDefinitionsAction());
            }
        }
    }, []); // eslint-disable-line
    React.useEffect(() => {
        fetchOrganizationIfUserHasOne(organizationId);
    }, [organizationId, fetchOrganizationIfUserHasOne]);
};

export default useSetupOnMount;
