import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { delViewDetailByUuid, pushViewDetail } from './viewDetailsSlice';
import { RootState, useAppStore } from 'reducers/rootReducer';

function useRegisterViewDetails(formId: string, type: 'entity'): [_internal_uuid: string];
function useRegisterViewDetails(
    formId: string,
    type: 'entity',
    entityId: string,
): [_internal_uuid: string, sameEntityExistsBelowInStack: () => boolean];
function useRegisterViewDetails(formId: string, type: 'start-form'): [_internal_uuid: string];
function useRegisterViewDetails(
    formId: string,
    type: 'entity' | 'start-form',
    entityId?: string,
): [string] | [string, () => boolean] {
    const store = useAppStore();
    const _internal_uuid: string = useMemo(() => {
        return uuidv4();
    }, []);
    useEffect(() => {
        store.dispatch(
            pushViewDetail({
                uuid: _internal_uuid,
                formId,
                type,
                entityId,
            }),
        );
        return () => {
            store.dispatch(delViewDetailByUuid(_internal_uuid));
        };
    }, [_internal_uuid, store, formId, type, entityId]);

    const sameEntityExistsBelowInStack = useCallback(() => {
        if (!entityId) {
            return false;
        }
        return (store.getState() as RootState).viewDetailsStack
            .filter(({ uuid }) => uuid !== _internal_uuid)
            .some((viewDetail) => {
                return viewDetail.type === 'entity' && viewDetail.entityId === entityId;
            });
    }, [store, entityId, _internal_uuid]);
    if (!entityId) {
        return [_internal_uuid];
    }
    return [_internal_uuid, sameEntityExistsBelowInStack];
}

export default useRegisterViewDetails;
