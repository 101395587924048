import React, { useMemo } from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { z } from 'zod';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
/**
 * Example calc
 * {"groups":[{"data":[1,2,3],"label":"12/1/2023"},{"data":[4,5,6],"label":"12/2/2023"}],"labels":["Dataset1","Dataset2","Dataset3"]}
 */

const groupedBarData = z.object({
    labels: z.array(z.string()),
    groups: z.array(
        z
            .object({
                data: z.array(z.number()),
                label: z.string(),
            })
            .passthrough(),
    ),
    xLabel: z.string().optional(),
    yLabel: z.string().optional(),
});

export type GroupedBarData = z.infer<typeof groupedBarData>;

interface GroupedBarChartProps {
    groupedBarData: GroupedBarData;
    titleText?: string;
}

// // Example chart.js data:
// const data = {
//     labels: ['one', 'two', 'three'],
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: [11, 2, 1],
//             // backgroundColor: 'red',
//             // if we want to stack items.
//             // stack: 'Stack 0',
//         },
//         {
//             label: 'Dataset 2',
//             data: [0, 23, 24],
//             // backgroundColor: 'blue',
//         },
//         {
//             label: 'Dataset 3',
//             data: [33, 99, 100],
//             // backgroundColor: 'green',
//         },
//     ],
// };
const GroupedBarChart: React.FC<GroupedBarChartProps> = ({ titleText, groupedBarData }) => {
    const chartJsData = useMemo(() => {
        return {
            labels: groupedBarData.labels,
            datasets: groupedBarData.groups,
        } as ChartData<'bar', number[], string>;
    }, [groupedBarData]);
    const { yLabel, xLabel } = groupedBarData;
    return (
        <Bar
            data={chartJsData}
            options={{
                plugins: {
                    title: titleText
                        ? {
                              display: true,
                              text: titleText,
                          }
                        : undefined,
                },
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    y: yLabel && {
                        title: {
                            display: true,
                            text: yLabel,
                        },
                    },
                    x: xLabel && {
                        title: {
                            display: true,
                            text: xLabel,
                        },
                    },
                },
            }}
        />
    );
};

const RuntimeValidatedGroupedBarChart = (props: GroupedBarChartProps) => {
    const result:
        | {
              type: 'success';
              parsed: GroupedBarData;
          }
        | {
              type: 'failure';
              message: string;
          } = useMemo(() => {
        try {
            const parsed = groupedBarData.parse(props.groupedBarData) as GroupedBarData;
            return {
                type: 'success',
                parsed,
            };
        } catch (e) {
            console.error(e);
            return {
                type: 'failure',
                message: e.message,
            };
        }
    }, [props.groupedBarData]);

    if (result.type === 'failure') {
        return (
            <Alert severity="error">
                <AlertTitle>Configuration Error</AlertTitle>
                <p>
                    The data passed to the report widget is of an unexpected shape. Details may be found below. A
                    descriptive error has also been logged to the console.
                </p>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <table>
                            <tr>
                                <th>Incoming</th>
                                <td style={{ border: '1px solid black' }}>
                                    <pre>{JSON.stringify(props.groupedBarData, null, 2)}</pre>
                                </td>
                            </tr>
                            <tr>
                                <th>Expected (example)</th>
                                <td style={{ border: '1px solid black' }}>
                                    <pre>
                                        {JSON.stringify(
                                            (() => {
                                                const type: GroupedBarData = {
                                                    labels: ['Dataset 1', 'Dataset 2'],
                                                    groups: [
                                                        {
                                                            data: [1, 10],
                                                            label: 'Group 1',
                                                            backgroundColor: 'red',
                                                        },
                                                        {
                                                            data: [5, 8],
                                                            label: 'Group 2',
                                                        },
                                                    ],
                                                };
                                                return type;
                                            })(),
                                            null,
                                            2,
                                        )}
                                    </pre>
                                </td>
                            </tr>
                            <tr>
                                <th>Errors</th>
                                <td style={{ border: '1px solid black' }}>
                                    <pre>{result.message}</pre>
                                </td>
                            </tr>
                        </table>
                    </AccordionDetails>
                </Accordion>
            </Alert>
        );
    }
    return <GroupedBarChart {...props} groupedBarData={result.parsed} />;
};

export default RuntimeValidatedGroupedBarChart;
