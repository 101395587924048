import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { CheckCircle, Error as ErrorIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { State } from 'components/ConfigPackDownload/StateUnion';
import { useRef, useState } from 'react';
import buildHeaders from 'sideEffect/buildHeaders';
import Paper from '@material-ui/core/Paper/Paper';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
const config = require('../config.js');

const resolveConflict = (abortRef: AbortController) => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/admin/configs/resolve-conflict`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/zip',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({}),
        signal: abortRef.signal,
    });
    return fetch(request).then((res) => {
        if (!res.ok) {
            throw new Error('Request failed with status ' + res.status);
        }

        return res.blob();
    });
};

export const ResolveConflictButton = () => {
    const abortController = useRef<AbortController | null>(null);
    const [state, setState] = useState<State>({ type: 'INITIAL' });

    const handleReset = () => {
        setState({ type: 'INITIAL' });
    };

    const handleResolve = () => {
        if (abortController.current) {
            abortController.current.abort();
        }
        const controller = new AbortController();
        abortController.current = controller;

        setState({ type: 'LOADING' });

        resolveConflict(abortController.current)
            .then(() => {
                setState({ type: 'SUCCESS' });
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.error(e);
                }
                if (!controller.signal.aborted) {
                    setState({ type: 'ERROR', errorMessage: e.message });
                }
            });
    };
    const isButtonDisabled = state.type === 'LOADING' || state.type === 'SUCCESS';

    const stateProperties = {
        INITIAL: {
            buttonText: 'Approve Conflict Resolution',
            buttonIcon: null,
            tooltipText: 'Click to resolve conflict',
        },
        LOADING: {
            buttonText: 'Loading...',
            buttonIcon: <CircularProgress size={24} />,
            tooltipText: 'Loading...',
        },
        ERROR: {
            buttonText: 'Retry',
            buttonIcon: <ErrorIcon color="error" />,
            tooltipText: 'Request failed. Retry?',
        },
        SUCCESS: {
            buttonText: 'Success',
            buttonIcon: <CheckCircle color="primary" />,
            tooltipText: 'Resolve conflict request sent. If unsuccessful, you will receive updates through email.',
        },
    };

    const buttonText = stateProperties[state.type].buttonText;
    const buttonIcon = stateProperties[state.type].buttonIcon;
    const tooltipText = stateProperties[state.type].tooltipText;

    return (
        <Tooltip title={tooltipText}>
            <span>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    onClick={() => {
                        switch (state.type) {
                            case 'INITIAL': {
                                handleResolve();
                                break;
                            }
                            case 'SUCCESS': {
                                break;
                            }
                            case 'ERROR': {
                                handleReset();
                                break;
                            }
                        }
                    }}
                    startIcon={buttonIcon}
                >
                    {buttonText}
                </Button>
            </span>
        </Tooltip>
    );
};

const ResolveConflictPage = () => {
    return (
        <>
            <SsgAppBarMobile title={'Resolve Merge Conflict'}></SsgAppBarMobile>
            <Paper
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '1em',
                    marginBottom: '2em',
                }}
            >
                <ResolveConflictButton />
            </Paper>
        </>
    );
};
export default ResolveConflictPage;
