import React, { FunctionComponent, useMemo, useState } from 'react';
import { View, FieldViewField, ViewField } from 'reducers/ViewConfigType';
import { useFormContext } from 'react-hook-form';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    IconButton,
    Dialog,
    makeStyles,
    createStyles,
    Theme,
    Button,
} from '@material-ui/core';
import {
    getLabelForFieldExpr,
    isExpressionViewField,
    isFieldViewField,
} from 'components/generics/utils/viewConfigUtils';
import useViewConfig from 'util/hooks/useViewConfig';
import Reorder from 'react-reorder';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddField from 'layout-editor/add-field/components/AddViewField';
import fromEntries from 'util/fromentries';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            display: 'inline-block',
        },
        list: {
            display: 'inline-block',
        },
        reorderableListItem: {
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.grey[300],
            },
        },
    }),
);

interface ListViewEditorProps {
    rootEntity: string;
    setFields: (fields: View['fields']) => void;
    fields: View['fields'];
}
export const ListViewEditor: FunctionComponent<ListViewEditorProps> = ({ fields, setFields, rootEntity }) => {
    const viewConfig = useViewConfig();
    const list = useMemo(() => Object.values(fields), [fields]);
    const classes = useStyles();
    const [openForEditIx, setOpenForEditIx] = useState<number | 'newButton'>(-1);
    return (
        <React.Fragment>
            <Dialog maxWidth={false} fullWidth={true} open={openForEditIx !== -1} onClose={() => setOpenForEditIx(-1)}>
                {openForEditIx !== -1 && (
                    <div style={{ margin: '1em', padding: '1em' }}>
                        <AddField
                            requireExpressionFieldId
                            includeSortAttributes
                            rootEntity={rootEntity}
                            initialValues={
                                openForEditIx === 'newButton'
                                    ? undefined
                                    : {
                                          ...list[openForEditIx],
                                          fieldPath: (list[openForEditIx] as FieldViewField).field,
                                      }
                            }
                            onAdd={(_field) => {
                                const field =
                                    openForEditIx === 'newButton'
                                        ? _field
                                        : {
                                              order: list?.[openForEditIx]?.order, // keep order around if editing existing
                                              ..._field,
                                          };
                                setFields(
                                    fromEntries(
                                        (openForEditIx === 'newButton'
                                            ? [...list, field]
                                            : [...list.slice(0, openForEditIx), field, ...list.slice(openForEditIx + 1)]
                                        ).flatMap((f): [string, ViewField][] =>
                                            isFieldViewField(f)
                                                ? [[f.field, f]]
                                                : isExpressionViewField(f) && f.field
                                                ? [[f.field, f]]
                                                : [],
                                        ),
                                    ),
                                );
                                setOpenForEditIx(-1);
                            }}
                        />
                    </div>
                )}
            </Dialog>
            <Table>
                <TableHead>
                    <TableRow>
                        <Reorder
                            itemKey="key"
                            list={list || []}
                            template={(props) => (
                                <TableCell className={classes.reorderableListItem}>
                                    <DragIndicatorIcon
                                        style={{
                                            opacity: 0.5,
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    {props.item.label ||
                                        (isFieldViewField(props.item) &&
                                            getLabelForFieldExpr(
                                                viewConfig,
                                                props.item.entity,
                                                props.item.field,
                                                'POP_LAST',
                                            ))}
                                    <IconButton
                                        size="small"
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onClick={(e) => {
                                            setOpenForEditIx((list || []).indexOf(props.item));
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onClick={(e) => {
                                            setFields(
                                                fromEntries(
                                                    [
                                                        ...list.slice(0, list.indexOf(props.item)),
                                                        ...list.slice(list.indexOf(props.item) + 1),
                                                    ].map(
                                                        (f) =>
                                                            [(f as FieldViewField).field, f] as [
                                                                string,
                                                                FieldViewField,
                                                            ],
                                                    ),
                                                ),
                                            );
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            )}
                            // Function that is called once a reorder has been performed
                            callback={(
                                event,
                                itemThatHasBeenMoved,
                                itemsPreviousIndex,
                                itemsNewIndex,
                                reorderedArray,
                            ) => {
                                setFields(reorderedArray.map((e, i) => ({ ...e, order: i })));
                            }}
                            listClass={classes.list}
                            itemClass={classes.listItem}
                            selectedKey="tabKey"
                            disableReorder={false}
                            disableDragClass="no-drag"
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            endIcon={<Add />}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                            onClick={(e) => {
                                setOpenForEditIx('newButton');
                            }}
                        >
                            Add Column
                        </Button>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {Object.values(fields).map((f, i) => {
                            return <TableCell key={i}></TableCell>;
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </React.Fragment>
    );
};

const ListViewColumnsController: FunctionComponent<{ rootEntity: string }> = (props) => {
    const { watch, setValue } = useFormContext();
    const fields: View['fields'] = watch('fields');
    const setFields = React.useCallback(
        (fields: View['fields']) => {
            setValue('fields', fields, {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        [setValue],
    );
    return <ListViewEditor rootEntity={props.rootEntity} fields={fields} setFields={setFields} />;
};
export default ListViewColumnsController;
