import React, { useState, useCallback, useMemo } from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { useDispatch } from 'react-redux';
import { tryCatch } from 'fp-ts/lib/Either';

const DebugActionDispatcher: React.SFC<{}> = (props) => {
    const dispatch = useDispatch();
    const open = useAppSelector((state: RootState) => state.expressionTesterOpen);
    const [text, setText] = useState('');
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setText(e.target.value);
        },
        [setText],
    );
    const parsedAction = useMemo(
        () =>
            tryCatch(
                () => {
                    return JSON.parse(text);
                },
                (e: Error) => e,
            ),
        [text],
    );
    const dispatchAction = useCallback(() => {
        if (parsedAction.isRight()) {
            dispatch(parsedAction.value);
        }
    }, [parsedAction, dispatch]);
    if (!open) {
        return null;
    }
    return (
        <div style={{ paddingLeft: '20px' }}>
            <textarea value={text} onChange={handleChange} />
            {parsedAction.fold(
                (error) => (
                    <div>
                        Invalid JSON:
                        {error.message}
                    </div>
                ),
                (action) => (
                    <div>
                        <button onClick={dispatchAction}>Dispatch</button>
                    </div>
                ),
            )}
        </div>
    );
};
export default DebugActionDispatcher;
