/*
    defaultMessages should be applied to FormattedMessage defaultMessage literal prop when possible.
    https://formatjs.io/docs/getting-started/message-declaration
    Since some FormattedMessages are templated by configuration, we can apply defaults here when there isn't a component in code
*/

const defaultMessages = {
    processes: {
        case: 'Case',
        cases: 'Cases',
        backToCase: 'Back to Case',
        processDetails: {
            caseType: 'Case Type',
            caseStatus: 'Case Status',
            caseNumber: 'Case Number',
            caseStarted: 'Case Started',
            caseEnded: 'Case Ended',
            closeCase: 'Close Case',
            processVariables: 'Process Variables',
            deleteCase: 'Delete Case',
            openedDate: 'Opened Date',
            unlinkCase: 'Unlink Case',
        },
        search: {
            cases: 'Search Cases',
        },
        caseNotes: {
            showAll: 'Show all Case notes',
        },
        task: {
            closedSuccess: 'Task {0} has been closed successfully.',
        },
        caseCalendar: {
            showCaseCalendar: 'Show Case Calendar',
        },
    },
    reports: {
        personalized: {
            description: '',
        },
        standard: {
            description: '',
        },
        delivered: {
            description: '',
        },
    },
    action: {
        actionFailed: 'Action failed',
        tryAgain: 'Try Again',
    },
    dialog: {
        close: 'Close',
        cancel: 'Cancel',
        continue: 'Continue',
    },
    loading: {
        pleaseWait: 'Please wait...',
    },
    validate: {
        error: 'Error',
        warning: 'Warning',
        errors: 'errors',
        required: 'required',
        Required: 'Required',
        maxLength: 'Must be {0} characters or less',
        minLength: 'Must be {0} characters at least',
        minValue: 'Must be at least {0}',
        maxValue: 'Must be {0} or less',
        mustBeNumber: 'Must be a number',
        mustBeEmail: 'Must be a valid email',
    },
    file: {
        choose: 'Choose File',
        noFiles: 'No files chosen',
        failure: {
            empty: 'File could not be uploaded because the file is empty.',
            size: 'File is too large. Upload limit is {0}',
        },
    },
    date: {
        invalidFormat: 'Invalid Date Format',
    },
    widgets: {
        datetime: {
            helpertext:
                'Expected format is month month / day day / year year year year hour hour : minute minute am or pm',
        },
    },
    auth: {
        mfa: {
            expired: 'MFA token has expired, please login again.',
        },
        code: {
            authcode: 'authorization code',
            characterOf: 'character {0} of {1}',
            retry: 'Authentication failed. Please retry.',
        },
        login: {
            login: 'Username',
            password: 'Password',
            failed: 'Login Failed, please check your username and password.',
            failedAttempts: 'Number of failed login attempts reached for user {0}.',
            forgotPasswordPrompt: 'Forgot password?',
            signIn: 'Sign in',
        },
        logout: {
            success: 'Logged out successfully.',
        },
    },
    primatives: {
        true: 'True',
        false: 'False',
        null: 'Null',
    },
    offline: {
        task: {
            download: 'Download Task',
            downloading: 'Downloading Task Data',
            changesBeingBackedUp: 'Your changes will be backed up as you work.',
        },
        pin: {
            enterPin: 'Enter your security PIN',
            choosePin: 'Choose a security PIN',
            pinMustMatchExistingError: 'The PIN used must match the PIN for existing offline data',
        },
    },
    list: {
        noResults: 'No results found',
    },
} as const;

export default defaultMessages;
