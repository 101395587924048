/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */
import React, { useContext, useMemo } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import DocxTemplater from 'docxtemplater';
import clone from 'clone';
import { fullyRenderDocument } from '@mkanai/casetivity-shared-js/lib/print-templates/render/renderDocument';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import { getDefaultPathsForPrintTemplate } from '@mkanai/casetivity-shared-js/lib/print-templates/parseDataPaths';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { processContext } from 'bpm/components/processContext';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';
import { Button, CircularProgress } from '@material-ui/core';
import sanitize from 'sanitize-filename';
import { getPrintTemplateByName } from 'printTemplate/getPrintTemplatesByName/actions';
import { casetivityViewContext, CasetivityConfigRootViewContextVariable } from 'util/casetivityViewContext';
import { PrintTemplate } from 'printTemplate/definitions';
import { useLocale } from 'i18n/useMessages';
import buildHeaders from 'sideEffect/buildHeaders';
import isOffline from 'util/isOffline';
import evaluateExpression from 'ts-spel-utils/evaluateExpression';

const config = require('../../config.js');

export const PAGE_BREAK_XML = '<w:br w:type="page" />';

export const getFileName = (
    printTemplateRecord: PrintTemplate,
    rootEntityRecord?: {
        id: string;
        title?: string;
        entityType: string;
        config?: string;
    },
) => {
    const recordTitle = rootEntityRecord && rootEntityRecord.title;
    let filename = '';
    if (printTemplateRecord && printTemplateRecord.displayName && recordTitle) {
        filename = sanitize(printTemplateRecord.displayName.concat('-').concat(recordTitle));
    } else if (printTemplateRecord && printTemplateRecord.displayName) {
        filename = sanitize(printTemplateRecord.displayName);
    } else if (recordTitle) {
        filename = sanitize(recordTitle);
    } else {
        filename = 'PrintTemplateFile';
    }
    return filename;
};
export const getDisplayText = (printTemplateRecord: PrintTemplate) => {
    if (printTemplateRecord) {
        return printTemplateRecord.displayName ? printTemplateRecord.displayName : printTemplateRecord.name;
    }
    return '';
};

const makeMapStateToProps = () => {
    const getEntities = createGetEntities();
    return (state: RootState, ownProps: PrintTemplateFileFieldProps) => {
        const printTemplateRecord = state.printTemplates.byName[ownProps.printTemplateName];
        return {
            entitiesLoading: state.admin.loading > 0,
            entities: getEntities(state),
            printTemplateRecord,
            viewConfig: state.viewConfig,
            task: ownProps.taskId && state.bpm.tasks.byId[ownProps.taskId],
        };
    };
};

const dispatches = {
    fetchPrintTemplateConfigByName: getPrintTemplateByName,
    fetchWithExpansions: (
        resource: string,
        id: string,
        expansions: string[],
        cb: (id: string, data: {}) => void,
        errorCb: () => void,
    ) =>
        crudGetOne({
            appendExpansions: expansions,
            cb,
            resource,
            id,
            errorsCbs: {
                '*': errorCb,
            },
            view: null,
        }),
};
type Dispatches = typeof dispatches;

function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
const getPrintTemplateDocument = (documentIdentifier: string): Promise<string> => {
    const request = new Request(
        `${config.BACKEND_BASE_URL}api/print-templates/download?anyBlobFieldIdentifier=${documentIdentifier}&anyBlobField=document`,
        {
            method: 'GET',
            credentials: 'same-origin',
            headers: buildHeaders({
                includeCredentials: true,
            }),
        },
    );
    return new Promise((resolve, reject) => {
        fetch(request).then(
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    response.arrayBuffer().then((data) => {
                        const b64 = _arrayBufferToBase64(data);
                        resolve(b64);
                    });
                } else {
                    reject(new Error(`Fetch failed with error code ${response.status}`));
                }
            },
            (error) => {
                reject(new Error(error.message));
            },
        );
    });
};

interface PrintTemplateFieldProps {
    resource: string;
    printTemplateName: string;
    record?: {
        id: string;
        title?: string;
        entityType: string;
        config?: string;
    };
    component?: 'button' | 'a' | 'mui-button';
    buttonClass?: string;
    anchorClass?: string;
    padding?: 'none' | 'default';
    displayText?: string;
    label?: string;
}

interface PrintTemplateFileFieldProps extends PrintTemplateFieldProps {
    formData?: {};
    viewContext: CasetivityConfigRootViewContextVariable;
    taskId?: string;
    locale: string;
}

interface PrintTemplateFileFieldComponentProps
    extends PrintTemplateFileFieldProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        Dispatches {}

interface PrintTemplateFileFieldComponentState {
    downloadOnNextRender:
        | {
              status: 'WHEN_LOADING_REACHES_0';
              documentB64: string;
          }
        | {
              status: 'YES';
              documentB64: string;
          }
        | {
              status: 'NO';
          };
    fetching: boolean;
}

class PrintTemplateFileFieldComponent extends React.Component<
    PrintTemplateFileFieldComponentProps,
    PrintTemplateFileFieldComponentState
> {
    state: PrintTemplateFileFieldComponentState = {
        downloadOnNextRender: {
            status: 'NO',
        },
        fetching: false,
    };
    componentDidMount() {
        if (this.props.taskId || this.props.viewContext === 'START_FORM') {
            /*
                If we are in the context of a task, we may be dealing with a non-entity related printTemplate,
                so we have to fetch it independently.
                (otherwise we are in a view, which should fetch all its printTemplates on its own.)
            */
            this.props.fetchPrintTemplateConfigByName(this.props.printTemplateName);
        }
    }
    getFileName = () => {
        const { printTemplateRecord, record: rootEntityRecord } = this.props;
        return getFileName(printTemplateRecord, rootEntityRecord);
    };
    getRootObject = (docB64?: string) => {
        const { record: rootEntityRecord, entities, viewConfig, resource } = this.props;
        const paths = this.getExpansions(docB64);
        const res = denormalizeEntitiesByPaths(entities, paths, viewConfig, resource, rootEntityRecord.id);
        console.log('denormalized rootObject with paths from config', res);
        return {
            ...res,
            _locale: this.props.locale,
            pageBreak: PAGE_BREAK_XML,
        };
    };
    getExpansions = (docB64?: string) => {
        const { printTemplateRecord, viewConfig, resource } = this.props;
        let paths: string[] = [];
        if (!printTemplateRecord.config && docB64) {
            paths = getDefaultPathsForPrintTemplate(viewConfig, resource, docB64).getOrElse([]);
        } else if (printTemplateRecord.config) {
            paths = JSON.parse(printTemplateRecord.config);
        }
        return paths;
    };
    fetchRecord = (docB64?: string) => {
        const { record: rootEntityRecord, resource } = this.props;
        const paths = this.getExpansions(docB64);
        this.props.fetchWithExpansions(
            resource,
            rootEntityRecord.id,
            paths,
            () => {
                // success callback:
                // Everything after this point (dispatching actions and updating store)
                // is synchronous. So our entities next render will have
                // the entities with the expansions merged in.
                this.setState({
                    downloadOnNextRender: {
                        status: 'YES',
                        documentB64: docB64,
                    },
                });
            },
            () => this.setState({ fetching: false }),
        );
    };
    componentDidUpdate(
        prevProps: PrintTemplateFileFieldComponentProps,
        prevState: PrintTemplateFileFieldComponentState,
    ) {
        if (prevState.downloadOnNextRender.status === 'NO' && this.state.downloadOnNextRender.status === 'YES') {
            if (this.props.entitiesLoading) {
                this.setState({
                    downloadOnNextRender: {
                        status: 'WHEN_LOADING_REACHES_0',
                        documentB64: this.state.downloadOnNextRender.documentB64,
                    },
                });
            }
        } else if (this.state.downloadOnNextRender.status === 'WHEN_LOADING_REACHES_0') {
            if (!this.props.entitiesLoading) {
                const docB64 = this.state.downloadOnNextRender.documentB64;
                this.setState({ downloadOnNextRender: { status: 'NO' }, fetching: false }, () =>
                    this.downloadDocument(docB64),
                );
            }
        }
    }
    downloadDocument = (documentB64: string) => {
        fullyRenderDocument({
            JSZIP: JSZip,
            DocxTemplater,
            clone,
            spelEvaluator: evaluateExpression,
        })(
            {
                processVariables: {},
                formContext: this.props.formData || {},
                taskInfo: this.props.task || {},
            },
            {
                viewConfig: this.props.viewConfig,
                normalizedEntities: this.props.entities,
                rootObject: this.props.record
                    ? this.getRootObject(documentB64)
                    : {
                          _locale: this.props.locale,
                          pageBreak: PAGE_BREAK_XML,
                      },
            },
            documentB64,
            'blob',
        ).fold(
            (l) => {
                console.error(l);
            },
            (r) => {
                console.log(r.renderedDocumentText);
                saveAs(r.subStatus.renderedDocumentOutput, `${this.getFileName()}.docx`);
            },
        );
    };
    fetchAndDownloadNextRender = () => {
        this.setState({ fetching: true }, () => {
            getPrintTemplateDocument(this.props.printTemplateRecord.documentIdentifier)
                .then((document) => {
                    if (this.props.record) {
                        this.fetchRecord(document);
                    } else {
                        this.setState({
                            downloadOnNextRender: {
                                status: 'WHEN_LOADING_REACHES_0',
                                documentB64: document,
                            },
                        });
                    }
                })
                .catch(() => this.setState({ fetching: false }));
        });
    };
    getDisplayText = () => {
        const { printTemplateRecord, displayText, label } = this.props;
        if (label && label !== 'Label') {
            return label;
        }
        return displayText ?? getDisplayText(printTemplateRecord);
    };
    render() {
        const renderSpacedIcon = () => {
            if (!this.state.fetching) {
                return null;
            }
            return (
                <span style={{ position: 'relative', width: 0 }}>
                    <span style={{ position: 'absolute', left: 8, marginTop: 1 }}>
                        <CircularProgress style={{ height: 15, width: 15 }} />
                    </span>
                </span>
            );
        };
        if (this.props.component === 'button') {
            return (
                <button
                    disabled={this.state.fetching}
                    className={this.props.buttonClass}
                    onClick={this.fetchAndDownloadNextRender}
                >
                    <span>
                        {this.getDisplayText()}
                        {renderSpacedIcon()}
                    </span>
                </button>
            );
        }
        if (this.props.component === 'mui-button') {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.fetching}
                    className={this.props.buttonClass}
                    onClick={this.fetchAndDownloadNextRender}
                >
                    <span>
                        {this.getDisplayText()}
                        {renderSpacedIcon()}
                    </span>
                </Button>
            );
        }
        const anchor = (
            <a className={this.props.anchorClass} href="javascript:;" onClick={this.fetchAndDownloadNextRender}>
                <span>
                    {this.getDisplayText()}
                    {renderSpacedIcon()}
                </span>
            </a>
        );
        if (this.props.padding === 'none') {
            return anchor;
        }
        return (
            <div style={{ paddingBottom: '.75em', paddingTop: '.5em', paddingLeft: '.5em', paddingRight: '.5em' }}>
                {anchor}{' '}
            </div>
        );
    }
}

const PrintTemplateNameLookupField: React.ComponentType<PrintTemplateFileFieldProps> = connect(
    makeMapStateToProps,
    dispatches,
)(PrintTemplateFileFieldComponent);

const PrintTemplateField: React.FC<PrintTemplateFieldProps> = (props) => {
    const { rootViewContext: viewContext } = useContext(casetivityViewContext);
    const { taskId } = useContext(processContext);
    const fc = useContext(formContext);
    const locale = useLocale();
    const formData = useMemo(() => ({ ...fc.fieldValues, ...fc.variables }), [fc.fieldValues, fc.variables]);
    const printMode = useAppSelector((state) => state.printMode);
    if (isOffline() || printMode) {
        return null;
    }
    return (
        <PrintTemplateNameLookupField
            viewContext={viewContext}
            formData={formData}
            taskId={taskId}
            locale={locale}
            {...props}
        />
    );
};
export default PrintTemplateField;
