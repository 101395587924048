import { useOverriddenViewDefaultValues } from 'expression-tester/entity-form/DefaultValueConfiguration/getDefaultValueConfigMetaData';
import useKeyCachingEval from 'expressions/Provider/hooks/useKeyCachingEval';
import React, { useMemo } from 'react';
import { useAppSelector } from 'reducers/rootReducer';
import getAdhocVariablesContextSelector from '../form/EntityFormContext/util/getVariablesContextSelector';
import { alertOnce } from './alertOnce';

export const useDefaultValuesMeta = (viewName: string) => {
    const overriddenViewDefaultValues = useOverriddenViewDefaultValues(viewName);
    const viewDefaultValues = useAppSelector((state) => state.viewDefaultValueExpressions[viewName]);
    return overriddenViewDefaultValues ?? viewDefaultValues;
};

const isPrimitive = (value) => {
    return value === null || (typeof value !== 'object' && typeof value !== 'function');
};

const useDefaultValues = (viewName: string, evaluatedAdhocSPELVariables?: Record<string, unknown>) => {
    const defaultValuesMeta = useDefaultValuesMeta(viewName);

    const defaultValueExpressions = useMemo(() => {
        return Object.fromEntries(
            Object.entries(defaultValuesMeta ?? {}).map(
                ([field, exp]) => [field, [exp.expression]] as [string, [string]],
            ),
        );
    }, [defaultValuesMeta]);
    // insert adhoc variables utils
    const adhocVariablesContextSelector = useMemo(getAdhocVariablesContextSelector, []);
    const results = useKeyCachingEval(defaultValueExpressions)(
        adhocVariablesContextSelector(evaluatedAdhocSPELVariables),
    );
    const defaultValues = useMemo(() => {
        return Object.fromEntries(
            Object.entries(results).flatMap(([field, [evaluatedExpr]]) => {
                if (evaluatedExpr && !isPrimitive(evaluatedExpr)) {
                    const originalExpression = defaultValueExpressions[field][0];
                    alertOnce(originalExpression);
                    return [];
                }
                return [[field, evaluatedExpr]];
            }),
        );
    }, [results, defaultValueExpressions]);
    return defaultValues;
};

export const WithDefaultValues: React.FC<{
    viewName: string;
    children: (props: { defaultValues: {} }) => JSX.Element;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}> = ({ viewName, children }) => {
    const defaultValues = useDefaultValues(viewName);
    return children({
        defaultValues,
    });
};

export default useDefaultValues;
