import { TableRowContexts } from 'expressions/CachingEvaluator/FormContextEvaluator';

const mapTableColumnValidationsToDataRows =
    (formData: {}, tableRowContexts?: TableRowContexts) =>
    ([fid, validation]: [string, string]): [string, string][] => {
        if (fid.includes('_c_')) {
            const tableId = fid.split('_c_')[0];
            const rootTableData = formData[tableId] || [];
            const rootTableContexts = tableRowContexts?.[tableId];
            if (fid.split('_c_').length === 2) {
                const fieldId = fid.split('_c_')[1];
                return rootTableData.flatMap((_, i) => {
                    // don't include validations on hidden fields
                    const include = !rootTableContexts?.[i]?.hiddenFields[fieldId];
                    if (!include) {
                        return [];
                    }
                    return [[`${fid}_${i}`, validation.replace(/[a-zA-Z0-9]+_c_[a-zA-Z0-9]+/g, `$&_${i}`)]];
                });
            }
            if (fid.split('_c_').length === 3) {
                const [rootTableId, nestedTableInRowId, fieldId] = fid.split('_c_');
                let validations: [string, string][] = [];
                rootTableData.forEach((rowData, i) => {
                    // we only add validations for rows that exist
                    const nestedTableData = rowData[nestedTableInRowId];
                    const nestedTableContexts = rootTableContexts?.[i].tableRowContexts?.[nestedTableInRowId];
                    if (nestedTableData && Array.isArray(nestedTableData)) {
                        nestedTableData.forEach((row, j) => {
                            const nestedTableContext = nestedTableContexts?.[j];
                            if (nestedTableContext?.hiddenFields[fieldId]) {
                                return; // skip
                            }
                            validations.push([
                                `${rootTableId}_c_${nestedTableInRowId}_${i}_c_${fieldId}_${j}`,
                                (() => {
                                    const tableFieldRx = /([a-zA-Z0-9]+(_c_[a-zA-Z0-9]+)+)/g;
                                    let newValidation: string = validation.replace(tableFieldRx, (match) => {
                                        let [rootTable, nestedTable, field] = match.split('_c_');
                                        return `${rootTable}_c_${nestedTable}_${i}${field ? `_c_${field}_${j}` : ''}`;
                                    });
                                    return newValidation;
                                })(),
                            ]);
                        });
                    }
                });
                return validations;
            }
        }
        // not a table field.
        return [[fid, validation]];
    };

export default mapTableColumnValidationsToDataRows;
