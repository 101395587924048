import { MapType, StaticType, Types } from '../evalStatically/evalStatically';
import { filterFind, hasIntrinsicName, hasNumericId, isGeneric } from './utils';
import { ContainerReflection, DeclarationReflection } from 'typedoc/dist/lib/serialization/schema';

export const handleGenericType = (obj: DeclarationReflection, memo: { [key: string]: StaticType }, parentClass) => {
    let objType = obj.signatures?.[0].type ?? obj.type;
    const parentMemo = memo[parentClass.id] as MapType;
    const parentGenerics = Object.keys(parentMemo?.parameters);

    if (objType?.type === 'reference') {
        if (parentGenerics.includes(objType.name)) {
            memo[obj.id] = memo[parentClass.id]['parameters'][objType.name];
            return memo[obj.id];
        }
    }
    if (objType?.type === 'array') {
        if (objType?.['id']) {
            if (memo[objType['id']]) {
                memo[obj.id] = Types.array(false, memo[objType['id']]);
            }
            return memo[obj.id];
        }
    }
    console.warn(`Unhandled objType.type: ${objType.type} in handleGenericMethod`);
    return null;
};

export const handleArrayType = (
    objType: any,
    obj: DeclarationReflection,
    memo: { [key: string]: StaticType },
    fullDoc: ContainerReflection,
    mapNodeToType: (obj: DeclarationReflection) => StaticType,
    parentClass: DeclarationReflection,
): StaticType => {
    objType = objType.elementType;
    if ('name' in objType && isGeneric(objType, parentClass)) {
        const name = objType.elementType?.name ?? objType.name;
        let matchedClassGeneric;

        if (memo[parentClass.id]['parameters'][name]) {
            matchedClassGeneric = memo[parentClass.id]['parameters'][name];
            memo[obj.id] = Types.array(false, matchedClassGeneric);
            return memo[obj.id];
        }
        const nextNode = filterFind(fullDoc, (node) => node.name === name);
        if (nextNode) {
            memo[objType.name] = mapNodeToType(nextNode);
            matchedClassGeneric = memo[objType.name];
        }

        if (!nextNode) {
            console.warn('Unable to match generic to parentClass generic');
            matchedClassGeneric = Types.unknown;
        }

        memo[obj.id] = Types.array(false, matchedClassGeneric);
        return memo[obj.id];
    } else if ('name' in objType && objType.type === 'reference' && !isGeneric(objType, parentClass)) {
        memo[obj.id] = Types.array(false, Types.unknown);

        if (memo[objType.id]) {
            memo[obj.id]['of'] = memo[objType.id];
        } else {
            const id = objType.id;
            const nextNode = filterFind(fullDoc, (node) => node.id === id && node.kindString === 'Class');
            if (!nextNode) {
                memo[objType.id] = Types.unknown;
                return memo[objType.id];
            }
            memo[objType.id] = mapNodeToType(nextNode);
            memo[obj.id]['of'] = memo[objType.id];
        }
        return memo[obj.id];
    }
    console.warn(`Unhandled array property: "${obj.name}" `);

    return Types.unknown;
};

export const handleReferenceType = (
    objType: any, // Replace with the specific type
    obj: DeclarationReflection,
    memo: { [key: string]: StaticType },
    fullDoc: ContainerReflection,
    mapNodeToType: (obj: DeclarationReflection) => StaticType,
    parentClass: DeclarationReflection,
): StaticType => {
    if (objType?.type === 'reference' && isGeneric(objType, parentClass)) {
        return handleGenericType(obj, memo, parentClass);
    }

    if (objType?.type === 'reference' && hasNumericId(objType)) {
        if (memo[objType.id]) {
            return memo[objType.id];
        }
        const id = objType.id;
        const nextNode = filterFind(fullDoc, (node) => node.id === id && node.kindString === 'Class');
        if (!nextNode) {
            memo[objType.id] = Types.unknown;
            return memo[objType.id];
        }
        memo[objType.id] = mapNodeToType(nextNode) ?? Types.unknown;
        return memo[objType.id];
    }
    if (objType?.type === 'reference' && !hasNumericId(objType) && hasIntrinsicName(objType)) {
        const name = objType['name'];
        if (memo[name]) {
            return memo[name];
        }
        const nextNode = filterFind(fullDoc, (node) => node.name === name);
        if (nextNode) {
            memo[objType['name']] = mapNodeToType(nextNode);
            return memo[objType['name']];
        }
        memo[objType['name']] = Types.unknown;
        console.warn(`Could not find typedoc node by name "${objType['name']}".`);
        return memo[objType['name']];
    }

    if (objType?.type === 'query') {
        const queryType = objType.queryType;
        if (queryType.type === 'reference' && hasNumericId(queryType)) {
            if (memo[queryType.id]) {
                return memo[queryType.id];
            }
            const nextNode = filterFind(fullDoc, (node) => node.id === queryType.id);
            if (!nextNode) {
                memo[queryType.id] = Types.unknown;
                console.warn('No next node.');
                return memo[queryType.id];
            }

            memo[queryType.id] = mapNodeToType(nextNode);
            return memo[queryType.id];
        }
    }
};
