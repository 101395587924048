import React, { useMemo } from 'react';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { Radio, RadioGroup, createStyles, Theme, FormControl, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import FormControlLabel from 'fieldFactory/input/components/mui/FormControlLabel';
import uniqueId from 'lodash/uniqueId';
import { evaluateFormattedText, useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { useIntl } from 'react-intl';
import formatError from 'fieldFactory/util/formatError';
import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';

const styles = (theme: Theme) =>
    createStyles({
        checkBox: {
            width: 30,
            height: 30,
            marginLeft: 0,
        },
        addPadding: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        error: { color: theme.palette.error.main, fontSize: '0.75rem' },
    });

const hideIfNoInput = branch(
    (props) => !props.input, // input is not provided
    renderNothing,
);
const getFlexDirection = (direction: 'VERTICAL' | 'HORIZONTAL'): 'row' | 'column' => {
    return direction === 'HORIZONTAL' ? 'row' : 'column';
};
const RadioSelect = hideIfNoInput(
    ({
        choices,
        meta: { touched, error },
        label,
        input,
        disabled,
        theme,
        direction = 'VERTICAL',
        classes,
        ariaInputProps,
    }: any) => {
        let idBase = useMemo(() => uniqueId('RadioSelect'), []);
        const intl = useIntl();
        const templatedLabel = useEvaluateTemplateInFormContext(label);
        const mappingToIntName = React.useMemo(() => {
            return Object.fromEntries(choices.map(({ name }) => [name, evaluateFormattedText(intl, name)]));
        }, [choices, intl]);

        const { evaluateFormattedMessage, translate } = useEvaluateFormattedMessage();
        return (
            <div>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{templatedLabel}</FormLabel>
                    <div style={{ height: '.5em' }} />
                    <RadioGroup
                        aria-label={templatedLabel}
                        {...ariaInputProps}
                        onChange={(event, value) => {
                            input.onBlur(choices.find(({ name }) => name === value));
                        }}
                        onBlur={() => input.onBlur(undefined)}
                        value={input.value ? input.value.name : ''}
                        tabIndex={0}
                        style={{
                            display: 'flex',
                            marginLeft: 11,
                            flexDirection: getFlexDirection(direction),
                            justifyContent: getFlexDirection(direction) === 'row' ? 'flex-start' : undefined,
                            flexWrap: 'wrap',
                        }}
                    >
                        {choices.map(
                            (object, i) =>
                                object.name && (
                                    <FormControlLabel
                                        htmlFor={`${idBase} (${i})`}
                                        control={
                                            <Radio
                                                id={`${idBase} (${i})`}
                                                className={classnames(classes.checkBox, {
                                                    [classes.addPadding]:
                                                        getFlexDirection(direction) === 'row' ? true : false,
                                                })}
                                            />
                                        }
                                        disabled={disabled}
                                        label={mappingToIntName[object.name] ?? object.name}
                                        value={object.name}
                                        key={object.name}
                                    />
                                ),
                        )}
                    </RadioGroup>
                    {touched && error && (
                        <span className={classes.error}>
                            {translate({ id: 'validate.error' })}: {evaluateFormattedMessage(formatError(error))}
                        </span>
                    )}
                </FormControl>
            </div>
        );
    },
);

export default withStyles(styles)(RadioSelect);
