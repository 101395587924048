import { checkLastRequestTime } from '../actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector, useAppStore } from 'reducers/rootReducer';
import isOffline from 'util/isOffline';

const ONE_MINUTE = 60 * 1000;
const CHECK_INTERVAL_DEFAULT = 1.5 * ONE_MINUTE;
const CheckLastRequestRunner = () => {
    const dispatch = useDispatch();
    const isOnline = useAppSelector((state: RootState) => state.connectivityStatus);
    const store = useAppStore();
    useEffect(() => {
        if (isOnline && !isOffline()) {
            const checkLastRequestPollInterval = setInterval(() => {
                const alreadyInFailureRecoveryLoop = (store.getState() as RootState).reauth.successiveFailureCount > 0;
                dispatch(checkLastRequestTime(!alreadyInFailureRecoveryLoop));
            }, (window as any).CASETIVITY_CHECKLASTREQUESTTIME_INTERVAL || CHECK_INTERVAL_DEFAULT);
            return () => {
                clearInterval(checkLastRequestPollInterval);
            };
        }
    }, [isOnline]); // eslint-disable-line
    return null;
};

export default CheckLastRequestRunner;
