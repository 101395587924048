import ViewConfig, { EntityField } from 'reducers/ViewConfigType';
import { parse } from 'ts-spel';
import { StaticType, Types, getEvaluateStatically } from './evalStatically/evalStatically';
import { filledFType } from './StaticTypes/f';
import isUnclosedStringExpression from './isUnclosedStringExpression';

const StringTypes = {
    'length()': Types.number,
    'trim()': Types.string(false),
    'toLowerCase()': Types.string(false),
    'toUpperCase()': Types.string(false),
    'substring(': Types.string(false),
    'startsWith(': Types.boolean,
    'split(': Types.string(false),
    'replaceAll(': Types.string(false),
    'replace(': Types.string(false),
    'matches(': Types.boolean,
    'lastIndexOf(': Types.number,
    'endsWith(': Types.boolean,
    'concat(': Types.string(false),
    'charAt(': Types.string(false),
} as const;

const getStaticTypeFromEntityField = (ef: EntityField): StaticType => {
    const dt = ef.dataType;
    switch (dt) {
        case 'BIGDECIMAL':
        case 'DOUBLE':
        case 'INSTANT':
        case 'FLOAT':
        case 'INTEGER':
        case 'LONG':
            return Types.number;
        case 'BOOLEAN':
            return Types.boolean;
        case 'DATE': {
            return Types.unknown;
        }
        case 'STRING':
        case 'TEXTBLOB':
            return Types.string(true);
        case 'REFMANY':
        case 'REFMANYMANY':
        case 'REFMANYJOIN':
            return Types.array(true, Types.entity(false, ef.relatedEntity));
        case 'VALUESETMANY':
            return Types.array(true, Types.entity(false, 'Concept'));
        case 'REFONE':
        case 'REFONEJOIN':
            return Types.entity(true, ef.relatedEntity);
        case 'VALUESET':
            return Types.entity(true, 'Concept');
    }
};

const resolvePath =
    (viewConfig: ViewConfig) =>
    (path: string, topContext: StaticType): StaticType => {
        if (!path) {
            return topContext;
        }
        switch (topContext._type) {
            case 'entity': {
                if (path.endsWith('Id') || path.endsWith('Code')) {
                    const adjustedField = path.endsWith('Id') ? path.slice(0, -2) : path.slice(0, -4);
                    const field = viewConfig.entities[topContext.entityType]?.fields?.[adjustedField];
                    if (!field) {
                        return Types.unknown;
                    }
                    return Types.string(true);
                }
                // Removing 'Codes' below for now, since it's not well-supported in the app.
                if (path.endsWith('Ids') /* || path.endsWith('Codes') */) {
                    const adjustedField = path.endsWith('Ids') ? path.slice(0, -3) : path.slice(0, -5);
                    const field = viewConfig.entities[topContext.entityType]?.fields?.[adjustedField];
                    if (!field) {
                        return Types.unknown;
                    }
                    return Types.array(true, Types.string(false));
                }
                const field = viewConfig.entities[topContext.entityType]?.fields[path];
                if (!field) {
                    return Types.unknown;
                }
                return getStaticTypeFromEntityField(field);
            }
            case 'array':
                if (path === 'size()') {
                    return Types.number;
                }
                return Types.unknown;
            case 'map': {
                if (!topContext.staticValue) {
                    return Types.unknown;
                }
                const wildcard = topContext.staticValue['*'];
                if (wildcard) {
                    return wildcard;
                }
                return topContext.staticValue[path] ?? Types.unknown;
            }
            case 'string': {
                return StringTypes[path] ?? Types.unknown;
            }
            default:
                return Types.unknown;
        }
    };

const getTypeAtCursor =
    (viewConfig: ViewConfig, rootEntity: string | null = null) =>
    (valueUntilCursor: string) => {
        let type: StaticType = Types.unknown;
        const rootEntityContext = !rootEntity
            ? Types.map(false, {})
            : ({
                  _type: 'entity',
                  entityType: rootEntity,
                  maybeNull: false,
              } as const);
        if (!valueUntilCursor) {
            return rootEntityContext;
        }
        try {
            const __ast = parse(valueUntilCursor, true);
            if (isUnclosedStringExpression(__ast)) {
                return Types.unknown;
            }
            type = getEvaluateStatically(
                (path, topContext = rootEntityContext): StaticType => {
                    const simultaneousContexts = [
                        topContext,
                        Types.map(false, {
                            '#f()': filledFType,
                            '#this': topContext,
                            '#root': rootEntityContext,
                        }),
                    ];
                    if (!path && topContext !== rootEntityContext) {
                        simultaneousContexts.push(rootEntityContext);
                    }
                    return (
                        simultaneousContexts
                            .map((ctxt) => resolvePath(viewConfig)(path, ctxt))
                            .find((res) => res._type !== 'unknown') ?? Types.unknown
                    );
                },
                {
                    disableBoolOpChecks: true,
                },
            )(__ast);
        } catch (e) {
            console.error(e);
        }
        return type;
    };
export default getTypeAtCursor;
