import { useReducer, useCallback } from 'react';

const useRemoteData = <Data extends any, Error extends any>() => {
    type Fold = <R>(
        renderInitial: () => R,
        renderPending: (prevData?: Data) => R,
        renderSuccess: (data: Data) => R,
        renderError: (error: Error, prevData?: Data) => R,
    ) => R;

    const fold: Fold = (renderInitial, renderPending, renderSuccess, renderError) => {
        switch (state.status) {
            case 'initial':
                return renderInitial();
            case 'pending':
                return renderPending(state.prevData);
            case 'success':
                return renderSuccess(state.data);
            case 'error':
                return renderError(state.error, state.prevData);
        }
    };

    type LoadingState = (
        | {
              status: 'initial';
          }
        | {
              status: 'pending';
              prevData?: Data;
          }
        | {
              status: 'success';
              data: Data;
          }
        | {
              status: 'error';
              error: Error;
              prevData?: Data;
          }
    ) & {
        fold: Fold;
    };
    const [state, dispatch] = useReducer(
        (
            state: LoadingState,
            action:
                | {
                      type: 'setInitial';
                  }
                | {
                      type: 'setPending';
                  }
                | {
                      type: 'setSuccess';
                      data: Data;
                  }
                | {
                      type: 'setError';
                      error: Error;
                  },
        ): LoadingState => {
            switch (action.type) {
                case 'setPending': {
                    const prevData =
                        state.status === 'success' ? state.data : state.status === 'error' ? state.prevData : undefined;
                    return { status: 'pending', prevData, fold };
                }
                case 'setSuccess': {
                    return { status: 'success', data: action.data, fold };
                }
                case 'setError': {
                    const prevData = state.status === 'pending' ? state.prevData : undefined;
                    return { status: 'error', prevData, error: action.error, fold };
                }
                case 'setInitial': {
                    return { status: 'initial', fold };
                }
            }
        },
        { status: 'initial', fold },
    );
    const setSuccess = useCallback((data: Data) => {
        dispatch({
            type: 'setSuccess',
            data,
        });
    }, []);
    const setPending = useCallback(() => {
        dispatch({
            type: 'setPending',
        });
    }, []);
    const setInitial = useCallback(() => {
        dispatch({
            type: 'setInitial',
        });
    }, []);
    const setError = useCallback((error: Error) => {
        dispatch({
            type: 'setError',
            error,
        });
    }, []);
    return {
        state,
        fold,
        setSuccess,
        setPending,
        setError,
        setInitial,
    };
};

export default useRemoteData;
