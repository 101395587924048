import { TypographyProps } from '@material-ui/core';
import isOffline from 'util/isOffline';
import { isFieldFromFlowable } from './../Address/util';

const getListSelectProps = (propConfiguration, fieldDefinition) => {
    try {
        const configStr: string | undefined = isFieldFromFlowable(fieldDefinition)
            ? fieldDefinition &&
              fieldDefinition.params &&
              fieldDefinition.params.configs &&
              fieldDefinition.params.configs.fieldConfig
            : fieldDefinition.config;
        let hasCreate = false;
        let singleSelect = false;
        let expansions: string[] = [];
        let openTo: 'show' | 'edit' | undefined = undefined;
        let labelVariant: TypographyProps['variant'] = undefined;
        let hyperlinks: boolean = true;
        let showListWhenDisabled = true;
        let noResultsHtml: string = null;
        let disableCellClick = false;
        let noNavButton = false;
        if (configStr) {
            const parsedConfig = JSON.parse(configStr);
            noResultsHtml = parsedConfig.noResultsHtml || null;
            hasCreate = parsedConfig.hasCreate || false;
            singleSelect = parsedConfig.singleSelect || false;
            expansions = parsedConfig.expansions || [];
            openTo = parsedConfig.openTo;
            labelVariant = parsedConfig.labelVariant;
            hyperlinks = isOffline() ? false : parsedConfig.hyperlinks ?? true;
            showListWhenDisabled = parsedConfig.showListWhenDisabled ?? true;
            disableCellClick = parsedConfig.disableCellClick ?? false;
            noNavButton = parsedConfig.noNavButton ?? false;
        }

        propConfiguration = {
            ...propConfiguration,
            noResultsHtml,
            openTo,
            hasCreate,
            singleSelect,
            expansions,
            labelVariant,
            hyperlinks,
            showListWhenDisabled,
            disableCellClick,
            noNavButton,
        };
        return propConfiguration;
    } catch (e) {
        console.error(
            `Misconfigured field configuration for ListSelect component! hasCreate will be the component default. Error below`,
        );
        console.error(e);
        return propConfiguration;
    }
};

export default getListSelectProps;
