import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Dialog, TextField, Tooltip, Typography } from '@material-ui/core';
import { CheckCircle, Close, Error, Send } from '@material-ui/icons';
import { useResetStateToInitialAfterASecondOfSuccess } from 'components/ConfigPackDownload/resetStateToInitialAfterASecondOfSuccess';
import { State } from 'components/ConfigPackDownload/StateUnion';
import { useCurrentBranch } from 'branches/BranchExplorer';
import { RootState, useAppSelector } from 'reducers/rootReducer';
const config = require('../../config.js');

export const PushAllButton = () => {
    const [status, setStatus] = useState<State>({ type: 'INITIAL' });
    const abortController = useRef<AbortController | null>(null);

    const currentBranch = useCurrentBranch();

    useEffect(() => {
        const currentController = abortController.current;

        return () => {
            if (currentController) {
                currentController.abort();
            }
        };
    }, []);
    useResetStateToInitialAfterASecondOfSuccess(status, setStatus);

    const [popup, setPopup] = useState(null);
    const [inputValue, setInputValue] = useState<string>('');

    const handlePushAll = async () => {
        if (abortController.current) {
            abortController.current.abort();
        }
        const controller = new AbortController();
        abortController.current = controller;
        setStatus({ type: 'LOADING' });
        try {
            const response = await fetch(`${config.BACKEND_BASE_URL}api/admin/configs/push-all`, {
                method: 'POST',
                body: JSON.stringify({
                    branch: currentBranch,
                    message: inputValue,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: abortController.current.signal,
            });
            if (response.ok) {
                setStatus({ type: 'SUCCESS' });
            } else {
                console.error(`Response failed with status ${response.status}`);
                setStatus({ type: 'ERROR', errorMessage: `Response failed with status ${response.status}` });
            }
        } catch (error) {
            if (!abortController.current.signal.aborted) {
                setStatus({ type: 'ERROR', errorMessage: `Error: ${error}` });
                console.error(`Error: ${error}`);
            }
        }
    };
    const handleClick = (event) => {
        setPopup(event.currentTarget);
    };

    const handleClose = () => {
        setPopup(null);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = () => {
        handlePushAll();
        handleClose();
    };

    const openPopup = Boolean(popup);
    const basicInfo = useAppSelector((state: RootState) => state.basicInfo);
    if (!basicInfo.showConfigMgmt) {
        return null;
    }

    return (
        <div
            style={{
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography>Push All and Repopulate Repo: </Typography>
            <Tooltip
                title={
                    status.type === 'INITIAL'
                        ? 'Push All and Repopulate Repo'
                        : status.type === 'LOADING'
                        ? 'Attempting'
                        : status.type === 'SUCCESS'
                        ? 'Success'
                        : status.type === 'ERROR'
                        ? 'Error'
                        : null
                }
            >
                <Button onClick={handleClick} aria-label="Push All and Repopulate Repo">
                    {status.type === 'INITIAL' && <Send />}
                    {status.type === 'LOADING' && (
                        <CircularProgress
                            size={24}
                            aria-live="assertive"
                            color="primary"
                            aria-label="Loading"
                            role="alert"
                        />
                    )}
                    {status.type === 'SUCCESS' && <CheckCircle color="primary" aria-label="Success Check Mark" />}
                    {status.type === 'ERROR' && (
                        <Error color="error" aria-live="assertive" aria-label="Error Icon" role="alert" />
                    )}
                </Button>
            </Tooltip>
            <Dialog open={openPopup} onClose={handleClose}>
                <div style={{ display: 'flex', flexDirection: 'row', padding: '5px' }}>
                    <TextField
                        autoFocus
                        style={{ padding: '5px' }}
                        autoComplete="off"
                        margin="dense"
                        id="name"
                        label="Commit Message"
                        type="text"
                        fullWidth
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                    />
                    <Button onClick={handleClose} aria-label="Close">
                        <Tooltip title={'Close'}>
                            <Close />
                        </Tooltip>
                    </Button>
                    <Button onClick={handleSubmit} aria-label="Submit with Commit Message">
                        <Tooltip title={'Submit with Commit Message'}>
                            <Send />
                        </Tooltip>
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};
