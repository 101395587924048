import React from 'react';
import PasswordResetPage from './Page';
import { parse } from 'querystring';
import { PasswordResetTextField, PasswordResetStatus, renderActions } from './shared';
import { Formik } from 'formik';
import * as config from 'config';
import { getResetPageTextSelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import Template from './PasswordResetForm';
import buildHeaders from 'sideEffect/buildHeaders';
import MinimalUIToolbarForStandaloneApp from 'components/layouts/MinimalUIToolbarForStandaloneApp';
import { SelfResetProps, FormData } from './DefaultRendererComponent';
import { useAppSelector } from 'reducers/rootReducer';

const getKey = (location: Location) => {
    return parse(location.search.slice(1)).key;
};

const SelfReset: React.FC<SelfResetProps> = ({ location }) => {
    const resetPageText = useAppSelector(getResetPageTextSelector);
    return (
        <PasswordResetPage
            renderForm={({ attemptAction }) =>
                (state) => {
                    return (
                        <Formik
                            initialValues={{ password1: '', password2: '' }}
                            validate={(values: FormData) => {
                                let errors: Partial<FormData> = {};
                                if (!values.password1) {
                                    errors.password1 = 'Required';
                                }
                                if (!values.password2) {
                                    errors.password2 = 'Required';
                                }
                                if (values.password1 && values.password2 && values.password1 !== values.password2) {
                                    errors.password2 = 'Password must match';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                attemptAction({
                                    lazyRequest: () =>
                                        fetch(`${config.BACKEND_BASE_URL}api/account/forgot-password/finish`, {
                                            method: 'POST',
                                            body: JSON.stringify({
                                                key: getKey(location),
                                                newPassword: values.password1,
                                            }),
                                            credentials: 'same-origin',
                                            headers: buildHeaders({
                                                Accept: 'application/json',
                                                'Content-Type': 'application/json',
                                                includeCredentials: false,
                                            }),
                                        }),
                                });
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                /* and other goodies */
                            }) => {
                                const PasswordElement = (
                                    <PasswordResetTextField
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.password1}
                                        name="password1"
                                        label="New Password"
                                        error={errors.password1}
                                        touched={touched.password1}
                                        type="password"
                                    />
                                );
                                const PasswordConfirmElement = (
                                    <PasswordResetTextField
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.password2}
                                        name="password2"
                                        label="Please re-enter password"
                                        error={errors.password2}
                                        touched={touched.password2}
                                        type="password"
                                    />
                                );
                                const PasswordResetStatusElement = <PasswordResetStatus state={state} />;
                                const PageTextElement = <SafeHtmlAsReact html={resetPageText} />;
                                const ActionsElement = renderActions(state);
                                return (
                                    <div>
                                        <MinimalUIToolbarForStandaloneApp />
                                        <form
                                            style={{ height: 'calc(100% - 30px)' }}
                                            autoComplete="off"
                                            onSubmit={handleSubmit}
                                        >
                                            <Template
                                                {...{
                                                    PasswordConfirmElement,
                                                    PasswordElement,
                                                    PasswordResetStatusElement,
                                                    PageTextElement,
                                                    ActionsElement,
                                                    showTitle: true,
                                                }}
                                            />
                                        </form>
                                    </div>
                                );
                            }}
                        </Formik>
                    );
                }}
        />
    );
};

export default SelfReset;
