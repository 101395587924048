import React, { useContext, useEffect } from 'react';
import 'react-grid-layout/css/styles.css';
import { useDispatch } from 'react-redux';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import Dashboard from './Dashboard';
import { getLoginName } from 'components/generics/utils/viewConfigUtils';
import { Helmet } from 'react-helmet';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { getPrimaryDash } from 'dashboard2/dashboard-config/user-primary-dashboard/get-primary-dashboard/actions';
import { loadAllDashboards } from 'dashboard2/dashboard-config/load-dashboards/actions';
import { getHasNoAnonWelcomeMessageSelector } from 'util/applicationConfig';
import { layoutModeContext } from 'components/layouts/LayoutMode';
import DeferredSpinner from 'components/DeferredSpinner';
import Themed from 'components/Themed';

interface DashboardPageProps {}

const DashboardPage: React.FunctionComponent<DashboardPageProps> = () => {
    const dispatch = useDispatch();
    const loadedAllOnce = useAppSelector((state: RootState) => state.dashboard.dashboards.loadedAllOnce);
    useEffect(() => {
        dispatch(getPrimaryDash());
        if (!loadedAllOnce) {
            dispatch(loadAllDashboards());
        }
    }, []); // eslint-disable-line
    const login = useAppSelector(
        (state: RootState) => state.viewConfig && state.viewConfig.user && state.viewConfig.user.login,
    );
    const loginName = useAppSelector((state: RootState) => getLoginName(state.viewConfig));
    const noAnonWelcomeMessage = useAppSelector(getHasNoAnonWelcomeMessageSelector);
    const anon = !login || login === 'anonymousUser';
    const { mode } = useContext(layoutModeContext);
    const welcomeMessage = (() => {
        if (anon) {
            return 'Welcome!';
        }
        if (loginName) {
            return `Welcome Back, ${loginName}!`;
        }
        return 'Welcome Back!';
    })();
    return (
        <div>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <SsgAppBarMobile title="Dashboard" />
            {mode === 'old' ? (
                <div style={{ padding: '1em' }}>
                    <Themed>
                        {({ theme }) =>
                            anon && noAnonWelcomeMessage ? null : (
                                <h1
                                    style={{
                                        color: theme.palette.getContrastText(theme.palette.background.default),
                                    }}
                                >
                                    {welcomeMessage}
                                </h1>
                            )
                        }
                    </Themed>
                    <Dashboard />
                </div>
            ) : (
                <React.Fragment>
                    {anon && noAnonWelcomeMessage ? null : <h1>{welcomeMessage}</h1>}
                    <Dashboard />
                </React.Fragment>
            )}
        </div>
    );
};

const DashboardPageShortCircuited: React.FunctionComponent<{}> = () => {
    const viewConfigLoaded = useAppSelector((state: RootState) => !!(state.viewConfig && state.viewConfig.application));
    if (!viewConfigLoaded) {
        return <DeferredSpinner />;
    }

    return <DashboardPage />;
};
export default DashboardPageShortCircuited;
