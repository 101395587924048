const getCssNormal = () => {
    return `
       .casetivity-print-only {
            display: none;
        }
        @media print {
            .casetivity-print-only {
                display: unset !important;
            }

            .casetivity-smallonly {
                display: none;
            }

            .casetivity-largeonly {
                display: block;
            }

            .casetivity-no-print {
                display: none !important;
            }
            .casetivity-noprint {
                display: none !important;
            }

            .casetivity-hide-printing {
                display: none !important;
            }
        }
    `;
};

const getCssPagedJSPrintMode = () => {
    return `
    .casetivity-smallonly {
        visibility: hidden;
        height: 0px;
        width: 0px;
    }

    .casetivity-largeonly {
        display: block;
    }
    .casetivity-no-print {
        visibility: hidden;
        height: 0px;
        width: 0px;
    }
    .casetivity-noprint {
        visibility: hidden;
        height: 0px;
        width: 0px;
    }

    .casetivity-hide-printing {
        visibility: hidden;
        height: 0px;
        width: 0px;
    }
    `;
};

const STYLE_ID = 'media-print-styles';
export const insertMediaPrint = () => {
    const style = document.createElement('style');
    style.id = STYLE_ID;

    const css = getCssNormal();

    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
};

export const extractMediaPrintAsNormalStylesForPagedJS = () => {
    const styleElement = document.getElementById(STYLE_ID) as HTMLStyleElement | null;

    if (styleElement) {
        styleElement.textContent = getCssPagedJSPrintMode();
    } else {
        throw new Error(`Failed to find style element with ID ${STYLE_ID}`);
    }
};
