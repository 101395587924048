import React, { useEffect, useMemo } from 'react';
import { Button, Card, CardHeader } from '@material-ui/core';
import Forward from '@material-ui/icons/Forward';
import TaskFormController from 'bpm/task-form-editor/TaskFormBuilder';
import { Link } from 'react-router-dom';
import { TaskForm } from 'reducers/taskFormType';
import { services } from 'sideEffect/services';
import useService from 'util/hooks/useService';
import { css } from 'emotion';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { map } from 'rxjs/operators';
import FormSaveNotifier from 'formSaveNotifier/components/FormSaveNotifier';

const ManagedFormCreate = () => {
    const service = useMemo(
        () => (taskForm: Omit<TaskForm, 'id'>) =>
            services.taskFormsDefinitions
                .create({
                    formRepresentation: {
                        description: taskForm.description,
                        fields: taskForm.fields,
                        outcomes: taskForm.outcomes,
                    },
                    name: taskForm.name,
                    key: taskForm.key,
                })
                .pipe(map((response) => taskForm)),
        [],
    );
    const [state, request, { StateIcon }] = useService(service);

    const dispatch = useDispatch();

    useEffect(() => {
        if (state.status === 'success') {
            const to = setTimeout(() => dispatch(push(`/admin/bpm/form/${state.data.key}`)), 150);
            return () => clearTimeout(to);
        }
    }, [state, dispatch]);
    return (
        <div>
            <Card style={{ margin: '1em' }}>
                <CardHeader
                    titleTypographyProps={{
                        variant: 'h5',
                    }}
                    title="Create Task Form"
                    action={
                        <div style={{ marginTop: '1em' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to="/admin/bpm/forms"
                                startIcon={
                                    <Forward
                                        className={css`
                                            -webkit-transform: scaleX(-1);
                                            transform: scaleX(-1);
                                        `}
                                    />
                                }
                            >
                                Back to all forms
                            </Button>
                        </div>
                    }
                />
            </Card>
            <TaskFormController
                title={<span />}
                isCreate
                renderSaveButton={({ id, ...form }, isDirty) => (
                    <>
                        <Button
                            endIcon={StateIcon}
                            variant="contained"
                            color="primary"
                            disabled={state.status === 'pending'}
                            onClick={() => request(form)}
                        >
                            Save
                        </Button>
                        <FormSaveNotifier when={isDirty && state.status !== 'success'} />
                    </>
                )}
            />
        </div>
    );
};

export default ManagedFormCreate;
