import React from 'react';
import get from 'lodash/get';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    WithStyles,
    withStyles,
    Theme,
    createStyles,
    FormHelperText,
    FormLabel,
    useTheme,
} from '@material-ui/core';
import { Concept } from './Concept';
import { valueSetsManyHoc } from './ValuesetManySelectDownshift';
import { ValuesetKeeperArounder } from './ValuesetKeeperArounder';
import compose from 'recompose/compose';
import uniqueId from 'lodash/uniqueId';
import { WarningOrErrorUtils } from '../hooks/useWarningOrErrorUtils';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { EvaluateLabel } from './aor/FieldTitle';
import { RootState } from 'reducers/rootReducer';
import { connect } from 'react-redux';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

type Input = any;
type Meta = any;

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        checkBox: {
            width: 24,
            height: 24,
        },
        error: {
            color: palette.error.main,
            fontSize: '0.75rem',
        },
    });

interface ConnectedComponentProps {
    valueSet?: string;
    resource?: string;
    source: string;
    shouldFetchValueset?: boolean;
    disabled?: boolean;
    record?: {};
    label: string;
    direction?: 'HORIZONTAL' | 'VERTICAL';
    ariaInputProps?: {};
    renderLabel?: boolean;
    conceptIds?: string[];
}

interface MultiCheckboxProps
    extends ConnectedComponentProps,
        WithStyles<typeof styles>,
        ReturnType<typeof mapStateToProps> {
    input: Input;
    meta?: Meta;
    dataTableByDisplay: {
        [display: string]: Concept;
    };
}

interface MultiCheckboxState {
    selectedIds: (string | number)[];
    inputValue: string;
}

const CheckboxIconForPrint: React.FC<{ checked: boolean }> = ({ checked }) => {
    const theme = useTheme();
    const style: React.CSSProperties = {
        opacity: theme.palette.action.disabledOpacity,
    };
    return checked ? <CheckBoxIcon style={style} /> : <CheckBoxOutlineBlank style={style} />;
};
class MultiCheckbox extends React.Component<MultiCheckboxProps, MultiCheckboxState> {
    private helperTextId = uniqueId('multi-valueset-checkbox-errorid');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    public valuesetKeeperArounder: ValuesetKeeperArounder = new ValuesetKeeperArounder();

    getSelectedIds = () => {
        const { input, record, source } = this.props;
        const conceptIds = (input && input.value) || get(record, source) || [];
        this.valuesetKeeperArounder.addAll(typeof conceptIds === 'string' ? JSON.parse(conceptIds) : conceptIds);
        return conceptIds;
    };
    isChecked = (checkBox) => {
        return this.getSelectedIds().includes(checkBox);
    };
    handleChange = (item) => {
        const oldSelectedIds = this.getSelectedIds();

        if (oldSelectedIds.indexOf(item.id) === -1) {
            // Item is not checked
            const selectedIds = [...oldSelectedIds, item.id];
            this.props.input.onBlur(selectedIds);
        } else {
            // Item is checked
            const selectedIds = [...oldSelectedIds];
            selectedIds.splice(oldSelectedIds.indexOf(item.id), 1);
            this.props.input.onBlur(selectedIds);
        }
    };
    getFlexDirection = (): 'row' | 'column' => {
        const { direction = 'VERTICAL' } = this.props;
        return direction === 'HORIZONTAL' ? 'row' : 'column';
    };
    makeCheckBoxes = (dataTableByDisplay) => {
        // Make the checkboxes from the datatable
        return Object.keys(dataTableByDisplay)
            .filter(
                (key) =>
                    dataTableByDisplay[key].active ||
                    this.valuesetKeeperArounder.encountered(dataTableByDisplay[key].id),
            )
            .filter((key) =>
                this.props.conceptIds ? this.props.conceptIds.includes(dataTableByDisplay[key].id) : true,
            )
            .map((key) => {
                const label = this.props.dataTableByDisplay[key].display;
                const checked = this.isChecked(this.props.dataTableByDisplay[key].id);
                return (
                    <div key={key} style={{ marginLeft: '11px' }}>
                        <FormControlLabel
                            control={
                                this.props.printMode ? (
                                    <CheckboxIconForPrint checked={checked} />
                                ) : (
                                    <Checkbox
                                        inputProps={{
                                            'aria-label': label,
                                        }}
                                        checked={checked}
                                        onChange={() => this.handleChange(this.props.dataTableByDisplay[key])}
                                        disabled={this.props.disabled}
                                        className={this.props.classes.checkBox}
                                        style={{ height: 15 }}
                                    />
                                )
                            }
                            label={label}
                            key={key}
                        />
                    </div>
                );
            });
    };

    render() {
        // meta prop is optional - this is unique.
        // (the field is used in a 'display' mode where there's no meta prop)
        const { meta = {} } = this.props;
        const { touched = false, error = '' } = meta;
        this.getSelectedIds();
        return (
            <themeOverrideContext.Consumer>
                {({ fieldVariant }) => (
                    <EvaluateLabel label={this.props.label}>
                        {({ templatedLabel }) => (
                            <WarningOrErrorUtils meta={meta}>
                                {({ helperText, muiErrorProp, textErrorClass, formHelperTextClasses }) => (
                                    <FormControl
                                        {...this.props.ariaInputProps}
                                        aria-invalid={Boolean(touched && error)}
                                        aria-describedby={muiErrorProp ? this.helperTextId : undefined}
                                        margin="none"
                                        component="fieldset"
                                        fullWidth={true}
                                        error={muiErrorProp}
                                        style={
                                            this.props.printMode
                                                ? {
                                                      display: 'block',
                                                  }
                                                : undefined
                                        }
                                    >
                                        {this.props.renderLabel && (
                                            <FormLabel component="legend" className={textErrorClass}>
                                                {templatedLabel}
                                            </FormLabel>
                                        )}
                                        <FormGroup
                                            onBlur={() => this.props.input.onBlur(undefined)}
                                            style={{
                                                display: 'flex',
                                                flexDirection: this.getFlexDirection(),
                                                justifyContent:
                                                    this.getFlexDirection() === 'row' ? 'flex-start' : undefined,
                                                flexWrap: 'wrap',
                                                marginLeft: 0,
                                                marginTop: '4px',
                                            }}
                                        >
                                            {this.makeCheckBoxes(this.props.dataTableByDisplay)}
                                        </FormGroup>
                                        {muiErrorProp && (
                                            <FormHelperText
                                                classes={formHelperTextClasses}
                                                aria-live="assertive"
                                                id={this.helperTextId}
                                            >
                                                {helperText}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            </WarningOrErrorUtils>
                        )}
                    </EvaluateLabel>
                )}
            </themeOverrideContext.Consumer>
        );
    }
}

const mapStateToProps = ({ printMode }: RootState) => ({ printMode });
const Multicheckbox = compose(valueSetsManyHoc, withStyles(styles), connect(mapStateToProps))(MultiCheckbox);

export default Multicheckbox;
