import { processContext } from 'bpm/components/processContext';
import getOnlineRecordCreateFollowupActions from 'fieldFactory/offline/util/getOnlineRecordCreateFollowupActions';
import React, { useCallback } from 'react';
import { RootState, useAppSelector, useAppStore } from 'reducers/rootReducer';
import useViewConfig from 'util/hooks/useViewConfig';

const WithUpdateDownloadedViews: React.FC<{
    showViewName: string;
    editViewName: string | -1;
    children: (props: { maybeUpdateDownloadedViews: (createdId: string) => void }) => JSX.Element;
}> = ({ showViewName, editViewName, children }) => {
    const { taskId } = React.useContext(processContext);
    const isCurrentlyWritingOffline = useAppSelector((state: RootState) =>
        taskId ? state.taskCurrentlyWritingToOffline === taskId : false,
    );
    const store = useAppStore();
    const viewConfig = useViewConfig();
    const maybeUpdateDownloadedViews = useCallback(
        (createdId: string) => {
            if (!isCurrentlyWritingOffline) {
                console.log({ isCurrentlyWritingOffline });
                return;
            }
            const actions = getOnlineRecordCreateFollowupActions(
                store,
                viewConfig,
                createdId,
                showViewName,
                editViewName,
            );
            console.log({
                actions,
                createdId,
            });
            actions.forEach((action) => {
                store.dispatch(action);
            });
        },
        [isCurrentlyWritingOffline, store, showViewName, editViewName, viewConfig],
    );

    return children({ maybeUpdateDownloadedViews });
};
export default WithUpdateDownloadedViews;
