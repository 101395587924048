import * as React from 'react';
import { reduxForm, SubmissionError, InjectedFormProps } from 'redux-form';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { TaskForm } from '../../../../../../reducers/taskFormType';
import { createGetInitialValues } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import useViewConfig from 'util/hooks/useViewConfig';
import useValueSets from 'util/hooks/useValueSets';
import useTaskFormFields from 'bpm/components/TaskDetail/TaskForm/hooks/useTaskFormFields';
import validate from 'bpm/components/TaskDetail/TaskForm/validate';
import TaskFormLayout, { FormLayoutProps } from './Layout';
import useEntities from 'util/hooks/useEntities';
import { EntityFormContextRef } from '../types';
import useLiveTaskFormValidation from '../../hooks/useLiveTaskFormValidation';

const Form = reduxForm<{}, FormLayoutProps & { entities: any }>({
    enableReinitialize: true,
    updateUnregisteredFields: true,
    keepDirtyOnReinitialize: true,
    form: 'current-task-form',
    shouldWarn: ({ values, nextProps, props, initialRender }) => {
        // maybe optimize this eventually if we know what the runtime cost is.
        return true;
    },
    shouldError: () => {
        /**
         * Validations need to be re-evaluated whenever fc.fieldValues changes (NOT just the redux form values).
         * fc.fieldValues can change independently due to either
         * 1. redux-form values change
         * 2. ENTITIES change.
         *
         * However redux-form is not aware of 2, and won't rerun the validation if entities change, unless we tell it to
         *
         * We could change the 'validate' prop we are passing every time entities update in the parent, but instead let's pass 'entities' to make it rerender, and re-call the
         * validation when entities have updated. This handles case 2.
         */
        return true;
    },
    ...({
        // custom prop added to custom redux-form fork
        alwaysRevalidateOnSubmit: true,
    } as any),
})(({ entities, ...props }: FormLayoutProps & InjectedFormProps & { entities: any }) => <TaskFormLayout {...props} />);

interface InternalTaskFormProps extends Pick<FormLayoutProps, 'renderLinkedEntity'> {
    taskId: string;
    processId: string;
    formDefinition: TaskForm;
    relatedEntityResource?: string;
    relatedEntityId?: string;
    entityFormContextRef?: EntityFormContextRef;
}

interface Values {
    submissionType: 'save' | 'complete';
    _outcome?: string;
}
/*
    Providers onSubmit (validation)
*/
const InternalTaskForm: React.SFC<InternalTaskFormProps> = (props) => {
    const { taskId, relatedEntityId, relatedEntityResource, formDefinition } = props;
    const fields = useTaskFormFields(props);
    const getInitialValues = React.useMemo(createGetInitialValues, []);
    const initialValues = useAppSelector((state: RootState) =>
        getInitialValues(state, {
            taskId,
            relatedEntityId,
            relatedEntityResource,
        }),
    );
    const fc = React.useContext(formContext);
    const entities = useEntities();
    const valueSets = useValueSets();
    const viewConfig = useViewConfig();

    const _onSubmit = React.useRef(null);
    React.useMemo(() => {
        _onSubmit.current = (values: Values) => {
            if (values.submissionType !== 'save') {
                const messagesIncludingUnexpectedErrors = validate({
                    tableRowContexts: fc.tableRowContexts,
                    outcome: values._outcome,
                    entities,
                    valuesAfterExpressionsApplied: fc.fieldValues,
                    visibleAndEditableFields: fc.visibleAndEditableFields,
                    viewConfig,
                    fields,
                    formDefinition,
                    valueSets,
                    ignoreFieldLevel: true, // let the field itself handle its 'field-level' validation
                });
                if (Object.keys(messagesIncludingUnexpectedErrors).length > 0) {
                    const submissionError = Object.assign(
                        { _error: messagesIncludingUnexpectedErrors },
                        messagesIncludingUnexpectedErrors,
                    );
                    throw new SubmissionError(submissionError);
                }
            }
        };
    }, [
        entities,
        fc.fieldValues,
        fc.visibleAndEditableFields,
        viewConfig,
        fields,
        formDefinition,
        valueSets,
        fc.tableRowContexts,
    ]);

    const onSubmit = React.useCallback((values: Values) => {
        _onSubmit.current && _onSubmit.current(values);
    }, []);

    const onLiveValidate = useLiveTaskFormValidation({
        formDefinition,
        fields,
    });
    return (
        <Form
            entities={entities}
            validate={onLiveValidate}
            entityFormContextRef={props.entityFormContextRef}
            processId={props.processId}
            formDefinition={formDefinition}
            fields={fields}
            taskId={taskId}
            onSubmit={onSubmit as () => void}
            initialValues={initialValues}
            renderLinkedEntity={props.renderLinkedEntity}
            relatedEntityResource={props.relatedEntityResource}
            relatedEntityId={props.relatedEntityId}
        />
    );
};
export default InternalTaskForm;
