import React, { useMemo } from 'react';
import TextTemplatePage from 'templatePage/components';
import { getOverrideHomepageSelector } from 'util/applicationConfig';
import { useAppSelector } from 'reducers/rootReducer';

const useOverrideHomepageComponent = () => {
    const override = useAppSelector(getOverrideHomepageSelector);
    return useMemo(() => {
        if (!override) {
            return undefined;
        }
        return () => <TextTemplatePage textTemplateName={override.textTemplateName} />;
    }, [override]);
};

export default useOverrideHomepageComponent;
