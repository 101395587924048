import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import { getEnvConfigCssSelector } from 'util/applicationConfig';
import { useAppSelector } from 'reducers/rootReducer';

const STYLE_SHEET_ID = 'casetivity-custom-css';

const updateCustomStyleSheet = (dangerousCss: string) => {
    const sheetToBeRemoved = document.getElementById(STYLE_SHEET_ID);
    if (sheetToBeRemoved) {
        const sheetParent = sheetToBeRemoved.parentNode;
        if (sheetParent) {
            sheetParent.removeChild(sheetToBeRemoved);
        }
    }
    const sheet = document.createElement('style');
    sheet.id = STYLE_SHEET_ID;
    const sanitizedCss = DOMPurify.sanitize('<style>' + dangerousCss + '</style>', { WHOLE_DOCUMENT: true }).slice(
        '<html><head><style>'.length,
        '</style></head><body></body></html>'.length * -1,
    );
    sheet.innerHTML = sanitizedCss;
    document.body.appendChild(sheet);
};
const WithEnvConfigGlobalStylesheet = (prop) => {
    const css = useAppSelector(getEnvConfigCssSelector);
    useEffect(() => {
        updateCustomStyleSheet(css);
    }, [css]);
    return null;
};
export default WithEnvConfigGlobalStylesheet;
