import { createListenerMiddleware, isAnyOf, configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createEpicMiddleware, ofType } from 'redux-observable';
import rootSaga from './sagas';
import rootEpic from './epics';
import rootReducer, { getInitialState, routerHistory } from './reducer';
import { services, Services } from 'sideEffect/services';
import { RootAction } from 'actions/rootAction';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { RootState } from 'reducers/rootReducer';
import debounce from 'lodash/debounce';
import { TOGGLE_DEBUG_MODE } from 'actions/constants';
import { rehydrateState } from 'reducers/serializeDeserialize';
import { OfflineTasks } from 'offline_app/offline_stateful_tasks/offlineTasks/offlineTasksReducer';
import { saveOfflineEncryptedDataToIDB } from 'workers/saveOfflineEncryptedDataToIDB';
import { unsetCurrentlyWritingToOffline } from 'offline_app/offline_stateful_tasks/currentlyWritingToOfflineState/actions';
import { getTaskId, onTaskPage } from './util';
import { loadTaskStateToStore } from './loadTaskStateToStore';
import { getDencryptTaskDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeTaskData';
import { offlineTaskExpirationDatesKeyVal } from 'IndexedDB/offlineTaskExpirationDates';
import sessionSecretsController from 'offline_app/sessionSecretsController';
import moment from 'moment';
import { startExpirationHandlers } from 'offline_app/expiration/startExpirationHandlers';
import { offlineTaskToProfileIdbKeyVal } from 'IndexedDB/offlineTaskToProfile';
import { deleteOfflineTaskData } from 'offline_app/expiration/deleteExpiredTasks';
import { getExpiredOfflineDataSubscribedComponentsRegistry } from 'offline_app/offline_stateful_tasks/ExpiredOfflineDataSubscribedComponentsRegistry';
import { EncryptionInput } from 'encryption/encryptionUtils';
import isOffline from 'util/isOffline';
import deepEql from 'fast-deep-equal';
import { offlineTaskIsPristine } from 'IndexedDB/offlineTaskIsPristine';
import { getExpireDaysSelector } from 'util/applicationConfig';
import { hide, show, toggle } from 'components/generics/visibleViewNames/visibleViewNamesSlice';
import { setVisibleViewNames } from 'components/generics/visibleViewNames/storage';
export { routerHistory } from './reducer';

const removeValidationHackProperty = ({ _validationHack, ...rest }) => rest;

export const epic$ = new BehaviorSubject(rootEpic);

// Since we're using mergeMap, by default any new
// epic that comes in will be merged into the previous
// one, unless an EPIC_END action is dispatched first,
// which would cause the old one(s) to be unsubscribed
const hotReloadingEpic = (action$, ...rest) =>
    epic$.pipe(
        mergeMap((epic) => epic(action$, ...(rest as [any, any])).pipe(takeUntil(action$.pipe(ofType('EPIC_END'))))),
    );

export const startNewRootEpic = () => epic$.next(rootEpic);

export function configureStore(
    initialState?: RootState,
    noSideEffectsMode: boolean = isOffline(),
    keepLastActionForTest = false,
) {
    const sagaMiddleware = createSagaMiddleware();
    const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
        dependencies: services,
    });
    const listenerMiddleware = createListenerMiddleware();
    listenerMiddleware.startListening({
        matcher: isAnyOf(toggle, show, hide),
        effect: (action, listenerApi) => {
            setVisibleViewNames((listenerApi.getState() as RootState).visibleViewNames);
        },
    });

    const store = (() => {
        const preloadedState = initialState ? (rehydrateState(initialState) as any) : getInitialState();
        if (noSideEffectsMode) {
            const reducer = keepLastActionForTest
                ? (state, action) => {
                      const { lastAction, ...restState } = state;
                      function lastActionReducer(state = null, action) {
                          return action;
                      }
                      return {
                          ...rootReducer(restState, action),
                          lastAction: lastActionReducer(lastAction, action),
                      };
                  }
                : rootReducer;

            return rtkConfigureStore({
                reducer,
                preloadedState,
                middleware: (getDefaultMiddleware) => {
                    const middleware = getDefaultMiddleware({
                        thunk: false,
                        serializableCheck: false,
                        immutableCheck: false,
                    })
                        .concat(routerMiddleware(routerHistory))
                        .concat(listenerMiddleware.middleware);
                    return middleware;
                },
            });
        }
        const store = rtkConfigureStore({
            reducer: rootReducer,
            preloadedState,
            middleware: (getDefaultMiddleware) => {
                const middleware = getDefaultMiddleware({
                    thunk: false,
                    serializableCheck: false,
                    immutableCheck: false,
                }).concat(
                    sagaMiddleware,
                    epicMiddleware,
                    routerMiddleware(routerHistory),
                    listenerMiddleware.middleware,
                );
                return middleware;
            },
        });

        epicMiddleware.run(hotReloadingEpic);

        sagaMiddleware.run(rootSaga);
        return store;
    })();

    // OFFLINE BELOW
    let prevOnlineTaskWritingToOffline: string = null;
    let prevOfflineTasks: OfflineTasks = null;
    let prevLocationPathname: string = '';

    const saveData = (taskId: string, data: RootState, encryptionInput: EncryptionInput) => {
        if (!store.getState().saveOfflineGateOpen) {
            return;
        }
        if (!store.getState().offlineTasks?.[taskId]) {
            /**
             * because this is debounced, we need to be careful not to write after the task is removed/deleted.
             * because then we end up adding the task right back!
             * */
            return;
        }
        const taskIdFromUrl = getTaskId(data.router?.location?.pathname ?? '');
        if (taskIdFromUrl !== taskId) {
            /**
             * We navigated to a different page - don't download!
             */
            return;
        }
        saveOfflineEncryptedDataToIDB(taskId, JSON.parse(JSON.stringify(data)), encryptionInput);
    };
    const debouncedSaveData = debounce(saveData, 1000);
    const unsubscribes: { [taskKey: string]: () => void } = {};
    const addTaskSubscription = (taskId: string) => {
        let taskPristine: 'pristine' | 'made_dirty' | 'already_dirty' = 'pristine';
        // lets set this, but also gracefully handle the case where we run a writeData before our offlineTaskIsPristine promise returns
        offlineTaskIsPristine.get(taskId).then((res) => {
            if (res && taskPristine === 'pristine') {
                taskPristine = 'already_dirty';
            }
        });

        let prevForms: RootState['form'] = {};
        let prevEntities: RootState['admin']['entities'] = {};
        let prevResources: RootState['admin']['resources'] = {};
        let prevValuesets: RootState['valueSets'] = {};
        let prevBpm: RootState['bpm'] = null;
        let prevGetOneStatus: RootState['getOneStatus'] = {};
        let prevPrintTemplates: RootState['printTemplates'] = null;
        let prevTaskFormStatuses: RootState['taskFormStatuses'] = {};
        let prevTaskForms: RootState['taskForms'] = {};
        let prevCurrentTaskForm = null;
        let prevLinkedEntityForm = null;
        let prevOfflineMeta: RootState['offlineMeta'] = null;
        const writeData = async (debounced = true) => {
            const state = store.getState() as RootState;
            /*
                Lets track changes to form data by checking for changes here.
                track previous, and then compare equality with current.
                if different, (and some 'dirty' hasn't been marked)
                write as dirty to idb
            */

            const {
                admin: { entities, resources },
                form,
                valueSets,
                bpm,
                getOneStatus,
                printTemplates,
                taskFormStatuses,
                taskForms,
                offlineMeta,
            } = state;
            const setCaches = () => {
                prevLinkedEntityForm = form['record-form'];
                prevCurrentTaskForm = form['current-task-form'];
                prevForms = form;
                prevResources = resources;
                prevEntities = entities;
                prevValuesets = valueSets;
                prevBpm = bpm;
                prevGetOneStatus = getOneStatus;
                prevPrintTemplates = printTemplates;
                prevTaskFormStatuses = taskFormStatuses;
                prevTaskForms = taskForms;
                prevOfflineMeta = offlineMeta;
            };
            if (
                prevResources !== resources ||
                prevForms !== form ||
                prevEntities !== entities ||
                prevValuesets !== valueSets ||
                prevBpm !== bpm ||
                prevGetOneStatus !== getOneStatus ||
                prevPrintTemplates !== printTemplates ||
                prevTaskFormStatuses !== taskFormStatuses ||
                prevTaskForms !== taskForms ||
                prevOfflineMeta !== offlineMeta
            ) {
                if (taskPristine === 'pristine') {
                    // Synchronous block where we figure out if the task-form is pristine and just made dirty
                    const ptfv = prevCurrentTaskForm?.['values'];
                    const ctfv = form['current-task-form']?.['values'];
                    if (ptfv && ctfv && !deepEql(ptfv, ctfv)) {
                        taskPristine = 'made_dirty';
                    }
                    const plefv = prevLinkedEntityForm?.['values'];
                    const clefv = form['record-form']?.['values'];
                    if (
                        plefv &&
                        clefv &&
                        !deepEql(removeValidationHackProperty(plefv), removeValidationHackProperty(clefv))
                    ) {
                        taskPristine = 'made_dirty';
                    }
                    if (taskPristine === 'made_dirty') {
                        taskPristine = 'already_dirty';
                        // don't await on this.
                        offlineTaskIsPristine.set(taskId, false);
                    }
                }
                setCaches();
                if (
                    onTaskPage(state.router.location.pathname) &&
                    form['current-task-form'] &&
                    state.taskFormStatuses[taskId]?._type === 'success' &&
                    !state.admin.loading
                ) {
                    let sessionSecrets = sessionSecretsController.get();
                    if (!sessionSecrets) {
                        await getDencryptTaskDataPromptController().promptDecodeTaskData(taskId);
                        sessionSecrets = sessionSecretsController.get();
                    }
                    if (debounced) {
                        debouncedSaveData(taskId, state, { type: 'secrets', secrets: sessionSecrets });
                    } else {
                        await saveData(taskId, state, { type: 'secrets', secrets: sessionSecrets });
                    }
                }
            }
        };

        writeData(false);
        const unsubscribe = store.subscribe(writeData);
        unsubscribes[taskId] = unsubscribe;
    };
    // when we open directly to that task page, we need to make sure we destroy our side-effects, and load state from idb
    if (onTaskPage(store.getState().router.location.pathname)) {
        if (isOffline()) {
            // contains PIN check
            loadTaskStateToStore(store, (taskId) => addTaskSubscription(taskId));
        } else {
            // we are online
        }
    }
    store.subscribe(() => {
        const state: RootState = store.getState();
        if (
            prevOfflineTasks &&
            prevOfflineTasks !== state.offlineTasks &&
            // this is important - if state.offlineTasks is set to null, don't treat it as 'we removed tasks'
            // treat it as 'we cleared our store out'.
            // this happens when CLEAR_STORE_BUT_KEEP_LOCATION is dispatched, e.g. from /login
            state.offlineTasks
        ) {
            const offlineTasksAdded: string[] = Object.keys(state.offlineTasks).filter(
                (taskId) => !prevOfflineTasks[taskId],
            );
            const offlineTasksRemoved: string[] = Object.keys(prevOfflineTasks).filter(
                (taskId) => !state.offlineTasks?.[taskId],
            );
            offlineTasksAdded.forEach(addTaskSubscription);
            offlineTasksAdded.forEach(async (taskId) => {
                const existing = await offlineTaskExpirationDatesKeyVal.get(taskId);
                if (!existing?.expires) {
                    const expireDays = getExpireDaysSelector(state);
                    offlineTaskExpirationDatesKeyVal.set(taskId, {
                        expires: moment().add(expireDays, 'days').toDate(),
                    });
                }
                const profile = await offlineTaskToProfileIdbKeyVal.get(taskId);
                if (!profile?.userId) {
                    offlineTaskToProfileIdbKeyVal.set(taskId, {
                        display: store.getState().viewConfig.user.title,
                        userId: store.getState().viewConfig.user.id,
                    });
                }
            });
            offlineTasksRemoved.forEach(async (taskId: string) => {
                unsubscribes[taskId]?.();
                await deleteOfflineTaskData(taskId);
                getExpiredOfflineDataSubscribedComponentsRegistry().dispatchSomeOfflineTasksExpired();
            });
        }
        prevOfflineTasks = state.offlineTasks;
        if (!isOffline()) {
            // we are online
            if (prevOnlineTaskWritingToOffline !== state.taskCurrentlyWritingToOffline) {
                let taskId = getTaskId(state.router.location.pathname);
                if (taskId && taskId === state.taskCurrentlyWritingToOffline) {
                    /*
                        We are online, but switching to writing to the offline task.
                        lets prompt for PIN, if there isn't one.
                    */
                    addTaskSubscription(taskId);
                }
            }
        }
        prevOnlineTaskWritingToOffline = state.taskCurrentlyWritingToOffline;
        if (state.router.location.pathname !== prevLocationPathname) {
            if (onTaskPage(state.router.location.pathname)) {
                const taskId = getTaskId(state.router.location.pathname);
                if (state.offlineTasks?.[taskId] && isOffline()) {
                    /*
                        We are offline, and just opened an offline task.
                        Prompt for PIN before adding the subscription.

                        We could have reached here by hitting the back button,
                        and not having a PIN entered. in that case, we should load state
                    */

                    loadTaskStateToStore(store, (taskId) => addTaskSubscription(taskId));
                }
            } else {
                Object.values(unsubscribes).forEach((unsubscribe) => {
                    unsubscribe?.();
                });
                if (isOffline()) {
                    if (state.taskCurrentlyWritingToOffline) {
                        store.dispatch(unsetCurrentlyWritingToOffline());
                    }
                    if (state.debugMode) {
                        startNewRootEpic();
                        store.dispatch({
                            type: TOGGLE_DEBUG_MODE,
                        });
                    }
                } else if (state.taskCurrentlyWritingToOffline) {
                    store.dispatch(unsetCurrentlyWritingToOffline());
                }
            }
            prevLocationPathname = state.router.location.pathname;
        }
    });
    startExpirationHandlers(store);
    return store;
}

export default configureStore;
