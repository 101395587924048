/* export default {
    TEXT: 'text',
    MULTILINE_TEXT: 'multiline-text',
    FLOAT: 'float',
    INTEGER: 'integer',
    BOOL: 'boolean',
    CHECKBOX: 'checkbox',
    DATE: 'date',
    RADIO: 'radio',
    DATETIME: 'date-time',
    ZONE_DATE: 'zonedate',
    FILE_UPLOAD: 'fileupload',
    PROCESS_FILE_UPLOAD: 'process-fileupload',
    ERROR_TABLE: 'error-table',
    EMAIL: 'email',
    REFONE_SELECT: 'refone_select',
    REFONE_JOIN_SELECT: 'refone_join_select',
    VALUESET_SELECT: 'valueset_select',
    VALUESET_MULTISELECT: 'valueset_multiselect',
    REFMANY_MULTISELECT: 'refmanymultiselect',
    REFMANY_JOIN_MULTISELECT: 'refmany_join_multiselect',
    REFMANY_MULTISELECT_IDSLIST: 'refmanymultiselect-idlist',
    CHOICES_SELECT: 'choices-select',
    EXPRESSION: 'expression',
    HTML_EXPRESSION: 'html-expression',
};
*/
export const RELATIVE_DATE = 'relative-date';
export const RECAPTCHA = 'recaptcha';
export const ADDRESS_VERIFICATION = 'address-verification';
export const ADDRESS_VERIFICATION_2 = 'address-verification2';
export const TEXT = 'text';
export const MULTILINE_TEXT = 'multiline-text';
export const FLOAT = 'float';
export const INTEGER = 'integer';
export const BOOL = 'boolean';
export const CHECKBOX = 'checkbox';
export const DATE = 'date';
export const RADIO = 'radio';
export const DATETIME = 'date-time';
export const TIME = 'time';
export const ZONE_DATE = 'zonedate';
export const FILE_UPLOAD = 'fileupload';
export const PROCESS_FILE_UPLOAD = 'process-fileupload';
export const ERROR_TABLE = 'error-table';
export const EMAIL = 'email';
export const REFONE_SELECT = 'refone_select';
export const REFONE_JOIN_SELECT = 'refone_join_select';
export const VALUESET_SELECT = 'valueset_select';
export const VALUESET_MULTISELECT = 'valueset_multiselect';
export const REFMANY_MULTISELECT = 'refmanymultiselect';
export const REFMANY_JOIN_MULTISELECT = 'refmany_join_multiselect';
export const REFMANY_MULTISELECT_IDSLIST = 'refmanymultiselect-idlist';
export const CHOICES_SELECT = 'choices-select';
export const EXPRESSION = 'expression';
export const HTML_EXPRESSION = 'html-expression';
export const TABLE = 'table';
export const LIST = 'list';
export const ONEOF = 'one-of';
export const VALUESET_MULTICHECKBOX = 'valueset-multi-box';
export const VALUESET_CHECKBOX = 'valueset-single-box';
export const TOGGLE = 'toggle';
export const BIGCALENDAR = 'big-calendar';
export const CURRENCY = 'currency';
export const EVENT = 'event';
export const REFMANYMANY_CHIP = 'refmanychip';
export const PERCENT = 'percent';
export const ENTITY_TYPEAHEAD = 'entitytypeahead';
export const MULTIPLE_ENTITY_TYPEAHEAD = 'multiple-entity-typeahead';
export const DMS_DOCUMENT = 'dms-document';
export const WYSIWYG = 'html-wysiwyg';
export const NULLABLE_BOOLEAN = 'nullable-boolean';
export const VALUESET_SUGGEST = 'valueset-suggest';
export const VALIDATION_EXPRESSION_EDITOR = 'validation-expression-editor';
export const VIDEO_WATCHED = 'video-watched';
export const IMAGE = 'image';
export const AUTH_CODE = 'AUTH_CODE';
export const WIZARD_CONTROL = 'WIZARD_CONTROL';
export const MULTI_CARD = 'MULTI_CARD';
export const MANYMANY_MULTI_CARD = 'MANYMANY_MULTI_CARD';

export const JSONSCHEMA_FORM = 'JSONSCHEMA_FORM';
export const JSONSCHEMA_FORM_BUILDER = 'JSONSCHEMA_FORM_BUILDER';

export const BPM_FORM = 'BPM_FORM';
export const BPM_FORM_BUILDER = 'BPM_FORM_BUILDER';

export const UST_DAY = 'UST_DAY';
export const INLINE_MANY = 'INLINE_MANY';
export const COMPONENT = 'COMPONENT';
