import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { useContext } from 'react';
import { casetivityViewContext } from 'util/casetivityViewContext';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';

export const useRelevantFormContext = () => {
    const { currentViewContext } = useContext(casetivityViewContext);
    const taskFormContext = useContext(formContext);

    const isTaskFormContext = currentViewContext === 'bpm';

    const entityFormContext = useCurrentFormContext();
    if (isTaskFormContext) return taskFormContext;
    return entityFormContext;
};
