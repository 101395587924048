import React, { createContext, useContext, useMemo } from 'react';
import NullableBoolean from '../NullableBoolean';
import NullableDropdown from './nullableDropdown/NullableDropdown';
import CasetivitySelect from 'components/CasetivitySelect';
import useViewConfig from 'util/hooks/useViewConfig';
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { AdhocBpmFormBuilder, defaultInitialTaskForm } from 'bpm/task-form-editor/AdhocBpmFormBuilder';
import { customWidgetsRegistry } from './customWidgetsRegistry';

export const componentViewPickerContext = createContext<string>(null);

const ComponentViewPicker = (props) => {
    // pick from a component view, from provided componentViewPickerContext
    const description = props.DescriptionField ? <props.DescriptionField /> : null;
    const { fieldVariant, forceLabelShrink } = React.useContext(themeOverrideContext);
    const componentViewPickerEntity = useContext(componentViewPickerContext);
    const viewConfig = useViewConfig();
    const viewNames = useMemo(() => {
        return Object.keys(viewConfig.views).filter(
            (k) =>
                viewConfig.views[k].viewType === 'COMPONENT' &&
                viewConfig.views[k].entity === componentViewPickerEntity,
        );
    }, [viewConfig, componentViewPickerEntity]);
    return (
        <div>
            <FormControl variant={fieldVariant} margin="normal" style={{ minWidth: 180 }}>
                <InputLabel shrink={forceLabelShrink} htmlFor="componentViewPicker">
                    Component View
                </InputLabel>
                <CasetivitySelect
                    value={props.value}
                    onChange={(event) => props.onChange(event.target.value)}
                    name="component"
                    label="Component View"
                    inputProps={{
                        id: 'componentViewPicker',
                    }}
                >
                    {({ OptionComponent }) => [
                        <OptionComponent key="none" value="">
                            None
                        </OptionComponent>,
                        ...viewNames.map((viewName) => (
                            <OptionComponent key={viewName} value={viewName}>
                                {viewName}
                            </OptionComponent>
                        )),
                    ]}
                </CasetivitySelect>
            </FormControl>
            {description}
        </div>
    );
};

const AdhocFormBuilder = (props) => {
    const initialForm = React.useMemo(
        () =>
            JSON.stringify({
                definition: defaultInitialTaskForm,
            }),
        [],
    );
    const builderValueRef = React.useRef(props.value || initialForm);
    const currentForm = React.useMemo(
        () => JSON.parse(props.value || initialForm).definition,
        [props.value, initialForm],
    );
    if (props.value) {
        builderValueRef.current = props.value;
    }
    return (
        <div>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!props.value}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    props.onChange(builderValueRef.current);
                                } else {
                                    props.onChange('');
                                }
                            }}
                        />
                    }
                    label="Define form statically"
                />
            </FormGroup>
            {props.value && (
                <AdhocBpmFormBuilder
                    currentForm={currentForm}
                    setCurrentForm={(form) => props.onChange(JSON.stringify({ definition: form }, null, 1))}
                    noOutcomes
                />
            )}
        </div>
    );
};

const customWidgets = {
    nullableBoolean: (props) => {
        const description = props.DescriptionField ? <props.DescriptionField /> : null;
        return (
            <div>
                <NullableBoolean
                    meta={{}}
                    input={{
                        value: props.value,
                        onBlur: (value) => props.onChange(value),
                    }}
                    label={props.label}
                />
                {description}
            </div>
        );
    },
    nullableDropdown: (props) => {
        return <NullableDropdown {...props} />;
    },
    componentViewPicker: (props) => {
        return <ComponentViewPicker {...props} />;
    },
    adhocFormBuilder: (props) => {
        return <AdhocFormBuilder {...props} />;
    },
};

Object.entries(customWidgets).forEach(([k, v]) => {
    customWidgetsRegistry[k] = v;
});
