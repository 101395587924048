import React, { useContext } from 'react';
import { bpmFormBuilderContext } from './internal/bpmFormBuilderContext';
import { BpmFormBuilderProps } from './BpmFormBuilderProps';

const BpmFormBuilderField: React.FC<BpmFormBuilderProps> = (props) => {
    const Component = useContext(bpmFormBuilderContext);
    return <Component {...props} />;
};

export default BpmFormBuilderField;
