import React from 'react';
import { createGetEntities } from '../EntityFormContext/util/getEntities';
import { createSelector } from 'reselect';
import { useAppSelector } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
const useDataLoaded = (resource: string, id: string) => {
    const dataLoadedSelector = React.useMemo(() => {
        const getEntities = createGetEntities();
        return createSelector(
            getEntities,
            (state, props) => props.id,
            (state, props) => props.resource,
            (entities, id, resource) => {
                return fromNullable(entities[resource])
                    .mapNullable((e) => e[id])
                    .fold(false, () => true);
            },
        );
    }, []);
    const dataLoaded = useAppSelector((state) => dataLoadedSelector(state, { id, resource }));
    return dataLoaded;
};

export default useDataLoaded;
