import React, { useContext } from 'react';
import { CasetivityConfigRootViewContextVariable, casetivityViewContext } from 'util/casetivityViewContext';
import { Subtract } from 'utility-types';

export default function getProviderAndHocWithViewContext<
    P extends { viewContext: CasetivityConfigRootViewContextVariable },
>(BaseFormContextProvider: React.ComponentType<P>) {
    type FormContextProviderProps = Subtract<P, { viewContext: CasetivityConfigRootViewContextVariable }>;
    const FormContextProvider: React.SFC<FormContextProviderProps> = (props) => {
        const { rootViewContext: viewContext } = useContext(casetivityViewContext);
        const BFCPProps: P = {
            viewContext,
            ...props,
        } as P;
        return <BaseFormContextProvider {...BFCPProps} />;
    };
    const formContextHoc =
        (overrideProps?: Partial<FormContextProviderProps>) =>
        <X extends FormContextProviderProps>(BaseComponent) =>
        (props: X) => {
            return (
                <FormContextProvider {...props} {...overrideProps}>
                    <BaseComponent {...props} />
                </FormContextProvider>
            );
        };

    return { formContextHoc, FormContextProvider };
}
