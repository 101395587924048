import ViewConfig from '../../reducers/ViewConfigType';
import { appendStarToTextFieldsInSearch } from './appendStarToTextFieldsInSearch';
import translateFilter from './translateFieldWithSearchTypeAppended';
import {
    getDataTypeForFieldExpr,
    getViewIndexAndAdditionalConfigFields,
    isRefOneField,
    isValueSetField,
} from 'components/generics/utils/viewConfigUtils';
import moment from 'moment';
import dateToDateTimeString from 'fieldFactory/input/components/DateTimePicker/dateToDateTimeString';
import replaceSeperatorsInKeys from './replaceSeperatorsInKeys';

const getViewEntity = (viewConfig: ViewConfig, viewName: string) => {
    if (viewName) {
        const [vx] = getViewIndexAndAdditionalConfigFields(viewName, viewConfig, 'KEEP_LINKEDX_TYPE');
        return viewConfig.views[vx].entity;
    }
    return null;
};
const getIsTimeStringToConvertToInstant = (viewConfig: ViewConfig, viewName?: string | null) => {
    // if not null, the entity we are looking at, for which we check data types and do conversions.
    const checkInstantsFromEntityAndConvert: string | null = (() => {
        return getViewEntity(viewConfig, viewName);
    })();
    return checkInstantsFromEntityAndConvert
        ? (field: string, value: any): string | null => {
              const f = field.split('__')[0].split('_~_').join('.');

              try {
                  if (
                      // lets skip those cases where we obviously will fail.
                      !f.endsWith('Id') &&
                      !f.endsWith('Ids') &&
                      !f.endsWith('Codes') &&
                      !f.endsWith('Code') &&
                      !f.endsWith('.id') &&
                      !f.endsWith('.code') &&
                      // lets also check that our data is in YYYY-MM-DD format
                      typeof value === 'string' &&
                      value.match(/^\d\d\d\d-\d\d-\d\d$/) &&
                      // finally we do our expensive check (expensive in that it throws if it fails...)
                      // it would be nice to have an alternate set of functions for this, because it kills performance.
                      getDataTypeForFieldExpr(viewConfig, checkInstantsFromEntityAndConvert, f, 'TRAVERSE_PATH') ===
                          'INSTANT'
                  ) {
                      // midnight in user's current timezone
                      const tvalue = moment(value)
                          .set('hours', 0)
                          .set('minutes', 0)
                          .set('seconds', 0)
                          .set('milliseconds', 0)
                          .toDate();
                      return dateToDateTimeString(tvalue);
                  }
              } catch (e) {
                  console.error(e);
              }
              return null;
          }
        : (null as ((field: string, value: any) => string | null) | false);
};

const preprocessFilter = (_fields: { [key: string]: {} }, viewConfig: ViewConfig, viewName?: string | null) => {
    const fields = replaceSeperatorsInKeys(_fields, '_~_', '.');
    const getIsInstant = getIsTimeStringToConvertToInstant(viewConfig, viewName);
    const res = Object.assign(
        {},
        ...Object.entries(appendStarToTextFieldsInSearch(fields, viewConfig, viewName)).flatMap(([field, value]) => {
            if (value === '*' || value === '') {
                return [];
            }
            if (
                (field.endsWith('__IN') || field.endsWith('__NOT_IN')) &&
                (!value || (Array.isArray(value) && value.length === 0))
            ) {
                return [];
            }
            try {
                const [_fieldPath, modifier] = field.split('__');
                const fieldPath = _fieldPath.split('_~_').join('.');
                const relatedEntity = getViewEntity(viewConfig, viewName);
                if (
                    !fieldPath.endsWith('Id') &&
                    relatedEntity &&
                    (isRefOneField(viewConfig, relatedEntity, fieldPath, 'TRAVERSE_PATH') ||
                        isValueSetField(viewConfig, relatedEntity, fieldPath, 'TRAVERSE_PATH'))
                ) {
                    return [
                        {
                            [translateFilter(fieldPath + 'Id' + (modifier ? `__${modifier}` : ''))]: value,
                        },
                    ];
                }
            } catch (e) {
                console.error(e);
                // continue
            }
            if (getIsInstant) {
                const maybeTimestamp = getIsInstant(field, value);
                if (maybeTimestamp) {
                    if (field.endsWith('__EXACT')) {
                        return [
                            {
                                [translateFilter(field.split('__')[0] + '__GREATER_EQUAL')]: maybeTimestamp,
                                [translateFilter(field.split('__')[0] + '__LESS')]: dateToDateTimeString(
                                    moment(maybeTimestamp).add(1, 'day').toDate(),
                                ),
                            },
                        ];
                    }
                    return [{ [translateFilter(field)]: maybeTimestamp }];
                }
            }
            return [{ [translateFilter(field)]: value }];
        }),
    );
    return res;
};

export default preprocessFilter;
