import { useCallback, useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { LiteralToPrimitive } from 'react-hook-form/dist/types/utils';
import { useDebouncedCallback } from 'use-debounce';

/**
 * Returns state of a warning for the given form field.
 * https://gist.github.com/jtomaszewski/519781e5d2a585bbdbc6f8b76bad7f43
 */
export function useFormFieldWarning<TFieldValues extends Record<string, any>, TFieldName extends string, TFieldValue>({
    name,
    validate: validateValue,
    onChangeDebounceDelay = 500,
}: {
    mode?: 'onChangeDebounced';
    name: TFieldName;
    validate(
        value: TFieldName extends keyof TFieldValues
            ? UnpackNestedValue<TFieldValues[TFieldName]>
            : UnpackNestedValue<LiteralToPrimitive<TFieldValue>>,
    ): string | undefined;
    onChangeDebounceDelay?: number;
}): {
    warning: string | undefined;
    triggerWarning(): void;
} {
    const [warning, setWarning] = useState<string>();

    const validateValueRef = useRef(validateValue);
    validateValueRef.current = validateValue;

    const { watch, getValues } = useFormContext<TFieldValues>();

    const validate = useCallback((): void => {
        const value = getValues<TFieldName, TFieldValue>(name);
        const nextWarning = validateValueRef.current(value as any);
        setWarning(nextWarning);
    }, [getValues, name]);

    const [validateDebounced, cancelValidateDebounce] = useDebouncedCallback(validate, onChangeDebounceDelay);

    const value = watch<TFieldName, TFieldValue>(name);
    useEffect((): void => {
        setWarning(undefined);
        cancelValidateDebounce();
        validateDebounced();
    }, [value, cancelValidateDebounce, validateDebounced]);

    return {
        warning,
        triggerWarning: validate,
    };
}
