import { createSlice } from '@reduxjs/toolkit';
import { ViewDefaultValueExpressionMetaData } from './ViewDefaultValueExpressions';

export const viewDefaultValueExpressions = createSlice({
    name: 'ViewDefaultValueExpressions',
    initialState: {} as ViewDefaultValueExpressionMetaData,
    reducers: {
        set: (
            state,
            action: {
                payload: ViewDefaultValueExpressionMetaData;
            },
        ) => {
            return action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { set } = viewDefaultValueExpressions.actions;

export default viewDefaultValueExpressions.reducer;
