import { Ast } from 'ts-spel/lib-esm/lib/Ast';

export const isUnclosedStringExpression = (node: Ast): boolean => {
    let foundUnclosedString = false;
    const traverseAst = (node: Ast) => {
        if (!node) {
            // if ast was partially parsed
            return;
        }
        switch (node.type) {
            case 'MethodReference':
            case 'FunctionReference':
                if (node.__unclosed) {
                    const lastNode = node.args[node.args.length - 1];
                    if (lastNode) {
                        traverseAst(lastNode);
                    }
                }
                break;
            case 'PropertyReference':
                break;
            case 'CompoundExpression':
                // #f().date.of(" is an example of an unclosed compound
                const lastNode = node.expressionComponents[node.expressionComponents.length - 1];
                traverseAst(lastNode);
                break;
            case 'Elvis':
                traverseAst(node.expression);
                if (node.ifFalse) {
                    traverseAst(node.ifFalse);
                }
                break;

            case 'Indexer':
                if (node.__unclosed) {
                    traverseAst(node.index);
                }
                break;
            case 'InlineList':
                if (node.__unclosed) {
                    const lastEl = node.elements[node.elements.length - 1];
                    traverseAst(lastEl);
                }
                break;
            case 'InlineMap':
                if (node.__unclosed) {
                    const values = Object.values(node.elements);
                    const lastValue = values[values.length - 1];
                    if (lastValue) {
                        traverseAst(lastValue);
                    }
                }
                break;
            case 'Negative':
                traverseAst(node.value);
                break;
            case 'OpAnd':
            case 'OpBetween':
            case 'OpDivide':
            case 'OpEQ':
            case 'OpGE':
            case 'OpGT':
            case 'OpLE':
            case 'OpLT':
            case 'OpMatches':
            case 'OpMinus':
            case 'OpModulus':
            case 'OpMultiply':
            case 'OpNE':
            case 'OpOr':
            case 'OpPlus':
                traverseAst(node.left);
                if (node.right) {
                    traverseAst(node.right);
                }
                break;
            case 'OpNot':
                traverseAst(node.expression);
                break;
            case 'Projection':
                if (node.__unclosed) {
                    traverseAst(node.expression);
                }
                break;
            case 'SelectionAll':
                if (node.__unclosed) {
                    traverseAst(node.expression);
                }
                break;
            case 'SelectionFirst':
            case 'SelectionLast':
                if (node.__unclosed) {
                    traverseAst(node.expression);
                }
                break;
            case 'OpPower':
                traverseAst(node.base);
                if (node.expression) {
                    traverseAst(node.expression);
                }
                break;
            case 'Ternary':
                traverseAst(node.expression);
                if (node.ifTrue) {
                    traverseAst(node.ifTrue);
                }
                if (node.ifFalse) {
                    traverseAst(node.ifFalse);
                }
                break;
            case 'VariableReference':
            case 'NumberLiteral':
            case 'NullLiteral':
            case 'BooleanLiteral':
                break;
            case 'StringLiteral':
                if (node.__unclosed) {
                    foundUnclosedString = true;
                }
                break;
        }
    };
    traverseAst(node);
    return foundUnclosedString;
};

export default isUnclosedStringExpression;
