import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { PotentialUser } from 'bpm/potentialUsers/types';
import { BACKEND_BASE_URL } from 'config';
import { Observable } from 'rxjs';
import { AuthPayload, MfaAuthPayload } from 'auth/definitions';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import ProcessDefinition from 'bpm/types/processDefinition';
import { PrintTemplate } from 'printTemplate/definitions';
import { CreateTaskPayload } from 'bpm/createTask/types';
import { map, tap } from 'rxjs/operators';
import { ProcessInstanceFromRestProcessInstances } from 'bpm/dataAdapters/network/rest/processInstances/entities/processInstance';
import { TaskForm } from 'reducers/taskFormType';
import { GlobalAlert } from 'global-alerts/definitions';
import { storageController } from 'storage';
import { StartProcessPayload } from 'bpm/create-process-instance/actions';
import { ReportDefinition } from 'report2/ReportDefinition';
import { Dashboard } from 'dashboard2/dashboard-config/types';
import { getMappedUrl } from './url-rewrite-registry/registry';
import buildHeaders from './buildHeaders';
import getBranchFromJwt from 'branches/getBranchFromJwt';
import { PersonalizedReport, PersonalizedReportSave } from 'custom-reports/types';
import { ValueSetFromMultipleVsEndpoint } from 'valueSets/domain';

// adds a token reset subscription to the ajax Observable
export const refreshJwt = (ajaxObservable: Observable<AjaxResponse>) => {
    return ajaxObservable.pipe(
        tap((ajaxResponse) => {
            const newJwt = ajaxResponse.xhr.getResponseHeader('JWT');
            if (newJwt) {
                console.log('jwt refreshed');
                storageController.setToken(newJwt);
            }
        }),
    );
};

export interface TaskPotentialUsersResponse {
    canClaimTask: boolean;
    canAssignTask: boolean;
    potentialUsers: PotentialUser[];
}

const getJSON = ajax.getJSON;

export const getHeaders = () => {
    return buildHeaders({
        includeCredentials: true,
        Accept: 'application/json',
    });
};

type NoBodyVerbs = 'GET' | 'DELETE' | 'POST';
type BodyVerbs = 'POST' | 'PUT';

export function getOptions(url: string, method: NoBodyVerbs): Object;
export function getOptions(url: string, method: BodyVerbs, body: Object): Object;
export function getOptions(url: string, method: NoBodyVerbs | BodyVerbs, body?: Object): AjaxRequest {
    return {
        url,
        method,
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        withCredentials: true,
        responseType: 'json',
        body,
    };
}
const monitorOptions = (monitor: boolean = false) => {
    return (options: AjaxRequest) => {
        if (monitor) {
            options.headers['X-Is-Monitored'] = true;
        }
        return options;
    };
};

export const getUrl = (url: string) => {
    const fullUrl = `${BACKEND_BASE_URL}${url}`;
    // pass through url-rewrite-registry to get any output transformations
    // if there are no retwrites registered, this just returns `fullUrl`
    return getMappedUrl(fullUrl);
};

export interface EntityBase {
    id: string;
    entityType: string;
    entityVersion: number;
}
const authenticateService = (body: AuthPayload) =>
    ajax({
        url: getUrl('api/authenticate'),
        method: 'POST',
        withCredentials: true,
        responseType: 'json',
        headers: buildHeaders({
            includeCredentials: false,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
        body,
    });

const mfaAuthenticateService = (body: MfaAuthPayload) =>
    ajax({
        url: getUrl('api/mfa-authenticate'),
        method: 'POST',
        withCredentials: true,
        responseType: 'json',
        headers: buildHeaders({
            includeCredentials: true,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
        body,
    });

interface ProcessDefinitionResponse {
    data: ProcessDefinition[];
    size: number;
    start: number;
    total: number;
}

interface DashboardResponse extends EntityBase {
    config: string;
    displayName: string;
    entityType: 'Dashboard';
    name: string;
}
export interface UserPrimaryDashboardResponse extends EntityBase {
    id: string;
    entityType: 'User';
    login: string;
    active: boolean;
    roles: [
        {
            entityType: 'Role';
            name: string; // e.g. "ROLE_SUPER"
        },
    ];
    primaryDashboardId: string | undefined;
}
export type CasetivityCreateForm = {
    name: string;
    key: string;
    formRepresentation: Pick<TaskForm, 'fields' | 'outcomes' | 'description'>;
};
export type CasetivityHandledForm = {
    id?: string;
    name: string;
    key: string;
    version?: number;
    lastUpdated?: string;
    lastUpdatedBy?: string;
    formRepresentation: Pick<TaskForm, 'fields' | 'outcomes' | 'description'>;
};
export type CasetivityFormListItem = {
    id: string;
    name: string;
    key: string;
    version: number;
};
export const services = {
    split: {
        splitRecord: (
            restUrl: string, // e.g. api/people
            primaryRecord: Record<string, unknown>,
            secondaryRecord: Record<string, unknown>,
        ): Observable<{
            primaryRecord: Record<string, unknown>;
            primaryRecordId: string;
            secondaryRecord: Record<string, unknown>;
            secondaryRecordId: string;
        }> =>
            refreshJwt(
                ajax(
                    getOptions(getUrl(`${restUrl}/${primaryRecord['id']}/split?splitType=GENERIC_SPLIT`), 'POST', {
                        primaryRecord,
                        secondaryRecord,
                    }),
                ),
            ).pipe(
                map((r) => {
                    return r.response;
                }),
            ),
    },
    taskFormsDefinitions: {
        importFile: (file: File) => {
            const formData = new FormData();
            formData.append('file', file);
            return refreshJwt(
                ajax({
                    url: getUrl(`api/admin/forms/form-definition/import`),
                    method: 'POST',
                    headers: buildHeaders({
                        includeCredentials: true,
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }),
                    withCredentials: true,
                    responseType: 'json',
                    body: formData,
                }),
            );
        },
        export: (formKey: string) =>
            getJSON<CasetivityHandledForm>(getUrl(`api/admin/forms/form-definition/${formKey}/export`), getHeaders()),
        delete: (formKey: string) =>
            refreshJwt(ajax(getOptions(getUrl(`api/admin/forms/form-definition/${formKey}`), 'DELETE'))),
        create: (formDefinition: CasetivityCreateForm) =>
            refreshJwt(ajax(getOptions(getUrl('api/admin/forms/form-definition'), 'POST', formDefinition))),
        update: (formDefinition: CasetivityHandledForm, asNewVersion: boolean) =>
            refreshJwt(
                ajax(
                    getOptions(
                        getUrl(`api/admin/forms/form-definition${asNewVersion ? '?isNewVersion=true' : ''}`),
                        asNewVersion ? 'POST' : 'PUT',
                        formDefinition,
                    ),
                ),
            ),
        get: (formDefinitionKey: string) =>
            getJSON<CasetivityHandledForm>(
                getUrl(`api/admin/forms/form-definition/${formDefinitionKey}`),
                getHeaders(),
            ),
        list: (options?: {
            page?: number;
            size?: number;
            filter?: Record<string, any>;
            orderBy?: [string, 'desc' | 'asc'];
        }) =>
            refreshJwt(
                ajax(
                    getOptions(
                        getUrl(
                            `api/admin/forms/form-definition${(() => {
                                const els = [
                                    typeof options?.page === 'number' ? 'page=' + options.page : '',
                                    typeof options?.size === 'number' ? 'size=' + options.size : '',
                                    options?.orderBy
                                        ? `sort=${options?.orderBy?.[0]},${options?.orderBy[1] ?? 'asc'}`
                                        : '',
                                    ...Object.entries(options?.filter ?? {}).map(
                                        ([key, value]) => value && `${key}=${value}`,
                                    ),
                                ]
                                    .filter(Boolean)
                                    .join('&');
                                return (els.length > 0 ? '?' : '') + els;
                            })()}`,
                        ),
                        'GET',
                    ),
                ),
            ).pipe(
                map((r) => {
                    const { response, xhr } = r;
                    const xTotalCount = xhr.getResponseHeader('x-total-count');
                    if (isNaN(Number(xTotalCount))) {
                        throw new Error(`The X-Total-Count header is missing in the HTTP Response.
     If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?`);
                    }
                    return {
                        data: response as CasetivityFormListItem[],
                        total: parseInt(xTotalCount, 10),
                    };
                }),
            ),
    },
    personalizedReports: {
        get: (id: string) => {
            return getJSON<PersonalizedReport>(getUrl('api/reports/personalize/' + id), getHeaders());
        },
        create: (config: PersonalizedReportSave) => {
            return refreshJwt(ajax(getOptions(getUrl('api/reports/personalize'), 'POST', config)));
        },
        update: (config: PersonalizedReportSave) => {
            return refreshJwt(ajax(getOptions(getUrl('api/reports/personalize'), 'PUT', config)));
        },
    },
    valuesetAdmin: {
        import: (vs: ValueSetFromMultipleVsEndpoint & { deactivateMissing?: boolean }) =>
            refreshJwt(ajax(getOptions(getUrl('api/valueset-loading/import'), 'POST', vs))),
        importMany: (valueSets: (ValueSetFromMultipleVsEndpoint & { deactivateMissing?: boolean })[]) =>
            refreshJwt(ajax(getOptions(getUrl('api/valueset-loading/import?multi=true'), 'POST', valueSets))),
        dryRun: (vs: ValueSetFromMultipleVsEndpoint & { deactivateMissing?: boolean }) =>
            refreshJwt(ajax(getOptions(getUrl('api/valueset-loading/dry-run'), 'POST', vs))).pipe(
                map(
                    (response) =>
                        response.response as {
                            toAdd: string[];
                            toUpdate: string[];
                            leftover: string[];
                        },
                ),
            ),
        dryRunMany: (
            valueSets: (ValueSetFromMultipleVsEndpoint & {
                deactivateMissing?: boolean;
            })[],
        ) =>
            refreshJwt(ajax(getOptions(getUrl('api/valueset-loading/dry-run?multi=true'), 'POST', valueSets))).pipe(
                map(
                    (response) =>
                        response.response as {
                            toAdd: string[];
                            toUpdate: string[];
                            leftover: string[];
                            newValueSets: string[];
                        },
                ),
            ),
        exportMany: (codes: string[]): Observable<ValueSetFromMultipleVsEndpoint[]> =>
            refreshJwt(ajax(getOptions(getUrl('api/valueset-loading/export'), 'POST', JSON.stringify(codes)))).pipe(
                map((r) => r.response),
            ),
        export: (code: string) =>
            getJSON<ValueSetFromMultipleVsEndpoint>(getUrl(`api/valueset-loading/export/${code}`), getHeaders()),
    },
    configuration_setBranch: (branch: string) => {
        return refreshJwt(
            ajax(
                getOptions(getUrl('api/admin/configs/set-branch'), 'POST', {
                    branch,
                }),
            ),
        );
    },
    configuration_getBranches: () => {
        return getJSON<string[]>(getUrl('api/admin/configs/list-branches'), getHeaders());
    },
    configuration_squashBranch: (message: string) => {
        const branch =
            getBranchFromJwt() ??
            JSON.parse((window as any).CASETIVITY_BASIC_INFO).application.environmentName + '-default';

        return refreshJwt(
            ajax(
                getOptions(getUrl('api/admin/configs/squash-branch'), 'POST', {
                    branch, // ?? '_I_am_not_on_a_branch_so_fail_me_', // I don't know what the null behavior is, and don't care to find out
                    message,
                }),
            ),
        );
    },
    runScript: (processInstanceId: string, script: string) =>
        refreshJwt(
            ajax(
                getOptions(getUrl(`api/bpm/process-instances/${processInstanceId}/run-script`), 'POST', {
                    script,
                }),
            ),
        ),
    mfaAuthenticateService,
    mfaGetQRUrl: () => getJSON<{ mfaQrUrl: string }>(getUrl('api/mfa-registration-urlcode'), getHeaders()),
    getJSONObservable: <DataType extends any>(url: string) => getJSON<DataType>(getUrl(url), getHeaders()),
    getPublicGlobalAlerts: () => getJSON<GlobalAlert[]>(getUrl('api/public/global-alerts'), getHeaders()),
    getPrivateGlobalAlerts: () => getJSON<GlobalAlert[]>(getUrl('api/global-alerts'), getHeaders()),
    getViewConfig: () => getJSON<ViewConfig>(getUrl('api/view-config'), getHeaders()),
    getPrintTemplates: (entityConfId: string) =>
        getJSON<PrintTemplate[]>(
            getUrl(`api/print-templates?entityConfId.equals=${entityConfId}&sort=id%2CDESC&size=9999`),
            getHeaders(),
        ),
    getStartForm: (processDefinitionId: string, taskFormKey?: string) =>
        getJSON<TaskForm>(
            getUrl(
                `api/bpm/process-definition/${processDefinitionId}/start-form${taskFormKey ? '/' + taskFormKey : ''}`,
            ),
            getHeaders(),
        ),
    getStartFormByProcessDefinitionName: (pdName: string, taskFormKey?: string) =>
        getJSON<TaskForm>(
            getUrl(`api/bpm/process-definition/${pdName}/start-form${taskFormKey ? '/' + taskFormKey : ''}`),
            getHeaders(),
        ),
    getPrintTemplateByName: (name: string) =>
        getJSON<PrintTemplate[]>(getUrl(`api/print-templates?name.equals=${name}&sort=id%2CDESC&size=2`), getHeaders()),
    authenticate: authenticateService,
    refreshToken: () =>
        refreshJwt(
            ajax({
                url: getUrl('api/authenticate/refresh'),
                method: 'GET',
                headers: {
                    credentials: 'same-origin',
                    Authorization: storageController.getToken() ? `Bearer ${storageController.getToken()}` : undefined,
                },
                // when we get back non-json string, we need responseType: 'text' or IE11 throws.
                responseType: 'text',
                withCredentials: true,
            }),
        ),
    getProcessInstance: (processId: string) =>
        getJSON<ProcessInstanceFromRestProcessInstances>(
            getUrl(`api/bpm/process-instances/${processId}`),
            getHeaders(),
        ),
    saveDashboard: (dash: Partial<Dashboard>) =>
        refreshJwt(ajax(getOptions(getUrl('api/dashboards'), dash.id ? 'PUT' : 'POST', dash))),
    getReportDefinition: (reportName: string) =>
        getJSON<ReportDefinition>(getUrl(`api/reports?name=${reportName}`), getHeaders()),
    createView: (view: View) => refreshJwt(ajax(getOptions(getUrl('api/view-config/update-view'), 'POST', view))),
    updateView: (view: View, previousViewName?: string) => {
        const urlAppend = previousViewName ? '/' + previousViewName : '';
        return refreshJwt(ajax(getOptions(getUrl('api/view-config/update-view' + urlAppend), 'PUT', view)));
    },
    loadAllDashboardConfigs: () => getJSON<DashboardResponse[]>(getUrl('api/user-dashboards'), getHeaders()),
    setCurrentUserPrimaryDashboard: (dashboardId: string) =>
        refreshJwt(ajax(getOptions(getUrl(`api/current-user/dashboard/${dashboardId}`), 'PUT', {}))),
    startProcessInstance: (payload: StartProcessPayload) =>
        refreshJwt(ajax(getOptions(getUrl('api/bpm/process-instances'), 'POST', payload))),
    getCurrentUserPrimaryDashboard: () =>
        getJSON<UserPrimaryDashboardResponse>(getUrl('api/current-user/dashboard'), getHeaders()),
    getProcessDefinitions: (roles?: readonly string[]) =>
        getJSON<ProcessDefinitionResponse>(
            getUrl(
                'api/bpm/process-definitions?latest=true' +
                    (roles && roles.length > 0 ? '&roles=' + [...roles].sort().join(',') : ''),
            ),
            getHeaders(),
        ),
    getCacheInfo: () => getJSON<ProcessDefinitionResponse>(getUrl('api/cache-info'), getHeaders()),
    getAllPotentialUsers: () => getJSON<PotentialUser[]>(getUrl('api/bpm/potential-users?isActive=true'), getHeaders()),
    getTaskPotentialUsers: (taskId: string) =>
        getJSON<TaskPotentialUsersResponse>(getUrl(`api/bpm/tasks/${taskId}/potential-users`), getHeaders()),
    createTask: (data: CreateTaskPayload) => refreshJwt(ajax(getOptions(getUrl('api/bpm/tasks'), 'POST', data))),
    impersonateUser: (userId: string) => ajax(getOptions(getUrl(`api/impersonate/${userId}`), 'POST')),
    getTaskForm: (taskId: string) => getJSON<TaskForm>(getUrl(`api/bpm/task-forms/${taskId}`), getHeaders()),
    crudCreate: (params: { data: {}; restUrl: string }) => {
        const { data, restUrl } = params;
        return refreshJwt(ajax(getOptions(getUrl(restUrl), 'POST', data)));
    },
    crudUpdate: (params: { data: {}; restUrl: string }) => {
        const { data, restUrl } = params;
        return refreshJwt(ajax(getOptions(getUrl(restUrl), 'PUT', data)));
    },
    crudGet: (params: { restUrl: string; monitorRequest?: boolean }) => {
        const { restUrl, monitorRequest } = params;
        return refreshJwt(ajax(monitorOptions(monitorRequest)(getOptions(getUrl(restUrl), 'GET'))));
    },
    crudDelete: (params: { restUrl: string }) => {
        const { restUrl } = params;
        return refreshJwt(ajax(getOptions(getUrl(restUrl), 'DELETE')));
    },
    getJSON,
} as const;
export type Services = typeof services;

export interface GenericCrudArgs<D> {
    data?: D;
    monitorRequest?: boolean;
    restUrl: string;
}
// must be compatible with AjaxResponse
export interface GenericAjaxResponse {
    status: number;
    response: any;
    responseText: string;
    xhr: {
        getResponseHeader(key: string): string;
    };
}

export type GenericCrudService<D> = (params: GenericCrudArgs<D>) => Observable<GenericAjaxResponse>;
export const crudServices: {
    [key: string]: GenericCrudService<unknown>;
} = {
    crudCreate: services.crudCreate,
};
