import { useMemo } from 'react';
import getTabToPrefetch from '../getTabToPrefetch';
import { useAppStore } from 'reducers/rootReducer';

const useTabToPrefetch = ({ id, resource, viewName }: { id: string; resource: string; viewName: string }) => {
    const store = useAppStore();
    const tabToPrefetch = useMemo(() => {
        return getTabToPrefetch(store.getState(), { id, resource, viewName });
    }, [store, id, resource, viewName]);
    return tabToPrefetch;
};
export default useTabToPrefetch;
