import { useEffect, useState, useCallback } from 'react';
import { services } from 'sideEffect/services';
import { AjaxError } from 'rxjs/ajax';
import { ReportDefinition } from 'report2/ReportDefinition';
import produce from 'immer';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ReportDefinitionStateItem } from './ReportDefinitionTypes';
import { failureReportDefinition, pendingReportDefinition, successReportDefinition } from './reportDefinitionSlice';

/**
 * Remove any asterisks from the ends of names of fields
 */
const cleanReportDefinition = (reportDefinition: ReportDefinition) => {
    return produce(reportDefinition, (draft) => {
        draft.params.forEach((p) => {
            if (p.name.endsWith('*')) {
                p.name = p.name.slice(0, -1);
            }
        });
    });
};

const useReportDefinition = (reportName: string) => {
    const debugMode = useSelector((state: RootState) => state.debugMode);
    const dispatch = useDispatch();
    const reportDefinition: ReportDefinitionStateItem = useAppSelector(
        (state: RootState) => state.reportDefinition[reportName],
    ) ?? { type: 'INITIAL' };
    const [refreshKey, setRefreshKey] = useState(1);
    const refresh = useCallback(() => {
        setRefreshKey(refreshKey + 1);
    }, [refreshKey, setRefreshKey]);

    const fetchReport = useCallback(
        (_reportName) => {
            if (debugMode) {
                return;
                //  Prevents component from making any requests while in debug mode.
                //  This is here in order to avoid side effects while testing.
            }
            const $ajax = services.getReportDefinition(_reportName);
            dispatch(pendingReportDefinition({ reportName }));
            const subscription = $ajax.subscribe(
                (res) => {
                    const cleanedDefinition = cleanReportDefinition(res);
                    dispatch(successReportDefinition({ reportName, data: cleanedDefinition }));
                },
                (error: AjaxError) => {
                    console.error(error);
                    dispatch(failureReportDefinition({ reportName, error }));
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        },
        [debugMode, dispatch, reportName],
    );
    useEffect(() => {
        const cleanup = fetchReport(reportName);
        return cleanup;
    }, [refreshKey, reportName, fetchReport]);
    return [reportDefinition, refresh] as const;
};
export default useReportDefinition;
