import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { useForm, Controller, FormProvider, Resolver } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles, Theme, createStyles, Button, TextField } from '@material-ui/core';
import { DebouncedField } from 'fieldFactory/input/components/DebouncedTextInput';
import { CompileExpression } from 'expressions/Provider/implementations/CompileExpression';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import uniq from 'lodash/uniq';
import { contextFunctions, taskContextPrimitives } from 'expressions/Provider/getContexts';
import { TaskForm } from '../../reducers/taskFormType';
import { UpdateMeta } from 'expression-tester/entity-form/ViewDefinitionConfig/EditExpression';
import useExpressionEval from 'expressions/Provider/hooks/useExpressionEval';
import { TableFormField } from 'fieldFactory/translation/fromFlowable/types';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
    }),
);
export interface ExpressionData {
    expression: string;
    message?: string;
    fieldsRequired: string[];
    compileSuccess: boolean;
    methodsAndFunctions: string[];
}

interface EditExpressionProps {
    initialExpression: string;
    initialMessage?: string;
    taskForm?: Partial<TaskForm>;
    includeMessage?: boolean;
    onSubmit: (data: ExpressionData) => void;
    renderActions?: (props: { SaveButton: JSX.Element }) => JSX.Element;
    optional?: boolean;
}

const isValidPathInTable = (tableField: TableFormField, fieldPath: string) => {
    const subpaths = fieldPath.split('_c_');
    const tablesInPath = subpaths.slice(0, -1);
    const field = subpaths[subpaths.length - 1];
    if (tablesInPath.length === 1) {
        return tablesInPath[0] === tableField.id && tableField.params.columnObj?.some((f) => f.id === field);
    }
    return tableField.params.columnObj?.some(
        (f) => f.type === 'table' && isValidPathInTable(f, tablesInPath.slice(1).concat([field]).join('_c_')),
    );
};

export const useValidationResolver = (
    taskForm: Partial<TaskForm>,
    optional: boolean = false,
): Resolver<ExpressionData, object> => {
    return useCallback(
        async (data) => {
            let errors = {};
            if (data.compileSuccess) {
                const invalidFunctions = uniq(
                    (data.methodsAndFunctions || []).flatMap((_fnnm, i) => {
                        if (contextFunctions[_fnnm]) {
                            return [];
                        }
                        return [_fnnm];
                    }),
                );
                if (invalidFunctions.length > 0) {
                    errors['methodsAndFunctions'] = `Functions not found: ${invalidFunctions.join(', ')}`;
                }
                const invalidPaths = uniq(
                    (data.fieldsRequired || []).flatMap((_fieldPath, i) => {
                        if (
                            /* not a valid path in the form data */
                            taskForm?.fields?.every((f) => {
                                if (f.type === 'table') {
                                    return _fieldPath !== f.id && !isValidPathInTable(f, _fieldPath);
                                }
                                if (f.type === 'dropdown' || f.type === 'radio-buttons') {
                                    if (_fieldPath.endsWith('.name')) {
                                        return f.id !== _fieldPath.slice(0, -'.name'.length);
                                    }
                                }
                                return f.id !== _fieldPath;
                            })
                        ) {
                            if (taskContextPrimitives[_fieldPath]) {
                                // it's provided in injected context.
                                return [];
                            }
                            return [_fieldPath];
                        }

                        return [];
                    }),
                );

                if (invalidPaths.length > 0) {
                    errors['fieldsRequired'] = `Paths not found: ${invalidPaths.join(', ')}`;
                }
            }
            if (!data.compileSuccess) {
                if ((!data.expression && optional) || data.expression?.includes('${')) {
                    return {
                        errors: errors,
                        values: data,
                    };
                }
                errors['fieldsRequired'] = 'Failed to compile expression.';
            }
            return {
                errors: errors,
                values: data,
            };
        },
        [taskForm, optional],
    );
};

const SpelExpressionForm: FunctionComponent<EditExpressionProps> = (props) => {
    const classes = useStyles(props);
    const { onSubmit, taskForm, includeMessage, initialMessage } = props;

    const resolver = useValidationResolver(taskForm, props.optional);
    const { compileExpression } = useExpressionEval();
    const initialCompiled: ReturnType<CompileExpression> = useMemo(
        () => compileExpression(props.initialExpression),
        [props.initialExpression, compileExpression],
    );
    const methods = useForm<ExpressionData>({
        resolver,
        defaultValues: {
            compileSuccess: initialCompiled.type === 'parse_success',
            expression: props.initialExpression,
            fieldsRequired: initialCompiled.type === 'parse_success' ? initialCompiled.getExpansions() : [],
            methodsAndFunctions: initialCompiled.type === 'parse_success' ? initialCompiled.methodsAndFunctions : [],
        },
        mode: 'onChange',
    });
    const { errors } = methods;
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const SaveButton = (
        <Button
            color="primary"
            variant="contained"
            // Allow submitting even when there are errors for now, since the validation resolver isn't all knowing, and prevents us from saving
            // when using things like #f().list.reducer

            /* disabled={Object.keys(errors).length > 0} */
            type="submit"
        >
            Save
        </Button>
    );
    return (
        <FormProvider {...methods}>
            <form
                // uncomment the below to prevent submission when there are validation errors.
                // Bypassing because validations on unknown paths is causing issues.
                // onSubmit={methods.handleSubmit(onSubmit)}
                onSubmit={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onSubmit(methods.getValues());
                    return false;
                }}
            >
                <Controller
                    rules={{ required: 'Provide an expression' }}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    variant={fieldVariant}
                    label="Expression *"
                    as={DebouncedField}
                    margin="normal"
                    error={
                        (Array.isArray(errors.expression)
                            ? errors.expression.length > 0
                            : Boolean(errors.expression)) ||
                        (Array.isArray(errors.fieldsRequired)
                            ? errors.fieldsRequired.length > 0
                            : Boolean(errors.fieldsRequired)) ||
                        (Array.isArray(errors.methodsAndFunctions)
                            ? errors.methodsAndFunctions.length > 0
                            : Boolean(errors.methodsAndFunctions))
                    }
                    helperText={<ErrorMessage errors={errors} name="expression" />}
                    defaultValue={props.initialExpression}
                    fullWidth
                    name="expression"
                    control={methods.control as any}
                />
                <pre className={classes.error}>{errors['methodsAndFunctions']}</pre>
                <pre className={classes.error}>{errors['fieldsRequired']}</pre>
                {includeMessage && (
                    <Controller
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        label="Message"
                        name="message"
                        fullWidth
                        control={methods.control}
                        defaultValue={initialMessage ?? ''}
                        as={TextField}
                        rules={{ required: 'A message is Required' }}
                        error={Boolean(errors.message)}
                        helperText={errors.message?.message}
                    />
                )}
                {props.renderActions?.({ SaveButton }) ?? SaveButton}
            </form>
            <UpdateMeta />
        </FormProvider>
    );
};
export default SpelExpressionForm;
