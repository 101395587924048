import { storeRegistry } from 'configureStore/storeRegistry';
import { RootState } from 'reducers/rootReducer';
import { Store, Unsubscribe } from 'redux';
import { extractMediaPrintAsNormalStylesForPagedJS } from './mediaQueryManagement/mediaQueryCss';

export type HandlePrintStatusChange = (state: 'pending' | 'complete' | 'error') => void;

/**
 * For some reason, the header on the first page doesn't always show up.
 * As a workaround, we can insert it ourselves.
 *
 * Make sure to check this still works (or, hopefully, the original issue was fixed) when we upgrade pagedjs- the css class names might change beneath us.
 */

const ensureInsertMargin = (options: {
    pages: 'all' | 'first';
    className:
        | 'print-footer-center'
        | 'print-footer-left'
        | 'print-footer-right'
        | 'print-header-center'
        | 'print-header-left'
        | 'print-header-right';
}) => {
    const { className, pages } = options;
    const content = document.getElementsByClassName(className)?.[0]?.textContent;
    if (!content) {
        return;
    }
    const style = document.createElement('style');
    style.type = 'text/css';

    const loc = (() => {
        switch (className) {
            case 'print-header-center':
                return 'top-center';
            case 'print-header-left':
                return 'top-left';
            case 'print-header-right':
                return 'top-right';
            case 'print-footer-center':
                return 'bottom-center';
            case 'print-footer-left':
                return 'bottom-left';
            case 'print-footer-right':
                return 'bottom-right';
        }
    })();
    const maybeFirstPage = pages === 'all' ? '' : '.pagedjs_first_page';
    const cssRule =
        `.pagedjs_page ${maybeFirstPage} .pagedjs_margin-${loc} > .pagedjs_margin-content::after { content: "` +
        content +
        '"; }';

    if ('styleSheet' in style) {
        (style as any).styleSheet.cssText = cssRule;
    } else {
        style.appendChild(document.createTextNode(cssRule));
    }

    document.head.appendChild(style);
};

/**
 * These break-inside: avoid styles are breaking paged-js.
 *
 * Let's _keep the styles, in case the user just calls print without using pagedjs_
 *
 * But remove them once we are committing to pagedjs.
 */

function hasClass(element: Element, className: string): boolean {
    return element.classList.contains(className);
}

function removeBreakInsideAvoidStyle(): void {
    // Get all elements in the document
    const allElements: NodeListOf<Element> = document.querySelectorAll('*');

    allElements.forEach((element: Element) => {
        const htmlElement = element as HTMLElement;
        if (htmlElement.style.breakInside === 'avoid' && !hasClass(htmlElement, 'allowBreakInsidePagedJS')) {
            htmlElement.style.breakInside = ''; // Remove the style
        }
    });

    // Check and modify CSS rules in stylesheets (for external and internal stylesheets)
    Array.from(document.styleSheets).forEach((sheet: CSSStyleSheet) => {
        try {
            const cssRulesList: CSSRuleList = sheet.cssRules;
            Array.from(cssRulesList).forEach((rule: CSSRule) => {
                const styleRule = rule as CSSStyleRule;
                if (styleRule.style && styleRule.style.breakInside === 'avoid') {
                    styleRule.style.breakInside = ''; // Remove the style
                }
            });
        } catch (e) {
            // Catch and ignore cross-origin issues
            console.warn('Could not modify styles from a cross-origin stylesheet');
        }
    });
}

function removeElementsById(ids: string[]): void {
    ids.forEach((id) => {
        const element = document.getElementById(id);
        if (element && element.parentNode) {
            element.parentNode.removeChild(element);
        }
    });
}

function removeAllCaptions() {
    // Get all caption elements in the document
    const captions = document.getElementsByTagName('caption');

    // Convert HTMLCollection to an array to avoid live collection issues
    const captionsArray = Array.from(captions);

    // Loop through the captions array and remove each from its parent node
    captionsArray.forEach((caption) => {
        caption.parentNode.removeChild(caption);
    });
}

const insertRefreshNotificationToDom = () => {
    const alertDiv = document.createElement('div');
    alertDiv.style.fontSize = '14px';
    alertDiv.innerHTML = 'To exit "print mode", please reload the page.';

    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '10px';
    alertDiv.style.right = '10px';
    alertDiv.style.backgroundColor = 'rgb(229, 246, 253)';
    alertDiv.style.color = 'rgb(1, 67, 97)';
    alertDiv.style.border = '1px solid rgb(1, 67, 97)';
    alertDiv.style.padding = '10px';
    alertDiv.style.borderRadius = '5px';
    alertDiv.style.boxShadow = '0 2px 4px rgba(0,0,0,0.2)';
    alertDiv.style.zIndex = '1000';

    // Add a "Reload" button
    const reloadButton = document.createElement('button');
    reloadButton.textContent = 'Reload';
    reloadButton.style.marginLeft = '10px';
    reloadButton.style.color = 'rgb(1, 67, 97)';
    reloadButton.style.border = 'none';
    reloadButton.style.backgroundColor = 'transparent';
    reloadButton.style.cursor = 'pointer';
    reloadButton.style.fontWeight = '500';

    // Append the reload button to the alert div
    alertDiv.appendChild(reloadButton);

    // Event listener to reload the page when the reload button is clicked
    reloadButton.onclick = function () {
        window.location.reload();
    };

    // Append the alert div to the body
    document.body.appendChild(alertDiv);

    // Add CSS to hide the alert during printing
    const styleSheet = document.createElement('style');
    styleSheet.textContent = `
    @media print {
        #alertDiv {
            display: none;
        }
    }
`;
    document.head.appendChild(styleSheet);

    // Set an ID for the alert div for the media query to work
    alertDiv.id = 'alertDiv';
};

function roundFractionalCSSValues() {
    // Iterate over all elements on the page
    document.querySelectorAll('*').forEach((element) => {
        if (element.id === 'maincontent') {
            element['style']['margin-left'] = '0px';
            element['style']['margin-right'] = '0px';

            // In idss.apps and some other apps, we have custom css in the EnvConfig which sets margins left and right to 'auto' on #maincontent.
            // if we continue down here, that margin will be fixed, and embedded into the doc, which we don't want.
            // instead, set those margins to 0, and short circuit.
            return;
        }
        const style = window.getComputedStyle(element);

        const properties = [
            'margin-top',
            'margin-bottom',
            'margin-left',
            'margin-right',
            'padding-top',
            'padding-bottom',
            'padding-left',
            'padding-right',
            'border-top-width',
            'border-bottom-width',
            'border-left-width',
            'border-right-width',
            'font-size',
            'line-height',
            'letter-spacing',
        ];

        properties.forEach((prop) => {
            let value = style.getPropertyValue(prop);

            if (value.endsWith('px')) {
                value = value.slice(0, -2);
            }
            // For properties like lineHeight, which can be "normal", skip non-numeric values
            if (!isNaN(parseFloat(value)) && value.trim() !== '') {
                const numericValue = parseFloat(value);
                // Check if the value is fractional
                if (numericValue > 0 && !Number.isInteger(numericValue)) {
                    // Round up (or down) the value and set it as an inline style

                    if (prop === 'line-height') {
                        element['style'][prop] = 'normal';
                    } else if (prop === 'letter-spacing') {
                        element['style'][prop] = `${Math.floor(numericValue)}px`;
                    } else {
                        element['style'][prop] = `${Math.ceil(numericValue)}px`;
                    }
                }
            }
        });
    });
}

class PrintController {
    private subscriptions: HandlePrintStatusChange[] = [];
    private store: Store<RootState>;
    private to: NodeJS.Timeout;
    private storeUnSub: Unsubscribe;
    public constructor(store: Store<RootState>) {
        this.store = store;
        this.subscribeToPrint = this.subscribeToPrint.bind(this);
        this.unsubscribeToPrint = this.unsubscribeToPrint.bind(this);
        this.startPrint = this.startPrint.bind(this);
    }

    public startPrint() {
        this.subscriptions.forEach((cb) => cb('pending'));

        const maxTries = 180;
        let tries = 0;
        // number of times we've iterated, and seen loading=0.
        // We track this so we stop only if loading=0 N consecutive times, with some time delta apart.
        // That way we don't abort early by some unlikely timing where it stops briefly and restarts
        let consecutiveNotLoadingCount = 0;
        const STOP_ON_CONSECUTIVE_COUNTS = 3;
        this.to = setInterval(() => {
            tries += 1;
            if (tries >= maxTries) {
                clearInterval(this.to);
                this.storeUnSub?.();
                this.subscriptions.forEach((cb) => cb('error'));
            }
            if (!this.store.getState().admin.loading) {
                consecutiveNotLoadingCount += 1;
                if (consecutiveNotLoadingCount < STOP_ON_CONSECUTIVE_COUNTS) {
                    // continue
                    return;
                }
                // finally.
                clearInterval(this.to);
                this.storeUnSub?.();
                this.subscriptions.forEach((cb) => cb('complete'));
                setTimeout(() => {
                    if (window['CANCEL_PRINT']) {
                        return;
                    }
                    extractMediaPrintAsNormalStylesForPagedJS();
                    // Uncomment below if breakInside becomes a problem again.a
                    // removeBreakInsideAvoidStyle();
                    removeElementsById(['a11y-status-message', '_rwt_injected_sheet', 'notistack-forscreenreader']);
                    removeAllCaptions();
                    roundFractionalCSSValues();
                    // dynamically import pagedjs, since it's quite large.

                    import('pagedjs')
                        .then((pagedjs) => {
                            // from https://gist.github.com/theinvensi/e1aacc43bb5a3d852e2e85b08cf85c8a
                            class RepeatTableHeadersHandler extends pagedjs.Handler {
                                splitTablesRefs: any[];
                                chunker: any;
                                constructor(chunker, polisher, caller) {
                                    super(chunker, polisher, caller);
                                    this.splitTablesRefs = [];
                                }

                                afterPageLayout(pageElement, page, breakToken, chunker) {
                                    this.chunker = chunker;
                                    this.splitTablesRefs = [];

                                    if (breakToken) {
                                        const node = breakToken.node;
                                        const tables = this.findAllAncestors(node, 'table');
                                        if (node.tagName === 'TABLE') tables.push(node);

                                        if (tables.length > 0) {
                                            this.splitTablesRefs = tables.map((t) => t.dataset.ref);

                                            let thead =
                                                node.tagName === 'THEAD' ? node : this.findFirstAncestor(node, 'thead');
                                            if (thead) {
                                                let lastTheadNode = thead.hasChildNodes() ? thead.lastChild : thead;
                                                breakToken.node = this.nodeAfter(lastTheadNode, chunker.source);
                                            }

                                            this.hideEmptyTables(pageElement, node);
                                        }
                                    }
                                }

                                hideEmptyTables(pageElement, breakTokenNode) {
                                    this.splitTablesRefs.forEach((ref) => {
                                        let table = pageElement.querySelector("[data-ref='" + ref + "']");
                                        if (table) {
                                            let sourceBody = table.querySelector('tbody > tr');
                                            if (
                                                !sourceBody ||
                                                this.refEquals(sourceBody.firstElementChild, breakTokenNode)
                                            ) {
                                                table.style.visibility = 'hidden';
                                                table.style.position = 'absolute';
                                                let lineSpacer = table.nextSibling;
                                                if (lineSpacer) {
                                                    lineSpacer.style.visibility = 'hidden';
                                                    lineSpacer.style.position = 'absolute';
                                                }
                                            }
                                        }
                                    });
                                }

                                refEquals(a, b) {
                                    return a && a.dataset && b && b.dataset && a.dataset.ref === b.dataset.ref;
                                }

                                findFirstAncestor(element, selector) {
                                    while (element.parentNode && element.parentNode.nodeType === 1) {
                                        if (element.parentNode.matches(selector)) return element.parentNode;
                                        element = element.parentNode;
                                    }
                                    return null;
                                }

                                findAllAncestors(element, selector) {
                                    const ancestors = [];
                                    while (element.parentNode && element.parentNode.nodeType === 1) {
                                        if (element.parentNode.matches(selector)) ancestors.unshift(element.parentNode);
                                        element = element.parentNode;
                                    }
                                    return ancestors;
                                }

                                layout(rendered, layout) {
                                    this.splitTablesRefs.forEach((ref) => {
                                        const renderedTable = rendered.querySelector("[data-ref='" + ref + "']");
                                        if (renderedTable) {
                                            if (!renderedTable.getAttribute('repeated-headers')) {
                                                const sourceTable = this.chunker.source.querySelector(
                                                    "[data-ref='" + ref + "']",
                                                );
                                                this.repeatColgroup(sourceTable, renderedTable);
                                                this.repeatTHead(sourceTable, renderedTable);
                                                renderedTable.setAttribute('repeated-headers', true);
                                            }
                                        }
                                    });
                                }

                                repeatColgroup(sourceTable, renderedTable) {
                                    let colgroup = sourceTable.querySelectorAll('colgroup');
                                    let firstChild = renderedTable.firstChild;
                                    colgroup.forEach((colgroup) => {
                                        let clonedColgroup = colgroup.cloneNode(true);
                                        renderedTable.insertBefore(clonedColgroup, firstChild);
                                    });
                                }

                                repeatTHead(sourceTable, renderedTable) {
                                    let thead = sourceTable.querySelector('thead');
                                    if (thead) {
                                        let clonedThead = thead.cloneNode(true);
                                        renderedTable.insertBefore(clonedThead, renderedTable.firstChild);
                                    }
                                }

                                nodeAfter(node, limiter) {
                                    if (limiter && node === limiter) return;
                                    let significantNode = this.nextSignificantNode(node);
                                    if (significantNode) return significantNode;
                                    if (node.parentNode) {
                                        while ((node = node.parentNode)) {
                                            if (limiter && node === limiter) return;
                                            significantNode = this.nextSignificantNode(node);
                                            if (significantNode) return significantNode;
                                        }
                                    }
                                }

                                nextSignificantNode(sib) {
                                    while ((sib = sib.nextSibling)) {
                                        if (!this.isIgnorable(sib)) return sib;
                                    }
                                    return null;
                                }

                                isIgnorable(node) {
                                    return node.nodeType === 8 || (node.nodeType === 3 && this.isAllWhitespace(node));
                                }

                                isAllWhitespace(node) {
                                    return !/[^\t\n\r ]/.test(node.textContent);
                                }
                            }

                            pagedjs.registerHandlers(RepeatTableHeadersHandler);
                            // if we weren't asked to have custom stuff involving page numbers, we would just print here.
                            // window.print();
                            // instead, we need to use pagedjs as an irreversible step below (we can't go back and recover our react tree)
                            let paged = new pagedjs.Previewer();
                            paged.preview().then((flow) => {
                                ensureInsertMargin({
                                    pages: 'all',
                                    className: 'print-header-center',
                                });
                                ensureInsertMargin({
                                    pages: 'all',
                                    className: 'print-header-left',
                                });
                                ensureInsertMargin({
                                    pages: 'all',
                                    className: 'print-header-right',
                                });
                                ensureInsertMargin({
                                    pages: 'all',
                                    className: 'print-footer-center',
                                });
                                ensureInsertMargin({
                                    pages: 'all',
                                    className: 'print-footer-left',
                                });

                                // now set first page stuff
                                ensureInsertMargin({
                                    pages: 'first',
                                    className: 'print-footer-center',
                                });
                                ensureInsertMargin({
                                    pages: 'first',
                                    className: 'print-footer-left',
                                });
                                ensureInsertMargin({
                                    pages: 'first',
                                    className: 'print-footer-right',
                                });
                                ensureInsertMargin({
                                    pages: 'first',
                                    className: 'print-header-center',
                                });

                                insertRefreshNotificationToDom();
                                console.log('Rendered', flow.total, 'pages.');
                                setImmediate(() => {
                                    window.print();
                                });
                            });
                        })
                        .catch((e) => {
                            alert('Printing failed. You may need to reconnect to the network.');
                        });
                }, window['PRINT_WAIT'] ?? 200);
            } else {
                // reset and check again
                consecutiveNotLoadingCount = 0;
            }
        }, 600);
        const getCurrPath = () => this.store.getState().router.location.pathname;
        const initialPath = getCurrPath();
        this.storeUnSub = this.store.subscribe(() => {
            const currPath = getCurrPath();
            if (initialPath !== currPath) {
                clearInterval(this.to);
            }
        });
    }

    public subscribeToPrint(cb: HandlePrintStatusChange) {
        this.subscriptions.push(cb);
    }
    public unsubscribeToPrint(cb: HandlePrintStatusChange) {
        this.subscriptions = this.subscriptions.filter((_cb) => _cb !== cb);
    }
}

let printController: PrintController;
export const getPrintController = () => {
    if (!printController) {
        printController = new PrintController(storeRegistry['store']);
    }
    return printController;
};
