import { createSelector } from 'reselect';
import { RootState } from '../../../../../reducers/rootReducer';
import createRecordWithManyValuePaths from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData/createRecordWithManyPaths';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { createGetEntities } from './getEntities';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { TemplateExpressionsGenerated } from 'viewConfigCalculations/expressionTemplates/TemplateExpressionsGenerated';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/EntityFieldConceptExps';

interface RecordSelectorProps {
    record: { id?: string; entityType?: string };
    viewName: string;
    overrideFieldsInExpressions?: string[];
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
    };
}
const emptyObj = {};

export const visibilityExpressionsSelector = <
    Props extends {
        viewName: string;
        overrides?: {
            visibilityExps?: EntityVisibilityExps[0];
        };
    },
>(
    state: RootState,
    props: Props,
): EntityVisibilityExps[0] => props.overrides?.visibilityExps ?? state.entityVisibility[props.viewName] ?? emptyObj;

export const templateExpressionsSelector = <
    Props extends {
        viewName: string;
        overrides?: {
            templateExps?: TemplateExpressionsGenerated[0];
        };
    },
>(
    state: RootState,
    props: Props,
): TemplateExpressionsGenerated[0] => props.overrides?.templateExps ?? state.templateExps[props.viewName] ?? emptyObj;

export const conceptExpressionsSelector = <
    Props extends {
        viewName: string;
        overrides?: {
            conceptExps?: EntityFieldConceptExps[0];
        };
    },
>(
    state: RootState,
    props: Props,
): EntityFieldConceptExps[0] => props.overrides?.conceptExps ?? state.entityConceptExps[props.viewName] ?? emptyObj;

const createRecordSelector = () => {
    const getEntities = createGetEntities();
    return createSelector(
        visibilityExpressionsSelector,
        conceptExpressionsSelector,
        templateExpressionsSelector,
        (state: RootState, props: RecordSelectorProps) => props.overrideFieldsInExpressions,
        getEntities,
        (state: RootState, props: RecordSelectorProps) => state.viewConfig,
        (state: RootState, props: RecordSelectorProps) => (props.record ? props.record.id : null),
        (state: RootState, props: RecordSelectorProps) => (props.record ? props.record.entityType : null),
        (
            visConfig,
            conceptExpsConfig,
            templateConfig,
            overrideFieldsInExpressions,
            entities,
            viewConfig,
            id,
            entityType,
        ) => {
            if (!id || !entityType) {
                return emptyObj;
            }
            // this could be optimized.
            const getFieldsRequired = <
                ConfEntry extends {
                    valuesetFieldsRequired: {
                        [field: string]: string;
                    };
                    expansionsRequired: string[];
                },
            >(
                confEntry: ConfEntry,
            ) => {
                return [
                    // below line: if traverseGetData handled e.g. field._ALL_._ALL and would do the recursive expand, we wouldn't have to truncate
                    ...(confEntry.expansionsRequired.map((exp) => exp.split('._ALL_')[0]).filter(Boolean) || []),
                    ...Object.keys(confEntry.valuesetFieldsRequired || {}).map((f) => `${f}Id`),
                ];
            };
            const allFieldsRequired: string[] = overrideFieldsInExpressions || [
                ...Object.values(visConfig).flatMap((c) => (c as any).flatMap((co) => getFieldsRequired(co))),
                ...Object.values(conceptExpsConfig).flatMap((c) => getFieldsRequired(c)),
                ...Object.values(templateConfig).flatMap((c) => getFieldsRequired(c)),
            ];
            const pathsAndValues = Object.assign(
                {
                    id,
                    entityType,
                },
                ...allFieldsRequired.map((fr) => ({
                    [fr]: traverseGetData(viewConfig, fr, { id, entityType }, entities).fold(null, (a) => a),
                })),
            );
            return createRecordWithManyValuePaths(pathsAndValues);
        },
    );
};
export default createRecordSelector;
