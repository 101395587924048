import React, { useContext, useMemo } from 'react';
import { EntityFormContext, formContext } from '../UnsplitFormContextProvider';

export const columnFormContextsContext = React.createContext<{
    [columnId: string]: EntityFormContext;
}>({});

export const useAddFormContextToDict = (columnId: string) => {
    const existingColumnFormContextsContext = useContext(columnFormContextsContext);
    const fc = useContext(formContext);
    // TODO: can also keep the form context object ref not changing, and mutate it, if it's an issue
    const newFormContext = useMemo(
        () => ({
            ...existingColumnFormContextsContext,
            [columnId]: fc,
        }),
        [columnId, fc, existingColumnFormContextsContext],
    );
    return newFormContext;
};

export const ApplyFormContextWithId: React.FC<{ columnId: string }> = ({ columnId, children }) => {
    const existingColumnFormContextsContext = useContext(columnFormContextsContext);
    return <formContext.Provider value={existingColumnFormContextsContext[columnId]}>{children}</formContext.Provider>;
};
