import React from 'react';
import { Pagination as MuiRestListPagination } from 'mui-rest-list';
import { PaginationProps } from 'mui-rest-list/lib/src/components/Pagination';
import { RootState, useAppSelector } from 'reducers/rootReducer';

const Pagination: React.FC<PaginationProps> = (props) => {
    const maxExactTotalCount = useAppSelector((state: RootState) => state.basicInfo?.maxExactTotalCount);
    const printMode = useAppSelector((state: RootState) => state.printMode);
    if (printMode) {
        return null;
    }
    return <MuiRestListPagination {...props} maxExactTotalCount={maxExactTotalCount} />;
};

export default Pagination;
