import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import { getFieldSourceFromPath, getValueSetForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import useIsTaskFormContext from 'expressions/hooks/allForms/useIsTaskFormContext';
import { useContext, useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';

const useGetMaybeValuesetFromField = () => {
    const isTaskFormContext = useIsTaskFormContext();
    const flowableFormDefinitionContext = useContext(stagedFormDefinitionContext);
    const viewConfig = useViewConfig();
    const maybeViewName = useCurrentFormContext().viewName;
    const maybeRootEntity = maybeViewName && viewConfig.views[maybeViewName]?.entity;
    return useMemo(() => {
        if (isTaskFormContext) {
            return (field: string) => {
                if (!field) {
                    return null;
                }
                return flowableFormDefinitionContext.formDefinition.fields.flatMap((f) => {
                    switch (f.type) {
                        case 'value-set-dropdown':
                        case 'value-set-radiobox': {
                            const id = f.id;
                            if (!id) {
                                return [];
                            }
                            const adjustedFieldId = (() => {
                                if (id.endsWith('Id')) {
                                    return id.slice(0, 'Id'.length * -1);
                                }
                                return id;
                            })();
                            const matches =
                                adjustedFieldId === field ||
                                (field.endsWith('Id')
                                    ? adjustedFieldId === field.slice(0, 'Id'.length * -1)
                                    : field.endsWith('Code')
                                    ? adjustedFieldId === field.slice(0, 'Code'.length * -1)
                                    : false);
                            if (matches) {
                                return [f.params.valueSet];
                            }
                            return [];
                        }
                        default:
                            return [];
                    }
                })[0];
            };
        }
        if (maybeRootEntity) {
            return (field: string) => {
                if (!field) {
                    return undefined;
                }
                const source = getFieldSourceFromPath(viewConfig, maybeRootEntity, field);
                try {
                    return getValueSetForFieldExpr(viewConfig, maybeRootEntity, source, 'TRAVERSE_PATH');
                } catch (e) {
                    return undefined;
                }
            };
        }
        return () => {
            throw Error('Expected to be in either a task or entity context.');
        };
    }, [flowableFormDefinitionContext, maybeRootEntity, viewConfig, isTaskFormContext]);
};

export default useGetMaybeValuesetFromField;
