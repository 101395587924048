import React, { useContext, useMemo } from 'react';
import { ReduxFormContext, InjectedFormProps } from 'redux-form';

export const columnReduxFormContextsContext = React.createContext<{
    [columnId: string]: InjectedFormProps;
}>({});

//
export const useAddReduxFormContextToDict = (columnId: string) => {
    const existingColumnReduxFormContextsContext = useContext(columnReduxFormContextsContext);
    const current = useContext(ReduxFormContext);
    const newContext = useMemo(
        () => ({
            ...existingColumnReduxFormContextsContext,
            [columnId]: current,
        }),
        [columnId, current, existingColumnReduxFormContextsContext],
    );
    return newContext;
};
export const ApplyReduxFormContextWithId: React.FC<{ columnId: string }> = ({ columnId, children }) => {
    const existingColumnReduxFormContextsContext = useContext(columnReduxFormContextsContext);
    return (
        <ReduxFormContext.Provider value={existingColumnReduxFormContextsContext[columnId]}>
            {children}
        </ReduxFormContext.Provider>
    );
};
