import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import { EntityMultipleTypeaheadField } from 'fieldFactory/translation/fromFlowable/types';
import uniqWith from 'lodash/uniqWith';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import deepEql from 'deep-eql';
import getExpansionsFromFilter from 'isomorphic-query-filters/expand';
import getImpl from 'expressions/Provider/implementations/getImpl';
import useEvalContext from 'expressions/Provider/hooks/useEvalContext';
import { isTableField } from 'bpm/components/TaskDetail/TaskForm/ErrorsList/ErrorsList';

const isHardFalse = (expression?: string | null) => {
    if (!expression) {
        return false;
    }
    const whitespaceRemoved = expression.split(' ').join('');
    return ['false', '["false"]', "['false']"].includes(whitespaceRemoved);
};

const useFetchTaskFormOfflineData = (taskId: string) => {
    const taskForm = useSelector((state: RootState) => state.taskForms[taskId]);
    const baseContext = useEvalContext();
    const dispatch = useDispatch();

    const allFields = taskForm.fields.flatMap((f) => (isTableField(f) ? f.params.columnObj ?? [] : [f]));
    const fetchData = useCallback(() => {
        const multiselectEntityFields = allFields.filter(
            (f) =>
                f.type === 'multiple-entity-typeahead' || f.type === 'entity-typeahead' || f.type === 'entity-lookup',
        ) as EntityMultipleTypeaheadField[];

        const requestActions = multiselectEntityFields
            .filter((f) => !isHardFalse(f.params?.configs?.visibility) && !isHardFalse(f.params?.configs?.editable))
            .map((f) => {
                const filterStr = !f.params?.filter
                    ? null
                    : getImpl()
                          .evaluateTemplate(f.params.filter)(baseContext, baseContext)
                          .fold(
                              (l) => {
                                  l.forEach((msg) => console.error(msg));
                                  /**
                                 * We used to throw an error in this case.
                                 * so that any issues are caught in testing
                                  //   throw new Error(
                                  //       `The errors above occurred evaluating the filter "${
                                  //           f.params.filter
                                  //       }" for the field: ${JSON.stringify(f, null, 1)}`,
                                  //   );

                                  But now these fields are sometimes in table-form rows. The filtering isn't going to work here,
                                  because we don't have access to the data in those rows (in fact, more may be added)

                                  So instead, let's strip out any $[] tags, and return the resulting filter 
                                  just in case there's any hardcoded ones we need

                                  TODO:
                                  The _ideal_ solution would be to _individually_ run each $[] segment and only remove the ones with errors.
                                  That would keep the non-failing $[] template tags.
                                  Should be straightforwards, but leaving that to be done at a later time.

                                 */

                                  const [first, ...rest] = f.params.filter.split('$[');
                                  const filter = [
                                      first,
                                      ...rest.map((s) => (s.includes(']') ? s.slice(s.indexOf(']') + 1) : s)),
                                  ].join('');
                                  return filter;
                              },
                              (value) => value,
                          );
                const filter = !filterStr ? {} : getFilterFromFilterString(filterStr);
                const expansions = !f.params?.filter ? [] : getExpansionsFromFilter(f.params.filter);
                return crudGetList({
                    resource: f.params.entity,
                    filter,
                    pagination: {
                        page: 1,
                        perPage: 1500,
                    },
                    sort: {
                        field: 'id',
                        order: 'ASC',
                    },
                    view: null,
                    appendExpansions: expansions,
                });
            });
        const uniqRequestActions = uniqWith(requestActions, deepEql);
        uniqRequestActions.forEach((r) => dispatch(r));
    }, [dispatch, allFields, baseContext]);
    return fetchData;
};

export default useFetchTaskFormOfflineData;
