import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import aorLoading from './aor/loading';
import aorUi from './aor/ui';
import lists from './lists/index';
import bpmReducer from 'bpm/reducer/BPMState';
import viewConfigReducer from 'viewConfig/reducers';
import viewConfigIsLoadingReducer from 'viewConfig/reducers/isLoading';
import valueSetsReducer from 'valueSets/reducer';
import printTemplateReducer from 'printTemplate/reducer';
import taskFormReducer from 'bpm/task-form/reducer/taskForms';
import taskFormStatusReducer from 'bpm/task-form/reducer/remoteStatus';
import dashboardReducer from 'dashboard2/dashboard-config/reducer';
import basicInfoReducer from './basicInfoReducer';
import viewStackReducer from '../popoverStackManagement/reducer';
import recentTabReducer from './recentTabReducer';
import entityValidationsReducer from './entityValidationsReducer';
import viewValidationsReducer from './viewValidationsReducer';
import searchValidationsReducer from './searchValidationsReducer';
import expressionReducer from './expressionReducer';
import entityVisibilityReducer from './entityVisibilityReducer';
import debugModeReducer from './debugMode';
import toggleHighlightMui0 from './toggleHighlightMui0';
import printModeReducer from './printModeReducer';
import lastSearchOrProcessPageReducer from './lastSearchOrProcessPageReducer';
import entityConceptExpReducer from 'viewConfigCalculations/ConceptAvailabilityExpressions/reducer';
import viewItemFilterExpReducer from 'viewConfigCalculations/filterExpressions/reducer';
import templateExpReducer from 'viewConfigCalculations/expressionTemplates/reducer';
import actionButtonExpReducer from 'viewConfigCalculations/actionButtonDisplayExpressions/reducer';
import entityEditabilityReducer from './entityEditabilityReducer';
import notistackReducer from 'notistack/reducer';
import { RootAction } from 'actions/rootAction';
import getResource from './lists/list/getResource';
import listIsLoading from './lists/listLoading';
import getOneStatusReducer from 'remoteStatus/one/reducer';
import disableFormSaveNotifierStatusReducer from 'formSaveNotifier/reducer';
import impersonatingReducer from 'impersonate/reducer';
import reauthReducer from 'reauth-interval-and-retries/reducers';
import expressionTesterReducer from 'expression-tester/reducer';
import undisableTaskFormsReducer from 'bpm/undisable-task-form/reducer';
import connectivityStatusReducer from 'connectivity/reducer';
import mfaReducer from 'auth/reducer/mfaReducer';
import localeReducer from 'i18n/store/reducer';
import profilesReducer from 'auth/profiles/reducer';
import offlineTasksReducer from 'offline_app/offline_stateful_tasks/offlineTasks/offlineTasksReducer';
import taskCurrentlyWritingToOfflineReducer from 'offline_app/offline_stateful_tasks/currentlyWritingToOfflineState/currentlyWritingToOfflineReducer';
import entitySubmitsInTaskContextReducer from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/entitySubmitsInTaskContextReducer';
import downloadedListViewsReducer from 'offline_app/offline_stateful_tasks/download/downloadedListViews/reducer';
import downloadedRef1ViewsReducer from 'offline_app/offline_stateful_tasks/download/downloadedRef1Views/reducer';
import offlineMetaReducer from 'offline_app/offline_stateful_tasks/offlineMeta/reducer';
import saveOfflineGateOpenReducer from 'offline_app/offline_stateful_tasks/saveOfflineGate/saveOfflineGateOpenReducer';
import entityFormSubmissionStateReducer from './entityFormSubmissionStateReducer';
import viewDetailsStackReducer from 'popoverStackManagement/viewDetailsStack/viewDetailsSlice';
import { RootState } from './rootReducer';
import entities from './entities';
import globalAlerts from 'global-alerts/reducer';
import refreshProcessDataKeyReducer from 'bpm2/refreshProcessData/refreshProcessDataKeySlice';
import visibleViewNamesReducer from 'components/generics/visibleViewNames/visibleViewNamesSlice';
import reportDefinitionReducer from 'report2/hooks/reportDefinitionSlice';
import viewDefaultValueExpressionsReducer from 'expression-tester/entity-form/DefaultValueConfiguration/defaultValueExpressionsSlice';

const resourcesReducer = (
    previousState: {
        [resource: string]: {
            lists: ReturnType<ReturnType<typeof lists>>;
            listIsLoading: ReturnType<ReturnType<typeof listIsLoading>>;
        };
    } = {},
    action: RootAction,
) => {
    const _resource = getResource(action);
    if (!_resource) {
        return previousState;
    }
    return {
        ...previousState,
        [_resource]: {
            lists: lists(_resource)(previousState[_resource] && previousState[_resource].lists, action),
            listIsLoading: listIsLoading(_resource)(
                previousState[_resource] && previousState[_resource].listIsLoading,
                action,
            ),
        },
    };
};
const adminReducer = combineReducers({
    loading: aorLoading,
    ui: aorUi,
    entities,
    resources: resourcesReducer,
});
const appReducer = (history) =>
    combineReducers<RootState>({
        visibleViewNames: visibleViewNamesReducer,
        refreshProcessDataKey: refreshProcessDataKeyReducer,
        viewDetailsStack: viewDetailsStackReducer,
        entityFormSubmissionState: entityFormSubmissionStateReducer,
        viewDefaultValueExpressions: viewDefaultValueExpressionsReducer,
        saveOfflineGateOpen: saveOfflineGateOpenReducer,
        offlineMeta: offlineMetaReducer,
        offlineDownloadedRef1Views: downloadedRef1ViewsReducer,
        offlineDownloadedListViews: downloadedListViewsReducer,
        entitySubmitsInTaskContext: entitySubmitsInTaskContextReducer,
        taskCurrentlyWritingToOffline: taskCurrentlyWritingToOfflineReducer,
        offlineTasks: offlineTasksReducer,
        profiles: profilesReducer,
        locale: localeReducer,
        mfa: mfaReducer,
        globalAlerts,
        impersonating: impersonatingReducer,
        form: formReducer,
        reauth: reauthReducer,
        getOneStatus: getOneStatusReducer,
        router: connectRouter(history),
        viewConfigIsLoading: viewConfigIsLoadingReducer,
        viewConfig: viewConfigReducer,
        bpm: bpmReducer,
        undisableTaskForms: undisableTaskFormsReducer,
        valueSets: valueSetsReducer,
        connectivityStatus: connectivityStatusReducer,
        printTemplates: printTemplateReducer,
        taskForms: taskFormReducer as any,
        taskFormStatuses: taskFormStatusReducer,
        dashboard: dashboardReducer,
        viewStack: viewStackReducer,
        recentTab: recentTabReducer,
        basicInfo: basicInfoReducer as any,
        admin: adminReducer as any,
        entityValidations: entityValidationsReducer,
        viewValidations: viewValidationsReducer,
        searchValidations: searchValidationsReducer,
        entityEditability: entityEditabilityReducer,
        entityVisibility: entityVisibilityReducer,
        entityConceptExps: entityConceptExpReducer,
        actionButtonExps: actionButtonExpReducer,
        expressionEval: expressionReducer,
        debugMode: debugModeReducer,
        reportDefinition: reportDefinitionReducer,
        thm0: toggleHighlightMui0,
        printMode: printModeReducer,
        lastSearchOrProcessPage: lastSearchOrProcessPageReducer,
        notistack: notistackReducer,
        disableFormSaveNotifier: disableFormSaveNotifierStatusReducer,
        expressionTesterOpen: expressionTesterReducer,
        viewItemFilterExps: viewItemFilterExpReducer,
        templateExps: templateExpReducer,
    });
export default appReducer;
