import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { toggleSidebar as toggleSidebarAction } from 'actions/aor/uiActions';
import useWidth from 'util/hooks/useWidth';
import { overrideAppbarTitleSelector } from './layouts/menu2/overrideAppbarTitleSelector';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { RootState, useAppSelector } from 'reducers/rootReducer';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        flex: {
            flex: 1,
            fontSize: '2em',
        },
    }),
);

interface AppbarMobileProps {
    title?: string | JSX.Element;
}
const AppBarMobile: React.FC<AppbarMobileProps> = ({ title = '' }) => {
    const { overrideMobileAppbarTitleHtml, hideAllMenuItems, mobileToolbarStyles } =
        useAppSelector(overrideAppbarTitleSelector);

    const dispatch = useDispatch();
    const classes = useStyles();
    const handleLeftIconButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            dispatch(toggleSidebarAction());
        },
        [dispatch],
    );
    const printMode = useAppSelector((state: RootState) => state.printMode);
    const width = useWidth();
    if (width !== 'xs') {
        return null;
    }
    const TitleElem = (
        <Typography variant="h1" color="inherit" className={classes.flex}>
            {title}
        </Typography>
    );
    if (printMode) {
        return TitleElem;
    }
    return (
        <AppBar position="static">
            <Toolbar style={mobileToolbarStyles}>
                {!hideAllMenuItems && (
                    <IconButton onClick={handleLeftIconButtonClick} color="inherit" aria-label="Menu">
                        <MenuIcon />
                    </IconButton>
                )}
                {overrideMobileAppbarTitleHtml ? (
                    <SafeHtmlAsReact containerStyle={{ width: '100%' }} html={overrideMobileAppbarTitleHtml} />
                ) : (
                    TitleElem
                )}
            </Toolbar>
        </AppBar>
    );
};

export default AppBarMobile;
