import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Form, FormSpy, FormRenderProps, FormProps } from 'react-final-form';
import deepEql from 'deep-eql';
import useValidation from 'components/generics/form/validate/useValidation';
import produce from 'immer';

interface ControlledValidatedFormProps {
    resource: string;
    registeredFields: string[];
    values: {};
    setValues: (values: {}) => void;
    children: (props: FormRenderProps) => JSX.Element;
    submitFailed?: boolean;
}

const TouchFieldsWhenSubmitFailed = ({
    submitFailed,
    onSubmitFailed,
}: {
    submitFailed?: boolean;
    onSubmitFailed: () => void;
}) => {
    // New rows added after a failed submission will show errors - I think that's fine.
    const prevSubmitFailed = useRef(false);
    useEffect(() => {
        if (submitFailed && prevSubmitFailed.current !== submitFailed) {
            prevSubmitFailed.current = true;
            onSubmitFailed();
        }
    }, [submitFailed, onSubmitFailed]);
    return null;
};

export const ControlledForm: React.FC<{
    validate?: FormProps['validate'];
    values: {};
    setValues: (values: {}) => void;
    children: (props: FormRenderProps) => JSX.Element;
    submitFailed?: boolean;
}> = ({ values, setValues, children, submitFailed, validate }) => {
    const handleSubmit = useCallback((data) => {
        // No-op: we are using the form to keep track of values, and propagating using FormSpy
    }, []);
    return (
        <Form
            mutators={{
                setFieldTouched: (args: any[], state) => {
                    const [name, touched] = args;
                    const field = state.fields[name];
                    if (field) {
                        field.touched = !!touched;
                    }
                },
            }}
            validate={validate}
            validateOnBlur
            initialValues={values}
            onSubmit={handleSubmit}
        >
            {(props) => {
                return (
                    <>
                        {children(props)}
                        <TouchFieldsWhenSubmitFailed
                            submitFailed={submitFailed}
                            onSubmitFailed={() => {
                                props.form.getRegisteredFields().forEach((f) => {
                                    props.form.mutators.setFieldTouched(f, true);
                                });
                            }}
                        />
                        <FormSpy
                            subscription={{ values: true, hasValidationErrors: true }}
                            onChange={(formState) => {
                                if (
                                    !deepEql(formState.values, values) ||
                                    Boolean(formState.hasValidationErrors) !== values['__invalid']
                                ) {
                                    const newValues = formState.hasValidationErrors
                                        ? {
                                              ...formState.values,
                                              __invalid: true,
                                          }
                                        : produce(formState.values, (draft) => {
                                              delete draft['__invalid'];
                                          });
                                    setValues(newValues);
                                }
                            }}
                        />
                    </>
                );
            }}
        </Form>
    );
};
const ControlledValidatedForm: React.FC<ControlledValidatedFormProps> = ({
    resource,
    registeredFields: _registeredFields,
    values,
    setValues,
    children,
    submitFailed,
}) => {
    const registeredFieldsStr = useMemo(() => JSON.stringify(_registeredFields), [_registeredFields]);
    const registeredFields = useMemo(() => {
        return JSON.parse(registeredFieldsStr);
    }, [registeredFieldsStr]);

    const validate = useValidation({
        values,
        type: '*',
        registeredFields,
        resource,
    });
    return (
        <ControlledForm
            validate={validate}
            values={values}
            setValues={setValues}
            children={children}
            submitFailed={submitFailed}
        />
    );
};
export default ControlledValidatedForm;
