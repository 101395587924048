/* eslint no-loop-func: 0 */
import { entityPreprocessValuesForEval } from 'expressions/formValidation';
import memoizeOne from 'memoize-one';
import deepEql from 'deep-eql';
import get from 'lodash/get';
import set from 'lodash/set';
import deepExtend from 'util/cyclicDeepExtend';
import { SpelOptions } from 'expressions/evaluate';
import {
    getCalculateValuesBasedOnAvailableFields,
    ValuesetFieldAvailableConcepts,
    AvailableOptions,
} from 'expressions/expressionArrays/formValuesInDynamicContext/util/calcValuesBasedOnAvailableFields';
import ViewConfig from 'reducers/ViewConfigType';
import { isADirectRefOneField } from 'components/generics/utils/viewConfigUtils';
import updateDataOnReferenceChange from 'components/generics/form/EntityFormContext/util/updatedDataOnSubRefChange';
import flatten, { unflatten } from 'flat';
import uniq from 'lodash/uniq';
import { some, none } from 'fp-ts/lib/Option';
import produce from 'immer';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import filterEntityByQueryRepresentation from 'isomorphic-query-filters/filterEntityByQueryRepresentation';
import { applyToFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import cleaner from 'deep-cleaner';
import getDirtyPaths from 'expressions/expressionArrays/formValuesInDynamicContext/util/getDirtyPaths';
import isEmpty from 'lodash/isEmpty';
import fastCopy from 'fast-copy';
import fromEntries from 'util/fromentries';
import KeyCachingEvaluator, { Evaler } from './Evaluator';
import { ValueSets } from 'valueSets/reducer';
import { getSubExpressionsOfFilterTemplate } from 'viewConfigCalculations/filterExpressions/epic';
import { ExpressionEvaluatorContext } from 'expressions/Provider/expressionEvaluatorContext';
import isFunction from 'lodash/isFunction';
import { setupGenericContext } from 'expressions/Provider/setupGenericContext';
import isPlainObject from 'lodash/isPlainObject';
import { diff } from 'jsondiffpatch';
import getImpl from 'expressions/Provider/implementations/getImpl';

/**
// Example usage:
const result = getSubpaths('foo.bar.baz');
console.log(result); // Should print ['foo', 'foo.bar', 'foo.bar.baz']
 */
function getSubpaths(input: string): string[] {
    // Split the input string by '.' to get each part
    const parts = input.split('.');

    // Use the reduce method to construct each subpath, accumulating them into an array
    const subpaths = parts.reduce((accumulator: string[], currentPart: string, currentIndex: number) => {
        // For the first part, just add it as is. For subsequent parts, append the current part to the last item in the accumulator
        const subpath = currentIndex === 0 ? currentPart : `${accumulator[currentIndex - 1]}.${currentPart}`;
        accumulator.push(subpath);
        return accumulator;
    }, []);

    return subpaths;
}

// '', null, undefined are considered the same.
const isEmptyStrWise = (v) => v === '' || v === null || typeof v === 'undefined';
const equalStrWise = (left, right) => {
    return (isEmptyStrWise(left) && isEmptyStrWise(right)) || deepEql(left, right);
};
// e.g. for finding widget keys to hide or mark as disabled, because all their visibility/editability expressions are false.

/**
 * IMPORTANT!
 * This used to be 'getAllFalsyKeys'.
 * However, it turns out when we are evaluating visibility+editability expressions, we only care if _any_ expression returns false, not if all of them do.
 * To ensure that change was safe, I ran the below in the console after logging-in to several environments, and it returned no results, except in the case of
 * component views, where there are visibility expressions on the component, and internal to the component as well.
 * In that case, the change fixed that behavior, and should have no other effects, since I found no value arrays longer than 1 otherwise.
 *
 * Object.entries(window.casetivity_store.getState().entityVisibility).flatMap(([k, v]) => Object.entries(v).map(([field, exps]) => [k + ':' + field, exps])).filter(t => t[1].length > 1)
 *
 * @param results An dict where values are an array of values to be evaluated based on their truthiness
 * @returns the keys in 'results' where there is any falsy value present.
 */
const getAnyFalsyKeys = (results: { [k: string]: any[] }) =>
    Object.entries(results || {}).flatMap(([k, v]) => (v.some((v) => !v) ? [k] : []));
const getObjOfInstancesInList = (instances: string[]): { [f: string]: true } => {
    const res: { [f: string]: true } = {};
    instances.forEach((f) => {
        res[f] = true;
    });
    return res;
};

const cleanIfObjOrArray = (v) => {
    if (v && typeof v === 'object') {
        // clone until https://github.com/darksinge/deep-cleaner/issues/13 closed
        return cleaner(fastCopy(v));
    }
    return v;
};

export const keysToClearValuesetCachedResults = [
    'containsCodes',
    'lookupConceptIdsFromValuesetGroup',
    'valuesetIsLoaded',
    'getConceptFromCode',
    'getConceptFromDisplay',
    'getConceptIdFromCode',
    'getConceptIdFromDisplay',
    'getConceptCodeFromDisplay',
    'isValidConcept',
    'isValidConceptFromCode',
    'isValidConceptFromDisplay',
    'getConceptIdsFromCodes',
    'getValuesetConceptIdsFromCodes',
];

const isNonEditablePathToConsiderClean = (
    path: string,
    tableRowFieldContext: ReturnType<typeof evaluateContext2>['tableRowContexts'][0] | undefined,
) => {
    if (!tableRowFieldContext) {
        return false;
    }
    const [indexStr, ...rest] = path.split('.');
    const index = parseInt(indexStr, 10);
    if (isNaN(index)) {
        return false;
    }
    const rowContext = tableRowFieldContext[index];
    if (!rowContext) {
        // row was deleted
        return false;
    }
    const isBaseCase = rest.length === 1 || !rest.find((sp) => !isNaN(parseInt(sp, 10)));
    if (isBaseCase) {
        // there may be undocumented values in tableRows that don't match the schema.
        // lets ignore these for dirtyness. (can't simply check hidden/disabled field list)
        return !rowContext.visibleAndEditableFields.includes(rest.join('.'));
    }
    const [nextField, ...rest2] = rest;
    // recurse to all nested tables.
    return isNonEditablePathToConsiderClean(rest2.join('.'), rowContext.tableRowContexts[nextField]);
};

export interface AvailableOptionsExpressions {
    [source: string]: {
        emptyValue:
            | {
                  name: string;
                  value?: any;
              }
            | string
            | null;
        optionVisibilities: {
            // e.g. { "name": "Foo" } or { "name": "Foo", value: "foo" }
            [stableStringifiedOptionObject: string]: 'ALWAYS_VISIBLE' | string;
        };
    };
}
export interface EvaluationFactors {
    variables?: {
        [$variableName: string]: string;
    };
    fieldWidgets: {
        [field: string]: string[]; // key: field. value: array of widget Ids associated with that field.
        // There may be different field-instances with different visibilities mapping to the same field.
        // Only when they all (disable or hide), we set the field to its initial value.
    };
    valueset1Fields?: {
        [source: string /* 'Id' removed */]: string;
    };
    valueset1AvailableConceptsExpressions?: {
        // values are spel expressions resulting in a list of available concepts to that field
        [source: string /* 'Id' removed */]: string;
    };
    dropdownAvailableOptionsExpressions?: AvailableOptionsExpressions;
    visibilityExpressions?: {
        [formField: string]: string[];
    };
    editabilityExpressions?: {
        [formField: string]: string[];
    };
    reference1EntityFilterExpressions?: {
        [source: string /* Includes 'Id' if present. */]: {
            entityType: string;
            expression: string;
        };
    };
    referenceManyEntityFilterExpressions?: {
        [source: string /* Includes 'Ids' if present. */]: {
            entityType: string;
            expression: string;
        };
    };
    tableExpressions?: {
        // table data is ephemeral - if something is 'disabled' for 'hidden' - we make it null (instead of initial value)
        [fieldId: string]: EvaluationFactors;
    };
    useBackingValuesRegardlessOfDisplayStatus?: {
        [field: string]: true;
    };
    nullWhenHidden?: {
        [field: string]: true;
    };
    nullWhenDisabled?: {
        [field: string]: true;
    };
    dontAdjustValueBasedOnConceptExpressions?: {
        [fieldId: string]: true;
    };
}
interface EvalOptions {
    bypassInitialConsitencyForFilters?: boolean;
    trackInitialState?: boolean;
}
interface ConstructorArgs {
    evaluationFactors: EvaluationFactors;
    basedOnEntityOptions: {
        basedOnEntity: string;
        fieldsUsedInExpressions: string[];
    } | null;
    viewConfig: ViewConfig;
    options: SpelOptions;
    evalOptions?: EvalOptions;
    parserEvaluator?: ExpressionEvaluatorContext;
}
const getExpressionsForCachingEvaluator = (
    args: Pick<
        EvaluationFactors,
        | 'valueset1AvailableConceptsExpressions'
        | 'dropdownAvailableOptionsExpressions'
        | 'visibilityExpressions'
        | 'editabilityExpressions'
        | 'reference1EntityFilterExpressions'
        | 'referenceManyEntityFilterExpressions'
        | 'variables'
    >,
) => {
    return {
        visibility: args.visibilityExpressions,
        editability: args.editabilityExpressions,
        availableConcepts:
            args.valueset1AvailableConceptsExpressions &&
            fromEntries(
                Object.entries(args.valueset1AvailableConceptsExpressions).map(
                    ([k, v]) => [k, [v]] as [string, string[]],
                ),
            ),
        availableOptions:
            args.dropdownAvailableOptionsExpressions &&
            fromEntries(
                Object.entries(args.dropdownAvailableOptionsExpressions).map(([k, v]) => [
                    k,
                    fromEntries(Object.entries(v.optionVisibilities).map(([k, v]) => [k, [v]] as [string, string[]])),
                ]),
            ),
        ref1filterExpressions:
            args.reference1EntityFilterExpressions &&
            fromEntries(
                Object.entries(args.reference1EntityFilterExpressions).map(([k, v]) => {
                    return [k, getSubExpressionsOfFilterTemplate(v.expression)];
                }),
            ),
        refmanyFilterExpressions:
            args.referenceManyEntityFilterExpressions &&
            fromEntries(
                Object.entries(args.referenceManyEntityFilterExpressions).map(([k, v]) => {
                    return [k, getSubExpressionsOfFilterTemplate(v.expression)];
                }),
            ),
    };
};
export class FormContextEvaluator {
    expressionsEvaluator: KeyCachingEvaluator<ReturnType<typeof getExpressionsForCachingEvaluator>>;
    initialsExpressionsEvaluator: KeyCachingEvaluator<
        Pick<ReturnType<typeof getExpressionsForCachingEvaluator>, 'visibility' | 'editability'>
    >;
    variablesEvaluator: KeyCachingEvaluator<{ variables: { [$var: string]: [string] } }>;
    variablesLevel2Evaluator: KeyCachingEvaluator<{ variables: { [$var: string]: [string] } }>;
    variablesLevel3Evaluator: KeyCachingEvaluator<{ variables: { [$var: string]: [string] } }>;
    variablesLevel4Evaluator: KeyCachingEvaluator<{ variables: { [$var: string]: [string] } }>;
    initialFiltersHandler:
        | {
              type: 'dontHandle';
          }
        | {
              type: 'allowInitialEvenIfInvalid';
              evaluator: KeyCachingEvaluator<
                  ReturnType<typeof getExpressionsForCachingEvaluator>['ref1filterExpressions']
              >;
          };
    constructorArgs: ConstructorArgs;
    fields: string[];
    refOneFields: string[];
    // we need to initialize these on the first 'evaluate'
    cachedEntities: { Concept?: {} } | null = null;
    cachedValuesets: ValueSets | null = null;
    nullInitializedVariables: {
        [variableName: string]: null;
    };
    constructor(args: ConstructorArgs) {
        this.constructorArgs = args;
        const { fieldWidgets = {} } = args.evaluationFactors;
        const { viewConfig, basedOnEntityOptions } = args;
        this.fields = Object.keys(fieldWidgets);
        // below: if we only want real fields.
        // The thing is, if we use expression fields as 'calculated' intermediate fields, we might have a different situation.
        // .filter(
        //     f =>
        //         !basedOnEntityOptions ||
        //         isValidEntityFieldExpression(
        //             viewConfig,
        //             basedOnEntityOptions.basedOnEntity,
        //             f.endsWith('Ids') ? f.slice(0, -3) : f.endsWith('Id') ? f.slice(0, -2) : f,
        //         ),
        // );
        this.refOneFields = basedOnEntityOptions
            ? this.fields.filter((f) => {
                  return (
                      !f.endsWith('Ids') &&
                      isADirectRefOneField(
                          viewConfig,
                          basedOnEntityOptions.basedOnEntity,
                          f.endsWith('Id') ? f.slice(0, -2) : f,
                      )
                  );
              })
            : [];

        this.variablesEvaluator = new KeyCachingEvaluator(
            {
                variables: fromEntries(
                    Object.entries(args.evaluationFactors.variables || {})
                        .filter(([k, v]) => !k.startsWith('$') || (k.startsWith('$') && !k.startsWith('$$')))
                        .map(([k, v]) => [k, [v]]),
                ),
            },
            this.evaluationFn,
            {}, // WE HAVE TO RE-INITIALIZE THIS ONE WE GET OUR REAL CONTEXT (by calling clearCaches for all expressions)
            'deepeql',
        );
        this.variablesLevel2Evaluator = new KeyCachingEvaluator(
            {
                variables: fromEntries(
                    Object.entries(args.evaluationFactors.variables || {})
                        .filter(([k, v]) => k.startsWith('$$') && !k.startsWith('$$$'))
                        .map(([k, v]) => [k, [v]]),
                ),
            },
            this.evaluationFn,
            {}, // WE HAVE TO RE-INITIALIZE THIS ONE WE GET OUR REAL CONTEXT (by calling clearCaches for all expressions)
            'deepeql',
        );
        this.variablesLevel3Evaluator = new KeyCachingEvaluator(
            {
                variables: fromEntries(
                    Object.entries(args.evaluationFactors.variables || {})
                        .filter(([k, v]) => k.startsWith('$$$') && !k.startsWith('$$$$'))
                        .map(([k, v]) => [k, [v]]),
                ),
            },
            this.evaluationFn,
            {}, // WE HAVE TO RE-INITIALIZE THIS ONE WE GET OUR REAL CONTEXT (by calling clearCaches for all expressions)
            'deepeql',
        );
        this.variablesLevel4Evaluator = new KeyCachingEvaluator(
            {
                variables: fromEntries(
                    Object.entries(args.evaluationFactors.variables || {})
                        .filter(([k, v]) => k.startsWith('$$$$') && !k.startsWith('$$$$$'))
                        .map(([k, v]) => [k, [v]]),
                ),
            },
            this.evaluationFn,
            {}, // WE HAVE TO RE-INITIALIZE THIS ONE WE GET OUR REAL CONTEXT (by calling clearCaches for all expressions)
            'deepeql',
        );
        this.nullInitializedVariables = fromEntries(
            Object.keys(args.evaluationFactors.variables || {}).map((k) => [k, null]),
        );
        const expressions = getExpressionsForCachingEvaluator(args.evaluationFactors);
        this.expressionsEvaluator = new KeyCachingEvaluator(
            expressions,
            this.evaluationFn,
            {}, // WE HAVE TO RE-INITIALIZE THIS ONE WE GET OUR REAL CONTEXT (by calling clearCaches for all expressions)
            'deepeql',
        );
        const { visibility, editability } = expressions;
        this.initialsExpressionsEvaluator = new KeyCachingEvaluator(
            {
                visibility,
                editability,
            },
            this.evaluationFn,
            {}, // WE HAVE TO RE-INITIALIZE THIS ONE WE GET OUR REAL CONTEXT (by calling clearCaches for all expressions)
            'deepeql',
        );

        this.initialFiltersHandler = this.byPassConsistencyForInitialFilters()
            ? {
                  type: 'allowInitialEvenIfInvalid',
                  evaluator: new KeyCachingEvaluator(
                      getExpressionsForCachingEvaluator(args.evaluationFactors).ref1filterExpressions || {},
                      this.evaluationFn,
                      {}, // WE HAVE TO RE-INITIALIZE THIS ONE WE GET OUR REAL CONTEXT (by calling clearCaches for all expressions)
                      'deepeql',
                  ),
              }
            : { type: 'dontHandle' };
    }
    byPassConsistencyForInitialFilters = () => {
        const { evalOptions: { bypassInitialConsitencyForFilters = true } = {} } = this.constructorArgs;
        return bypassInitialConsitencyForFilters;
    };
    trackInitialState = () => {
        const { evalOptions: { trackInitialState = true } = {} } = this.constructorArgs;
        return trackInitialState;
    };
    getParserEvaluator = () => {
        const { parserEvaluator = getImpl() } = this.constructorArgs;
        return parserEvaluator;
    };
    getContext = ({ valueSets, entities, extra }: { valueSets: ValueSets; entities: { Concept?: {} }; extra?: {} }) => {
        const context = {
            ALWAYS_VISIBLE: true,
            ...setupGenericContext({
                viewConfig: this.constructorArgs.viewConfig,
                valueSets,
                entities,
                viewContext: this.constructorArgs.options.viewContext,
                extra,
                backref: this.constructorArgs.options.backref,
            }),
        };
        return context;
    };
    evaluationFn = (expression: string) => {
        const compiledExpression = this.getParserEvaluator().compileExpression(expression);
        if (compiledExpression.type === 'parse_failure') {
            throw new Error('Failed to compile "' + expression + '": ' + compiledExpression.msg);
        }
        const methodNames = compiledExpression.methodsAndFunctions;
        const propertyNames = compiledExpression
            .getExpansionsWithoutArrayDescendants()
            .flatMap((path) =>
                // this is necessary so if we have e.g. insuranceConsent.name, and insuranceConsent is null, we still have insuranceConsent as a property to set (we don't skip over it)
                getSubpaths(path),
            )
            .sort((a, b) => a.length - b.length);

        const filterContext = (ctxt) => {
            const rtctxt = {};
            methodNames.forEach((mn) => {
                if (isFunction(ctxt[mn])) {
                    rtctxt[mn] = ctxt[mn];
                }
            });

            // propertyNames needs to be sorted shortest to longest
            propertyNames.forEach((pn) => {
                const got = get(ctxt, pn);
                if (typeof got !== 'undefined') {
                    set(rtctxt, pn, got);
                }
            });
            return rtctxt;
        };

        const evaler: Evaler = function (context: {}) {
            const functionsAndVariables = filterContext(context);
            return (values: {}) => {
                const filteredContext = filterContext(values);
                const evaluatedExpression = compiledExpression.evaluate(
                    { ...functionsAndVariables, ...filteredContext },
                    functionsAndVariables,
                    // (twice the number of closures being copied, etc.)
                );
                if (evaluatedExpression.type === 'evaluation_failure') {
                    throw new Error('Failed to evaluate "' + expression + '": ' + evaluatedExpression.msg);
                }
                return evaluatedExpression.result;
            };
        } as Evaler;
        evaler.methodsRequired = methodNames;
        evaler.fieldsRequired = propertyNames;
        return evaler;
    };
    evaluate = (_values: {}, valueSets: ValueSets, initialValues: {}, entities: { Concept?: {} }, extra?: {}) => {
        if (!this.cachedEntities || !this.cachedValuesets) {
            // Our context hasn't been properly set up yet, so we need to initialize it.
            this.cachedEntities = entities;
            this.cachedValuesets = valueSets;
            if (this.initialFiltersHandler.type === 'allowInitialEvenIfInvalid') {
                this.initialFiltersHandler.evaluator.clearCaches(
                    () => true,
                    this.getContext({ valueSets, entities, extra }),
                );
            }
            this.expressionsEvaluator.clearCaches(() => true, this.getContext({ valueSets, entities, extra }));
            this.initialsExpressionsEvaluator.clearCaches(() => true, this.getContext({ valueSets, entities, extra }));
            this.variablesEvaluator.clearCaches(() => true, this.getContext({ valueSets, entities }));
            this.variablesLevel2Evaluator.clearCaches(() => true, this.getContext({ valueSets, entities, extra }));
            this.variablesLevel3Evaluator.clearCaches(() => true, this.getContext({ valueSets, entities }));
            this.variablesLevel4Evaluator.clearCaches(() => true, this.getContext({ valueSets, entities }));
        }
        // Now selectively clear caches for the expressions for which it is necessary.
        if (entities !== this.cachedEntities) {
            this.cachedEntities = entities;
            const ccSelector = (expression: string) => {
                return expression.includes('lookupEntityData') || expression.includes('mapToEntityData');
            };
            this.expressionsEvaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.initialsExpressionsEvaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesEvaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesLevel2Evaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesLevel3Evaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesLevel4Evaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
        }
        if (valueSets !== this.cachedValuesets) {
            this.cachedValuesets = valueSets;
            const ccSelector = (expression: string) => {
                // todo: also include valueset functions
                return (
                    expression.includes('Code') || keysToClearValuesetCachedResults.some((x) => expression.includes(x))
                );
            };
            this.expressionsEvaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.initialsExpressionsEvaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesEvaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesLevel2Evaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesLevel3Evaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
            this.variablesLevel4Evaluator.clearCaches(ccSelector, this.getContext({ valueSets, entities, extra }));
        }

        const {
            viewConfig,
            options,
            basedOnEntityOptions,
            evaluationFactors: {
                useBackingValuesRegardlessOfDisplayStatus = {},
                nullWhenHidden = {},
                nullWhenDisabled = {},
                fieldWidgets = {},
                valueset1AvailableConceptsExpressions = {},
                dropdownAvailableOptionsExpressions = {},
                valueset1Fields = {},
                reference1EntityFilterExpressions = {},
                referenceManyEntityFilterExpressions = {},
                tableExpressions = {},
                dontAdjustValueBasedOnConceptExpressions = {},
            },
        } = this.constructorArgs;
        // cache things which we close on, so we can detect changes to them and clear caches of expressions that reference them.
        // e.g. expressions that include 'lookupEntityData' or '.+Code'

        let values = _values;
        let valuesWithInitialsAndConcptAvailbtyApplied = values;
        let hiddenFieldInstances: string[] = [];
        let disabledFieldInstances: string[] = [];
        let nullFilteredRefOneFields: string[] = [];
        let availableConcepts: ValuesetFieldAvailableConcepts = fromEntries(
            Object.entries(valueset1AvailableConceptsExpressions).map(([f, expression]) => [f, '*']),
        );
        let evaluatedRefManyFilters: {
            [field: string]: {
                entityType: string;
                filter: {};
            };
        } = {};
        let dropdownAndRadioAvailableOptions: AvailableOptions = fromEntries(
            Object.entries(dropdownAvailableOptionsExpressions).map(([f, conf]) => {
                const initOptions = fromEntries(Object.keys(conf.optionVisibilities).map((option) => [option, true]));
                return [f, { empty: conf.emptyValue as any, options: initOptions }];
            }),
        );

        const getHiddenFieldDict = memoizeOne((hiddenFields: string[]) => {
            // nice 1-liner, but too slow for such a hot path: reimplementing faster below
            // return fromEntries(hiddenFields.map(field => [field, true]));
            let res = {};
            hiddenFields.forEach((f) => {
                res[f] = true;
            });
            return res;
        });
        const getDisabledFieldsFieldDict = memoizeOne((disabledFields: string[]) => {
            // nice 1-liner, but too slow for such a hot path: reimplementing faster below
            // return fromEntries(disabledFields.map(field => [field, true]));
            let res = {};
            disabledFields.forEach((f) => {
                res[f] = true;
            });
            return res;
        });

        const getEntirelyNonEditableOrVisibleFields = (() => {
            const getNonEditableFields = memoizeOne(
                (_hiddenFieldInstances: string[], _disabledFieldInstances: string[]): string[] =>
                    Object.entries(fieldWidgets).reduce((prev, [field, fieldInstances]) => {
                        const nonEditable = fieldInstances.every(
                            (widget) =>
                                !useBackingValuesRegardlessOfDisplayStatus[widget] &&
                                (getHiddenFieldDict(_hiddenFieldInstances)[widget] ||
                                    getDisabledFieldsFieldDict(_disabledFieldInstances)[widget]),
                        );
                        if (nonEditable) {
                            prev.push(field);
                            return prev;
                        }
                        return prev;
                    }, []),
            );
            return (_hiddenFieldInstances = hiddenFieldInstances, _disabledFieldInstances = disabledFieldInstances) =>
                getNonEditableFields(_hiddenFieldInstances, _disabledFieldInstances);
        })();

        /*
            If we make another 'evaluate' call after all our caches are initialized,
            and on our 'assume nothing is hidden' initial iteration of the do-while
            all results in the expressionEvaluator can come from cache,
            we never actually make the second pass over where we know what fields are hidden from the previous (first) dowhile iteration,
            and adjust our form values...

            So after the loop ends, we need to call 'getFieldsToForceNull' again, and apply our nulls there,
            knowing our final state of what fields are hidden.
        */
        const getFieldsToForceNull = () =>
            uniq([
                ...hiddenFieldInstances.filter((fid) => {
                    // hiddenFieldInstances end with Id or Ids.
                    // nullWhenHidden either does, or doesn't.
                    if (fid.endsWith('Id')) {
                        return nullWhenHidden[fid] || nullWhenHidden[fid.slice(0, -2)];
                    }
                    if (fid.endsWith('Ids')) {
                        return nullWhenHidden[fid] || nullWhenHidden[fid.slice(0, -3)];
                    }
                    return nullWhenHidden[fid];
                }),
                ...disabledFieldInstances.filter((fid) => {
                    if (fid.endsWith('Id')) {
                        return nullWhenDisabled[fid] || nullWhenDisabled[fid.slice(0, -2)];
                    }
                    if (fid.endsWith('Ids')) {
                        return nullWhenDisabled[fid] || nullWhenDisabled[fid.slice(0, -3)];
                    }
                    return nullWhenDisabled[fid];
                }),
            ]);

        const calculateValuesBasedOnAvailableFields = () => {
            const adjustedValues = getCalculateValuesBasedOnAvailableFields(
                viewConfig,
                entities,
                initialValues,
                values,
                getEntirelyNonEditableOrVisibleFields(),
                availableConcepts,
                valueset1Fields,
                dropdownAndRadioAvailableOptions,
                nullFilteredRefOneFields,
                evaluatedRefManyFilters,
                getFieldsToForceNull(),
                dontAdjustValueBasedOnConceptExpressions,
            );

            // insert references based on above with the data in expansions drawn from either
            // the reference, or the user input according to editability of the field.
            if (basedOnEntityOptions) {
                // preserve arrays when flattening.
                const av = flatten(adjustedValues, { safe: true });
                const avKeys = Object.keys(av);
                const u = updateDataOnReferenceChange(
                    viewConfig,
                    basedOnEntityOptions.basedOnEntity,
                    entities,
                    this.fields.map((f) => [f, av[f]] as [string, unknown]),
                    uniq([
                        ...getEntirelyNonEditableOrVisibleFields(),
                        ...basedOnEntityOptions.fieldsUsedInExpressions.map((p) => p.split('._ALL_')[0]),
                    ]),
                );
                u.forEach(([path, v]) => {
                    const kToDel = avKeys.find((k) => k === path || (k.startsWith(path) && k[path.length] === '.'));
                    if (kToDel) {
                        delete av[kToDel];
                    }
                });
                const toRet = unflatten(Object.assign({}, av, fromEntries(u), flatten(fromEntries(u))));
                return toRet;
            }
            return adjustedValues;
        };

        const preprocessValues = (values: {}) => ({
            ...entityPreprocessValuesForEval(
                values,
                basedOnEntityOptions
                    ? uniq([...this.fields, ...basedOnEntityOptions.fieldsUsedInExpressions])
                    : this.fields,
                valueset1Fields,
                entities,
                options,
                valueSets,
            ),
        });
        let variableResults: {
            [$variable: string]: any;
        } = {};

        // INFINITE_LOOP_VARIABLES
        let i = 1;
        let lastValues;
        do {
            // START_DEBUG_INFINTE_LOOP
            if (i === 100) {
                lastValues = valuesWithInitialsAndConcptAvailbtyApplied;
            }
            if (i > 100) {
                const difference = diff(lastValues, valuesWithInitialsAndConcptAvailbtyApplied);
                lastValues = valuesWithInitialsAndConcptAvailbtyApplied;
                const diffs = flatten(difference, { safe: true });
                const log = Object.entries(diffs).reduce((prev, [key, value]) => {
                    if (Array.isArray(value)) {
                        const [left, right] = value;
                        return prev + 'The value at ' + key + ' was changed from ' + left + ' to ' + right + '\n';
                    }
                    return prev + 'Diff at ' + key + ': ' + JSON.stringify(value);
                }, '');
                console.log('Infinite Loop:\n' + log);
                if (i > 103) {
                    alert(`Configuration error detected:"\nInfinite loop detected involving fields ${Object.keys(diffs)
                        .map((k) => `"${k}"`)
                        .join(', ')}.\n
                    Check console for more details.`);
                    break;
                }
            }
            i += 1;
            // END_DEBUG_INFINTE_LOOP
            if (!values) {
                break;
            }
            valuesWithInitialsAndConcptAvailbtyApplied = calculateValuesBasedOnAvailableFields();

            const preprocessedValues = preprocessValues(valuesWithInitialsAndConcptAvailbtyApplied);
            /*
                When we have deep relationships between variables (e.g. $c depends on $b, depends on $a) this gets quite unnecessarily slow.
                It also probably causes thrashing (because we run e.g. $b initially without $a calculated each pass)
                The ways around this are:
                1. encode execution-order in our variables, and keep seperate caches for step 1, step 2, etc, executing them in order.
                2. Don't allow depth to our variables, so they are correctly calculated in a single pass.
            */

            variableResults = {
                ...variableResults,
                ...fromEntries(
                    Object.entries(
                        this.variablesEvaluator.evaluateAll({
                            ...this.nullInitializedVariables,
                            ...preprocessedValues,
                            ...variableResults,
                        }).variables,
                    ).map(([k, [r]]) => [k, r]),
                ),
            };
            variableResults = {
                ...variableResults,
                ...fromEntries(
                    Object.entries(
                        this.variablesLevel2Evaluator.evaluateAll({
                            ...this.nullInitializedVariables,
                            ...preprocessedValues,
                            ...variableResults,
                        }).variables,
                    ).map(([k, [r]]) => [k, r]),
                ),
            };

            variableResults = {
                ...variableResults,
                ...fromEntries(
                    Object.entries(
                        this.variablesLevel3Evaluator.evaluateAll({
                            ...this.nullInitializedVariables,
                            ...preprocessedValues,
                            ...variableResults,
                        }).variables,
                    ).map(([k, [r]]) => [k, r]),
                ),
            };
            variableResults = {
                ...variableResults,
                ...fromEntries(
                    Object.entries(
                        this.variablesLevel4Evaluator.evaluateAll({
                            ...this.nullInitializedVariables,
                            ...preprocessedValues,
                            ...variableResults,
                        }).variables,
                    ).map(([k, [r]]) => [k, r]),
                ),
            };

            const expressionResults = this.expressionsEvaluator.evaluateAll({
                ...preprocessedValues,
                ...variableResults,
            });

            hiddenFieldInstances = getAnyFalsyKeys(expressionResults.visibility);
            disabledFieldInstances = getAnyFalsyKeys(expressionResults.editability);
            availableConcepts = fromEntries(
                Object.entries(expressionResults.availableConcepts || {}).map(([k, [r]]) => {
                    const result = (() => {
                        if (r === '*') {
                            return '*';
                        }
                        if (!Array.isArray(r) || r.some((e) => typeof e !== 'string')) {
                            // log a warning.
                            return '*';
                        }
                        return fromEntries(r.map((e) => [e, true] as [string, true]));
                    })();
                    if (
                        initialValues[`${k}Id`] &&
                        initialValues[`${k}Id`] === valuesWithInitialsAndConcptAvailbtyApplied[`${k}Id`] &&
                        result !== '*'
                    ) {
                        result[initialValues[`${k}Id`]] = true;
                    }
                    return [k, result] as [string, '*' | { [conceptId: string]: true }];
                }),
            );
            dropdownAndRadioAvailableOptions = fromEntries(
                Object.entries(expressionResults.availableOptions || {}).map(([field, results]) => {
                    const optionsEntry: AvailableOptions[0] = {
                        empty: dropdownAndRadioAvailableOptions[field].empty,
                        options: fromEntries(Object.entries(results).map(([k, [v]]) => [k, Boolean(v)])),
                    };
                    return [field, optionsEntry];
                }),
            );

            const initialFilters =
                this.initialFiltersHandler.type === 'allowInitialEvenIfInvalid'
                    ? some(
                          this.initialFiltersHandler.evaluator.evaluateAll({
                              ...variableResults,
                              ...preprocessValues(initialValues),
                          }),
                      )
                    : none;
            nullFilteredRefOneFields = Object.entries(expressionResults.ref1filterExpressions || {}).flatMap(
                ([field, results]) => {
                    const { expression, entityType } = reference1EntityFilterExpressions[field];
                    const evaluatedString = applyToFilterString(expression, results);
                    const filter = getFilterFromFilterString(evaluatedString);
                    const value = valuesWithInitialsAndConcptAvailbtyApplied[field];
                    // BYPASS CONSISTENCY FOR ANY STARTING DATA THAT FAILS FILTER
                    // IF this field's value is the initial value AND the initial value is invalid against the initial form state, then lets ignore.
                    if (value && value === initialValues[field] && initialFilters.isSome()) {
                        const initialFilter = getFilterFromFilterString(
                            applyToFilterString(expression, initialFilters.value[field]),
                        );
                        if (
                            !filterEntityByQueryRepresentation(viewConfig)(initialFilter)(
                                { id: value, entityType },
                                entities,
                            )
                        ) {
                            return []; // Our starting data wasn't consistent, so ONLY in this case, lets ignore that this particular initial value might not be valid.
                        }
                    } // END BYPASS CONSISTENCY
                    if (value && typeof value === 'string') {
                        const filteredOut = !filterEntityByQueryRepresentation(viewConfig)(filter)(
                            { id: value, entityType },
                            entities,
                        );
                        if (filteredOut) {
                            return [field]; // it had a value and was filtered out
                        }
                    } else if (nullFilteredRefOneFields.includes(field)) {
                        return [field]; // don't cause a change if it's hidden because it was filtered on the last pass.
                        // (otherwise this will cause an infinite loop where the field is added and removed to the list)
                    }
                    return [];
                },
            );
            evaluatedRefManyFilters = fromEntries(
                Object.entries(expressionResults.refmanyFilterExpressions || {}).map(([field, results]) => {
                    const { expression, entityType } = referenceManyEntityFilterExpressions[field];
                    const evaluatedString = applyToFilterString(expression, results);
                    const filter = getFilterFromFilterString(evaluatedString);
                    return [field, { entityType, filter }];
                }),
            );
        } while (
            this.expressionsEvaluator.someValueWasRecalculated() ||
            this.variablesEvaluator.someValueWasRecalculated() ||
            this.variablesLevel2Evaluator.someValueWasRecalculated() ||
            this.variablesLevel3Evaluator.someValueWasRecalculated() ||
            this.variablesLevel4Evaluator.someValueWasRecalculated()
        );

        valuesWithInitialsAndConcptAvailbtyApplied = calculateValuesBasedOnAvailableFields();

        const tableRowContexts: {
            [field: string]: ReturnType<typeof evaluateContext2>[];
        } = (() => {
            let _tableRowContexts = {};
            Object.entries(tableExpressions).forEach(([id, evaluationFactors]) => {
                const tableRows = (valuesWithInitialsAndConcptAvailbtyApplied[id] || []).map((rowData) => {
                    // generally, the values fields in table-rows depend on for their filters shouldn't be changing unexpectedly
                    // causing data to be invalid, so it's ok to not allow initially inconsistent data to be kept inside a table.
                    // makes things easier.
                    const evaluatedRowContext = getEvaluateContext({
                        bypassInitialConsitencyForFilters: false,
                        trackInitialState: false,
                    })(
                        evaluationFactors,
                        null,
                        viewConfig,
                        valueSets,
                        {
                            ...Object.assign(
                                {},
                                ...Object.keys(evaluationFactors.fieldWidgets).map((f) => {
                                    return {
                                        [id + '_c_' + f]: typeof rowData[f] === 'undefined' ? null : rowData[f],
                                    };
                                }),
                            ),
                            // by null-initializing values here (instead of adding fields to evaluationFactors.fieldWidgets),
                            // we restrict 'registeredFields' in the TableRowContext but still get null-initialized 'outer scope' values
                            ...entityPreprocessValuesForEval(
                                valuesWithInitialsAndConcptAvailbtyApplied,
                                this.fields,
                                valueset1Fields,
                                entities,
                                options,
                                valueSets,
                            ),
                            ...rowData,
                        },
                        entities,
                        options,
                        {},
                        'allTrue',
                        'allTrue',
                        this.getParserEvaluator(),
                    );
                    // only store row data.
                    return { ...evaluatedRowContext, fieldValues: evaluatedRowContext.registeredValues };
                });
                _tableRowContexts[id] = tableRows;
            });
            return _tableRowContexts;
        })();
        valuesWithInitialsAndConcptAvailbtyApplied = produce(
            valuesWithInitialsAndConcptAvailbtyApplied,
            (draftValues) => {
                Object.entries(tableRowContexts).forEach(([field, rows]) => {
                    draftValues[field] = rows.map((rowContext) => rowContext.registeredValues);
                });
                return draftValues;
            },
        );

        // now that we know the final state of what fields are hidden, we can force the fields null that we need to as a final measure.
        // (we do this in the dowhile loop as well, since this affects our value calculations there, however we don't always make a second pass there,
        // e.g. if no expressions need a second pass to calculate. So we put this here after everything finishes to be sure.)
        valuesWithInitialsAndConcptAvailbtyApplied = produce(
            valuesWithInitialsAndConcptAvailbtyApplied,
            (draftValues) => {
                const fieldsToForceNull = getFieldsToForceNull();
                fieldsToForceNull.forEach((fieldPath) => {
                    // if it's an array, set it to an empty array
                    // "forceNull" should really mean "force to empty value"
                    if (Array.isArray(get(draftValues, fieldPath))) {
                        set(draftValues, fieldPath, []);
                        return;
                    }
                    set(draftValues, fieldPath, null);
                });
            },
        );
        const hiddenFieldsInstancesObj = getObjOfInstancesInList(hiddenFieldInstances);
        const disabledFieldInstancesObj = getObjOfInstancesInList(disabledFieldInstances);

        const visibleAndEditableFieldInstances = (() => {
            const instances: string[] = [];
            Object.values(fieldWidgets).forEach((widgets) => {
                widgets.forEach((f) => {
                    if (!hiddenFieldsInstancesObj[f] && !disabledFieldInstancesObj[f]) {
                        instances.push(f);
                    }
                });
            });
            return instances;
        })();

        const dirtyValues: {} = deepExtend(
            {},
            ...this.fields.map((f) => {
                const v1OrNull = cleanIfObjOrArray(get(initialValues, f, null));
                const v2OrNull = cleanIfObjOrArray(get(valuesWithInitialsAndConcptAvailbtyApplied, f, null));
                // The only table values that should count as 'dirty' should be for visible, editable fields.
                if (tableRowContexts[f]) {
                    // we can't check tableRowContexts[f][i].isDirty because rows don't have determinate initialValues to compare against
                    // instead iterate over all dirty-value paths: if they are all non-editable or undocumented fields, then the value isn't dirty.
                    const isDirtyTableField = (() => {
                        if (isEmpty(v1OrNull) && isEmpty(v2OrNull)) {
                            return false;
                        }
                        if (isEmpty(v1OrNull) || isEmpty(v2OrNull)) {
                            return true;
                        }
                        const dirtyValuePaths = getDirtyPaths(v1OrNull, v2OrNull, true);
                        return dirtyValuePaths.find((path) => {
                            return !isNonEditablePathToConsiderClean(path, tableRowContexts[f]);
                        });
                    })();
                    return isDirtyTableField ? { [f]: get(valuesWithInitialsAndConcptAvailbtyApplied, f) } : {};
                }
                return !equalStrWise(v1OrNull, v2OrNull)
                    ? unflatten({ [f]: get(valuesWithInitialsAndConcptAvailbtyApplied, f) })
                    : {};
            }),
        );
        const registeredValues: {} = unflatten(
            fromEntries(this.fields.map((f) => [f, get(valuesWithInitialsAndConcptAvailbtyApplied, f)])),
        );

        // isDirty is NOT just Object.keys(dirtyValues).length > 0 !!!!
        // we can have dirtyValues that are 'corrections' applied to consistency of the data due to visibility,
        // which are created solely from the initial state of the form, and not from any user action.
        // This is essentially from 'fieldsToForceNull.'
        const isDirty = (() => {
            // traverse dirtyValues - If it only consists of forceNullIfHiddens (which are mapped onto fields which are hidden), then it's dirty.
            // we can assume that means the form is clean,
            // because if it's not hidden from the initialValues provided there must be some other value changed to turn that field hidden,
            // which we would see in dirtyValues.
            const fieldsToForceNull: { [field: string]: true } = getFieldsToForceNull().reduce((prev, curr) => {
                prev[curr] = true;
                return prev;
            }, {});
            let foundSomeDirtyFieldNotForcedNullFromVisibility = false;
            const traverseAcc = (data: unknown, currPath = '') => {
                // I think we can ignore arrays. Just traverse nested objects.
                if (isPlainObject(data)) {
                    Object.entries(data).forEach(([key, data]) => {
                        traverseAcc(data, currPath ? currPath + '.' + key : key);
                    });
                } else if (data === null && fieldsToForceNull[currPath]) {
                    return;
                } else {
                    foundSomeDirtyFieldNotForcedNullFromVisibility = true;
                }
            };
            traverseAcc(dirtyValues);
            return foundSomeDirtyFieldNotForcedNullFromVisibility;
        })();
        const initialFormContext = (() => {
            if (!this.trackInitialState()) {
                return null;
            }
            const initialsResults = this.initialsExpressionsEvaluator.evaluateAll(
                preprocessValues({
                    ...initialValues,
                    ...variableResults,
                }),
            );
            const initialHiddenFieldInstances = getObjOfInstancesInList(getAnyFalsyKeys(initialsResults.visibility));
            const initialDisabledFieldInstances = getObjOfInstancesInList(getAnyFalsyKeys(initialsResults.editability));
            return {
                hiddenFields: initialHiddenFieldInstances,
                disabledFields: initialDisabledFieldInstances,
            };
        })();
        return {
            variables: variableResults,
            availableOptions: dropdownAndRadioAvailableOptions,
            hiddenFields: hiddenFieldsInstancesObj,
            disabledFields: disabledFieldInstancesObj,
            visibleAndEditableFields: visibleAndEditableFieldInstances,
            fieldValues: valuesWithInitialsAndConcptAvailbtyApplied,
            nullFilteredRefOneFields,
            registeredValues,
            dirtyValues,
            isDirty,
            initialValues,
            valuesetFieldAvailableConceptIds: availableConcepts,
            tableRowContexts,
            initialFormContext,
        };
    };
}

/*
 */
const getEvaluateContext =
    (evalOptions: EvalOptions) =>
    (
        evaluationFactors: EvaluationFactors,
        basedOnEntityOptions: {
            basedOnEntity: string;
            fieldsUsedInExpressions: string[];
        } | null,
        // we use this to calculate values based on
        // reference changes if we are in 'entity' context
        viewConfig: ViewConfig,
        valueSets: ValueSets,
        formValues: {},
        entities: { Concept?: {} },
        options: SpelOptions,
        initialValues: {} = {},
        // escape hatch so the value being hidden doesn't force us to use the initial value for the field
        // added because gisIdentifier is hidden, but we want to submit that value when the value is changed
        // by the Address widget.
        visibleWhen: any = 'allTrue',
        visibleWhenArray: any = 'allTrue',
        parserEvaluator: ExpressionEvaluatorContext = getImpl(),
    ) => {
        const { initialFormContext, ...result } = new FormContextEvaluator({
            basedOnEntityOptions,
            evaluationFactors,
            options,
            viewConfig,
            evalOptions,
            parserEvaluator,
        }).evaluate(formValues, valueSets, initialValues, entities);
        return result;
    };

export type TableRowContexts = {
    [field: string]: ReturnType<typeof evaluateContext2>[];
};

export const evaluateContext2 = getEvaluateContext({ bypassInitialConsitencyForFilters: true });
