import GenericMovableGrid from 'dash-editor/GenericEditableGrid';
import renderFieldAdder from 'dash-editor/renderFieldAdder';
import { useWidgets } from 'dashboard2/components/WithWidgets';
import { getDashConfigWithWidgetMap } from 'dashboard2/dashboard-config/load-dashboards/reducer';
import { DashboardConfig } from 'dashboard2/dashboard-config/types';
import WidgetFactory from 'dashboard2/service/WidgetFactory';
import React, { FunctionComponent, useMemo, useCallback } from 'react';

interface DashEditorProps {
    dashConfig: DashboardConfig;
    setDashConfig: (dashConfig: DashboardConfig) => void;
}
const DashEditor: FunctionComponent<DashEditorProps> = (props) => {
    const { dashConfig, setDashConfig } = props;
    const overrideDashConfig = useMemo(() => {
        return getDashConfigWithWidgetMap(dashConfig);
    }, [dashConfig]);
    // const setDashConfig = useCallback((dc: DashboardConfigWithWidgetMap): DashboardConfig => {
    //     return {
    //         ...dc,
    //         widgets: Object.values(dc.widgets),
    //     };
    // }, []);
    const { widgetElements, grid } = useWidgets(overrideDashConfig, false);
    const fields = useMemo(
        () =>
            widgetElements.map((e) => {
                return React.cloneElement(e, {
                    'data-originaldefinition': JSON.stringify({
                        ...JSON.parse(e.props['data-originaldefinition']),
                        ...grid.layouts.md.find((i) => i.i === e.props.id),
                    }),
                });
            }),
        [widgetElements, grid.layouts.md],
    );
    const onLayoutChange = useCallback(
        ({ layout }) => {
            const mdLayout = layout.map(({ 'data-originaldefinition': originalDefinition, w, h, x, y, id }) => ({
                w,
                h,
                x,
                y,
                i: JSON.parse(originalDefinition).id,
            }));
            setDashConfig({
                ...dashConfig,
                grid: {
                    ...dashConfig.grid,
                    layouts: {
                        ...dashConfig.grid.layouts,
                        md: mdLayout,
                    },
                },
                widgets: layout
                    .map((l) => JSON.parse(l['data-originaldefinition']))
                    .map(({ id, type, title, definition }) => ({
                        id,
                        type,
                        title,
                        definition,
                    })),
            });
        },
        [dashConfig, setDashConfig],
    );
    const getLayoutFromElements = useCallback(
        (elems, opts) => {
            return elems.map((elem) => {
                return (
                    elem.props &&
                    elem.props['data-originaldefinition'] && {
                        ...JSON.parse(elem.props['data-originaldefinition']),
                        ...grid.layouts.md.find((i) => i.i === elem.key), //<- this works
                        'data-originaldefinition': elem.props['data-originaldefinition'],
                        content: elem,
                    }
                );
            });
        },
        [grid.layouts.md],
    );
    return (
        grid &&
        widgetElements && (
            <GenericMovableGrid
                newFieldH={3}
                rowHeight={30}
                compactType="vertical"
                autoCalcHeight={false}
                adjustLayoutLeft={false}
                onLayoutChange={onLayoutChange}
                columnStartsAt={0}
                createField={WidgetFactory.create}
                fields={fields}
                getLayoutFromElements={getLayoutFromElements}
                renderFieldAdder={renderFieldAdder}
            />
        )
    );
};

export default DashEditor;
