import React from 'react';
import { Button, TextField } from '@material-ui/core';

type Filter = {
    key: string;
    name: string;
};
const FilterForm = ({
    filter,
    setFilter,
    fetchData,
}: {
    filter: Filter;
    setFilter: (filter: Filter) => void;
    fetchData: () => void;
}) => {
    return (
        <div style={{ marginTop: '1em' }}>
            <form onSubmit={fetchData}>
                <TextField
                    fullWidth
                    InputProps={{
                        inputProps: {
                            'aria-label': 'Name',
                        },
                    }}
                    style={{ maxWidth: 350, marginLeft: '1rem' }}
                    label="Name"
                    onChange={(e) =>
                        setFilter({
                            ...filter,
                            name: e.target.value,
                        })
                    }
                    value={filter.name ?? ''}
                />
                <TextField
                    fullWidth
                    InputProps={{
                        inputProps: {
                            'aria-label': 'Key',
                        },
                    }}
                    style={{ maxWidth: 350, marginLeft: '1rem' }}
                    label="Key"
                    onChange={(e) =>
                        setFilter({
                            ...filter,
                            key: e.target.value,
                        })
                    }
                    value={filter.key ?? ''}
                />
                <div
                    style={{
                        display: 'flex',
                        gap: '.5rem',
                        margin: '1rem',
                    }}
                >
                    <Button variant="contained" color="primary" type="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            setFilter({ name: '', key: '' });
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Clear
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default FilterForm;
