import React, { useContext, useMemo } from 'react';
// this exists for the sake of configuration where we want to know if our entity was opened
// with a process or task at the root.
// See: https://strategicsolutionsgroup.atlassian.net/browse/FISH-2926
export type CasetivityConfigRootViewContextVariable = 'PROCESS' | 'PROCESS_TASK' | 'ENTITY' | 'START_FORM';

// This one exists so we can determine what context to evaluate our expressions in.
// Should be the most recently opened form
// (so an "entity" popup/linked-entity opened from a "task" would be "entity")
// - this is unlike the rootViewContext/CasetivityConfigRootViewContextVariable
export type CurrentViewContext = 'entity' | 'bpm' | 'none';
type AppViewContext = {
    rootViewContext: CasetivityConfigRootViewContextVariable;
    currentViewContext: CurrentViewContext;
};
export const casetivityViewContext = React.createContext<AppViewContext>({
    rootViewContext: 'ENTITY',
    currentViewContext: 'none',
});

export const CasetivityViewContextProvider: React.FC<Partial<AppViewContext>> = (props) => {
    const currAppContext = useContext(casetivityViewContext);
    const { rootViewContext = currAppContext.rootViewContext, currentViewContext = currAppContext.currentViewContext } =
        props;

    const newAppContext = useMemo(() => {
        return {
            rootViewContext,
            currentViewContext,
        };
    }, [rootViewContext, currentViewContext]);
    return <casetivityViewContext.Provider value={newAppContext}>{props.children}</casetivityViewContext.Provider>;
};
// export default casetivityViewContext;
