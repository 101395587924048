import { RootState, useAppSelector } from 'reducers/rootReducer';

const useProcessIsOpen = (processId: string) =>
    useAppSelector((state: RootState) => {
        return (
            processId &&
            state.bpm.processInstances.byId[processId] &&
            !state.bpm.processInstances.byId[processId].endTime
        );
    });
export default useProcessIsOpen;
