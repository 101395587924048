import React from 'react';
import makeAdhocList from '.';
import PureDateTimeField from 'fieldFactory/display/components/DateTimeField';
import Popup from 'components/Popup';
import { Button, IconButton, Card, TableCell, CardActions, CardContent } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import fromEntries from 'util/fromentries';
import { Link } from 'react-router-dom';

type JobRecord = {
    id: string;
    executionId: string;
    processInstanceId: string;
    createdAt: string;
    elementName: string;
    elementId: string;
    processDefinitionId: string;
};
const { AdhocList, AdhocListColumn, getPagedObservable } = makeAdhocList<JobRecord>();

interface JobsListProps {
    type: 'active' | 'dead';
}
const JobsList: React.FunctionComponent<JobsListProps> = (props) => {
    const { type } = props;
    const getDataObservable = React.useCallback(
        (params: { size: number; page: number }) =>
            getPagedObservable(`api/admin/processes/jobs?type=${type}&page=${params.page}&size=${params.size}`),
        [type],
    );

    const processDefinitions = useAppSelector((state: RootState) => state.bpm.processDefinitions.byId);
    const pdidToTitles = React.useMemo(() => {
        return fromEntries(Object.entries(processDefinitions).map(([pdk, entry]) => [entry.id, entry.name]));
    }, [processDefinitions]);

    return (
        <div style={{ width: '100%', margin: '1em' }}>
            <AdhocList
                type="paginated"
                hasRefresh
                pagesNIndexed={0}
                titleOptions={{
                    type: 'Typography',
                    TypographyProps: {
                        variant: 'h5',
                        component: 'div',
                    },
                    text: type === 'active' ? 'Active Jobs' : 'Dead Jobs',
                }}
                defaultSize={10}
                getDataObservable={getDataObservable}
                tableCaption={`${type} jobs`}
            >
                <AdhocListColumn title="Element Name" fieldKey="elementName" />
                <AdhocListColumn
                    title="Process definition"
                    fieldKey="processDefinitionId"
                    renderdata={(pdid) => <TableCell>{pdidToTitles[pdid]}</TableCell>}
                />
                <AdhocListColumn
                    title="Process Instance Id"
                    fieldKey="processInstanceId"
                    renderdata={(piid) => (
                        <TableCell>
                            <Link target="_blank" aria-label="Go to Process Instance Page" to={`/processes/${piid}`}>
                                {piid}
                            </Link>
                        </TableCell>
                    )}
                />
                <AdhocListColumn
                    title="Created At"
                    fieldKey="createdAt"
                    renderdata={(dateStr: string, record: JobRecord, i: number) => {
                        return (
                            <TableCell>
                                <PureDateTimeField record={record} source="createdAt" />
                            </TableCell>
                        );
                    }}
                />
                <AdhocListColumn
                    title="Inspect Action"
                    hideColTitle
                    fieldKey="id"
                    renderdata={(id: string, record: JobRecord, i: number) => {
                        return (
                            <TableCell style={{ padding: 0 }}>
                                <Popup
                                    renderDialogContent={({ closeDialog }) => (
                                        <Card>
                                            <CardContent>
                                                <pre>{JSON.stringify(record, null, 1)}</pre>
                                            </CardContent>
                                            <CardActions>
                                                <Button onClick={closeDialog}>Close</Button>
                                            </CardActions>
                                        </Card>
                                    )}
                                    renderToggler={({ openDialog }) => (
                                        <IconButton aria-label={`inspect record ${id}`} onClick={openDialog()}>
                                            <Search />
                                        </IconButton>
                                    )}
                                />
                            </TableCell>
                        );
                    }}
                />
            </AdhocList>
        </div>
    );
};
export default JobsList;
