import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { useStyles } from 'bpm-casetivity-managed/useStyles';

import React, { useCallback, useEffect } from 'react';
import { services } from 'sideEffect/services';
import useService from 'util/hooks/useService';

const DeleteForm: React.FC<{
    formKey: string;
    onCancel: () => void;
    onDelete: () => void;
}> = (props) => {
    const classes = useStyles();
    const [deletionState, _delete, { StateIcon }] = useService(services.taskFormsDefinitions.delete);
    const isDeleted = deletionState.status === 'success';
    useEffect(() => {
        if (!isDeleted) {
            return;
        }
        const to = setTimeout(props.onDelete, 100);
        return () => clearTimeout(to);
    }, [isDeleted, props.onDelete]);
    const deleteForm = useCallback(() => _delete(props.formKey), [props.formKey, _delete]);
    return (
        <>
            <DialogTitle>Delete {props.formKey}?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Clicking 'Delete' below will delete the <em>latest version</em> of the form.
                </DialogContentText>
                <DialogContentText>
                    This action <u>cannot be reversed.</u>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    disabled={deletionState.status === 'pending'}
                    onClick={props.onCancel}
                    variant="contained"
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    disabled={deletionState.status === 'pending'}
                    onClick={deleteForm}
                    className={classes.error}
                    endIcon={StateIcon ?? <Delete />}
                >
                    Delete
                </Button>
            </DialogActions>
        </>
    );
};

export default DeleteForm;
