import { RootState, useAppSelector } from 'reducers/rootReducer';
const useHasTaskDrawer = () => {
    return useAppSelector(
        (state: RootState) =>
            state.viewConfig &&
            state.viewConfig.application &&
            state.viewConfig.application.name !== 'rvrs' &&
            state.viewConfig.user.login !== 'anonymousUser',
    );
};
export default useHasTaskDrawer;
