import uniq from 'lodash/uniq';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'reducers/rootReducer';
import useViewConfig from 'util/hooks/useViewConfig';
import { getValueSetCodesRequiredForEntity } from '../utils/viewConfigUtils';
import { getEditableViewVSCodeLiteralsSelector } from '../form/valuesetCodeExpressionLiteralsSelector';
import { loadValueSets as loadValueSetsAction } from 'valueSets/actions';
import { useDefaultValuesMeta } from './useDefaultValues';

const useDefaultValuesValuesets = (viewName: string) => {
    const defaultValuesMeta = useDefaultValuesMeta(viewName);
    return useMemo(() => {
        return uniq<string>(
            Object.values(defaultValuesMeta ?? {}).reduce((prev, curr) => {
                Object.values(curr.valuesetFieldsRequired).forEach((valueSet) => {
                    prev.push(valueSet);
                });
                curr.valuesetLiterals.forEach((valueSet) => {
                    prev.push(valueSet);
                });
                return prev;
            }, [] as string[]),
        );
    }, [defaultValuesMeta]);
};
export const useFetchValuesets = (viewName: string) => {
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName].entity;

    const getValueSetCodeLiterals = useMemo(getEditableViewVSCodeLiteralsSelector, []);
    const valueSetCodeLiterals = useAppSelector((state) =>
        getValueSetCodeLiterals(state, {
            viewName,
            resource,
            overrideViewConfig: viewConfig,
        }),
    );
    const defaultValuesValuesets = useDefaultValuesValuesets(viewName);
    useEffect(() => {
        const valuesets = uniq(
            getValueSetCodesRequiredForEntity(viewConfig, viewName)
                .concat(valueSetCodeLiterals)
                .concat(defaultValuesValuesets),
        ).map((valueSetCode) => ({ valueSet: valueSetCode }));
        dispatch(loadValueSetsAction(valuesets));
    }, []); // eslint-disable-line
};

export const FetchValuesets: React.FC<{ viewName: string }> = ({ viewName }) => {
    useFetchValuesets(viewName);
    return null;
};
