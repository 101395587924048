import { Box, Button, Card, CardHeader, IconButton, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import BranchExplorer, { SquashForm, useBranchExplorer, useCurrentBranch } from 'branches/BranchExplorer';
import { useAppSelector } from 'reducers/rootReducer';
import Popup from './Popup';
import { ResolveConflictButton } from 'branches/ResolveConflict';

export const BranchManagementCard = () => {
    const currentBranch = useCurrentBranch();
    const [branchState, setBranch] = useBranchExplorer();
    const basicInfo = useAppSelector((state) => state.basicInfo);

    return (
        basicInfo.showConfigMgmt && (
            <Card style={{ marginBottom: '1em' }}>
                <CardHeader title={'Branch Management'} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography style={{ textAlign: 'center', marginBottom: '1em' }}>
                        {currentBranch ? (
                            <>
                                Your work will be tagged under branch:
                                <Box component="span" fontWeight="fontWeightBold">
                                    {' ' + currentBranch}
                                </Box>
                            </>
                        ) : (
                            'Your work is not being tagged under any branch'
                        )}
                    </Typography>
                    <Popup
                        paperStyle={{
                            minWidth: 'min(600px, 90vw)',
                        }}
                        renderDialogContent={({ closeDialog }) => (
                            <div style={{ margin: '1em' }}>
                                <div style={{ float: 'right' }}>
                                    <IconButton size="small" onClick={closeDialog}>
                                        <Clear />
                                    </IconButton>
                                </div>
                                <BranchExplorer
                                    onChangeBranch={() => {
                                        closeDialog();
                                    }}
                                />
                            </div>
                        )}
                        renderToggler={({ openDialog }) => {
                            const mergeText = currentBranch ? 'Merge Branch' : 'Merge default branch to stable';
                            const switchText = currentBranch ? 'Switch Branch' : 'Switch to a working branch';
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                        alignContent: 'center',
                                        margin: '1em',
                                    }}
                                >
                                    <Popup
                                        renderToggler={({ openDialog }) => (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={openDialog()}
                                                style={{ margin: '0 8px' }}
                                            >
                                                {mergeText}
                                            </Button>
                                        )}
                                        renderDialogContent={({ closeDialog }) => (
                                            <SquashForm
                                                onSuccess={() => {
                                                    closeDialog();
                                                    setBranch(null);
                                                }}
                                                Actions={
                                                    <IconButton size="small" onClick={closeDialog}>
                                                        <Clear />
                                                    </IconButton>
                                                }
                                            />
                                        )}
                                    />

                                    <Button
                                        onClick={openDialog()}
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: '0 8px' }}
                                    >
                                        {switchText}
                                    </Button>
                                    <div style={{ margin: '0 8px' }}>
                                        <ResolveConflictButton />
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            </Card>
        )
    );
};
