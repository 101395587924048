import uniq from 'lodash/uniq';
import { schema } from 'normalizr';
import ViewConfig, { EntityField } from '../reducers/ViewConfigType';
import createUnionSchema, { WILDCARD_ENTITY } from './createUnionSchema';

/*
    Step 1: Create an entity schema for each individual entity
    Step 2. Create relationships TO each of these entities.
    Step 3. Define the entities in step 1. with the relationships in step 2
*/

/*
    Note: assumes entity name is the same as its index
    @param Exceptions: list of entities we DON'T want to denormalize
*/
const printError = console.log.bind(console);
const buildSchemaFromViewConfig = (viewConfig: ViewConfig, exceptions: string[] = [], errorReporter = printError) => {
    const entitySchemas = {};
    const relationshipSchemas = {};
    Object.values(viewConfig.entities).forEach((e) => {
        entitySchemas[e.name] = new schema.Entity(e.name);
    });
    const unionSchema = createUnionSchema(Object.values(entitySchemas));
    entitySchemas[WILDCARD_ENTITY] = unionSchema;

    // now entitySchemas is loaded with each of our entities.

    // now iterate over all relationships
    const fields = [].concat.apply(
        // 1 dimensional array of all fields
        [],
        Object.values(viewConfig.entities).map((e) => Object.values(e.fields)),
    );

    // all unique relationships in form REL_TYPE:entity
    const relationships: string[] = uniq<string>(
        fields
            .filter(
                (f) =>
                    (f.calcType !== 'CALC_DYNAMIC' && f.dataType === 'REFONE') ||
                    f.dataType === 'REFONE_JOIN' ||
                    f.dataType === 'REFMANY' ||
                    f.dataType === 'REFMANYMANY' ||
                    f.dataType === 'REFMANY_JOIN' ||
                    f.dataType === 'VALUESET' ||
                    f.dataType === 'VALUESETMANY',
            )
            .map((f) => `${f.dataType}:${f.relatedEntity}`),
    );

    // add all arrays to relationshipSchemas
    relationships
        .filter((f) => f.startsWith('REFMANY') || f.startsWith('VALUESETMANY'))
        .forEach((ref: string) => {
            const schemaName = ref.split(':')[1];
            const entitySchema = entitySchemas[schemaName];
            relationshipSchemas[ref] = new schema.Array(entitySchema);
        });

    // now for each entitySchema, defined in terms of relationships
    Object.entries(entitySchemas)
        .filter(([key, entitySchema]) => key !== WILDCARD_ENTITY)
        .forEach(([key, entitySchema]) => {
            const definition = {};
            Object.values(viewConfig.entities[key].fields)
                // .filter(f => f.name !== 'xxxxx' && f.name !== 'mergeRecords')
                .forEach((f: EntityField) => {
                    if (
                        (f.calcType !== 'CALC_DYNAMIC' && f.dataType === 'REFONE') ||
                        f.dataType === 'REFONEJOIN' ||
                        f.dataType === 'VALUESET'
                    ) {
                        if (f.relatedEntity) {
                            definition[f.name] = entitySchemas[f.relatedEntity];
                        } else {
                            errorReporter(
                                'expected relatedEntity in REFONE/REFONE_JOIN/VALUESET field ' + JSON.stringify(f),
                            );
                        }
                    }
                    if (
                        f.dataType === 'REFMANY' ||
                        f.dataType === 'REFMANYMANY' ||
                        f.dataType === 'REFMANYJOIN' ||
                        f.dataType === 'VALUESETMANY'
                    ) {
                        if (f.relatedEntity) {
                            definition[f.name] = relationshipSchemas[`${f.dataType}:${f.relatedEntity}`];
                        } else {
                            errorReporter(
                                'expected relatedEntity in REFMANY/REFMANY_JOIN/VALUESETMANY field ' +
                                    JSON.stringify(f),
                            );
                        }
                    }
                });
            if (exceptions.indexOf(key) === -1) {
                // allow exceptions.
                entitySchemas[key].define(definition);
            }
        });

    delete entitySchemas[WILDCARD_ENTITY];
    return entitySchemas;
};
export default buildSchemaFromViewConfig;
