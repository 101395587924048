import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';
import React from 'react';

interface FieldTitleProps {
    label: string;
    isRequired?: boolean;
    appendScreenreaderText?: string;
}

export const EvaluateLabel = (props: {
    label?: string;
    children: (props: { templatedLabel?: string }) => JSX.Element;
}) => {
    const templatedLabel = useEvaluateTemplateInFormContext(props.label);
    return props.children({ templatedLabel });
};

export const FieldTitle: React.FC<any> = React.memo(
    ({ label: _label, isRequired, appendScreenreaderText }: FieldTitleProps) => {
        const label = useEvaluateTemplateInFormContext(_label);
        if (!label) {
            return null;
        }
        const appendElem = appendScreenreaderText ? (
            <span className="casetivity-off-screen">{appendScreenreaderText}</span>
        ) : null;
        return label.endsWith('*') ? (
            <span>
                {label.slice(0, -1)}
                <abbr title="Required">*</abbr>
                {appendElem}
            </span>
        ) : isRequired ? (
            <span>
                {label} <abbr title="Required">*</abbr>
                {appendElem}
            </span>
        ) : (
            <span>
                {label} {appendElem}
            </span>
        );
    },
);

export default FieldTitle;
