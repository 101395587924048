import { Dashboard } from 'dashboard2/dashboard-config/types';
import { useState, useCallback } from 'react';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';

type SubmissionState =
    | {
          type: 'SUCCESS';
      }
    | { type: 'NOT_SUBMITTING' }
    | { type: 'SUBMITTING' }
    | {
          type: 'ERROR';
          error: AjaxError;
      };

const useSubmitDash = () => {
    const [state, setState] = useState<SubmissionState>({ type: 'NOT_SUBMITTING' });
    const submit = useCallback(
        (dash: Partial<Dashboard>) => {
            setState({ type: 'SUBMITTING' });
            const $ajax = services.saveDashboard(dash);
            const subscription = $ajax.subscribe(
                (res) => {
                    if (res.status >= 200 && res.status < 300) {
                        setState({ type: 'SUCCESS' });
                    } else {
                        setState({ type: 'NOT_SUBMITTING' });
                    }
                },
                (error: AjaxError) => {
                    setState({ type: 'ERROR', error });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        },
        [setState],
    );
    return [state, submit] as const;
};

export default useSubmitDash;
