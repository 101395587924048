import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../src/reducers/rootReducer';

export interface CurrProcTaskSearchProps {
    processId: string;
    render: (arg: { currentSearch: string | null }) => JSX.Element | null;
}
const makeMapStateToProps = () => {
    const mapStateToProps = (state: RootState, props: CurrProcTaskSearchProps) => {
        return {
            currentSearch: state.printMode // clear out search so we can reset 'perPage'
                ? undefined
                : (props.processId && state.bpm.currentProcessTaskSearch[props.processId]) || null,
        };
    };
    return mapStateToProps;
};
interface CurrProcTaskSearchComponentProps
    extends CurrProcTaskSearchProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>> {}

class CurrProcTaskSearchComponent extends React.Component<CurrProcTaskSearchComponentProps> {
    render() {
        const { render, currentSearch } = this.props;
        return render({ currentSearch });
    }
}

const CurrProcTaskSearch: React.ComponentType<CurrProcTaskSearchProps> =
    connect(makeMapStateToProps)(CurrProcTaskSearchComponent);

export default CurrProcTaskSearch;
