import React from 'react';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';
import DeferredSpinner from 'components/DeferredSpinner';
import ExperimentalReportEditor from './ExperimentalReportEditor';
import useReportDefinition from 'report2/hooks/useReport';

interface Report {
    reportName: string;
}
const Report = ({ reportName }) => {
    const [state, refresh] = useReportDefinition(reportName);
    switch (state.type) {
        case 'INITIAL':
            return null;
        case 'LOADING':
            return <DeferredSpinner />;
        case 'FAILURE':
            if (state.error?.status) {
                return <ServerError code={state.error.status} message={state.error.message} />;
            }
            return <NetworkUnavailable retry={refresh} />;
        case 'SUCCESS':
            if (!state.data?.reportDefinitionDTO) {
                throw new Error('Successful request should return data with reportDefinitionDTO property.');
            }
            const reportDefinition = {
                ...state.data.reportDefinitionDTO,
                fields: state.data.params,
                config: state.data.reportDefinitionDTO.config
                    ? JSON.parse(state.data.reportDefinitionDTO.config)
                    : null,
            };
            return <ExperimentalReportEditor initialReportDefinition={reportDefinition} />;
    }
};
export default Report;
