import { StaticType, Types } from 'ace-editor/util/evalStatically/evalStatically';
import { ReflectionKind } from 'typedoc/dist/lib/models/reflections/kind';
import { ContainerReflection, DeclarationReflection } from 'typedoc/dist/lib/serialization/schema';

export const figureName = (obj: DeclarationReflection): string => {
    const name = obj.name;
    switch (obj.kind) {
        case ReflectionKind.Property:
        case ReflectionKind.Class: {
            return name;
        }
        case ReflectionKind.Method: {
            if (obj.signatures?.[0]?.parameters) {
                return name + '(';
            }
            return name + '()';
        }
    }
    return name;
};

export const assignIntrinsicType = (obj: DeclarationReflection): StaticType => {
    let type: 'number' | 'string' | 'boolean' | null = null;
    switch (obj.kind) {
        case ReflectionKind.Property:
            if (hasIntrinsicName(obj.type)) {
                type = obj.type.name;
            }
            break;
        case ReflectionKind.Method:
            if (obj.signatures?.[0]?.type && hasIntrinsicName(obj.signatures[0].type)) {
                type = obj.signatures[0].type.name;
            }
            break;
    }

    switch (type) {
        case 'number':
            return Types.number;
        case 'string':
            return Types.string(false);
        case 'boolean':
            return Types.boolean;
        default:
            return Types.unknown;
    }
};

export const filterFind = (
    node: ContainerReflection | DeclarationReflection,
    cb: (node: DeclarationReflection | ContainerReflection) => boolean,
): DeclarationReflection | null => {
    if (cb(node) && node.kindString) {
        return node as DeclarationReflection;
    }
    for (let key in node) {
        if (typeof node[key] === 'object' && node[key] !== null) {
            const found = filterFind(node[key], cb);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

export const hasNumericId = <T>(obj: T): obj is T & { id: number } => {
    return typeof obj['id'] === 'number';
};

export const hasIntrinsicName = <T>(obj: T): obj is T & { name: 'string' | 'number' | 'boolean' } => {
    return typeof obj['name'] === 'string';
};

export const hasStaticValue = (obj: any): obj is { staticValue: any } => {
    return !!obj?.staticValue;
};

export const isGeneric = (objType: DeclarationReflection, parentClass: DeclarationReflection) => {
    if (parentClass.typeParameters) {
        const parameterNames = parentClass.typeParameters.map((type) => type.name);
        if ('name' in objType) {
            const returnType = objType.name;
            if (parameterNames.includes(returnType)) {
                return true;
            }
        }
    }

    return false;
};
