import { Theme, ThemeOptions } from '@mui/system';
import updateRwtStylesheet from './rwtStyleInjector';
import { TextFieldProps, colors } from '@mui/material';
import { grey } from '@mui/material/colors';

type color = keyof typeof colors;
const getInitialV5Theme = (
    themeCreator: (themeInit: ThemeOptions) => Theme,
    args: {
        primary: color;
        secondary: color;
        error: color;
        darknessOverrides?: {
            primary?: any;
            secondary?: any;
            error?: any;
        };
        darkMode?: boolean;
        forceLabelShrink?: boolean;
        fieldVariant?: TextFieldProps['variant'];
        fontFamily?: string;
    },
    rebuildRWTStylesheet: boolean = false,
) => {
    const {
        primary,
        secondary,
        error,
        darknessOverrides = {},
        darkMode = false,
        forceLabelShrink,
        fieldVariant,
        fontFamily,
    } = args;

    const errorColor = colors[error];

    type ColorKey = keyof typeof colors.amber;
    const getRange = (attr?: keyof typeof darknessOverrides): [ColorKey, ColorKey, ColorKey] => {
        const normalizeColor = (override: number) => {
            return Math.min(Math.max(50, override), 100);
        };

        const override = darknessOverrides[attr];
        if (override) {
            if (typeof override === 'string' && override.startsWith('A')) {
                const aKeys: (keyof typeof colors.amber)[] = ['A100', 'A200', 'A400', 'A700'];
                return [aKeys.find((k) => k <= override), override as ColorKey, aKeys.find((k) => k >= override)];
            }
            return [normalizeColor(override - 100) as ColorKey, override, normalizeColor(override + 100) as ColorKey];
        }
        switch (attr) {
            case 'error':
                return [600, 900, 900];
            case 'primary':
                return [800, 900, 900];
            case 'secondary':
                return [300, 500, 700];
        }
    };
    const primaryIx = getRange('primary');
    const secondaryIx = getRange('secondary');
    const errorIx = getRange('error');
    const v5Palette = {
        mode: darkMode ? ('dark' as const) : 'light',
        ...(!darkMode
            ? {
                  primary: (() => {
                      if (primary.startsWith('#')) {
                          return {
                              main: primary,
                          };
                      }
                      const primaryColor = colors[primary];
                      return {
                          light: primaryColor[primaryIx[0]],
                          main: primaryColor[primaryIx[1]],
                          dark: primaryColor[primaryIx[2]],
                      };
                  })(),
                  secondary: (() => {
                      if (secondary.startsWith('#')) {
                          return {
                              main: secondary,
                          };
                      }
                      const secondaryColor = colors[secondary];
                      return {
                          light: secondaryColor[secondaryIx[0]],
                          main: secondaryColor[secondaryIx[1]],
                          dark: secondaryColor[secondaryIx[2]],
                      };
                  })(),
                  error: {
                      light: errorColor[errorIx[0]],
                      main: errorColor[errorIx[1]],
                      dark: errorColor[errorIx[2]],
                  },
                  warning: {
                      main: '#ED6C02',
                  },
              }
            : {
                  ///here lies darkmode
                  primary: { main: grey[200], light: grey[300], dark: grey[300] },

                  secondary: {
                      main: grey[300],
                      light: grey[200],
                      dark: grey[400],
                  },

                  error: {
                      main: colors[error][700],
                  },
                  info: {
                      main: grey[300],
                      primary: grey[400],
                      secondary: grey[300],
                  },

                  warning: {
                      main: '#ED6C02',
                  },
              }),
    };

    if (rebuildRWTStylesheet) {
        updateRwtStylesheet(v5Palette.primary.main);
    }

    const greyInputBackground =
        !darkMode && (!fieldVariant || fieldVariant === 'standard') && forceLabelShrink !== false;
    const themeV5Init = {
        palette: v5Palette,
        typography: {
            fontFamily,
        },
        components: {
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        zIndex: 'unset' as const,
                    },
                },
            },
            MuiOutlinedInput: {
                notched: forceLabelShrink ? true : undefined,
                styleOverrides: {
                    input: {
                        '& .Mui-disabled': {
                            color: '#595959',
                        },
                    },
                    root: {
                        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#595959',
                            borderStyle: 'dotted',
                        },
                    },
                },
            },
            MuiTextField: {
                variant: fieldVariant,
                InputLabelProps: {
                    shrink: forceLabelShrink,
                },
            },

            /// these are the v4 to v5 converted overrides
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        '&.Mui-disabled': {
                            color: colors.grey[600],
                        },
                    },
                    label: {
                        textTransform: 'capitalize' as 'capitalize',
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        overflow: 'unset', // prevents dropdowns from getting cut off
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: greyInputBackground
                    ? {
                          /*
                    Explains why "focused: { color: 'red' }" doesn't work as an override here.
                    https://github.com/mui-org/material-ui/issues/11244#issuecomment-386792253
                */
                          root: {
                              color: grey[900], // dark label by default
                              '&.Mui-disabled': {
                                  color: grey[900],
                              },
                              '&.Mui-focused': {
                                  color: v5Palette.primary.main, // primary color when focused.
                              },
                              '&.Mui-error': {
                                  color: grey[900], // dark label by default
                              },
                          },
                      }
                    : fieldVariant === 'outlined'
                    ? {
                          root: {
                              '&.Mui-disabled': {
                                  color: '#595959',
                              },
                          },
                      }
                    : undefined,
            },
            MuiMenuItem: {
                styleOverrides: greyInputBackground
                    ? {
                          root: {
                              '&:focus': {
                                  backgroundColor: '#DEDEDE',
                              },
                          },
                      }
                    : undefined,
            },
            MuiInput: {
                styleOverrides: greyInputBackground
                    ? {
                          root: {
                              backgroundColor: grey[100],
                              '&.Mui-disabled': {
                                  backgroundColor: 'transparent',
                              },
                          },
                          underline: {
                              '&:hover:not(.disabled):not(.error):not(.focused):before': {
                                  borderBottom: '2px solid',
                                  borderBottomColor: v5Palette.primary.main,
                              },
                              '&:after': {
                                  borderBottom: '2px solid',
                              },
                          },
                      }
                    : undefined,
            },
            MuiInputBase: {
                styleOverrides: greyInputBackground
                    ? {
                          root: {
                              '&.Mui-disabled': {
                                  color: 'rgba(0, 0, 0, 0.65)',
                              },
                          },
                      }
                    : undefined,
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        '&.Mui-error': {
                            whiteSpace: 'pre-wrap',
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    paddingNone: {
                        paddingLeft: '3px',
                        paddingRight: '3px',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    outlined: {
                        top: 0,
                    },
                    filled: {
                        top: '-.1em',
                    },
                    // this should work
                    // color: darkMode ? grey[200] : undefined,
                },
            },
        },
        props: {},
    };
    const V5Theme = themeCreator(themeV5Init);

    return V5Theme;
};

export default getInitialV5Theme;
