import React from 'react';
import BpmFormBuilderField from './internal/InternalComponent';
import { bpmFormBuilderContext } from './internal/bpmFormBuilderContext';

const BpmFormBuilderProvider = (props) => {
    return (
        <bpmFormBuilderContext.Provider value={BpmFormBuilderField}>{props.children}</bpmFormBuilderContext.Provider>
    );
};
export default BpmFormBuilderProvider;
