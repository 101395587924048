import ViewConfig from 'reducers/ViewConfigType';
import * as widgetTypes from 'components/generics/utils/widgetTypes';
import { getDataTypeForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import * as fieldTypes from 'fieldFactory/fieldTypes';
import getTypeFromEntityField from 'fieldFactory/translation/fromEntity/getTypeFromEntityField';
import filterPropertiesToSchemaDefined from 'dash-editor/util/filterPropertiesToSchemaDefined';
import merge from 'lodash/merge';
import { createPrecompiledValidator } from '@rjsf/validator-ajv8';

const edit_text = require('./configuration_schemas/edit/text.json');
const edit_property_field = require('./configuration_schemas/edit/property-field.json');
const translatable = require('./configuration_schemas/show/translatable.json');
const noWrappable = require('./configuration_schemas/show/noWrap.json');
const width = require('./configuration_schemas/show/width.json');
const group = require('./configuration_schemas/edit/group.json');
const valueSet = require('./configuration_schemas/edit/valueSet.json');
const defaultCode = require('./configuration_schemas/edit/defaultCode.json');
const defaultCodes = require('./configuration_schemas/edit/defaultCodes.json');

const direction = require('./configuration_schemas/both/direction.json');
const deselectable = require('./configuration_schemas/edit/deselectable.json');
const labelledBy = require('./configuration_schemas/both/labelledBy.json');
const filter = require('./configuration_schemas/both/filter.json');
const openTo = require('./configuration_schemas/both/openTo.json');
const viewNames = require('./configuration_schemas/both/viewNames.json');
const hasCreateEdit = require('./configuration_schemas/both/hasCreateEdit.json');
const createViewName = require('./configuration_schemas/both/createViewName.json');
const editShowViewNames = require('./configuration_schemas/both/editShowViewNames.json');
const reference_tables = require('./configuration_schemas/both/reference_tables.json');
const hidePaginationIfNotNeeded = require('./configuration_schemas/both/hidePaginationIfNotNeeded.json');
const tooltipText = require('./configuration_schemas/show/tooltipText.json');
const checkbox = require('./configuration_schemas/edit/checkbox.json');
const inlineMany = require('./configuration_schemas/edit/inlineMany.json');
const noSearch = require('./configuration_schemas/edit/noSearch.json');
const editxmany = require('./configuration_schemas/edit/xmany.json');
const booleanDefaultValue = require('./configuration_schemas/edit/booleanDefaultValue.json');
const sortable = require('./configuration_schemas/show/sortable.json');

sortable['validator'] = require('./configuration_schemas/show/compiledSchemas/sortable');
sortable['validator'] = createPrecompiledValidator(sortable['validator'] as any, JSON.parse(sortable.schema));

const listFilter = require('./configuration_schemas/both/listFilter.json');
const entityTypeahead = require('./configuration_schemas/edit/entityTypeahead.json');
const cellAlign = require('./configuration_schemas/show/cellAlign.json');
const disabledDisplayHtml = require('./configuration_schemas/edit/disabledDisplayHtml.json');
const searchField_boolean = require('./configuration_schemas/edit/searchField_boolean.json');
const searchField_nonBoolean = require('./configuration_schemas/edit/searchField_nonBoolean.json');
const addressVerification = require('./configuration_schemas/edit/addressVerification.json');
const defineSpelVariables = require('./configuration_schemas/both/adhocSpelVariables.json');
const component = require('./configuration_schemas/both/component.json');
const bpmForm = require('./configuration_schemas/both/bpmForm.json');
const manymanybuttons = require('./configuration_schemas/edit/manymanybuttons.json');
const selectOnlyFilteredResult = require('./configuration_schemas/edit/selectOnlyFilteredResult.json');

type FT = typeof fieldTypes;
type JsonSchemaFormSchema = { schema: string; uiSchema: string };
type ConfigurationSchemasByFieldType = {
    [key in FT[keyof FT]]?: Partial<JsonSchemaFormSchema>[];
};
type ReducedConfigurationSchemasByFieldType = {
    [key in FT[keyof FT]]?: Partial<JsonSchemaFormSchema>;
};

const transformations: ((
    mode: 'EDIT' | 'SHOW' | 'CREATE' | 'SORTABLE' | 'SEARCH',
    fieldType: FT[keyof FT],
    sch: JsonSchemaFormSchema,
) => JsonSchemaFormSchema)[] = [];

const mergeSchemas = (sch1: JsonSchemaFormSchema, sch2: JsonSchemaFormSchema): JsonSchemaFormSchema => {
    const { schema: schema1 = '{}', uiSchema: uiSchema1 = '{}' } = sch1;
    const { schema: schema2 = '{}', uiSchema: uiSchema2 = '{}' } = sch2;
    const parsedSchema1 = JSON.parse(schema1);
    const parsedSchema2 = JSON.parse(schema2);
    const mergedSchema = merge(parsedSchema1, parsedSchema2);
    const mergedUiSchema = (() => {
        const sch1 = JSON.parse(uiSchema1);
        const sch2 = JSON.parse(uiSchema2);
        const uiSchemaRes = merge(merge({}, sch1), sch2);
        Object.keys(uiSchemaRes)
            .filter((k) => !k.startsWith('ui:'))
            .forEach((k) => {
                if (
                    // the uiSchema entry was written from uiSchema1, but refers to an overwritten property
                    parsedSchema1?.properties?.[k] &&
                    parsedSchema2?.properties?.[k] &&
                    sch1[k] &&
                    !sch2[k]
                ) {
                    delete uiSchemaRes[k];
                }
            });
        uiSchemaRes['ui:order'] = [...(sch1['ui:order'] ?? []), ...(sch2['ui:order'] ?? [])];
        return uiSchemaRes;
    })();
    return {
        schema: JSON.stringify(mergedSchema),
        uiSchema: JSON.stringify(mergedUiSchema),
    };
};

const combineSchemas = (...schemas: JsonSchemaFormSchema[]): JsonSchemaFormSchema => {
    return schemas.reduce(mergeSchemas, {} as JsonSchemaFormSchema);
};

transformations.push(
    (mode, fieldType, schema) => {
        /**
         * fields operating on data fields on the root record in EDIT views.
         * have options:
         * nullIfHidden and nullIfDisabled
         */
        const exceptFields: FT[keyof FT][] = [
            'expression',
            'html-expression',
            'refmany_join_multiselect',
            'MULTI_CARD',
            'refmanymultiselect',
            'INLINE_MANY',
            'COMPONENT',
        ];
        if (!exceptFields.includes(fieldType)) {
            if (mode === 'CREATE') {
                if (fieldType === 'valueset_multiselect') {
                    return combineSchemas(edit_property_field, defaultCodes, schema);
                }
                if (fieldType === 'valueset-single-box' || fieldType === 'valueset_select') {
                    return combineSchemas(edit_property_field, defaultCode, schema);
                }
                if (fieldType === 'checkbox') {
                    return combineSchemas(edit_property_field, schema, booleanDefaultValue);
                }
                return mergeSchemas(edit_property_field, schema);
            }
            if (mode === 'EDIT') {
                return mergeSchemas(edit_property_field, schema);
            }
            if (mode === 'SEARCH') {
                if (fieldType === 'boolean' || fieldType === 'nullable-boolean' || fieldType === 'checkbox') {
                    return combineSchemas(tooltipText, searchField_boolean, schema);
                }
                return combineSchemas(tooltipText, searchField_nonBoolean, schema);
            }
            return mergeSchemas(tooltipText, schema);
        }
        return schema;
    },
    (mode, fieldType, schema) => {
        if (mode !== 'SORTABLE' && mode !== 'SEARCH') {
            return mergeSchemas(labelledBy, schema);
        }
        return schema;
    },
    (mode, fieldType, schema) => {
        if (mode === 'SORTABLE') {
            return mergeSchemas(schema, sortable);
        }
        return schema;
    },
    (mode, fieldType, schema) => {
        if (mode === 'SORTABLE') {
            return mergeSchemas(schema, cellAlign);
        }
        return schema;
    },
);

const configurationSchemas: {
    EDIT: ReducedConfigurationSchemasByFieldType;
    SHOW: ReducedConfigurationSchemasByFieldType;
    CREATE: ReducedConfigurationSchemasByFieldType;
    SORTABLE: ReducedConfigurationSchemasByFieldType;
    SEARCH: ReducedConfigurationSchemasByFieldType;
} = (() => {
    const schms: {
        EDIT: ConfigurationSchemasByFieldType;
        SHOW: ConfigurationSchemasByFieldType;
        CREATE: ConfigurationSchemasByFieldType;
        SORTABLE: ConfigurationSchemasByFieldType;
        SEARCH: ConfigurationSchemasByFieldType;
    } = (() => {
        /**
         * Fields, on entities, for which we want to include at least a basic jsonschema form.
         */
        const baseFields: ConfigurationSchemasByFieldType = {
            INLINE_MANY: [],
            MANYMANY_MULTI_CARD: [],
            MULTI_CARD: [],
            UST_DAY: [],
            boolean: [],
            checkbox: [],
            currency: [],
            'date-time': [],
            date: [],
            email: [],
            entitytypeahead: [],
            float: [],
            integer: [],
            'multiline-text': [],
            'multiple-entity-typeahead': [],
            'nullable-boolean': [],
            percent: [],
            refmany_join_multiselect: [],
            refmanychip: [],
            'refmanymultiselect-idlist': [],
            refmanymultiselect: [],
            refone_join_select: [],
            refone_select: [],
            text: [],
            time: [],
            toggle: [],
            'valueset-multi-box': [],
            'valueset-single-box': [],
            'valueset-suggest': [],
            valueset_multiselect: [],
            valueset_select: [],
        };
        const EDIT: ConfigurationSchemasByFieldType = {
            ...baseFields,
            text: [edit_text],
            'valueset-suggest': [group, valueSet],
            'multiline-text': [],
            'valueset-single-box': [direction, deselectable, group],
            radio: [direction],
            valueset_multiselect: [group],
            valueset_select: [group, selectOnlyFilteredResult],
            'valueset-multi-box': [direction, group],
            MULTI_CARD: [filter, openTo, viewNames, hasCreateEdit],
            MANYMANY_MULTI_CARD: [filter, listFilter, openTo, viewNames],
            'refmanymultiselect-idlist': [
                filter,
                listFilter,
                openTo,
                viewNames,
                createViewName,
                manymanybuttons,
                defineSpelVariables,
            ],
            refmanymultiselect: [
                filter,
                openTo,
                viewNames,
                createViewName,
                hasCreateEdit,
                reference_tables,
                defineSpelVariables,
                editxmany,
            ],
            refmany_join_multiselect: [
                filter,
                openTo,
                viewNames,
                createViewName,
                hasCreateEdit,
                reference_tables,
                defineSpelVariables,
                editxmany,
            ],
            entitytypeahead: [filter, viewNames, createViewName, entityTypeahead, selectOnlyFilteredResult],
            'multiple-entity-typeahead': [filter, viewNames, createViewName],
            checkbox: [checkbox],
            refone_select: [
                viewNames,
                createViewName,
                noSearch,
                filter,
                openTo,
                disabledDisplayHtml,
                selectOnlyFilteredResult,
            ],
            INLINE_MANY: [filter, openTo, viewNames, inlineMany],
            'address-verification2': [addressVerification],
            COMPONENT: [component],
            BPM_FORM: [bpmForm],
        };
        const SHOW: ConfigurationSchemasByFieldType = {
            ...baseFields,
            text: [translatable],
            'valueset-suggest': [translatable],
            refone_select: [editShowViewNames, openTo, disabledDisplayHtml],
            refone_join_select: [editShowViewNames],
            entitytypeahead: [editShowViewNames],
            'multiline-text': [noWrappable],
            'valueset-multi-box': [direction, group],
            'valueset-single-box': [direction, group],
            refmany_join_multiselect: [
                filter,
                openTo,
                viewNames,
                createViewName,
                hasCreateEdit,
                reference_tables,
                defineSpelVariables,
            ],
            refmanymultiselect: [
                filter,
                openTo,
                viewNames,
                createViewName,
                hasCreateEdit,
                reference_tables,
                defineSpelVariables,
            ],
            'refmanymultiselect-idlist': [
                listFilter,
                viewNames,
                createViewName,
                openTo,
                hidePaginationIfNotNeeded,
                defineSpelVariables,
            ],
            image: [width],
            MULTI_CARD: [editShowViewNames, openTo, hasCreateEdit, hidePaginationIfNotNeeded],
            refmanychip: [editShowViewNames, openTo],
            COMPONENT: [component],
            BPM_FORM: [bpmForm],
        };
        return {
            EDIT,
            CREATE: EDIT,
            SHOW,
            SORTABLE: SHOW,
            SEARCH: EDIT,
        };
    })();

    const resultSchms: {
        EDIT: ReducedConfigurationSchemasByFieldType;
        SHOW: ReducedConfigurationSchemasByFieldType;
        CREATE: ReducedConfigurationSchemasByFieldType;
        SORTABLE: ReducedConfigurationSchemasByFieldType;
        SEARCH: ReducedConfigurationSchemasByFieldType;
    } = {
        EDIT: {},
        SHOW: {},
        CREATE: {},
        SORTABLE: {},
        SEARCH: {},
    };
    (['EDIT', 'SHOW', 'CREATE', 'SORTABLE', 'SEARCH'] as const).forEach((mode) => {
        Object.entries(schms[mode]).forEach(([fieldType, schemas]: [FT[keyof FT], JsonSchemaFormSchema[]]) => {
            // first, lets compose the provided schemas.

            const schema = schemas.reduce((prev, currSchema) => {
                return mergeSchemas(prev, currSchema);
            }, {} as JsonSchemaFormSchema);

            // now lets provide any broad transformations that are better to do via code than listing them in every single field entry.
            resultSchms[mode][fieldType] = transformations.reduce((prev, currFn) => {
                return currFn(mode, fieldType, prev);
            }, schema);
        });
    });
    return resultSchms;
})();

export const filterConfigurationValueToCurrentType = (props: {
    rootEntity: string;
    fieldPath: string;
    viewConfig: ViewConfig;
    widgetType: (typeof widgetTypes)[keyof typeof widgetTypes];
    mode: 'EDIT' | 'SHOW' | 'SORTABLE' | 'CREATE' | 'SEARCH';
}) => {
    const { viewConfig, rootEntity, fieldPath, widgetType, mode } = props;
    const foundSchema: JsonSchemaFormSchema = (() => {
        let dataType;
        let fieldType: ReturnType<typeof getTypeFromEntityField>;
        if (widgetType === 'COMPONENT') {
            fieldType = 'COMPONENT';
        } else {
            try {
                dataType = getDataTypeForFieldExpr(viewConfig, rootEntity, fieldPath, 'TRAVERSE_PATH');
                fieldType = getTypeFromEntityField(widgetType, dataType, fieldPath);
            } catch (e) {}
        }
        return fieldType && (configurationSchemas[mode]?.[fieldType] as JsonSchemaFormSchema);
    })();

    const filterValue = (t) => {
        if (!foundSchema?.schema) {
            return t;
        }
        return filterPropertiesToSchemaDefined(JSON.parse(foundSchema.schema))(t);
    };
    return [foundSchema, filterValue] as const;
};
