export const ADDRESS_VERIFICATION = 'address';
export const DATE_TIME = 'datetime';
export const TEXT = 'text';
export const MULTILINE_TEXT = 'multi-line-text';
export const INTEGER = 'integer';
export const DECIMAL = 'decimal';
export const BOOLEAN = 'boolean';
export const DATE = 'date';
export const RADIO = 'radio-buttons';
export const DROPDOWN = 'dropdown';
export const UPLOAD = 'upload';
export const TIME = 'time';
export const EXPRESSION = 'expression';
export const TABLE = 'table';
export const VALUE_SET_DROPDOWN = 'value-set-dropdown';
export const ENTITY_LOOKUP = 'entity-lookup';
export const LIST = 'list-view';
export const VALUE_SET_MULTI_SELECT = 'value-set-multi-select';
export const VALUE_SET_MULTI_SELECT_CHECKBOXES = 'value-set-multi-checkbox';
export const VALUE_SET_RADIOBOX = 'value-set-radiobox';
export const BIG_CALENDAR = 'calendar-event';
export const CURRENCTY = 'currency';
export const EVENT = 'event';
export const ENTITY_MULTI_SELECT_CHIP = 'entity-multi-select-chip';
export const ENTITY_TYPEAHEAD = 'entity-typeahead';
export const MULTIPLE_ENTITY_TYPEAHEAD = 'multiple-entity-typeahead';
export const DMSDOCLINK = 'dmsdoclink';
export const VALUSET_SUGGEST = 'valueset-suggest';
export const IMAGE = 'image';
export const AUTH_CODE = 'auth-code';
export const RECAPTCHA = 'recaptcha';
export const WIZARD_CONTROL = 'wizard-control';
export const VIDEO_WATCHED = 'video-watched';
export const RELATIVE_DATE = 'relative-date';

export const BPM_FORM = 'bpm-form';
export const BPM_FORM_BUILDER = 'bpm-form-builder';
export const ADDRESS_VERIFICATION_2 = 'address2';

// unused
export const PEOPLE = 'people';
export const FUNCTIONAL_GROUP = 'functional-group';

// to depreciate
export const TO_DEPRECIATE_TEXT = 'TEXTBOX';
export const TO_DEPRECIATE_CALENDAR = 'CALENDAR';
