import React, { FunctionComponent } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import Show from './Show2';
import SimpleDynamicView from '../DynamicView/SimpleDynamicView';
import { getIsDynamicView } from '../DynamicView/util';
import { DynamicViewIndicator } from '../hoc/withVisibleViewName';

interface GenericShowProps {
    viewName: string;
    createMobileAppBar?: boolean;
    resource: string;
    match: {
        params: {
            id: string;
        };
    };
    actions?: any;
    formId?: string;
    toolbar?: any;
    titleElement?: JSX.Element;
    renderTitleElement?: (props: { titleElem: JSX.Element }) => JSX.Element;
    embeddedInFormId?: string;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    disallowClickAwayNavigation?: boolean;
    embeddedInMultiCard?: boolean;
}

export const ShowView: FunctionComponent<{
    viewName: string;
    id: string;
    actions?: any;
    formId?: string;
    toolbar?: any;
    titleElement?: JSX.Element;
    renderTitleElement?: (props: { titleElem: JSX.Element }) => JSX.Element;
    embeddedInFormId?: string;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    disallowClickAwayNavigation?: boolean;
}> = (props) => {
    const viewConfig = useViewConfig();
    /**
     * Control rendering according to isDynamicView view configuration here.
     */
    const delayRenderingUntilFetch = React.useMemo(() => {
        return getIsDynamicView(viewConfig, props.viewName);
    }, [viewConfig, props.viewName]);

    const resource = viewConfig.views[props.viewName].entity;
    if (delayRenderingUntilFetch) {
        // render component with pending states and resulting viewname
        return (
            <SimpleDynamicView
                id={props.id}
                entityType={resource}
                viewType="show"
                render={(casetivityViewName) => {
                    return (
                        <DynamicViewIndicator isDynamic={!!casetivityViewName} viewName={props.viewName}>
                            <Show
                                {...props}
                                resource={resource}
                                id={props.id}
                                viewName={casetivityViewName ?? props.viewName}
                            />
                        </DynamicViewIndicator>
                    );
                }}
            />
        );
    }

    return <Show {...props} resource={resource} />;
};

const GenericShow: FunctionComponent<GenericShowProps> = (props) => {
    const id = props.match?.params?.id;
    return <ShowView {...props} id={id} />;
};
export default GenericShow;
