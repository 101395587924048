import { useCallback, useEffect, useState } from 'react';
import { AjaxError } from 'rxjs/ajax';
import useRemoteData from 'util/hooks/useRemoteData';
import { Observable } from 'rxjs';
import ErrorIcon from '@material-ui/icons/Error';
import { CircularProgress } from '@material-ui/core';
import Check from '@material-ui/icons/Check';

const useService = <ServiceResponse, Args extends any[]>(req: (...args: Args) => Observable<ServiceResponse>) => {
    const { state, setSuccess, setError, setPending } = useRemoteData<ServiceResponse, string>();

    const request = useCallback(
        (...args: Args) => {
            setPending();
            const $ajax = req(...args);
            const subscription = $ajax.subscribe(
                (res) => {
                    setSuccess(res);
                },
                (error: AjaxError) => {
                    setError(error.message);
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        },
        [req, setError, setPending, setSuccess],
    );

    /**
     * We want this to null-out after a couple seconds, to reset.
     * We could put this in a seperate 'TemporaryCheck' component,
     * except where we use this hook, we want to allow
     * StateIcon ?? <MyIcon />
     * which is a real nice way of providing the default icon, and so we need that to be null, not a ReactElement.
     */
    const isSuccess = state.status === 'success';
    const [SuccessMaybeIcon, setSuccessMaybeIcon] = useState(<Check />);
    useEffect(() => {
        if (isSuccess) {
            setSuccessMaybeIcon(<Check />);
            const SECONDS = 1000;
            const to = setTimeout(() => setSuccessMaybeIcon(null), 2.5 * SECONDS);
            return () => clearTimeout(to);
        }
    }, [isSuccess]);

    const StateIcon =
        state.status === 'error' ? (
            <ErrorIcon />
        ) : state.status === 'pending' ? (
            <CircularProgress style={{ height: 24, width: 24 }} />
        ) : state.status === 'success' ? (
            SuccessMaybeIcon
        ) : null;

    const extra = {
        StateIcon,
    };

    return [state, request, extra] as [typeof state, typeof request, typeof extra];
};

export default useService;
