import React, { useContext, useMemo } from 'react';
import { formContext as taskFormContext } from '../../../bpm/components/TaskDetail/TaskForm/FormContext';
import { tableRowContext, getTableRowContext } from '../components/EditableTable/util/tableRowContext';
import {
    defaultReportFormContext,
    formContext as reportFormContext,
} from 'report2/components/FormContext/ReportFormContext';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import get from 'lodash/get';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';

export const useFormContext = (from: 'Flowable' | 'Entity') => {
    const tfc = useContext(taskFormContext);
    const rfc = useContext(reportFormContext);
    const efc = useCurrentFormContext();
    const trc = useContext(tableRowContext);
    if (from === 'Entity') {
        return efc;
    }
    if (rfc !== defaultReportFormContext) {
        return rfc;
    }
    if (trc) {
        const currentTableRowContext = getTableRowContext(trc, tfc as ReturnType<typeof evaluateContext2>);
        return currentTableRowContext;
    }
    return tfc;
};
const withValueFromFormContext = (from: 'Flowable' | 'Entity') => (BaseComponent) => {
    return (props) => {
        const formContext = useFormContext(from);
        const value = get(formContext.fieldValues, props.source);
        const input = useMemo(() => {
            return {
                ...props.input,
                value,
            };
        }, [value, props.input]);
        return <BaseComponent {...props} input={input} />;
    };
};

export default withValueFromFormContext;
