import React from 'react';
import { useIntl } from 'react-intl';

const ListDescription = (props: { messageId: string }) => {
    const intl = useIntl();
    const description = intl.formatMessage(
        {
            id: props.messageId,
            defaultMessage: '',
            //Defaults when there is no message for current language
            //If nothing is configured then will default to props.messageId
        },
        {},
    );
    if (!description || description === props.messageId) {
        return null;
    }
    return <p style={{ marginLeft: '1rem' }}>{description}</p>;
};

export default ListDescription;
