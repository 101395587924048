import React, { createContext, useEffect, useMemo, useRef } from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { EntityFormContextProvider, FCPProps as EditFCPProps } from 'components/generics/form/EntityFormContext';
import {
    FormContextProvider as ShowFormContextProvider,
    FCPProps as ShowFCPProps,
} from 'components/generics/form/EntityFormContext/Show';
import {
    Card,
    Button,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Chip,
    useTheme,
    Divider,
} from '@material-ui/core';
import { some } from 'fp-ts/lib/Option';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import makeGetExpression from 'sagas/util/makeGetExpression';
import getConceptAvailability from 'viewConfigCalculations/ConceptAvailabilityExpressions/getConceptAvailability';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { getAllValuesetFields } from 'components/generics/form/EntityFormContext/util/entityVisExp';
import ViewConfig from 'reducers/ViewConfigType';
import { getValueSetFieldsRequiredForEntity } from 'components/generics/utils/viewConfigUtils';
import getAdhocFieldsExpected, {
    getAdhocFieldsForView,
} from 'components/generics/form/EntityFormContext/util/getAllFieldsExpected';
import JsonDownload from 'expression-tester/util/JsonDownload';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import useViewConfig from 'util/hooks/useViewConfig';
import useViewConfiguration from 'layout-editor/build-layout/hooks/useViewConfigurationState';
import ViewDefConfigEditor from './ViewDefinitionConfig/ViewDefConfigEditor';
import EditHasXButtonConfigs from './ViewDefinitionConfig/hasXButton/EditHasXButtonConfigs';
import { getFilterExpressionsFromView } from 'viewConfigCalculations/filterExpressions/epic';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popup from 'components/Popup';
import EditTitleOverrideForm from './EditTitleOverrideForm';
import Edit from '@material-ui/icons/Edit';
import LayoutEditorWrapper from './LayoutEditorWrapper/LayoutEditorWrapper';
import useEditViewInPlace from './hooks/useEditViewInPlace';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import FullWidthTabs from './SplitView/Tabs';
import TestCalc from 'expression-tester/server-side';
import TestMatchScore from 'expression-tester/server-side/MatchScore';
import { Evaluator, ExpressionTestAreaProps, ExpressionTestValues } from 'expression-tester/util/ExpressionTestArea';
import { ValidationsEditor } from 'fieldFactory/input/components/ValidationExpressionEditor';
import EditValidationExpression from 'fieldFactory/input/components/ValidationExpressionEditor/EditExpression';
import Alert from '@material-ui/lab/Alert';
import LinkButton from 'components/links/LinkButton';
import LazyDynamicFetchingTestArea from 'expression-tester/util/LazyDynamicFetchingTestArea';
import FunctionsSearch from 'expression-tester/util/FunctionsSearch';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EditDefaultValuesExpressions from './DefaultValueConfiguration/EditDefaultValuesExpressions';
import DefaultValueExpressionForm from './DefaultValueConfiguration/DefaultValueExpressionForm';

export type RenderLayoutEditor = () => JSX.Element;

type EntityExpressionTesterProps =
    | ({
          type: 'EDIT';
      } & Pick<EditFCPProps, 'record' | 'viewName' | 'formId' | 'evaluatedAdhocSPELVariables' | 'entityFormContextRef'>)
    | ({
          type: 'SHOW';
      } & Pick<ShowFCPProps, 'record' | 'viewName' | 'evaluatedAdhocSPELVariables' | 'entityFormContextRef'>);

const getOverrides = (viewName: string, viewConfig: ViewConfig, viewConfiguration) => {
    const editableExp = makeGetExpression('editableField')(
        viewName,
        viewConfig.views[viewName].entity,
        viewConfig,
        some(JSON.stringify(viewConfiguration)),
    );
    const visibleExp = makeGetExpression('visibleField')(
        viewName,
        viewConfig.views[viewName].entity,
        viewConfig,
        some(JSON.stringify(viewConfiguration)),
    );
    const conceptExp = getConceptAvailability(viewName, viewConfig, some(JSON.stringify(viewConfiguration)));
    const filterExps = getFilterExpressionsFromView(viewConfig, viewConfig.views[viewName]);
    const expansionsRequired = (() => {
        return uniq([
            ...editableExp.fold([], (v) =>
                Object.values(v)
                    .flat()
                    .flatMap((c) => c.expansionsRequired),
            ),
            ...visibleExp.fold([], (v) =>
                Object.values(v)
                    .flat()
                    .flatMap((c) => c.expansionsRequired),
            ),
            ...conceptExp.fold([], (v) => Object.values(v).map((c) => (c as any).expansionsRequired)),
            ...Object.values(filterExps).flatMap((exp) => exp.expansionsRequired),
        ]);
    })();
    const dataPaths = (() => {
        return uniq([
            ...editableExp.fold([], (v) =>
                Object.values(v)
                    .flat()
                    .flatMap((c) => c.dataPaths),
            ),
            ...visibleExp.fold([], (v) =>
                Object.values(v)
                    .flat()
                    .flatMap((c) => c.dataPaths),
            ),
            ...conceptExp.fold([], (v) => Object.values(v).map((c) => (c as any).dataPaths)),
            ...Object.values(filterExps).flatMap((exp) => exp.dataPaths),
        ]);
    })();

    const allValueset1Fields = {
        ...getValueSetFieldsRequiredForEntity(viewConfig, viewName, 'ONES'),
        ...getAllValuesetFields(
            visibleExp.getOrElse({}),
            editableExp.getOrElse({}),
            conceptExp.getOrElse({}),
            filterExps,
        ),
    };

    return {
        formContextOverrides: {
            editableExps: editableExp.toUndefined(),
            visibilityExps: visibleExp.toUndefined(),
            conceptExps: conceptExp.toUndefined(),
            filterExps,
        },
        expansionsRequired,
        dataPaths,
        allValueset1Fields,
    };
};

export const expressionTesterProvidedViewConfigurationContext = createContext<{
    elements: {
        [viewName: string]: {
            EditTitleElem?: JSX.Element;
        };
    };
    config: { [viewName: string]: EntityViewConfig };
}>({ config: {}, elements: {} });

const emptyObj = {};
const EntityExpressionTester: React.FC<
    EntityExpressionTesterProps & {
        configStringRef: React.MutableRefObject<string>;
        actionsKey: number;
        children: (props: { open: boolean }) => JSX.Element;
    }
> = (props) => {
    const { open, manuallyOpen, Provider } = useEditViewInPlace();

    const viewConfig = useViewConfig();
    const registeredFields = useAppSelector((state: RootState) => {
        if (props.type === 'EDIT') {
            return (state.form![props.formId || 'record-form'] || {}).registeredFields || emptyObj;
        }
        return emptyObj;
    });

    const theme = useTheme();
    const getViewFields = () =>
        getAdhocFieldsExpected(viewConfig, props.viewName, registeredFields, getAdhocFieldsForView);
    const [viewConfiguration, setViewConfiguration, wasChanged] = useViewConfiguration(props.viewName);
    useMemo(() => {
        props.configStringRef.current = JSON.stringify(viewConfiguration);
    }, [viewConfiguration, props.configStringRef]);

    let prevActionsKey = useRef(props.actionsKey);
    useEffect(() => {
        if (prevActionsKey.current !== props.actionsKey) {
            setViewConfiguration(JSON.parse(props.configStringRef.current));
            prevActionsKey.current = props.actionsKey;
        }
    }, [props.actionsKey, props.configStringRef, setViewConfiguration]);

    // short-circuit on 'open' so we don't do these expensive calcs unless the tester is open
    const needOverrides = open !== 'CLOSED' || wasChanged;
    const overrides = React.useMemo(() => {
        return needOverrides && getOverrides(props.viewName, viewConfig, viewConfiguration);
    }, [needOverrides, props.viewName, viewConfig, viewConfiguration]);

    const EditTitleElem =
        open !== 'CLOSED' ? (
            <Popup
                renderDialogContent={({ closeDialog }) => (
                    <div style={{ padding: '1em' }}>
                        <EditTitleOverrideForm
                            initialExpression={viewConfiguration?.overrideTitle}
                            onSubmit={({ titleOverrideExpression }) => {
                                setViewConfiguration({
                                    ...viewConfiguration,
                                    overrideTitle: titleOverrideExpression,
                                });
                                closeDialog();
                            }}
                        />
                    </div>
                )}
                renderToggler={({ openDialog }) => (
                    <div style={{ marginTop: 2, marginBottom: 2 }}>
                        <Button size="small" endIcon={<Edit />} color="primary" onClick={openDialog()}>
                            Edit title
                        </Button>
                    </div>
                )}
            />
        ) : null;

    const resource = viewConfig.views[props.viewName]?.entity;
    const entityConf = viewConfig.entities[resource];
    const expressionTestAreaProps: ExpressionTestAreaProps = {
        formId: props.type === 'EDIT' ? props.formId : undefined,
        type: props.type,
        contextType: 'entity-view',
        viewName: props.viewName,
        record: props.record,
        dataPaths: overrides && uniq(flatten([...overrides.dataPaths, ...getViewFields()])),
        expansionsRequired: overrides && uniq(flatten([...overrides.expansionsRequired, ...getViewFields()])),
        valueset1Fields: overrides && overrides.allValueset1Fields,
    };
    const providerChildren = (
        <div>
            {open !== 'CLOSED' && (
                <Card>
                    <SplitPane split="vertical">
                        <Pane initialSize="50%">
                            <FullWidthTabs
                                items={[
                                    [
                                        'Visibility',
                                        <div>
                                            <ViewDefConfigEditor
                                                viewName={props.viewName}
                                                type="visibleField"
                                                json={viewConfiguration}
                                                onChangeJson={setViewConfiguration}
                                            />
                                        </div>,
                                    ],
                                    [
                                        'Editability',
                                        <div>
                                            <ViewDefConfigEditor
                                                viewName={props.viewName}
                                                type="editableField"
                                                json={viewConfiguration}
                                                onChangeJson={setViewConfiguration}
                                            />
                                        </div>,
                                    ],
                                    [
                                        'Concept filtering',
                                        <div>
                                            <ViewDefConfigEditor
                                                viewName={props.viewName}
                                                type="conceptIdsForFields"
                                                json={viewConfiguration}
                                                onChangeJson={setViewConfiguration}
                                            />
                                        </div>,
                                    ],
                                    viewConfig.views[props.viewName].viewType === 'COMPONENT'
                                        ? null
                                        : [
                                              'Button overrides',
                                              <div>
                                                  <EditHasXButtonConfigs
                                                      viewName={props.viewName}
                                                      viewDefConfig={viewConfiguration}
                                                      setViewDefConfig={setViewConfiguration}
                                                  />
                                              </div>,
                                          ],
                                    props.type !== 'EDIT'
                                        ? null
                                        : [
                                              'Validations',
                                              <div>
                                                  {entityConf?.id && (
                                                      <Alert severity="warning">
                                                          These are frontend validations ONLY on this view. This is{' '}
                                                          <em>probably not what you want</em> - Real data validations go
                                                          on the {entityConf.name}{' '}
                                                          <LinkButton to={`/EntityConfig/${entityConf.id}`}>
                                                              EntityConfig
                                                          </LinkButton>
                                                      </Alert>
                                                  )}
                                                  <ValidationsEditor
                                                      validations={viewConfiguration?.['validations']}
                                                      setValidationExpression={(validations) => {
                                                          setViewConfiguration({
                                                              ...viewConfiguration,
                                                              validations,
                                                          });
                                                      }}
                                                      renderValidationEditor={({ initialValues, onSubmit }) => (
                                                          <EditValidationExpression
                                                              resource={viewConfig.views[props.viewName]?.entity}
                                                              initialValues={initialValues}
                                                              onSubmit={onSubmit}
                                                          />
                                                      )}
                                                  />
                                              </div>,
                                          ],
                                    !props.record?.id
                                        ? [
                                              'Default Values',
                                              <EditDefaultValuesExpressions
                                                  viewDefConfig={viewConfiguration}
                                                  setViewDefConfig={setViewConfiguration}
                                                  renderForm={({ initialValues, onSubmit }) => (
                                                      <DefaultValueExpressionForm
                                                          viewName={props.viewName}
                                                          initialValues={initialValues}
                                                          onSubmit={onSubmit}
                                                      />
                                                  )}
                                              />,
                                          ]
                                        : null,
                                ].filter<[string, JSX.Element] | null>((e): e is [string, JSX.Element] => Boolean(e))}
                            />
                        </Pane>
                        <div>
                            <FullWidthTabs
                                items={[
                                    [
                                        'Generated Configuration',
                                        <div>
                                            <JSONEditorDemo
                                                json={viewConfiguration}
                                                onChangeJSON={setViewConfiguration}
                                            />
                                            <div style={{ display: 'flex', marginRight: '-1em', marginTop: '1em' }}>
                                                <div style={{ marginRight: '1em' }}>
                                                    <JsonDownload
                                                        ButtonProps={{ size: 'small' }}
                                                        json={viewConfiguration}
                                                    />
                                                </div>
                                                <div style={{ marginRight: '1em' }}>
                                                    <CopyToClipboard text={JSON.stringify(viewConfiguration || {})}>
                                                        <Button size="small" variant="contained" color="primary">
                                                            CopyConfiguration
                                                        </Button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        </div>,
                                    ],
                                    ['values', <ExpressionTestValues {...expressionTestAreaProps} />],
                                    [
                                        'test expressions',
                                        <div>
                                            {props.record.id ? (
                                                <FullWidthTabs
                                                    items={[
                                                        [
                                                            'current form data',
                                                            <div>
                                                                <p>
                                                                    Test your expressions against the current values in
                                                                    the form
                                                                </p>
                                                                <Evaluator {...expressionTestAreaProps} />
                                                            </div>,
                                                        ],
                                                        [
                                                            'possible form data',
                                                            <div
                                                                style={{
                                                                    backgroundColor: theme.palette.grey[100],
                                                                    border: theme.palette.grey[400],
                                                                }}
                                                            >
                                                                <Accordion>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMore />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        <Typography>
                                                                            Search available functions{' '}
                                                                            <Chip
                                                                                variant="outlined"
                                                                                size="small"
                                                                                label="Experimental"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        theme.palette.warning.light,
                                                                                }}
                                                                            />{' '}
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails style={{ display: 'block' }}>
                                                                        <FunctionsSearch />
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <p style={{ paddingLeft: '1em', paddingRight: '1em' }}>
                                                                    Test possible expressions in the context of this
                                                                    record
                                                                </p>
                                                                <LazyDynamicFetchingTestArea
                                                                    entityType={viewConfig.views[props.viewName].entity}
                                                                    recordId={props.record.id}
                                                                />
                                                            </div>,
                                                        ],
                                                    ]}
                                                />
                                            ) : (
                                                <Evaluator {...expressionTestAreaProps} />
                                            )}
                                            {props.record.id && (
                                                <>
                                                    <Divider />
                                                    <TestCalc
                                                        entityType={viewConfig.views[props.viewName].entity}
                                                        entityId={props.record.id}
                                                    />
                                                </>
                                            )}
                                            {props.record.id && (
                                                <TestMatchScore
                                                    entityType={viewConfig.views[props.viewName].entity}
                                                    entityId={props.record.id}
                                                />
                                            )}
                                        </div>,
                                    ],
                                ]}
                            />
                        </div>
                    </SplitPane>
                </Card>
            )}
            <expressionTesterProvidedViewConfigurationContext.Provider
                value={{
                    config: { [props.viewName]: viewConfiguration },
                    elements: { [props.viewName]: { EditTitleElem } },
                }}
            >
                {props.children({ open: manuallyOpen !== 'CLOSED' })}
            </expressionTesterProvidedViewConfigurationContext.Provider>
        </div>
    );

    return (
        <Provider>
            <div>
                {props.type === 'EDIT' ? (
                    <EntityFormContextProvider
                        overrideViewConfig={viewConfig}
                        record={props.record}
                        viewName={props.viewName}
                        formId={props.formId}
                        overrides={overrides ? overrides.formContextOverrides : undefined}
                        evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                        entityFormContextRef={props.entityFormContextRef}
                    >
                        {providerChildren}
                    </EntityFormContextProvider>
                ) : (
                    <ShowFormContextProvider
                        overrideViewConfig={viewConfig}
                        record={props.record}
                        viewName={props.viewName}
                        overrides={overrides ? overrides.formContextOverrides : undefined}
                        evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                        entityFormContextRef={props.entityFormContextRef}
                    >
                        {providerChildren}
                    </ShowFormContextProvider>
                )}
            </div>
        </Provider>
    );
};

const WrappedEntityExpressionTester: React.FC<
    EntityExpressionTesterProps & {
        children: (props: {
            renderLayoutEditor?: RenderLayoutEditor;
            renderEditActionsElem?: (id?: string) => JSX.Element;
        }) => JSX.Element;
    }
> = (props) => {
    return (
        <LayoutEditorWrapper viewName={props.viewName}>
            {({ configStringRef, EditLayoutElem: EditLayoutPopupElem, renderEditActionsElem, actionsKey }) => (
                <div>
                    <EntityExpressionTester {...props} actionsKey={actionsKey} configStringRef={configStringRef}>
                        {({ open }) =>
                            props.children({
                                renderLayoutEditor: open ? () => EditLayoutPopupElem : undefined,
                                renderEditActionsElem: open ? renderEditActionsElem : undefined,
                            })
                        }
                    </EntityExpressionTester>
                </div>
            )}
        </LayoutEditorWrapper>
    );
};
// export default EntityExpressionTester;
export default WrappedEntityExpressionTester;
