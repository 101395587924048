import React from 'react';
import { makeStyles, createStyles, Theme, Collapse, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Popup from 'components/Popup';
import BranchExplorer, { SquashForm, useBranchExplorer, useCurrentBranch } from 'branches/BranchExplorer';
import Clear from '@material-ui/icons/Clear';
import { RootState } from 'reducers/rootReducer';
import { useAppSelector } from 'reducers/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        defaultBranch: {
            display: 'flex',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.warning.light,
            color: 'white',
            position: 'relative',
        },
        workingBranch: {
            textAlign: 'center',
            backgroundColor: theme.palette.info.main,
            color: 'white',
        },
        message: {
            flex: 1,
        },
        root: {
            borderRadius: 0,
        },
    }),
);

const Banner: React.FC<{}> = (props) => {
    const classes = useStyles(props);
    const currentBranch = useCurrentBranch();
    const [branchState, setBranch] = useBranchExplorer();

    return (
        <Popup
            paperStyle={{
                minWidth: 'min(600px, 90vw)',
            }}
            renderDialogContent={({ closeDialog }) => (
                <div style={{ margin: '1em' }}>
                    <div style={{ float: 'right' }}>
                        <IconButton size="small" onClick={closeDialog}>
                            <Clear />
                        </IconButton>
                    </div>
                    <BranchExplorer
                        onChangeBranch={() => {
                            closeDialog();
                        }}
                    />
                </div>
            )}
            renderToggler={({ openDialog }) => (
                <div>
                    <Collapse in={!currentBranch} mountOnEnter unmountOnExit>
                        <Alert
                            severity="warning"
                            classes={{
                                root: classes.root,
                                message: classes.message,
                            }}
                        >
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div>Your work is not being tagged under any branch</div>
                                <div>
                                    <button onClick={openDialog()} className="casetivity-linkbutton">
                                        Switch to a working branch
                                    </button>
                                </div>
                            </div>
                        </Alert>
                    </Collapse>
                    <Collapse in={Boolean(currentBranch)} mountOnEnter unmountOnExit>
                        <Alert
                            severity="info"
                            classes={{
                                root: classes.root,
                                message: classes.message,
                            }}
                        >
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div>
                                    Your work will be tagged under branch <b>{currentBranch}</b>
                                </div>
                                <div>
                                    <Popup
                                        renderToggler={({ openDialog }) => (
                                            <button
                                                onClick={openDialog()}
                                                style={{ marginRight: '1em', borderRadius: '2px' }}
                                            >
                                                Merge to main
                                            </button>
                                        )}
                                        renderDialogContent={({ closeDialog }) => (
                                            <SquashForm
                                                onSuccess={() => {
                                                    closeDialog();
                                                    setBranch(null);
                                                }}
                                                Actions={
                                                    <IconButton size="small" onClick={closeDialog}>
                                                        <Clear />
                                                    </IconButton>
                                                }
                                            />
                                        )}
                                    />
                                    <button onClick={openDialog()} className="casetivity-linkbutton">
                                        Switch branches
                                    </button>
                                </div>
                            </div>
                        </Alert>
                    </Collapse>
                </div>
            )}
        />
    );
};

export const BranchManagementBanner = () => {
    const basicInfo = useAppSelector((state: RootState) => state.basicInfo);

    return <> {basicInfo.showConfigMgmt && <Banner />}</>;
};
