import * as React from 'react';
import { push as pushAction } from 'connected-react-router';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { loadValueSets as loadValueSetsAction } from 'valueSets/actions';
import { getEntityRestUrl, getValueSetCodesRequiredForEntity } from '../utils/viewConfigUtils';
import mergeAction from '../../../actions/merge';
import stripFieldsForMerge from '../genericMerge/utilities/stripFieldsForMerge';
import GenericMerge from '../genericMerge';
import LoadingOverlay from 'react-loading-overlay';
import createGetData from '../utils/createGetData';
import createExpansion from '../../../clients/utils/createExpansionQuery/buildCommaSeperatedExpansions';
import notAMatchAction from '../../../actions/notmatch';
import PopoverRecordTitle from './popoverRecordTitle';
import { apply as cleanData } from '../../../viewConfigSchema/stripHibernateArtifactsAndReplaceWithIds';

import { Button, Typography } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Done from '@material-ui/icons/Done';

import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import useViewConfig from 'util/hooks/useViewConfig';
import ViewConfig from 'reducers/ViewConfigType';
import buildHeaders from 'sideEffect/buildHeaders';
import withVisibleViewName from '../hoc/withVisibleViewName';

const config = require('../../../config.js');

const styles = (theme: Theme) =>
    createStyles({
        notAMatchButton: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText,
        },
    });

interface MatchViewProps {
    match: {
        params: {
            id: string;
            id2: string;
        };
    };
    resource: string;
    viewName: string;
    basePath?: string; // TODO Remove this
    onMergeSuccess?: () => void;
    onNotAMatchSuccess?: () => void;
}

const redirectBasedOnRemainingMatches = (
    dispatch: Function | null,
    ownProps: MatchViewPropsInternal1,
    responseBody: { id: string },
    finallyCb: () => void,
) => {
    let pushLoc: (loc: string) => void;
    if (dispatch) {
        pushLoc = (loc) => dispatch(pushAction(loc));
    } else if (ownProps.push) {
        pushLoc = ownProps.push;
    } else {
        throw new Error('must provide dispatch or a push via props');
    }
    /* Decide redirection based on number of remaining matches. */
    fetch(
        new Request(
            `${config.BACKEND_BASE_URL}${getEntityRestUrl(
                ownProps.viewConfig,
                `${ownProps.resource}Mrg`,
            )}?matchDecision.code.equals=PENDING&matchingId.equals=${responseBody.id}&sort=id%2CDESC`,
            {
                method: 'GET',
                credentials: 'same-origin',
                headers: buildHeaders({ includeCredentials: true }),
            },
        ),
    )
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                finallyCb();
                // by default go to matches page.
                pushLoc(`/${ownProps.resource}/${responseBody.id}/matches`);
            } else {
                return response
                    .json()
                    .then((res: {}[]) => {
                        finallyCb();
                        if (res.length > 0) {
                            pushLoc(`/${ownProps.resource}/${responseBody.id}/matches`);
                        } else {
                            pushLoc(`/${ownProps.resource}/${responseBody.id}`);
                        }
                    })
                    .catch((e) => {
                        finallyCb();
                        pushLoc(`/${ownProps.resource}/${responseBody.id}/matches`);
                    });
            }
        })
        .catch((e) => {
            finallyCb();
        });
};

const makeMapStateToProps = () => {
    const getRecord1Data = createGetData('Merge', 'id');
    const getRecord2Data = createGetData('Merge', 'id2');
    const mapStateToProps = (state: RootState, props: MatchViewProps) => ({
        record1: getRecord1Data(state, props) as { title?: string } | undefined,
        record2: getRecord2Data(state, props) as { title?: string } | undefined,
    });
    return mapStateToProps;
};

const dispatches = {
    crudGetOne: crudGetOneAction,
    loadValueSets: loadValueSetsAction,
    notAMatch: notAMatchAction,
    push: pushAction,
};
type Dispatches = typeof dispatches;

interface WithStateProps {
    record: {};
    setMergeRepresentation: (record: {}) => void;
}
const handlers = {
    fetchMerge: (props) => async (id1, id2) => {
        const request = new Request(
            `${config.BACKEND_BASE_URL}${getEntityRestUrl(
                props.viewConfig,
                props.resource,
            )}/${id1}/merge/${id2}?expand=${createExpansion(`${props.resource}Merge`, props.viewConfig)}`,
            {
                method: 'GET',
                credentials: 'same-origin',
                headers: buildHeaders({ includeCredentials: true }),
            },
        );

        let response;
        try {
            response = await fetch(request);
        } catch (e) {
            console.error('Network Error: ', e);
            props.setGetMergedRecordError('A network error occured: please retry');
            return;
        }
        if (!response.ok) {
            if (!response.status) {
                props.setGetMergeredRecordError('An unexpected error occurred');
                return;
            }
            try {
                const serverResponse = await response.json();
                props.setGetMergedRecordError(
                    serverResponse?.description ? serverResponse.description : `A ${response.status} error occurred.`,
                );
            } catch (e) {
                console.error(e);
                props.setGetMergedRecordError(`A ${response.status} error occurred.`);
            }
            return;
        }
        try {
            const data = await response.json();
            const cleaned = cleanData({ id: '_fake_', entityType: 'FAKEENTITY', ...data });
            props.setMergeRepresentation(cleaned.result);
        } catch (e) {
            console.error(e);
            props.setGetMergedRecordError('An unexpected error occurred. See the console for more details.');
        }
    },
};
type handlersProps = {
    [handler in keyof typeof handlers]: ReturnType<(typeof handlers)[handler]>;
};
interface MatchViewPropsInternal1
    extends MatchViewProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        Dispatches,
        WithStateProps,
        handlersProps {
    viewConfig: ViewConfig;
    merging: boolean;
    setMerging: (merging: boolean) => void;
    markingNotAMatch: boolean;
    setMarkingNotAMatch: (markingNotAMatch: boolean) => void;
}
const mapDispatchToProps = (dispatch, ownProps: MatchViewPropsInternal1) => ({
    merge: (values) => {
        ownProps.setMerging(true);
        return dispatch(
            mergeAction(
                ownProps.resource,
                ownProps.match.params.id,
                ownProps.match.params.id2,
                stripFieldsForMerge(values, ownProps.viewName, ownProps.viewConfig),
                (responseBody) => {
                    if (ownProps.onMergeSuccess) {
                        ownProps.setMerging(false);
                        ownProps.onMergeSuccess();
                    } else {
                        redirectBasedOnRemainingMatches(dispatch, ownProps, responseBody, () =>
                            ownProps.setMerging(false),
                        );
                    }
                },
                () => ownProps.setMerging(false),
            ),
        );
    },
});
interface MatchViewEnhancedProps
    extends MatchViewPropsInternal1,
        ReturnType<typeof mapDispatchToProps>,
        WithStyles<typeof styles> {
    createMobileAppBar?: boolean;
}

const enhance = compose(
    withVisibleViewName,
    (BaseComponent) => (props) => {
        const [merging, setMerging] = React.useState(false);
        const [markingNotAMatch, setMarkingNotAMatch] = React.useState(false);
        const viewConfig = useViewConfig();
        return (
            <BaseComponent
                viewConfig={viewConfig}
                merging={merging}
                setMerging={setMerging}
                markingNotAMatch={markingNotAMatch}
                setMarkingNotAMatch={setMarkingNotAMatch}
                {...props}
            />
        );
    },
    connect(makeMapStateToProps, dispatches),
    withState('record', 'setMergeRepresentation'),
    withState('getMergedRecordError', 'setGetMergedRecordError'),
    withHandlers(handlers),
    lifecycle({
        componentDidMount() {
            this.props.crudGetOne({
                resource: this.props.resource,
                id: this.props.match.params.id,
                view: `${this.props.resource}Merge`,
            });
            this.props.crudGetOne({
                resource: this.props.resource,
                id: this.props.match.params.id2,
                view: `${this.props.resource}Merge`,
            });
            this.props.fetchMerge(this.props.match.params.id, this.props.match.params.id2);
            this.props.loadValueSets(
                getValueSetCodesRequiredForEntity(this.props.viewConfig, `${this.props.resource}Merge`).map(
                    (valueSetCode) => ({ valueSet: valueSetCode }),
                ),
            );
        },
        componentWillReceiveProps(nextProps: {
            record1: {};
            record2: {};
            match: { params: { id: string; id2: string } };
        }) {
            // since we are caching in our getData, we know the data has changed when the record1/record2 has changed.
            // Refetch the merge in this case.
            if (this.props.match.params.id !== nextProps.match.params.id || this.props.record1 !== nextProps.record1) {
                this.props.crudGetOne({
                    resource: this.props.resource,
                    id: this.props.match.params.id,
                    view: `${this.props.resource}Merge`,
                });
                this.props.fetchMerge(this.props.match.params.id, this.props.match.params.id2);
            }
            if (
                this.props.match.params.id2 !== nextProps.match.params.id2 ||
                this.props.record2 !== nextProps.record2
            ) {
                this.props.crudGetOne({
                    resource: this.props.resource,
                    id: this.props.match.params.id2,
                    view: `${this.props.resource}Merge`,
                });
                this.props.fetchMerge(this.props.match.params.id, this.props.match.params.id2);
            }
        },
    }),
    connect(null, mapDispatchToProps),
    withStyles(styles),
);

const MatchWithNotAMatch = (props: MatchViewEnhancedProps) => {
    const title = props.record1 && props.record2 && (
        <span>
            Merging Records <span>"{props.record1.title}"</span> and <span>"{props.record2.title}"</span>
        </span>
    );
    return (
        <LoadingOverlay
            active={props.merging || props.markingNotAMatch}
            spinner={true}
            styles={{
                overlay: (base) => ({
                    ...base,
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,
                }),
                content: (base) => ({
                    ...base,
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                }),
            }}
            text={
                props.merging
                    ? 'Merging records...'
                    : props.markingNotAMatch
                    ? 'Marking records as not matching...'
                    : ''
            }
        >
            {props.createMobileAppBar ? <SsgAppBarMobile title="Merging Records" /> : null}
            <GenericMerge
                {...props}
                topTitle={
                    props.record1 &&
                    props.record2 && (
                        <Typography
                            style={{ textAlign: 'center', paddingTop: '.5em' }}
                            variant="h6"
                            component="h1"
                            gutterBottom={true}
                        >
                            {title}
                        </Typography>
                    )
                }
                altRecordTitle={
                    <PopoverRecordTitle
                        // text={`Record ${props.match.params.id2}`}
                        text="Matching Record"
                        recordId={props.match.params.id2}
                        resource={props.resource}
                    />
                }
                primaryRecordTitle={
                    <PopoverRecordTitle
                        // text={`Record ${props.match.params.id}`}
                        text="Current Record"
                        recordId={props.match.params.id}
                        resource={props.resource}
                    />
                }
                includeRefManys={true}
                renderActions={({ handleSubmit, merge }) => (
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '1em' }}>
                        {props.record1 &&
                            props.record1['casetivityCanMerge'] &&
                            props.record2 &&
                            props.record2['casetivityCanMerge'] && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit(({ mergeRecords, ...values }) => merge(values))}
                                    disabled={props.merging}
                                >
                                    Merge <Done />
                                </Button>
                            )}
                        <div style={{ width: '2em' }} />
                        <Button
                            className={props.classes.notAMatchButton}
                            variant="contained"
                            onClick={() => {
                                props.setMarkingNotAMatch(true);
                                props.notAMatch(
                                    props.resource,
                                    props.match.params.id,
                                    props.match.params.id2,
                                    () => {
                                        if (props.onNotAMatchSuccess) {
                                            props.setMarkingNotAMatch(false);
                                            props.onNotAMatchSuccess();
                                        } else {
                                            redirectBasedOnRemainingMatches(
                                                null,
                                                props,
                                                {
                                                    id: props.match.params.id,
                                                },
                                                () => props.setMarkingNotAMatch(false),
                                            );
                                        }
                                    },
                                    () => props.setMarkingNotAMatch(false),
                                );
                            }}
                        >
                            Not a Match <Clear />
                        </Button>
                    </div>
                )}
            />
        </LoadingOverlay>
    );
};
const EnhancedMatchView: React.FC<MatchViewProps> = enhance(MatchWithNotAMatch);

const MatchView: React.FC<MatchViewProps> = (props) => <EnhancedMatchView {...props} basePath={`/${props.resource}`} />;

export default MatchView;
