import React, { FunctionComponent, useState, useCallback, useContext } from 'react';
import { DashboardConfig, AttributeSecurity } from 'dashboard2/dashboard-config/types';
import {
    TextField,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Button,
    CircularProgress,
    useTheme,
} from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Check from '@material-ui/icons/Check';
import Warning from '@material-ui/icons/Warning';
import AttributeSecurityForm from 'dash-editor/attributeSecurity/AttributeSecurityForm';
import FormSaveNotifier from 'formSaveNotifier/components/FormSaveNotifier';
import DashEditor from './util/DashGridEditor';
import useSubmitDash from './util/useSubmitDash';
import Alert from '@material-ui/lab/Alert/Alert';

const startingDash = require('./startingDash.json');

const DashCreatorController: FunctionComponent = () => {
    const theme = useTheme();
    const [dashConfig, setDashConfig] = useState<DashboardConfig | null>(startingDash);
    const [attributeSecurityConfig, setAttributeSecurityConfig] = useState<AttributeSecurity>();
    const [displayName, setDisplayName] = useState<string>('');
    const [validationErrors, setValidationErrors] = useState({
        dashName: '',
        displayName: '',
    });
    const validateFields = useCallback(() => {
        let errors = {
            dashName: '',
            displayName: '',
        };
        if (!dashConfig?.name) {
            errors.dashName = 'Dashboard Name is required';
        }
        if (!displayName) {
            errors.displayName = 'Display Name is required';
        }
        setValidationErrors(errors);
        return !errors.dashName && !errors.displayName;
    }, [displayName, dashConfig?.name]);

    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const [submissionState, submit] = useSubmitDash();
    const save = useCallback(() => {
        if (validateFields()) {
            let dashToSubmit: any = {
                name: dashConfig.name,
                displayName: displayName,
                config: JSON.stringify({
                    dashboardConfig: dashConfig,
                    attributeSecurity: attributeSecurityConfig,
                    loaded: false,
                }),
            };

            submit(dashToSubmit);
        }
    }, [validateFields, dashConfig, displayName, submit, attributeSecurityConfig]);

    return (
        <div style={{ margin: '1em', padding: '1em' }}>
            <FormSaveNotifier when={submissionState.type !== 'SUCCESS'} />
            <div style={{ marginBottom: '1em', paddingBottom: '1em' }}>
                <Typography component="h1" variant="h5">
                    Create Dashboard
                </Typography>
                <div style={{ height: '2em' }} />
                <TextField
                    error={!!validationErrors.dashName}
                    helperText={validationErrors.dashName}
                    label="Dashboard Name"
                    fullWidth
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({
                        shrink: true,
                        disabled: false,
                    })}
                    value={(dashConfig && dashConfig.name) || ''}
                    onChange={(e) => {
                        setDashConfig({
                            ...dashConfig,
                            name: e.target.value,
                        });
                        validateFields();
                    }}
                    onBlur={(e) => {
                        setDashConfig({
                            ...dashConfig,
                            name: e.target.value,
                        });
                        validateFields();
                    }}
                />
                <div style={{ height: '2em' }} />
                <TextField
                    error={!!validationErrors.displayName}
                    helperText={validationErrors.displayName}
                    label="Dashboard (Display) Name"
                    fullWidth
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({
                        shrink: true,
                        disabled: false,
                    })}
                    value={displayName}
                    onChange={(e) => {
                        setDisplayName(e.target.value);
                        validateFields();
                    }}
                    onBlur={(e) => {
                        setDisplayName(e.target.value);
                        validateFields();
                    }}
                />
                <div style={{ height: '2em' }} />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Inspect JSON</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <pre>{dashConfig && JSON.stringify(dashConfig, null, 1)}</pre>
                    </AccordionDetails>
                </Accordion>
                <div style={{ height: '2em' }} />
                <AttributeSecurityForm value={attributeSecurityConfig} onChange={setAttributeSecurityConfig} />
            </div>
            {/* works as is but may be better to just pass null as key. PUT request should be creating pickedDashId */}
            {dashConfig && <DashEditor key={null} dashConfig={dashConfig} setDashConfig={setDashConfig} />}
            {submissionState.type === 'ERROR' ? (
                <pre style={{ color: theme.palette.error.main }}>
                    {submissionState.error.name}
                    <br />
                    {submissionState.error.message} <br />
                    {typeof submissionState.error.response === 'object' &&
                        JSON.stringify(submissionState.error.response, null, 1)}
                </pre>
            ) : null}
            <div style={{ height: '2em' }}>
                {submissionState.type === 'SUCCESS' && (
                    <Alert style={{ flex: 1, marginBottom: '1em' }} severity="info">
                        Your changes have been saved successfully and will be visible shortly. Please refresh to view
                        the latest updates.
                    </Alert>
                )}
                <Button
                    disabled={
                        submissionState.type === 'SUBMITTING' ||
                        !!validationErrors.dashName ||
                        !!validationErrors.displayName
                    }
                    color="primary"
                    variant="contained"
                    onClick={save}
                >
                    Save{' '}
                    {submissionState.type === 'SUBMITTING' ? (
                        <CircularProgress style={{ height: 15, width: 15 }} />
                    ) : submissionState.type === 'SUCCESS' ? (
                        <Check />
                    ) : submissionState.type === 'ERROR' ? (
                        <Warning />
                    ) : null}
                </Button>
            </div>
        </div>
    );
};

export default DashCreatorController;
