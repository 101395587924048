import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import debounce from 'debounce-fn';
import { getHeaders } from 'sideEffect/services';
import { stringify } from 'query-string';

interface AxiosState<Data extends any> {
    data?: Data;
    loading: boolean;
    error: string | false;
    total?: number;
}
const useAxios = <Data extends any>(
    props: Pick<AxiosRequestConfig, 'url' | 'method' | 'params' | 'data'>,
): AxiosState<Data> & {
    refetch: () => void;
} => {
    const { url, method = 'get', params, data } = props;
    const cancelToken = useRef(null);
    const [state, setState] = useState<AxiosState<Data>>({
        loading: false,
        error: false,
    });

    const makeNetworkRequest = useMemo(
        () =>
            debounce(
                () => {
                    if (!url) {
                        cancelToken.current?.();

                        setState({
                            loading: false,
                            error: false,
                            data: null,
                            total: undefined,
                        });

                        // this is how we delay the fetch until the caller is ready: pass empty url until you know what you want.
                        return;
                    }
                    axios({
                        url,
                        method,
                        params,
                        headers: getHeaders(),
                        data,
                        paramsSerializer: {
                            serialize: (params) => {
                                return stringify(params, { encode: false }).replace('#', '%23');
                            },
                        },
                        cancelToken: new axios.CancelToken((token) => {
                            cancelToken.current = token;
                        }),
                    })
                        .then((res) => {
                            cancelToken.current = null;
                            const total = parseInt(res.headers['x-total-count'], 10);
                            setState({
                                data: res.data,
                                loading: false,
                                error: false,
                                total,
                            });
                        })
                        .catch((e) => {
                            // Early return if request was cancelled
                            if (axios.isCancel(e)) {
                                return;
                            } else if (!e.response) {
                                setState({ loading: false, error: 'Network error', data: null, total: undefined });
                                return;
                            }
                            setState({ data: undefined, error: e.message, loading: false, total: undefined });
                            console.error(e);
                        });
                },
                { wait: 500 },
            ),
        [data, method, params, url],
    );

    const fetchData = useCallback(() => {
        cancelToken.current?.();
        setState({ error: false, loading: true });
        makeNetworkRequest();
    }, [makeNetworkRequest]);

    useEffect(() => {
        fetchData();
        return () => {
            cancelToken.current?.();
            makeNetworkRequest.cancel();
        };
    }, [fetchData, makeNetworkRequest]);

    return {
        ...state,
        refetch: fetchData,
    };
};

export default useAxios;
