import { createSelector } from 'reselect';
import createFormContext from 'components/generics/form/EntityFormContext/util/createFormContext';
import { createGetEntities, createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import createDeepEqlSelector from 'components/generics/form/EntityFormContext/util/createDeepEqlSelector';
import { getValueset1Fields } from 'bpm/form-context-utils';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import ReportDefinition from 'report2/components/ReportDefinition';
import { RootState } from 'reducers/rootReducer';
import { ReportDefinitionParam } from 'report2/ReportDefinition';
import { ValueSets } from 'valueSets/reducer';
import { getFilteredRef1FilterExpressions } from 'bpm/form-context-utils/selectorCombiner';

type ReportFormContextProps = {
    reportDefinition: Pick<ReportDefinition, 'fields' | 'config'>;
};

const getReportFields = (state: RootState, props: ReportFormContextProps) => {
    return props.reportDefinition.fields.map(({ id, ...f }) => ({ ...f, id: '' + id }));
};

const getFormContextEvaluatorSelector = () => {
    const getReferenceManyEntityFilterExpressionsSelector = createSelector(
        (state: RootState, props: ReportFormContextProps) => props.reportDefinition,
        (reportDefinition) => {
            return Object.fromEntries(
                Object.entries(reportDefinition.config?.filters ?? {}).flatMap(([k, expression]) => {
                    const entityType = reportDefinition.fields?.find((f) => f.name === k)?.params?.['entity'];
                    if (!entityType) {
                        return [];
                    }
                    return [
                        [
                            k,
                            {
                                expression,
                                entityType,
                            },
                        ],
                    ];
                }),
            );
        },
    );
    return createSelector(
        getReportFields,
        (state: RootState, props: ReportFormContextProps) => state.viewConfig,
        (state: RootState, props: ReportFormContextProps) => {
            return props.reportDefinition.config?.availableConcepts;
        },
        getReferenceManyEntityFilterExpressionsSelector,
        (
            fields: ReportDefinitionParam[] = [],
            viewConfig,
            valueset1AvailableConceptsExpressions: {
                [source: string]: string;
            },
            referenceManyEntityFilterExpressions: {
                [source: string]: {
                    entityType: string;
                    expression: string;
                };
            },
        ) => {
            const reference1EntityFilterExpressions = getFilteredRef1FilterExpressions(fields || []);
            const Evaluator = new FormContextEvaluator({
                basedOnEntityOptions: null,
                evaluationFactors: {
                    fieldWidgets: (fields || []).reduce((prev, curr) => {
                        const fieldName = getFieldNameInFormContext(curr);
                        prev[fieldName] = [fieldName];
                        return prev;
                    }, {}),
                    referenceManyEntityFilterExpressions,
                    reference1EntityFilterExpressions,
                    valueset1Fields: getValueset1Fields(fields || []),
                    valueset1AvailableConceptsExpressions,
                },
                options: { dateFormat: viewConfig && viewConfig.application && viewConfig.application.dateFormat },
                viewConfig,
            });
            return Evaluator;
        },
    );
};

const combiner = (
    evaluator: FormContextEvaluator,
    fields: ReportDefinitionParam[],
    values: {},
    entities: {},
    valueSets: ValueSets,
    initialValues: {},
    extraContext?: {},
): ReturnType<FormContextEvaluator['evaluate']> => {
    const result = evaluator.evaluate(
        values || initialValues || {},
        valueSets,
        initialValues || {},
        entities,
        extraContext,
    );
    return result;
};
const getFormValues = (state: RootState) => state.form!['current-report-form']?.values;

const getFieldNameInFormContext = (curr: ReportDefinitionParam) => {
    switch (curr.type) {
        case 'value-set-dropdown':
        case 'entity-typeahead': {
            return curr.name.endsWith('Id') ? curr.name : curr.name + 'Id';
        }
        // It looks like we're not doing this for multiple entity ones.
        // case 'multiple-entity-typeahead':
        case 'value-set-multi-select': {
            return curr.name.endsWith('Ids') ? curr.name : curr.name + 'Ids';
        }
        default: {
            return curr.name;
        }
    }
};
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    const formContextEvaluatorSelector = getFormContextEvaluatorSelector();

    const initialValueSelector = createSelector(
        (state: RootState, props: ReportFormContextProps) => props.reportDefinition,
        (reportDefinition) => {
            const initialValues =
                reportDefinition?.fields.reduce((prev, curr) => {
                    const fieldName = getFieldNameInFormContext(curr);
                    prev[fieldName] = null;
                    return prev;
                }, {}) ?? {};
            return initialValues;
        },
    );
    const selector = createSelector(
        formContextEvaluatorSelector,
        getReportFields,
        getFormValues,
        getEntities,
        getValueSets,
        initialValueSelector,
        combiner,
    );
    return createDeepEqlSelector(selector);
};

export const defaultReportFormContext: ReturnType<typeof evaluateContext2> = {
    variables: {},
    hiddenFields: {},
    disabledFields: {},
    fieldValues: {},
    nullFilteredRefOneFields: [],
    registeredValues: {},
    visibleAndEditableFields: [],
    isDirty: false,
    dirtyValues: {},
    initialValues: {},
    valuesetFieldAvailableConceptIds: {},
    availableOptions: {},
    tableRowContexts: {},
};

export const { formContext, formContextHoc, FormContextProvider } = createFormContext(
    createFormContextSelector,
    defaultReportFormContext,
);
