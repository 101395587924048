import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ManagedFormCreate from './Create/ManagedFormCreate';
import List from './List/List';
import Edit from './Edit/Edit';

const CasetivityManagedBpmRoute = () => {
    let match = useRouteMatch();
    return (
        <div>
            <Switch>
                <Route path={`${match.path}/create-form`} component={ManagedFormCreate} />
                <Route path={`${match.path}/form/:key`} component={Edit} />
                <Route path={`${match.path}/forms`} component={List} />
                <Route path={`${match.path}/`} component={() => <div>Home</div>} />
            </Switch>
        </div>
    );
};

export default CasetivityManagedBpmRoute;
