import React, { FunctionComponent, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import GenericList from 'components/generics/genericList';
import getListRenderer from 'components/generics/genericList/renderList';
import { push as pushAction } from 'connected-react-router';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import useViewConfig from 'util/hooks/useViewConfig';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerCell: {
            position: 'sticky',
            zIndex: 3,
            backgroundColor: theme.palette.background.paper,
            top: 0,
        },
        listResults: {
            position: 'relative',
            overflowY: 'auto',
            maxHeight: 270,
            '@media print': {
                overflowY: 'unset',
                maxHeight: 'unset',
            },
        },
    }),
);
interface ClaimableTasksWidgetProps {
    processKey: string[];
    taskKey: string[];
    roles: string[];
    viewName: string;
    onlyUnassigned?: boolean;
    onlyAssignedToUser?: boolean;
    claimTaskOnSelect?: boolean;
    filter?: string;
}

const ClaimableTasksWidget: FunctionComponent<ClaimableTasksWidgetProps> = (props) => {
    const {
        viewName = '_ACTIVETASK_LIST_DASHBOARD',
        processKey,
        taskKey,
        roles,
        onlyUnassigned,
        onlyAssignedToUser,
        claimTaskOnSelect = false,
        filter: additionalFilterStr,
    } = props;
    const classes = useStyles();
    const viewNotFound = useAppSelector((state: RootState) => !state.viewConfig?.views?.[viewName]);
    const viewConfig = useViewConfig();
    const userLogin = viewConfig?.user?.login;
    const dispatch = useDispatch();
    const [location, setLocation] = useState({
        pathname: '/activetasklistdash',
        search: '',
    });
    const filter = useMemo(() => {
        let _filter = {};
        if (processKey && processKey.length > 0) {
            _filter['processInstance.businessKey__IN'] = `${processKey}`;
        }
        if (taskKey && taskKey.length > 0) {
            _filter['taskKey__IN'] = `${taskKey}`;
        }
        if (roles) {
            _filter['roleNames__IN'] = `${roles}`;
        }
        if (onlyUnassigned) {
            _filter['assigneeId__NOT_EMPTY'] = false;
        }
        if (onlyAssignedToUser && userLogin) {
            _filter['assignee.login'] = userLogin;
        }
        if (additionalFilterStr) {
            const evaluatedFilterString = evaluateFilterString(additionalFilterStr, {});
            const filterObj = getFilterFromFilterString(evaluatedFilterString);

            Object.assign(_filter, filterObj);
        }
        return _filter;
    }, [processKey, taskKey, roles, onlyUnassigned, userLogin, onlyAssignedToUser, additionalFilterStr]);

    if (viewNotFound) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'rgba(0,0,0,0.6)',
                }}
            >
                <span style={{ flexShrink: 0 }}>View name is incorrect or not specified</span>
            </div>
        );
    }
    return (
        <div style={{ height: '100%' }}>
            <WithErrorBoundary>
                <GenericList
                    useCard={false}
                    actions={null}
                    onRowSelect={([task]: any) => {
                        dispatch(
                            pushAction(
                                task.processInstanceId
                                    ? `/processes/${task.processInstanceId}/tasks/${task.id}/start${
                                          claimTaskOnSelect ? '?claimIfUnassigned=true' : ''
                                      }`
                                    : `/tasks/${task.id}`,
                            ),
                        );
                    }}
                    overrideApi={'api/claimable-tasks'}
                    filter={filter}
                    hasCreate={false}
                    multiSelectable={true}
                    updateUrlFromFilter={false}
                    viewName={viewName}
                    showFilters={false}
                    resource={'ActiveTask'}
                    showImmediately={true}
                    perPage={'25'}
                    renderList={(r) =>
                        getListRenderer(
                            classes,
                            {},
                        )({
                            ...r,
                            ariaProps: { ...r.ariaProps, 'aria-label': 'My Tasks' },
                        })
                    }
                    location={location}
                    fakePush={(location) => {
                        setLocation(location);
                    }}
                />
            </WithErrorBoundary>
        </div>
    );
};

export default ClaimableTasksWidget;
