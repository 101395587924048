import React, { useCallback } from 'react';
import { Button, Card, IconButton, TableCell, Tooltip } from '@material-ui/core';
import getNoResultsElement from 'components/generics/genericList/getNoResultsTextElement';
import LinkButton from 'components/links/LinkButton';
import Message from 'i18n/components/Message';
import { makeAdhocList } from 'mui-rest-list';
import { Link } from 'react-router-dom';
import { CasetivityFormListItem, services } from 'sideEffect/services';
import { Add, GetApp } from '@material-ui/icons';
import FilterForm from './Filter';
import useService from 'util/hooks/useService';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import ImportFormButton from './ImportForm';

const TaskFormList = makeAdhocList<CasetivityFormListItem>();

const ExportButton = ({ formKey }: { formKey: string }) => {
    const service = useCallback(
        () =>
            services.taskFormsDefinitions.export(formKey).pipe(
                tap((formDefinition) => {
                    saveAs(
                        new Blob([JSON.stringify(formDefinition, null, 2)], { type: 'application/json' }),
                        `${formKey}-export.json`,
                    );
                }),
            ),
        [formKey],
    );
    const [exportState, exportForm, { StateIcon: ExportStateIcon }] = useService(service);

    return (
        <Tooltip title={exportState.status === 'error' ? 'An error occurred' : 'Download Deployment File'}>
            <IconButton disabled={exportState.status === 'pending'} onClick={exportForm} size="small">
                {ExportStateIcon ?? <GetApp />}
            </IconButton>
        </Tooltip>
    );
};
const List = () => {
    return (
        <Card>
            <TaskFormList.AdhocList
                type="paginated"
                pagesNIndexed={0}
                defaultSize={10}
                hasRefresh
                titleOptions={{
                    type: 'Typography',
                    TypographyProps: {
                        variant: 'h5',
                        component: 'span',
                        style: {
                            marginLeft: '1rem',
                            marginTop: '1rem',
                        },
                    },
                    text: 'Form Definitions',
                }}
                TopRightAction={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ImportFormButton />
                        <span style={{ marginLeft: '1em' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to="/admin/bpm/create-form"
                                endIcon={<Add />}
                            >
                                Create
                            </Button>
                        </span>
                    </div>
                }
                initialFilter={{ name: '', key: '' }}
                renderFilter={(params) => <FilterForm {...params} />}
                renderNoResults={() => getNoResultsElement(<Message id="list.noResults" dm="No results found" />)}
                getDataObservable={({ orderBy, filter, size, page }) =>
                    services.taskFormsDefinitions.list({
                        page,
                        size,
                        filter: {
                            nameLike: filter?.name ?? '',
                            keyLike: filter?.key ?? '',
                        },
                        orderBy,
                    })
                }
                tableCaption="Reports available"
            >
                <TaskFormList.AdhocListColumn sortable fieldKey="name" title="name" />
                <TaskFormList.AdhocListColumn sortable fieldKey="key" title="Key" />
                <TaskFormList.AdhocListColumn fieldKey="version" title="Version" />
                <TaskFormList.AdhocListColumn
                    fieldKey="key"
                    hideColTitle
                    title="Link"
                    renderdata={(key) => (
                        <TableCell>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                <ExportButton formKey={key} />
                                <LinkButton to={`/admin/bpm/form/${key}`}>Edit</LinkButton>
                            </div>
                        </TableCell>
                    )}
                />
            </TaskFormList.AdhocList>
        </Card>
    );
};

export default List;
