import { createSlice } from '@reduxjs/toolkit';

export const refreshProcessDataKey = createSlice({
    name: 'RefreshProcessDataKey',
    initialState: 1,
    reducers: {
        increment: (state) => {
            return state + 1;
        },
    },
});

// Action creators are generated for each case reducer function
export const { increment } = refreshProcessDataKey.actions;

export default refreshProcessDataKey.reducer;
