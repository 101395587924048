import React from 'react';
import Downshift, { StateChangeOptions } from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, MenuItem, Paper, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { WithStyles } from '@material-ui/core';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';
import DebouncedTextInput from '../DebouncedTextInput';
import { valuesetOneHoc, ValuesetHocInjectedProps, renderInput, styles } from '../ValueSelectDownshift';
import { Concept } from '../Concept';
import Clear from '@material-ui/icons/Clear';

type Input = WrappedFieldInputProps;
type Meta = WrappedFieldMetaProps;

interface ValuesetSuggestProps {
    emptyText?: string;
    options?: {
        id: string;
    };
    label?: string;
    input: Input;
    meta: Meta;
    isRequired?: boolean;
    disabled?: boolean;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    ariaInputProps?: {};
    renderLabel?: boolean;
    valueSet?: string;
    resource?: string;
    source: string;
    group?: string | null;
    conceptIds?: string[];
    shouldFetchValueset?: boolean;
    isPopover?: boolean; // used to determine size of dropdown
}

type ValuesetSuggestComponentProps = ValuesetSuggestProps & ValuesetHocInjectedProps & WithStyles<typeof styles>;

class ValuesetSuggestComponent extends React.Component<ValuesetSuggestComponentProps> {
    getDisplayFromId = (id) => ((this.props.dataTableById || {})[id] || { display: '' }).display;

    getSuggestions = (inputValue: string) => {
        let count = 0;

        return this.props.dataSource
            .filter((suggestion) => suggestion.active)
            .filter((suggestion) => {
                const keep =
                    (!inputValue || suggestion.display.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) &&
                    count < 1000;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    renderSuggestion = ({
        suggestion,
        index,
        itemProps,
        highlightedIndex,
    }: {
        suggestion: Concept;
        index?: number;
        itemProps?: {};
        highlightedIndex: number | null;
        selectedItem?: string;
    }) => {
        const isHighlighted = highlightedIndex === index;
        return (
            <MenuItem
                data-value={suggestion.code}
                {...itemProps}
                key={suggestion.id || 'nullitem'}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: 400,
                }}
                role={'option'}
            >
                {suggestion.display}
            </MenuItem>
        );
    };

    handleStateChange = (changes: StateChangeOptions<Concept>) => {
        if (
            changes.type === Downshift.stateChangeTypes.changeInput ||
            changes.type === Downshift.stateChangeTypes.unknown ||
            changes.type === Downshift.stateChangeTypes.clickItem ||
            changes.type === Downshift.stateChangeTypes.keyDownEnter
        )
            if (changes.hasOwnProperty('selectedItem')) {
                this.props.input.onBlur(
                    changes.selectedItem && typeof changes.selectedItem === 'object'
                        ? this.getDisplayFromId(changes.selectedItem.id)
                        : typeof changes.selectedItem === 'string'
                        ? changes.selectedItem
                        : '',
                );
            } else if (changes.hasOwnProperty('inputValue')) {
                this.props.input.onChange(changes.inputValue || '');
            }
    };
    render() {
        const {
            classes,
            emptyText = '',
            disabled = false,
            dropdownPosition = 'below',
            ariaInputProps = {},
            renderLabel = true,
            isPopover = false,
            tooltipText,
        } = this.props;
        const paperClass = dropdownPosition === 'below' ? classes.paper : classes.paperTop;
        const El = (
            <div className={classes.root} style={{ position: 'relative' }}>
                <Downshift
                    itemToString={(item) => item && item.display}
                    onStateChange={this.handleStateChange}
                    inputValue={this.props.input.value || ''}
                    selectedItem={this.props.input.value || ''}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        getLabelProps,
                        isOpen,
                        inputValue,
                        selectedItem: selectedItem2,
                        highlightedIndex,
                        clearSelection,
                        openMenu,
                    }) => (
                        <div className={classes.container}>
                            {renderInput({
                                fullWidth: true,
                                classes: {
                                    inputRoot: classes.inputRoot,
                                    iconButton: renderLabel
                                        ? classes.iconButtonWithLabelSpacing
                                        : classes.iconButtonWithoutLabelSpacing,
                                },
                                InputProps: getInputProps({
                                    placeholder: selectedItem2 ? selectedItem2.display : emptyText,
                                    onClick: () => !disabled && openMenu(),
                                    onBlur: this.props.input.onBlur,
                                    disabled,
                                }),
                                InputLabelProps: getLabelProps(),
                                clearSelection,
                                label: this.props.label,
                                meta: this.props.meta,
                                ariaInputProps,
                                renderLabel,
                                isRequired: this.props.isRequired,
                                disabled,
                                value: inputValue,
                            })}
                            {isOpen ? (
                                <Paper
                                    {...getMenuProps()}
                                    className={isPopover ? classnames(paperClass, classes.popoverPaper) : paperClass}
                                    square={true}
                                >
                                    {this.getSuggestions(inputValue).map((suggestion, index) =>
                                        this.renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem: selectedItem2,
                                        }),
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    )}
                </Downshift>
                {this.props.input.value && !disabled && (
                    <IconButton
                        color="inherit"
                        classes={{
                            root: renderLabel
                                ? classes.iconButtonWithLabelSpacing
                                : classes.iconButtonWithoutLabelSpacing,
                        }}
                        onClick={() => {
                            this.props.input.onChange?.('');
                            this.props.input.onBlur('');
                        }}
                        aria-label={`Clear value for "${this.props.label}"`}
                    >
                        <Clear />
                    </IconButton>
                )}
            </div>
        );
        if (tooltipText) {
            return (
                <Tooltip title={tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                    <div>{El}</div>
                </Tooltip>
            );
        }
        return El;
    }
}

const ValuesetSelect: React.SFC<ValuesetSuggestProps> = valuesetOneHoc(
    withStyles(styles)((props: ValuesetSuggestComponentProps) => (
        <DebouncedTextInput
            emptyInitialValue=""
            input={props.input}
            renderInput={({ value, onChange, onBlur }) => {
                return (
                    <ValuesetSuggestComponent
                        {...props}
                        input={{
                            ...props.input,
                            value,
                            onChange,
                            onBlur,
                        }}
                    />
                );
            }}
        />
    )),
);
export default ValuesetSelect;
