import React from 'react';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import Dialog from '@material-ui/core/Dialog';
import { closeProfilePrompt } from '../actions';
import { Button, Card, CardActions, CardHeader } from '@material-ui/core';
import SelectProfileList from './ProfileList';

const SelectProfilePrompt = () => {
    const dispatch = useDispatch();
    const profiles = useAppSelector((state: RootState) => state.profiles);
    /*
        https://strategicsolutionsgroup.atlassian.net/browse/VEA-1126
        Apparently, when you log in, you can be served an initial user
        which isn't one of the possible profiles you select here.

        In that scenario, hitting 'Close' below allows you to continue as that profile
        (which they don't want - in the JIRA above)
        If that's the case, it's weird that you are served a profile on login that you aren't even allowed to use.

        But anyway - in order to allow access to that profile for debugging and testing purposes,
        I'm giving myself secret access:
        1. in development mode
        2. In production mode, you can set 'debugMode' in the hidden tools

        Which will enable clicking 'close' and continuing without switching to a different profile.
        
    */
    const inDebugMode = useAppSelector((state: RootState) => state.debugMode);
    const allowClose = inDebugMode || process.env.NODE_ENV === 'development';
    if (profiles.state === 'pick_profile') {
        return (
            <Dialog open>
                <Card>
                    <CardHeader title="Select Profile" />
                    <SelectProfileList />
                    {profiles.currentUserId && allowClose ? (
                        <CardActions>
                            <Button
                                onClick={() => {
                                    dispatch(closeProfilePrompt());
                                }}
                                variant="contained"
                            >
                                Close
                            </Button>
                        </CardActions>
                    ) : null}
                </Card>
            </Dialog>
        );
    }
    return null;
};
export default SelectProfilePrompt;
