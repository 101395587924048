import React, { useCallback, useMemo } from 'react';
import { TaskForm } from 'reducers/taskFormType';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import useEntities from 'util/hooks/useEntities';
import useViewConfig from 'util/hooks/useViewConfig';
import get from 'lodash/get';
import { getRefEntityName } from 'components/generics/utils/viewConfigUtils';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import ControlledValidatedBpmForm from './internal/ControlledForm';
import BpmFormExpressionTester from 'expression-tester/bpm-form';
import { AsyncEventsInProgressContextProvider } from 'bpm/components/TaskDetail/asyncEventCountContext';
import { InternationalizeForm } from 'bpm/internationalizeForm/useInternationalizedForm';
import { startFormContext } from 'bpm/start-form/components/startFormContext';
import EnhancedRGridWithVis from 'components/generics/form/EnhancedRGridTask';
import memoizeOne from 'memoize-one';
import { useFormContext } from 'fieldFactory/input/inputHigherOrderComponents/withValueFromFormContext';
import { EntityFormContext } from 'components/generics/form/EntityFormContext';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import { FormRenderProps } from 'react-final-form';
import { CasetivityViewContextProvider } from 'util/casetivityViewContext';
import { createGetStartFormInitialValues } from 'bpm/components/TaskDetail/TaskForm/getInitialValues';
import { useAppSelector } from 'reducers/rootReducer';

interface BpmFormFieldProps {
    input: Pick<WrappedFieldInputProps, 'onChange' | 'onBlur' | 'value'>;
    meta?: Partial<WrappedFieldMetaProps>;
    bpmFormDefinition?: Partial<TaskForm>;
    readSchemasFromField?: string;
    disabled?: boolean;
    from: 'Flowable' | 'Entity';
    renderBelowFields?: (props: FormRenderProps) => JSX.Element;
}

export const useBpmFormDefinition = <
    Props extends {
        from: 'Flowable' | 'Entity';
        readSchemasFromField?: string;
        bpmFormDefinition?: Partial<TaskForm>;
    },
>(
    props: Props,
) => {
    const { from, readSchemasFromField } = props;
    const fc = useFormContext(from);

    const formValues = fc.fieldValues;
    const viewConfig = useViewConfig();
    const entities = useEntities();
    const rootId = fc.fieldValues['id'];
    const viewName = (fc as EntityFormContext).viewName;

    // So we only produce one formDefinition object per config string, and prevent render infinite loops
    const JSONparse = useMemo(() => memoizeOne((value: string) => JSON.parse(value)), []);

    const { bpmFormDefinition = props.bpmFormDefinition } = useMemo((): {
        bpmFormDefinition?: Partial<TaskForm>;
    } => {
        if (readSchemasFromField) {
            let f = readSchemasFromField;
            const fieldPath = f.split('.');
            /**
             * field from our base record - either reaching the form definition value directly, or
             * at least reaching some dynamic reference which can then be further looked up using the remaining 'fieldFromRelationPath'
             * */

            let fieldFromUsPath: string = '';
            /**
             * if we have some dynamic reference, this field will be used to look up the form definition values from that referenced entity.
             * if it's empty, we can assume fieldFromUsPath contains the value we are after
             * */

            let fieldFromRelationPath: string = '';

            fieldPath.forEach((fieldInPath) => {
                const newPath = (fieldFromUsPath ? fieldFromUsPath + '.' : '') + fieldInPath;
                if (get(formValues, newPath) || get(formValues, newPath + 'Id')) {
                    fieldFromUsPath = newPath;
                } else {
                    fieldFromRelationPath += fieldFromRelationPath ? '.' + fieldInPath : fieldInPath;
                }
            });

            if (!fieldFromRelationPath) {
                const valueAtUsPath = get(formValues, fieldFromUsPath);
                if (!valueAtUsPath) {
                    return {};
                }
                const parsedValue = JSONparse(valueAtUsPath);
                if (parsedValue.definition) {
                    return { bpmFormDefinition: parsedValue.definition };
                }
                return {};
            } else if (from === 'Flowable') {
                const def = get(formValues, fieldFromRelationPath);
                if (def) {
                    try {
                        return {
                            bpmFormDefinition: JSONparse(def)['definition'],
                        };
                    } catch (e) {
                        console.error(e);
                    }
                }
            }

            const id = !fieldFromUsPath ? rootId : get(formValues, fieldFromUsPath + 'Id');
            if (!id) {
                return {};
            }
            if (from === 'Flowable') {
                // no deep paths, since we are in a task form.
                return {};
            }
            try {
                const rootEntityType = viewConfig.views[viewName].entity;
                const relEntityName = !fieldFromUsPath
                    ? rootEntityType
                    : getRefEntityName(viewConfig, rootEntityType, fieldFromUsPath, 'TRAVERSE_PATH');
                return traverseGetData(
                    viewConfig,
                    fieldFromRelationPath,
                    { id, entityType: relEntityName },
                    entities,
                    false,
                ).fold({}, (field) => {
                    try {
                        const config = JSONparse(field);
                        return {
                            bpmFormDefinition: config['definition'],
                        };
                    } catch (e) {
                        console.error(e);
                        return {};
                    }
                });
            } catch (e) {
                console.error(e);
                return {};
            }
        }
        return {};
    }, [formValues, entities, viewConfig, readSchemasFromField, viewName, JSONparse, rootId, from]);
    return { bpmFormDefinition };
};

const BpmFormField: React.FC<BpmFormFieldProps> = (props) => {
    const { renderBelowFields } = props;
    const { bpmFormDefinition } = useBpmFormDefinition(props);
    const { input: { value, onChange, onBlur } = {}, meta = {}, disabled } = props;

    const expressionTesterOpen = useExpressionTesterOpen();

    const initial = meta.initial;

    const defaultValuesSelector = useMemo(createGetStartFormInitialValues, []);
    const defaultValues = useAppSelector((state) =>
        defaultValuesSelector(state, { formDefinition: bpmFormDefinition as TaskForm }),
    );

    const initialValues = useMemo(() => (initial ? JSON.parse(initial) : defaultValues), [initial, defaultValues]);

    const values = useMemo(() => {
        const _values = value && JSON.parse(value);
        return {
            ...defaultValues,
            ..._values,
        };
    }, [value, defaultValues]);
    const setValues = useCallback(
        (v) => {
            const values = v ? JSON.stringify(v) : '';
            onChange?.(values);
        },
        [onChange],
    );
    if (!bpmFormDefinition) {
        if (expressionTesterOpen === 'CLOSED') {
            return null;
        }
        return (
            <div>
                <b>No form definition</b>
                <pre>
                    {JSON.stringify(
                        {
                            formDefinition: props.bpmFormDefinition,
                            readFromField: props.readSchemasFromField,
                            originalDef: JSON.parse(props['data-originaldefinition'] ?? '{}'),
                        },
                        null,
                        1,
                    )}
                </pre>
            </div>
        );
    }

    return (
        <CasetivityViewContextProvider currentViewContext="bpm">
            <BpmFormExpressionTester
                contextType="controlled"
                values={values}
                initialValues={initialValues}
                formDefinition={bpmFormDefinition as TaskForm}
            >
                {({ formDefinition }) => (
                    <AsyncEventsInProgressContextProvider>
                        <InternationalizeForm taskForm={formDefinition}>
                            {({ internationalizedForm }) => (
                                <startFormContext.Provider value={internationalizedForm}>
                                    <div>
                                        <ControlledValidatedBpmForm
                                            disabled={disabled}
                                            values={values}
                                            submitFailed={meta.submitFailed}
                                            bpmFormDefinition={internationalizedForm}
                                            setValues={setValues}
                                        >
                                            {(props, fields) => {
                                                return (
                                                    // we only need a StartFormValidateContext if we want a wizard widget
                                                    // <StartFormValidateContextProvider formId={formId} fields={fields}>
                                                    <div>
                                                        {fields && (
                                                            <EnhancedRGridWithVis
                                                                fields={fields}
                                                                formDefinition={internationalizedForm}
                                                            />
                                                        )}
                                                        {renderBelowFields?.(props) ?? null}
                                                    </div>
                                                );
                                            }}
                                        </ControlledValidatedBpmForm>
                                    </div>
                                </startFormContext.Provider>
                            )}
                        </InternationalizeForm>
                    </AsyncEventsInProgressContextProvider>
                )}
            </BpmFormExpressionTester>
        </CasetivityViewContextProvider>
    );
};

export default BpmFormField;
