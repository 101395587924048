import { createSlice } from '@reduxjs/toolkit';

export const visibleViewNames = createSlice({
    name: 'VisibleViewNames',
    initialState: false,
    reducers: {
        toggle: (state) => {
            return !state;
        },
        show: () => true,
        hide: () => false,
    },
});

export const { show, hide, toggle } = visibleViewNames.actions;

export default visibleViewNames.reducer;
