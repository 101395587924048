import React, { useCallback } from 'react';
import Axios from './axiosProps';
import { MenuItem } from '@material-ui/core';
import { BACKEND_BASE_URL } from 'config';
import { GetItemPropsOptions } from 'downshift';
import Add from '@material-ui/icons/Add';

interface EntityAutocompleteDropdownProps {
    setItemCount: (count: number) => void;
    restUrl: string;
    inputValue: string;
    filter: {};
    refEntityDisplayNamePlural: string;
    getItemProps: (options: GetItemPropsOptions<any>) => any;
    highlightedIndex: number;
    selectedItem: any;
    createable?: boolean;
    resultSize?: number;
}

const EntityAutocompleteDropdown: React.SFC<EntityAutocompleteDropdownProps> = ({
    setItemCount,
    restUrl,
    inputValue,
    filter,
    getItemProps,
    refEntityDisplayNamePlural,
    selectedItem,
    highlightedIndex,
    createable,
    resultSize,
}) => {
    const createElem = (index: number) => (
        <MenuItem
            key="create-item"
            {...getItemProps({
                item: { __create: true },
                index,
                selected: highlightedIndex === index,
                isSelected: selectedItem?.__create,
            })}
            style={{ width: '100%' }}
            component="div"
        >
            <Add color="primary" fontSize="small" />
            {'  '}Create
        </MenuItem>
    );
    const setItemCountPlusOne = useCallback((n: number) => setItemCount(n + 1), [setItemCount]);
    return (
        <Axios<any>
            onSuccess={createable ? setItemCountPlusOne : setItemCount}
            url={BACKEND_BASE_URL + restUrl}
            params={{
                'title.equals': inputValue ? `*${inputValue}*` : '*',
                sort: 'title%2CASC',
                size: resultSize ?? 10,
                ...filter,
            }}
        >
            {({ loading, error, data: items = [], total }) => {
                if (loading) {
                    return (
                        <MenuItem component="div" aria-live="polite" disabled key="status">
                            Loading...
                        </MenuItem>
                    );
                }

                if (error) {
                    return (
                        <MenuItem component="div" aria-live="polite" disabled key="status">
                            Error! {error}
                        </MenuItem>
                    );
                }

                if (!items.length) {
                    return (
                        <>
                            <MenuItem component="div" aria-live="polite" disabled key="status">
                                No {refEntityDisplayNamePlural || 'results'} found
                            </MenuItem>
                            {createable && createElem(0)}
                        </>
                    );
                }
                return items
                    .map((record, index) => (
                        <MenuItem
                            key={record.id}
                            {...getItemProps({
                                item: record,
                                index,
                                selected: highlightedIndex === index,
                                isSelected: selectedItem && selectedItem.id === record.id,
                            })}
                            component="div"
                        >
                            {record.title}
                        </MenuItem>
                    ))
                    .concat(createable ? [createElem(items.length)] : [])
                    .concat([
                        <MenuItem component="div" aria-live="polite" key="status" disabled>
                            Showing {items.length} out of {total} result
                            {total === 1 ? '' : 's'}.
                        </MenuItem>,
                    ]);
            }}
        </Axios>
    );
};

export default EntityAutocompleteDropdown;
