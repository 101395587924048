import React from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { createSelector } from 'reselect';
import { fromNullable } from 'fp-ts/lib/Option';

const useTitle = (args: { id: string; resource: string }) => {
    const { id, resource } = args;
    const titleSelector = React.useMemo(() => {
        const getEntities = createGetEntities();
        return createSelector(
            getEntities,
            (state, props) => props.id,
            (state, props) => props.resource,
            (entities, id, resource) => {
                return fromNullable(entities[resource])
                    .mapNullable((e) => e[id])
                    .mapNullable((r) => r.title)
                    .toUndefined();
            },
        );
    }, []);
    return useAppSelector((state: RootState) => titleSelector(state, { id, resource }));
};
export default useTitle;
