import React, { useState, useMemo, FunctionComponent } from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { stringify } from 'query-string';
import { GenericListWithPopovers } from '../../components/generics/genericList/index';
import PopoverListActions from '../../components/generics/genericList/PopoverListActions';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import View from '@material-ui/icons/RemoveRedEye';
import { tableRowContext } from 'fieldFactory/input/components/EditableTable/util/tableRowContext';
import useViewConfig from 'util/hooks/useViewConfig';
import { getHasSomeVisibleSearchFields } from 'components/generics/genericList/List';

const PopoverListActns: any = PopoverListActions;

interface SearchSelectDialogProps {
    expansions?: string[];
    viewName?: string;
    createViewName?: string;
    reference: string;
    values: string[];
    isOpen: boolean;
    handleClose: () => void;
    setReference: (val: { id: string }, update: 'ADD' | 'REMOVE') => void;
    onCreateCb: (val: { id: string }) => void;
    filter?: {};
    formId?: string;
    hideCreate?: boolean;
    editViewName?: string;
    showViewName?: string;
    injectCreateValues?: {};
    evaluatedAdhocSPELVariables?: {};
}
const ClearIcon = (props) => <Clear {...props} color="secondary" />;
const AddIcon = (props) => <Add {...props} color="primary" />;

const SearchSelectDialog: FunctionComponent<SearchSelectDialogProps> = (props) => {
    const {
        isOpen,
        handleClose,
        reference,
        values,
        setReference,
        filter,
        injectCreateValues,
        onCreateCb,
        viewName: _viewName,
        formId: _formId,
        hideCreate,
        createViewName,
        editViewName,
        showViewName,
        evaluatedAdhocSPELVariables,
    } = props;
    const viewConfig = useViewConfig();
    const initialSearch = useMemo(() => {
        return `?${stringify({ filter: JSON.stringify(filter) })}`;
    }, [filter]);
    const [location, setLocation] = useState({ search: initialSearch });

    const viewName = _viewName || viewConfig.entities[reference].defaultViews?.LIST?.name;

    const viewHasSomeVisibleSearchFields = useMemo(() => {
        return viewName && getHasSomeVisibleSearchFields(viewConfig, viewName);
    }, [viewConfig, viewName]);
    const formId = _formId || `filterForm-resource:${reference}`;
    return (
        <tableRowContext.Provider value={null}>
            <Dialog
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
                fullWidth={true}
                open={isOpen}
                onClose={handleClose}
                maxWidth={false}
            >
                <DialogContent style={{ padding: 0, minWidth: '80vw' }}>
                    {isOpen && !viewName ? (
                        <div>No default LIST view was configured for the {reference} entity.</div>
                    ) : isOpen ? (
                        <GenericListWithPopovers
                            showViewName={showViewName}
                            editViewName={editViewName}
                            // It's really a pain clicking search all the time in integration tests, so lets have it search automatically
                            alwaysPreventInitialSearch={
                                process.env.NODE_ENV === 'test'
                                    ? undefined
                                    : (() => {
                                          const view = viewConfig.views[viewName];
                                          return (
                                              // no search has been submitted yet with this field
                                              // (without location.search === initialSearch, reopening and clicking submit doesn't work)
                                              location.search === initialSearch &&
                                              view &&
                                              viewHasSomeVisibleSearchFields
                                          );
                                      })()
                            }
                            formId={formId}
                            // onRowSelect={(selectedData, allData) => {
                            // do nothing.
                            // setSelected(fromEntries(selectedData.map(obj => [obj.id, obj])));
                            // }}
                            actions={
                                <PopoverListActns
                                    evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                    formId={formId}
                                    createViewName={createViewName}
                                    handleClose={handleClose}
                                    onCreateCb={(id, data) => {
                                        handleClose();
                                        onCreateCb(data);
                                    }}
                                    injectCreateValues={injectCreateValues}
                                    hideCreate={hideCreate}
                                />
                            }
                            renderList={({ defaultRenderer, ...args }) => {
                                return defaultRenderer({
                                    ...args,
                                    // navButtonBefore: true,
                                    onRowSelect: ([selected]) => {
                                        if (selected) {
                                            if (values.includes(selected.id)) {
                                                setReference(selected, 'REMOVE');
                                            } else {
                                                setReference(selected, 'ADD');
                                            }
                                        }
                                    },
                                    getSelectIconComponent: (r, record) => {
                                        if (values.includes(record.id)) {
                                            return ClearIcon;
                                        }
                                        return AddIcon;
                                    },
                                    renderAtRowEnd: (rargs, record) => {
                                        return [
                                            <IconButton
                                                key="clear"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    args.onRowSelect([record], rargs.data);
                                                }}
                                            >
                                                <View color="primary" />
                                            </IconButton>,
                                        ];
                                    },
                                });
                            }}
                            hasCreate={false}
                            multiSelectable={false}
                            updateUrlFromFilter={false}
                            viewName={viewName}
                            fakePush={setLocation}
                            location={location}
                            resource={reference}
                            reference={reference}
                            filter={filter}
                            isPopover={true}
                        />
                    ) : null}
                </DialogContent>
            </Dialog>
        </tableRowContext.Provider>
    );
};

export default SearchSelectDialog;
