import { ReportDefinitionStateItem } from './ReportDefinitionTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReportDefinition } from 'report2/ReportDefinition';
import { AjaxError } from 'rxjs/ajax';

const initialState: { [key: string]: ReportDefinitionStateItem } = {};
export const reportDefinitionSlice = createSlice({
    name: 'reportDefinitionState',
    initialState,
    reducers: {
        pendingReportDefinition(state, action: PayloadAction<{ reportName: string }>) {
            const { reportName } = action.payload;
            state[reportName] = { type: 'LOADING' };
        },
        successReportDefinition(state, action: PayloadAction<{ reportName: string; data: ReportDefinition }>) {
            const { reportName, data } = action.payload;
            state[reportName] = { type: 'SUCCESS', data };
        },
        failureReportDefinition(state, action: PayloadAction<{ reportName: string; error: AjaxError }>) {
            const { reportName, error } = action.payload;
            state[reportName] = { type: 'FAILURE', error };
        },
    },
});
export const { pendingReportDefinition, successReportDefinition, failureReportDefinition } =
    reportDefinitionSlice.actions;

export default reportDefinitionSlice.reducer;
