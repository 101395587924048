import React from 'react';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import { getTemplateDataPaths } from 'util/getTemplateDataPaths';
import WithStringEvaluatedInFormContext from 'fieldFactory/popovers/PopoverRefInput/EvaluateStringTemplateInFormContext';
import { useMemo, useContext } from 'react';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import { createSelector } from 'reselect';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import getViewConf from 'util/getViewConf';
import useViewConfig from 'util/hooks/useViewConfig';
import { expressionTesterProvidedViewConfigurationContext } from 'expression-tester/entity-form';

export const getOverrideTitle = (viewConfig: ViewConfig, viewName?: string): string | undefined => {
    return getViewConf(viewConfig, viewName)
        .mapNullable((pc: EntityViewConfig) => pc.overrideTitle)
        .toUndefined();
};

const getOverrideTitleSelector = <T extends { viewName: string; viewConfig?: ViewConfig }>() => {
    const overrideTitleSelector = createSelector(
        (state: RootState, props: T) => props.viewConfig || state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        getOverrideTitle,
    );
    return overrideTitleSelector;
};
export const useOverrideTitle = (viewName: string) => {
    const overriddenConfigurationContext = useContext(expressionTesterProvidedViewConfigurationContext);
    const viewConfig = useViewConfig();
    const overrideTitleSelector = useMemo(getOverrideTitleSelector, []);
    const _overrideTitle = useAppSelector((state: RootState) => overrideTitleSelector(state, { viewName, viewConfig }));
    const overrideTitle = overriddenConfigurationContext?.config?.[viewName]?.overrideTitle ?? _overrideTitle;
    const { expansionsRequired, dataPaths } = useMemo(() => {
        return getTemplateDataPaths(overrideTitle);
    }, [overrideTitle]);

    const overrideTitleElement = overrideTitle ? (
        <WithStringEvaluatedInFormContext
            from="Entity"
            context={{ type: 'adhoc', expansionsRequired, dataPaths, valuesetFieldsRequired: {} }}
            templateString={overrideTitle + ''}
        >
            {(html) => <SafeHtmlAsReact html={html} />}
        </WithStringEvaluatedInFormContext>
    ) : undefined;
    return {
        EditTitleElem: overriddenConfigurationContext?.elements?.[viewName]?.EditTitleElem ?? null,
        element: overrideTitleElement,
    };
};
