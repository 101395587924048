import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import { getFieldSourceFromPath } from 'components/generics/utils/viewConfigUtils';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import useIsTaskFormContext from 'expressions/hooks/allForms/useIsTaskFormContext';
import { useContext } from 'react';
import { TaskForm } from 'reducers/taskFormType';
import useViewConfig from 'util/hooks/useViewConfig';

const getFieldSourceInBpmForm = (field: string, formDefinition: Partial<TaskForm>) => {
    return (
        (formDefinition.fields ?? []).flatMap((f) => {
            switch (f.type) {
                case 'entity-lookup':
                case 'entity-typeahead':
                case 'value-set-dropdown':
                case 'value-set-radiobox': {
                    const id = f.id;
                    if (!id) {
                        return [];
                    }
                    const adjustedFieldId = (() => {
                        if (id.endsWith('Id')) {
                            return id.slice(0, 'Id'.length * -1);
                        }
                        return id;
                    })();
                    if (adjustedFieldId === field) {
                        return [adjustedFieldId];
                    }
                    if (field.endsWith('Id') && adjustedFieldId === field.slice(0, 'Id'.length * -1)) {
                        return [adjustedFieldId];
                    }
                    if (field.endsWith('Code') && adjustedFieldId === field.slice(0, 'Code'.length * -1)) {
                        return [adjustedFieldId];
                    }
                    return [];
                }
                default:
                    return [];
            }
        })[0] ?? field
    );
};

const useGetFieldSource = (): ((field: string) => string) => {
    const isTaskFormContext = useIsTaskFormContext();
    const flowableFormDefinitionContext = useContext(stagedFormDefinitionContext);
    const viewConfig = useViewConfig();
    const maybeViewName = useCurrentFormContext().viewName;
    const maybeRootEntity = maybeViewName && viewConfig.views[maybeViewName]?.entity;
    if (isTaskFormContext) {
        return (field) => getFieldSourceInBpmForm(field, flowableFormDefinitionContext.formDefinition);
    }
    if (maybeRootEntity) {
        return (field) => getFieldSourceFromPath(viewConfig, maybeRootEntity, field);
    }
    return () => {
        throw new Error('In unexpected context - expected either task form or entity form');
    };
};
export default useGetFieldSource;
