import { RootState } from '../rootReducer';
import isCyclic from 'util/isCyclic';

/**
 * These functions used to contain serialization/deserialization code, when our state wasn't strictly
 * serializable.
 *
 * It may be helpful to keep this around, in case it's needed in the future for state import/export.
 */
export const toSerializableState = (state: RootState) => {
    const stateIsCyclic = isCyclic(state);
    if (stateIsCyclic) {
        console.log('state is cyclic.');
    }
    return state;
};
export const rehydrateState = (state: ReturnType<typeof toSerializableState>): RootState => {
    return state;
};
