import React, { useRef, useEffect, useMemo } from 'react';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import useViewConfig from 'util/hooks/useViewConfig';
import preprocessFilter from 'clients/utils/preprocessFilter';
import useAxios from './useAxios';
import { BACKEND_BASE_URL } from 'config';

const AutoselectIfOnlySingleValue: React.FC<{
    filterString: string;
    restUrl: string;
    input: any;
    handleSelect: (record: any) => void;
}> = ({ filterString, input, restUrl, handleSelect }) => {
    const viewConfig = useViewConfig();

    /**
     * We need to track the prev/current filter to know if the filter changed due to a removal.
     * If so, we don't want to auto-select a value.
     */
    const currFilterRef = useRef<Record<string, any>>();
    const prevFilterRef = useRef<Record<string, any>>();
    const newFilter = useMemo(() => {
        prevFilterRef.current = currFilterRef.current;
        const filterObject = getFilterFromFilterString(filterString);
        const filter = preprocessFilter(filterObject, viewConfig);
        currFilterRef.current = filter;
        return filter;
    }, [filterString, viewConfig]);

    const axiosParams = useMemo(() => {
        return {
            size: 1,
            ...newFilter,
        };
    }, [newFilter]);

    const filterIsSmallerThanBefore =
        Object.keys(currFilterRef.current ?? {}).length < Object.keys(prevFilterRef.current ?? {}).length;

    const axios = useAxios<any[]>({
        url: filterIsSmallerThanBefore ? '' : BACKEND_BASE_URL + restUrl,
        params: axiosParams,
    });
    const { data, total } = axios;

    // allow clearing ourselves - don't fetch if our value just switched to null from not-null
    const prevInputValue = useRef(input.value);

    const record1ToSet =
        !filterIsSmallerThanBefore &&
        data?.[0] &&
        input.value !== data[0].id &&
        !(prevInputValue.current && !input.value)
            ? data[0]
            : null;

    prevInputValue.current = input.value;

    useEffect(() => {
        if (total === 1 && record1ToSet) {
            handleSelect(record1ToSet);
        }
    }, [total, handleSelect, record1ToSet]);
    return null;
};

export default AutoselectIfOnlySingleValue;
