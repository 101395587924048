import React, { useCallback, useMemo, useState } from 'react';
import { ReportDefinition, ReportDefinitionParam } from 'report2/ReportDefinition';
import SortableTransferList from './SortableTransferColumns';
import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import EditParamsTable from './EditParamsTable';
import { PersonalizedReportConfig, PersonalizedReportParams } from 'custom-reports/types';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import orderBy from 'lodash/orderBy';
import Alert from '@material-ui/lab/Alert/Alert';
import uniq from 'lodash/uniq';
import produce from 'immer';
import deepEql from 'deep-eql';
import { isEqual } from 'lodash';
import getInitialConfig from './getInitialConfig';

export const getConflicts = <Definition extends Pick<ReportDefinition, 'outputColumns' | 'params'>>(params: {
    reportDefinition: Definition;
    personalizedReportConfig: PersonalizedReportConfig;
}) => {
    const { reportDefinition, personalizedReportConfig } = params;
    const { outputColumnsMissing, sortColumnsMissing, paramsErrors } = (() => {
        const getParam = (paramName: string) => reportDefinition.params.find((param) => param.name === paramName);
        const getCol = (colName: string) => reportDefinition.outputColumns.find((col) => col === colName);
        const outputColumnsMissing: {
            [name: string]: true;
        } = {};
        personalizedReportConfig.outputColumns.forEach((colName) => {
            const matchingCol = getCol(colName);
            if (!matchingCol) {
                outputColumnsMissing[colName] = true;
            }
        });

        const paramsErrors: {
            [name: string]: 'missing' | 'defaultvalue-type-mismatch';
        } = {};
        Object.entries(personalizedReportConfig.params).forEach(([name, options]) => {
            const reportDefinitionParam = getParam(name);
            if (!reportDefinitionParam) {
                paramsErrors[name] = 'missing';
                return;
            }
            const isIncompatible = (type: ReportDefinitionParam['type'], defaultValue: unknown) => {
                // TODO implement this.
                return false;
            };
            if (
                typeof options.defaultValue !== 'undefined' &&
                isIncompatible(reportDefinitionParam.type, options.defaultValue)
            ) {
                paramsErrors[name] = 'defaultvalue-type-mismatch';
            }
        });

        const sortColumnsMissing: {
            [name: string]: true;
        } = {};

        personalizedReportConfig.sort.forEach(([sortColName]) => {
            const matchingParam = getCol(sortColName);
            if (!matchingParam) {
                sortColumnsMissing[sortColName] = true;
            }
        });
        return {
            outputColumnsMissing,
            sortColumnsMissing,
            paramsErrors,
        };
    })();
    const incompatibleWithParent =
        Object.values(outputColumnsMissing).length > 0 ||
        Object.values(sortColumnsMissing).length > 0 ||
        Object.values(paramsErrors).length > 0;
    return {
        incompatibleWithParent,
        outputColumnsMissing,
        sortColumnsMissing,
        paramsErrors,
    };
};
interface ReportEditorProps<ReportDefinition extends Pick<ReportDefinition, 'outputColumns' | 'params'>> {
    reportDefinition: ReportDefinition;
    initialValue: PersonalizedReportConfig;
    setInitialValue?: (initialValue: PersonalizedReportConfig) => void;
    onSubmit: (config: PersonalizedReportConfig) => void;
    disableSubmit?: boolean;
    onSubmitAsNew?: (config: PersonalizedReportConfig) => void;
    renderBelow?: (props: { dirty: boolean }) => JSX.Element;
}
const ReportEditor = <Definition extends Pick<ReportDefinition, 'outputColumns' | 'params'>>(
    props: ReportEditorProps<Definition>,
) => {
    const { reportDefinition } = props;
    const { outputColumns: reportDefinitionOutputColumns } = reportDefinition;
    const outputColumns = useMemo(() => {
        // Because the report-definition may have deleted an output column
        return uniq([...reportDefinitionOutputColumns, ...props.initialValue.outputColumns]);
    }, [reportDefinitionOutputColumns, props.initialValue.outputColumns]);

    const getItemTitle = useCallback(
        (ix) => {
            const title = outputColumns[ix];
            if (title.startsWith('"') && title.endsWith('"')) {
                return title.slice(1, -1);
            }
            return title;
        },
        [outputColumns],
    );

    const initialOutputColIxes: number[] = useMemo(
        () => Array.from(Array(outputColumns.length).keys()),
        [outputColumns],
    );
    const initialOutputIxes = useMemo(
        () => props.initialValue.outputColumns?.map((c) => outputColumns.indexOf(c)) ?? [],
        [props.initialValue.outputColumns, outputColumns],
    );

    const initialColIxes: number[] = useMemo(() => {
        const initial = initialOutputColIxes.filter((i) => !initialOutputIxes.includes(i));
        return orderBy(initial, [(ix) => outputColumns[ix].toLowerCase()]);
    }, [initialOutputColIxes, initialOutputIxes, outputColumns]);
    const [outputIxes, setOutputIxes] = useState<number[]>(initialOutputIxes);

    const initialSortsDirs = useMemo(
        () =>
            outputColumns.reduce((prev, curr) => {
                const currSortDir = props.initialValue.sort?.find(([field, dir]) => field === curr)?.[1];
                prev[curr] = currSortDir ?? 'ASC';
                return prev;
            }, {} as { [col: string]: 'ASC' | 'DESC' }),
        [outputColumns, props.initialValue.sort],
    );

    const [sortsDirs, setSortsDirs] = useState(initialSortsDirs);

    const initialSortColIxes = useMemo(
        () =>
            (props.initialValue.sort ?? []).map(([field, dir]) => {
                return outputColumns.indexOf(field);
            }),
        [props.initialValue.sort, outputColumns],
    );
    const remainingSortColIxes = useMemo(() => {
        const remaining = initialOutputColIxes.filter((i) => !initialSortColIxes.includes(i));
        return orderBy(remaining, [(ix) => outputColumns[ix].toLowerCase()]);
    }, [initialOutputColIxes, initialSortColIxes, outputColumns]);

    const [sortColIxes, setSortColIxes] = useState<number[]>(initialSortColIxes);

    const initialParams = useMemo(() => {
        const newParams = {
            // ensure any params from the reportDefinition are added.
            // for instance, if the ReportDefinition had params added to it since the last PersonalizedReport save
            ...getInitialConfig(props.reportDefinition).params,
            ...props.initialValue.params,
        };
        return newParams;
    }, [props.initialValue.params, props.reportDefinition]);
    const [params, setParams] = useState(initialParams);

    const assembledOutputConfig: PersonalizedReportConfig = useMemo(() => {
        return {
            outputColumns: outputIxes.map((ix) => outputColumns[ix]),
            params,
            sort: sortColIxes.map((sortIx) => [outputColumns[sortIx], sortsDirs[outputColumns[sortIx]]]),
        };
    }, [outputIxes, outputColumns, params, sortColIxes, sortsDirs]);

    const expressionTesterOpen = useExpressionTesterOpen() !== 'CLOSED';

    const { outputColumnsMissing, sortColumnsMissing, paramsErrors, incompatibleWithParent } = getConflicts<Definition>(
        {
            reportDefinition: props.reportDefinition,
            personalizedReportConfig: props.initialValue,
        },
    );

    const dirty = useMemo(() => {
        // get rid of nulls and empty strings
        const simplifyParamsForEqualityCheck = (params: PersonalizedReportParams) =>
            Object.entries(params).map(([k, v]) => [
                k,
                { ...v, defaultValue: !v.defaultValue ? undefined : v.defaultValue },
            ]);
        return (
            !deepEql(sortsDirs, initialSortsDirs) ||
            !isEqual(new Set(sortColIxes), new Set(initialSortColIxes)) ||
            !deepEql(simplifyParamsForEqualityCheck(params), simplifyParamsForEqualityCheck(initialParams)) ||
            !isEqual(new Set(initialOutputIxes), new Set(outputIxes))
        );
    }, [
        sortsDirs,
        initialSortsDirs,
        sortColIxes,
        params,
        initialParams,
        initialOutputIxes,
        outputIxes,
        initialSortColIxes,
    ]);

    const setInitialValue = props.setInitialValue;

    const handleSetParams = useCallback(
        (params, isDeleteOfInvalidParam) => {
            setParams(params);
            if (isDeleteOfInvalidParam) {
                const newInitialConfig = produce(assembledOutputConfig, (draft) => {
                    draft.params = params;
                });
                setInitialValue(newInitialConfig);
            }
        },
        [setParams, setInitialValue, assembledOutputConfig],
    );

    const renderItemMissingError = (outputColumnsIx: number) => {
        return (
            <Alert severity="error">
                Field not found in definition&nbsp;
                {!!props.setInitialValue && (
                    <Button
                        onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            const outputColumnName = outputColumns[outputColumnsIx];
                            const newInitialConfig = produce(assembledOutputConfig, (draft) => {
                                draft.outputColumns = assembledOutputConfig.outputColumns.filter(
                                    (col) => col !== outputColumnName,
                                );
                                draft.sort = assembledOutputConfig.sort.filter(([col]) => col !== outputColumnName);
                            });
                            props.setInitialValue(newInitialConfig);
                        }}
                        size="small"
                        color="inherit"
                        variant="outlined"
                    >
                        Delete?
                    </Button>
                )}
            </Alert>
        );
    };
    return (
        <div>
            {expressionTesterOpen ? (
                <pre>
                    {JSON.stringify(
                        {
                            paramsErrors,
                            outputColumnsMissing,
                            sortColumnsMissing,
                        },
                        null,
                        2,
                    )}
                </pre>
            ) : null}
            <Typography style={{ marginLeft: '1rem' }} variant="h6">
                Output Columns
            </Typography>
            <SortableTransferList
                error={Object.keys(outputColumnsMissing).length > 0}
                initialLeft={initialColIxes}
                setRight={setOutputIxes}
                right={outputIxes}
                getItemTitle={getItemTitle}
                EmptyRightMessage={<Typography>Select columns for the report output</Typography>}
                rightItemIsError={(ix) => {
                    const columnName = outputColumns[ix];
                    if (columnName && !outputColumnsMissing[columnName]) {
                        return false;
                    }
                    return renderItemMissingError(ix);
                }}
            />
            <Typography style={{ marginLeft: '1rem' }} variant="h6">
                Sort By
            </Typography>
            <SortableTransferList
                error={Object.keys(sortColumnsMissing).length > 0}
                initialLeft={remainingSortColIxes}
                setRight={setSortColIxes}
                rightItemIsError={(ix) => {
                    const columnName = outputColumns[ix];
                    if (columnName && !sortColumnsMissing[columnName]) {
                        return false;
                    }
                    return renderItemMissingError(ix);
                }}
                renderSecondaryAction={({ ix }) => (
                    <Select
                        style={{ fontSize: 'inherit', minWidth: '65px', textAlign: 'center' }}
                        aria-label={`${getItemTitle(ix)} sort direction`}
                        value={sortsDirs[outputColumns[ix]]}
                        onChange={(e) =>
                            setSortsDirs((sd) => {
                                return {
                                    ...sd,
                                    [outputColumns[ix]]: e.target.value as 'ASC' | 'DESC',
                                };
                            })
                        }
                    >
                        <MenuItem aria-label="Sort ascending" value="ASC">
                            ASC
                        </MenuItem>
                        <MenuItem aria-label="Sort descending" value="DESC">
                            DESC
                        </MenuItem>
                    </Select>
                )}
                right={sortColIxes}
                getItemTitle={getItemTitle}
                EmptyRightMessage={<Typography>Select sort columns (optional)</Typography>}
            />
            <Typography style={{ marginLeft: '1rem' }} variant="h6">
                Parameter Values
            </Typography>
            <EditParamsTable reportDefinition={props.reportDefinition} params={params} setParams={handleSetParams} />
            {props.renderBelow?.({ dirty }) ?? null}
            <div style={{ margin: '1em', display: 'flex', gap: '1rem', flexDirection: 'row-reverse' }}>
                {!!props.onSubmitAsNew && (
                    <Button
                        disabled={props.disableSubmit || incompatibleWithParent}
                        onClick={() => props.onSubmitAsNew(assembledOutputConfig)}
                        variant="contained"
                        color="primary"
                    >
                        Save as New
                    </Button>
                )}
                <Button
                    disabled={props.disableSubmit || incompatibleWithParent}
                    onClick={() => props.onSubmit(assembledOutputConfig)}
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
                {!!incompatibleWithParent && <Alert severity="error">Fix the errors above to enable saving</Alert>}
            </div>
            {expressionTesterOpen ? <pre>{JSON.stringify(assembledOutputConfig, null, 2)}</pre> : null}
        </div>
    );
};
export default ReportEditor;
