import React from 'react';
import { BranchManagementBanner } from 'branches/Banner/BranchManagementBanner';
import DetectConnectivity, { ConnectivityBannerSpacer } from 'connectivity/components/DetectConnectivity';

const Banner = () => {
    const renderBanners = (isSpacer: boolean) => (
        <>
            {isSpacer ? <ConnectivityBannerSpacer /> : <DetectConnectivity />}
            <BranchManagementBanner />
        </>
    );

    return (
        <>
            <div style={{ position: 'fixed', left: 0, right: 0, top: 0, zIndex: 100 }}>{renderBanners(false)}</div>
            {/* Lets have important banners that should stay at the top in a fixed position div. 
            Include one instance of each banner below to space everything properly. */}
            {renderBanners(true)}
        </>
    );
};

export default Banner;
