import * as fieldTypes from '../../fieldTypes';
import * as flowableFieldType from './flowableFieldTypes';

type flowableFieldType = (typeof flowableFieldType)[keyof typeof flowableFieldType];

export default (type: flowableFieldType): (typeof fieldTypes)[keyof typeof fieldTypes] => {
    const t = fieldTypes;
    switch (type) {
        case 'address2':
            return t.ADDRESS_VERIFICATION_2;
        case 'bpm-form':
            return t.BPM_FORM;
        case 'bpm-form-builder':
            return t.BPM_FORM_BUILDER;
        case 'relative-date':
            return t.RELATIVE_DATE;
        case 'video-watched':
            return t.VIDEO_WATCHED;
        case 'wizard-control':
            return t.WIZARD_CONTROL;
        case 'recaptcha':
            return t.RECAPTCHA;
        case 'auth-code':
            return t.AUTH_CODE;
        case 'image':
            return t.IMAGE;
        case 'time':
            return t.TIME;
        case 'address':
            return t.ADDRESS_VERIFICATION;
        case 'datetime':
            return t.DATETIME;
        case 'TEXTBOX':
        case 'text':
            return t.TEXT;
        case 'multi-line-text':
            return t.MULTILINE_TEXT;
        case 'integer':
            return t.INTEGER;
        case 'decimal':
            return t.FLOAT;
        case 'boolean':
            // return t.BOOL;
            return t.CHECKBOX;
        case 'CALENDAR':
        case 'date':
            return t.DATE;
        case 'radio-buttons':
            return t.RADIO;
        case 'dropdown':
            // hardcoded select type? have to look into this...
            return t.CHOICES_SELECT;
        case 'upload':
            return t.PROCESS_FILE_UPLOAD;
        case 'expression':
            return t.EXPRESSION;
        case 'table':
            return t.TABLE;
        case 'value-set-dropdown':
            return t.VALUESET_SELECT;
        case 'value-set-multi-select':
            return t.VALUESET_MULTISELECT;
        case 'entity-lookup':
            return t.REFONE_SELECT;
        case 'list-view':
            return t.LIST;
        case 'value-set-multi-checkbox':
            return t.VALUESET_MULTICHECKBOX;
        case 'value-set-radiobox':
            return t.VALUESET_CHECKBOX;
        case 'calendar-event':
            return t.BIGCALENDAR;
        case 'currency':
            return t.CURRENCY;
        case 'event':
            return t.EVENT;
        case 'entity-multi-select-chip':
            return t.REFMANYMANY_CHIP;
        case 'entity-typeahead':
            return t.ENTITY_TYPEAHEAD;
        case 'multiple-entity-typeahead':
            return t.MULTIPLE_ENTITY_TYPEAHEAD;
        case 'dmsdoclink':
            return t.DMS_DOCUMENT;
        case 'valueset-suggest':
            return t.VALUESET_SUGGEST;
        case 'people':
        case 'functional-group':
        default:
            console.error(`unimplemented flowable form type ${type} in getTypeFromFlowableField.js`);
            return t.TEXT;
    }
};
