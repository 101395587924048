import React, { useContext, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, CardActions } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import CreateButton from 'components/generics/button/CreateButton';
import { allowsCreate, allowsEdit, areActionsHidden } from '../utils/viewConfigUtils/index';
import { customEntityListActions } from '../overrides';
import { RootState, useAppSelector } from '../../../reducers/rootReducer';
import { createSelector } from 'reselect';
import { useDispatch } from 'react-redux';
import { fromPredicate, fromEither } from 'fp-ts/lib/Option';
import ViewConfig from 'reducers/ViewConfigType';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';
import useViewConfig from 'util/hooks/useViewConfig';
import { expressionTesterOpenContext } from 'expression-tester/hooks/useExpressionTesterOpen';
import EditViewInPlaceSwitch from 'layout-editor/editViewInPlaceContext/Switch';
import useMaybeGetSearchFromFilter from './hooks/useMaybeGetSearchFromFilter';
import MappingCreateButton, { getMapSearchToCreateExpression } from './MappingCreate';
import { push } from 'connected-react-router';
import getFormId from './filter/getFormId';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block' as 'inline-block',
    float: 'right' as 'right',
    padding: 0,
};

interface PermissionedListActionsProps {
    showCreate?: boolean;
    accessLevel: number;
    resource?: string;
    displayedFilters?: {};
    filterValues?: {};
    basePath?: string;
    showFilter?: boolean | Function;
    refresh?: Function;
    exportList?: () => void;
    createRedirectQueryString?: string;
    viewConfig?: RootState['viewConfig'];
    listViewName?: string;
    roles: string[];
    formId?: string;
}
const getHideCreateSelector = <T extends { viewName: string; viewConfig?: ViewConfig }>() => {
    const hideCreateSelector = createSelector(
        (state: RootState, props: T) => props.viewConfig || state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        getHideCreate,
    );
    return hideCreateSelector;
};
const getShowExportForNonSuperSelector = <T extends { viewName: string; viewConfig?: ViewConfig }>() => {
    const showExportForNonSuperSelector = createSelector(
        (state: RootState, props: T) => props.viewConfig || state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        getShowExportForNonSuper,
    );
    return showExportForNonSuperSelector;
};
export const useHideCreateButton = (viewName: string) => {
    const viewConfig = useViewConfig();
    const hideCreateSelector = useMemo(getHideCreateSelector, []);
    const hideCreate = useAppSelector((state: RootState) => hideCreateSelector(state, { viewName, viewConfig }));
    const res = useSingleKeyCachingExpression(hideCreate, undefined, false);
    return res;
};
export const useShowExportButtonForNonSuper = (viewName: string) => {
    const viewConfig = useViewConfig();
    const showExportForNonSuperSelector = useMemo(getShowExportForNonSuperSelector, []);
    const showExportForNonSuper = useAppSelector((state: RootState) =>
        showExportForNonSuperSelector(state, { viewName, viewConfig }),
    );
    const res = useSingleKeyCachingExpression(showExportForNonSuper, undefined, false);
    return res;
};

const PermissionedListActionsComponent: React.SFC<PermissionedListActionsProps> = ({
    resource,
    displayedFilters,
    filterValues,
    basePath: _basePath,
    showFilter,
    showCreate,
    refresh,
    exportList,
    accessLevel,
    createRedirectQueryString,
    viewConfig,
    listViewName,
    roles,
    formId,
}) => {
    const dispatch = useDispatch();
    const hideCreateResult = useHideCreateButton(listViewName);
    const showExportForNonSuper = useShowExportButtonForNonSuper(listViewName);

    const customActions = resource && customEntityListActions[resource];
    const thereIsAPopover = useAppSelector((state: RootState) => state.viewStack.length > 1);

    const currentUserHasEditPermissionOnViewDefs = (() => {
        const accessLevel = viewConfig.entities['ViewDef']?.accessLevel;
        return accessLevel && allowsEdit(accessLevel);
    })();
    const mode = useContext(expressionTesterOpenContext);
    const EditInPlaceSwitch =
        !thereIsAPopover && currentUserHasEditPermissionOnViewDefs && mode !== 'OPEN_EXPRESSIONPANEL' ? (
            // Hide this in view editing wizard, since it's hardcoded open.
            <div style={{ float: 'right' }}>
                <EditViewInPlaceSwitch />
            </div>
        ) : null;

    const _search = useMaybeGetSearchFromFilter(listViewName, filterValues);
    // slice off leading ? and replace with & to combine search strings

    const search =
        createRedirectQueryString && _search
            ? createRedirectQueryString + '&' + _search.slice(1)
            : createRedirectQueryString ?? _search;
    const mapSearchToCreateExpression = getMapSearchToCreateExpression(viewConfig, listViewName);
    const showCreateButtons = showCreate && !hideCreateResult && allowsCreate(accessLevel);

    const successfullySearchedRef = useRef(false);
    const formState = useAppSelector((state: RootState) => state.form[getFormId(resource, formId) || 'filterForm']);

    if (formState?.submitSucceeded) {
        successfullySearchedRef.current = true;
    }
    const listViewConfig = viewConfig.views[listViewName]?.config;
    const hideCreateButtonUntilSearch = useMemo(() => {
        if (!listViewConfig?.trim()) {
            return false;
        }
        try {
            const config = JSON.parse(listViewConfig);
            return !!config['preventCreateUntilSearch'];
        } catch (error) {
            console.error(
                `Error parsing 'config' in view "${listViewName}" as JSON. Config: ${JSON.stringify(listViewConfig)}`,
                error,
            );
            return null;
        }
    }, [listViewConfig, listViewName]);

    return customActions ? (
        <CardActions style={cardActionStyle}>
            {EditInPlaceSwitch}
            {customActions.map((action, i) => (
                <Link to={action.url} key={`custom-link-${action.key}`}>
                    <Button color="primary">{action.label}</Button>
                </Link>
            ))}
        </CardActions>
    ) : (
        <CardActions style={cardActionStyle}>
            {EditInPlaceSwitch}
            {(() => {
                if (!showCreateButtons) {
                    return null;
                }
                if (mapSearchToCreateExpression) {
                    return (
                        <MappingCreateButton
                            onResult={(result) => {
                                // dispatch to (combine result with existing search string)
                                const querystring = search ? search + '&' + result : '?' + result;
                                dispatch(
                                    push(
                                        viewConfig.views[viewConfig.entities[resource].defaultViews.CREATE.name].route +
                                            querystring,
                                    ),
                                );
                            }}
                            entityType={resource}
                            searchExpression={mapSearchToCreateExpression}
                            formId={formId}
                        >
                            {({ onClick }) => (
                                <Button onClick={onClick}>
                                    Create&nbsp;
                                    <Add />
                                </Button>
                            )}
                        </MappingCreateButton>
                    );
                }
                if (search) {
                    return (
                        <Button
                            style={{ overflow: 'inherit' }}
                            color="primary"
                            component={(props) => (
                                <Link
                                    to={`${
                                        viewConfig.views[viewConfig.entities[resource].defaultViews.CREATE.name].route
                                    }${search}`}
                                    {...props}
                                />
                            )}
                        >
                            Create&nbsp;
                            <Add />
                        </Button>
                    );
                }
                return (
                    (!hideCreateButtonUntilSearch || successfullySearchedRef.current) && (
                        <CreateButton resource={resource} />
                    )
                );
            })()}
            {allowsCreate(accessLevel) && roles.includes('ROLE_SUPER') && (
                <Link to={`/import/${resource}`}>
                    <Button color="primary">Import</Button>
                </Link>
            )}
            {!areActionsHidden(listViewName, viewConfig) && (roles.includes('ROLE_SUPER') || showExportForNonSuper) && (
                <Button color="primary" onClick={exportList}>
                    Export
                </Button>
            )}
            {/* <FlatButton primary label="refresh" onClick={refresh} icon={<NavigationRefresh />} /> */}
            {/* Add your custom actions */}
            {/* <FlatButton primary label="Custom Action" onClick={customAction} /> */}
        </CardActions>
    );
};

PermissionedListActionsComponent.defaultProps = {
    showCreate: true,
    showFilter: true,
    basePath: '',
    filterValues: {},
    displayedFilters: {},
};
const getViewConf = (viewConfig: ViewConfig, viewName?: string) =>
    fromPredicate<string>(Boolean)(viewName && viewConfig?.views?.[viewName]?.config).chain((c) =>
        fromEither(
            parseConfig(c).mapLeft((e) => {
                console.error(e);
                return e;
            }),
        ),
    );
export const getHideCreate = (viewConfig: ViewConfig, listViewName?: string): string | boolean => {
    return getViewConf(viewConfig, listViewName)
        .mapNullable((pc: EntityViewConfig) => pc.hideCreateButton)
        .getOrElse(false);
};
export const getShowExportForNonSuper = (viewConfig: ViewConfig, listViewName?: string): string | boolean => {
    return getViewConf(viewConfig, listViewName)
        .mapNullable((pc: EntityViewConfig) => pc.showExportForNonSuper)
        .getOrElse(false);
};

const PermissionedListActions: React.ComponentType<PermissionedListActionsProps> = PermissionedListActionsComponent;

export default PermissionedListActions;
