import { EntityAddressVerification2Field } from 'fieldFactory/translation/types';
import { Address2Configuration, Address2Props } from './types';
import { simpleGetConfProperty } from 'fieldFactory/input/fieldUtils';

const getAddressWidgetProps = (
    fieldDefinition: EntityAddressVerification2Field,
): Pick<Address2Props, 'source' | 'addressExpressions' | 'writeOnlyFields' | 'isAddressVerificationField'> => {
    const line1 = simpleGetConfProperty<Address2Configuration>('line1', null)(fieldDefinition);
    const line2 = simpleGetConfProperty<Address2Configuration>('line2', null)(fieldDefinition);
    const city = simpleGetConfProperty<Address2Configuration>('city', null)(fieldDefinition);
    const state = simpleGetConfProperty<Address2Configuration>('state', null)(fieldDefinition);
    const county = simpleGetConfProperty<Address2Configuration>('county', null)(fieldDefinition);
    const zip = simpleGetConfProperty<Address2Configuration>('zip', null)(fieldDefinition);

    const addressExpressions = {
        line1,
        line2,
        city,
        state,
        county,
        zip,
    };

    const longitude = simpleGetConfProperty<Address2Configuration>('longitude', null)(fieldDefinition);
    const latitude = simpleGetConfProperty<Address2Configuration>('latitude', null)(fieldDefinition);
    const censusBlock = simpleGetConfProperty<Address2Configuration>('censusBlock', null)(fieldDefinition);
    const censusKey = simpleGetConfProperty<Address2Configuration>('censusKey', null)(fieldDefinition);
    const censusTract = simpleGetConfProperty<Address2Configuration>('censusTract', null)(fieldDefinition);
    const melissaAddressKey = simpleGetConfProperty<Address2Configuration>('melissaAddressKey', null)(fieldDefinition);
    const addressValidationDate = simpleGetConfProperty<Address2Configuration>(
        'addressValidationDate',
        null,
    )(fieldDefinition);
    const zipPlus = simpleGetConfProperty<Address2Configuration>('zipPlus', null)(fieldDefinition);
    const resultCodes = simpleGetConfProperty<Address2Configuration>('resultCodes', null)(fieldDefinition);

    const writeOnlyFields = {
        longitude,
        latitude,
        censusBlock,
        censusKey,
        censusTract,
        melissaAddressKey,
        addressValidationDate,
        resultCodes,
        zipPlus,
    };
    return {
        source: fieldDefinition.name + 'Code',
        addressExpressions,
        writeOnlyFields,
        isAddressVerificationField: true,
    };
};

export default getAddressWidgetProps;
