import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import useService from 'util/hooks/useService';
import { CasetivityHandledForm, services } from 'sideEffect/services';
import { Publish } from '@material-ui/icons';
import { TaskForm } from 'reducers/taskFormType';

const getJSONFromFile = (file: File): Promise<TaskForm> => {
    return new Promise((resolve, reject) => {
        const success = (content) => {
            const taskForm = JSON.parse(content) as TaskForm;
            resolve(taskForm);
        };
        const fileReader = new FileReader();
        fileReader.onload = (evt: ProgressEvent & { target: { result?: ArrayBuffer | string } | null }) => {
            if (evt && evt.target && evt.target.result) {
                success(evt.target.result);
            }
        };
        fileReader.onerror = reject;
        fileReader.readAsText(file);
    });
};

const ImportFormButton: React.FC<{}> = (props) => {
    const hiddenInputRef = useRef<HTMLInputElement>();
    const [importState, importFile, { StateIcon: ImportStateIcon }] = useService(services.taskFormsDefinitions.update);
    const [dialogOpen, setDialogOpen] = useState<
        | {
              open: true;
              resolve: (saveAsNewVersion: boolean | -1) => void; // -1 means cancel
          }
        | {
              open: false;
          }
    >({
        open: false,
    });

    const cancelLastRequestRef = useRef<() => void>();
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        cancelLastRequestRef.current?.();
        const file = event.target.files?.[0];
        event.target.value = ''; // to allow re-uploading the same file.
        if (!file) {
            return;
        }
        const taskForm = await getJSONFromFile(file);

        // now, let's check if the key exists, to decide if we need to display a dialog deciding to upload as a new version, or replacing current version.
        const exists = !taskForm.key
            ? false
            : await new Promise((resolve, reject) => {
                  const subscription = services.taskFormsDefinitions.get(taskForm.key).subscribe(
                      (next) => {
                          resolve(true);
                      },
                      (error) => {
                          resolve(false);
                      },
                  );
                  cancelLastRequestRef.current = () => {
                      if (!subscription.closed) {
                          subscription.unsubscribe();
                      }
                  };
              });

        let saveAsNewVersion: boolean | -1 = !exists;
        if (exists) {
            // open dialog with choice 'save as new version' or 'replace'
            saveAsNewVersion = await new Promise<boolean | -1>((resolve, reject) => {
                setDialogOpen({
                    open: true,
                    resolve: (value) => {
                        setDialogOpen({ open: false });
                        resolve(value);
                    },
                });
            });
        }
        if (saveAsNewVersion === -1) {
            return;
        }
        const { key, name, fields, outcomes } = taskForm;
        const formDefinition: CasetivityHandledForm = {
            key,
            name,
            formRepresentation: {
                fields,
                outcomes,
            },
        };
        cancelLastRequestRef.current = importFile(formDefinition, saveAsNewVersion);
    };
    useEffect(() => {
        return cancelLastRequestRef.current;
    }, []);

    const HiddenInput = <input type="file" hidden multiple={false} onChange={handleFileChange} ref={hiddenInputRef} />;
    return (
        <div>
            {HiddenInput}
            <Button
                disabled={importState.status === 'pending'}
                endIcon={ImportStateIcon ?? <Publish />}
                onClick={() => {
                    hiddenInputRef.current?.click();
                }}
            >
                Import Definition
            </Button>
            {dialogOpen.open && (
                <Dialog open>
                    <DialogTitle>Save as new version?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You may replace the current version, or save as a new one.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button variant="contained" onClick={() => dialogOpen.resolve(-1)}>
                            Cancel
                        </Button>
                        <div style={{ marginLeft: '2em' }}>
                            <Button variant="contained" color="primary" onClick={() => dialogOpen.resolve(false)}>
                                Replace current version
                            </Button>
                            <span style={{ marginLeft: '1em' }}>
                                <Button variant="contained" color="primary" onClick={() => dialogOpen.resolve(true)}>
                                    Create as new version
                                </Button>
                            </span>
                        </div>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};
export default ImportFormButton;
