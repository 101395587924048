import React, { useCallback, useEffect, useMemo } from 'react';
import { RootState, useAppStore } from 'reducers/rootReducer';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import useViewConfig from 'util/hooks/useViewConfig';
import { diff } from 'jsondiffpatch';
import { push } from 'connected-react-router';
import FormDisplayStatus from 'remoteStatus/one/components/implementations/FormDisplayStatus';
// TODO: see how this differs between edit and show, and possibly extract.
import { useMinimalRecord } from '../genericEdit/useMinimalRecord';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/processPageRefreshContext';

interface SimpleDynamicViewProps {
    id: string;
    entityType: string;
    viewType: 'edit' | 'show';
    render: (viewName?: string) => JSX.Element;
    noRedirectOnIdChange?: boolean;
}
/**
 * We make a single fetch to ensure that casetivityShowView and casetivityEditView are loaded -
 * then when we check store when rendering success, to render the Show view
 */
const SimpleDynamicView: React.FC<SimpleDynamicViewProps> = ({
    entityType,
    id,
    viewType,
    render,
    noRedirectOnIdChange,
}) => {
    const officialViewConfig = useViewConfig(false);
    const { refresh: refreshProcessPage } = React.useContext(processPageRefreshContext);
    const viewConfig = useViewConfig();
    const store = useAppStore();
    const { dataLoaded } = useMinimalRecord(entityType, id);
    const fetch = useCallback(() => {
        store.dispatch(
            crudGetOneAction({
                resource: entityType,
                id,
                view: -1,
                override:
                    officialViewConfig !== viewConfig
                        ? {
                              patchViewConfig: diff(officialViewConfig, viewConfig),
                          }
                        : undefined,
                cb: (responseId, responseData) => {
                    if (`${id}` !== `${responseId}`) {
                        if (noRedirectOnIdChange) {
                            if (refreshProcessPage) {
                                refreshProcessPage?.();
                            }
                            return;
                        }
                        // redirect if id is different (due to merge)
                        store.dispatch(push(`/${entityType}/${responseId}/show`));
                    }
                },
            }),
        );
    }, [entityType, id, store, viewConfig, officialViewConfig, noRedirectOnIdChange, refreshProcessPage]);
    useEffect(() => {
        fetch();
    }, [fetch]);

    const defaultViews = useMemo(() => {
        const dv = viewConfig.entities[entityType]?.defaultViews;
        return {
            edit: dv?.EDIT?.name,
            show: dv?.SHOW?.name,
        };
    }, [viewConfig, entityType]);

    const isValidViewName = useCallback(
        (viewName: string) => {
            const res = Boolean(viewConfig.views[viewName]);
            if (!res) {
                console.warn(
                    `Dynamic viewname "${viewName}" does not exist in the viewconfig. Falling back to default view`,
                );
            }
            return res;
        },
        [viewConfig],
    );

    const renderSuccess = useCallback(() => {
        const { casetivityEditView, casetivityShowView } = ((store.getState() as RootState).admin.entities[
            entityType
        ]?.[id] ?? {}) as { casetivityEditView?: string; casetivityShowView?: string };
        if (viewType === 'show') {
            return render(
                casetivityShowView && isValidViewName(casetivityShowView) ? casetivityShowView : defaultViews?.show,
            );
        }
        return render(
            casetivityEditView && isValidViewName(casetivityEditView) ? casetivityEditView : defaultViews?.edit,
        );
    }, [viewType, render, entityType, id, store, defaultViews, isValidViewName]);
    return (
        <FormDisplayStatus
            id={id}
            resource={entityType}
            showSuccessOffline={dataLoaded}
            renderSuccess={renderSuccess}
            refresh={fetch}
        />
    );
};

export default SimpleDynamicView;
