import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
            overflow: 'inherit',
            borderColor: theme.palette.error.dark,
        },
    }),
);
